import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Uri } from '@app/shared/utils/uri';
import { stringFormat } from '@brycemarshall/string-format';
import { PaymentPlanMinimumEventDate, PaymentPlanSummaryData } from '@clux-models';
import { cacheable, withTransaction } from '@datorama/akita';
import { Model } from '@legacymodels';
import { Criteria, ElectingParty } from '@models/configuration/model';
import { EligibilityEvent } from '@models/profileConfiguration/model';
import { forkJoin, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { BasePlanType, CoreService } from 'src/app/shared/models/clux/enum';
import { ParentType, PaymentPlan } from 'src/app/shared/models/uba/configuration/model';
import {
  ChainType,
  MatchType,
} from 'src/app/shared/models/uba/profile/model';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { ContactQuery, PaymentPlanQuery, PaymentPlanStore } from 'src/app/state';

@Injectable({
  providedIn: 'root',
})
export class PaymentPlanService {
  public constructor(
    private readonly serviceFactory: ServiceFactory,
    private readonly paymentPlanStore: PaymentPlanStore,
    private readonly paymentPlanQuery: PaymentPlanQuery,
    private readonly contactQuery: ContactQuery,
    private readonly http: HttpClient,
  ) { }

  public static getEventDuration(
    eligibilityEvent: EligibilityEvent,
    paymentPlan: PaymentPlan,
  ): number {
    const matchingPlanEvent = paymentPlan.eligibilityEvents.selections.find((ppEE) => ppEE.name === eligibilityEvent.eventType);
    if (matchingPlanEvent) {
      return matchingPlanEvent.duration;
    }
  }

  public hasPaymentPlan(): Observable<boolean> {
    return this.queryPaymentPlans().pipe(
      first(),
      map((paymentPlans) => paymentPlans.length > 0),
    );
  }

  public hasPaymentPlanWithGet(): Observable<boolean> {
    return this.getPaymentPlans().pipe(
      first(),
      map((paymentPlans) => paymentPlans.length > 0),
    );
  }

  public loadPaymentPlans(customSearchCriteria?: Criteria, cacheLoad: boolean = false): Observable<null> {
    const clientId = this.contactQuery.getActive().clientId;
    const getPath: Model.ConfigurationPath = CommonConstants.getPath.configuration;
    const requestUrl: string = stringFormat(getPath.paymentPlans, {
      profileId: this.contactQuery.getActiveId(),
    });

    const requestPayload: Model.SearchCriteria = [{
      key: 'parentId',
      value: clientId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: ParentType.CLIENT,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }];

    if (customSearchCriteria) {
      requestPayload.push(customSearchCriteria);
    }

    const request$ = this.serviceFactory.search<PaymentPlan[]>(getPath.serviceKey, requestUrl, requestPayload)
      .pipe(
        withTransaction((paymentPlans) => this.paymentPlanStore.set(paymentPlans.data)),
        map(() => null),
      );

    if (cacheLoad) {
      return cacheable(this.paymentPlanStore, request$, { emitNext: true })
        .pipe(
          mapTo(null),
        );
    }

    return request$;
  }

  public queryPaymentPlans(): Observable<PaymentPlan[]> {
    return this.paymentPlanQuery.selectAllWhenLoaded();
  }

  public getPaymentPlans(customSearchCriteria?: Criteria): Observable<PaymentPlan[]> {
    return this.loadPaymentPlans(customSearchCriteria).pipe(
      switchMap(() => this.queryPaymentPlans()),
    );
  }

  public queryPaymentPlanEntity(externalId: string): Observable<PaymentPlan> {
    return this.paymentPlanQuery.selectEntityWhenLoaded(externalId);
  }

  public getPaymentPlanEntity$(externalId: string, customSearchCriteria?: Criteria): Observable<PaymentPlan> {
    return this.loadPaymentPlans(customSearchCriteria).pipe(
      switchMap(() => this.queryPaymentPlanEntity(externalId)),
    );
  }

  public getPaymentPlanSummaryData(): Observable<PaymentPlanSummaryData[]> {
    return this.getPaymentPlans().pipe(
      map((res) => res.map((p) => {
        return this.buildPaymentPlanSummaryDataFromPaymentPlan(p);
      })),
    );
  }

  public getMinimumQualifiedEventDates(individualId: string, paymentPlans: PaymentPlan[]): Observable<PaymentPlanMinimumEventDate[]> {
    return forkJoin(
        paymentPlans.map<Observable<PaymentPlanMinimumEventDate>>((plan) => {
            const url = new Uri(`/profile/${individualId}/configuration/paymentPlan/${plan.id}/minimumEventDate`, CoreService.Configuration);
            return this.http.get<string>(url.toString()).pipe(
                map<string, PaymentPlanMinimumEventDate>((eventDate) => ({
                    paymentPlanId: plan.id,
                    minimumQualifiedEventDate: eventDate,
                    eventTypes: plan.eligibilityEvents,
                })),
            );
        }),
    );
}

  public buildPaymentPlanSummaryDataFromPaymentPlan(paymentPlan: PaymentPlan): PaymentPlanSummaryData {
    return {
      ...paymentPlan,
      menu: false,
      basePlanType: BasePlanType.Payment,
    };
  }

  public getAllPaymentPlan(): PaymentPlan[] {
    return this.paymentPlanQuery.getAll();
  }

  public canElect(paymentPlan: PaymentPlan): boolean {
    return paymentPlan.electingParty !== ElectingParty.Participant;
  }

  public canElectPlanId(planId: string): Observable<boolean> {
    return this.paymentPlanQuery.selectPlanById(planId).pipe(
      map((paymentPlan) => this.canElect(paymentPlan)),
    );
  }
}
