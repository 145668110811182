export interface AuthenticationChallenge {
  challengeType: AuthenticationChallengeType;
  identityProvider?: string;
  additionalInfo?: string;
  // tslint:disable-next-line: no-any
  idpOverrides?: any;
  error?: Error;
}

export enum AuthenticationChallengeType {
  Failed = 'Failed',
  Password = 'Password',
  SAML = 'SAML',
  Unknown = 'Unknown',
}
