
<div class="distributor-container container-fluid {{brand.brandCssClass}}">
  <main class="row">
    <div class="distributor-form col-5" *ngIf="{ contacts: contacts$ | async, allClients: allClients$ | async, clients: filteredClients$ | async } as model">
        <div class="tasc-logo">
            <img [alt]="brand.companyName" src="assets/img/{{brand.logoFileName}}" />
        </div>

        <div class="distributor-content">

        <h2 data-cy="welcome-contact"> WELCOME {{contactName}}</h2>

        <p data-cy="number-of-clients-message">You support {{numberOfClients}} employers (clients). Please select from the list below to continue. </p>

        <div data-cy="clients-dropdown" class="distributor-select">
              <mat-form-field>
                  <mat-label>Search employers (clients)</mat-label>
                  <mat-select data-cy="client-select" class="client-select" [disabled]="loading" [formControl]="selectedClientCtrl" tabindex="0" role="button" (keyup.enter)="$event.target.click()" #singleSelect>
                      <div class="option-wrapper">
                          <mat-option>
                              <ngx-mat-select-search data-cy="clients-search" [formControl]="clientFilterCtrl" placeholderLabel="Search employers (clients)"
                                  i18n-placeholderLabel i18n-noEntriesFoundLabel>
                              </ngx-mat-select-search>
                          </mat-option>
                          <mat-option data-cy="client-name" class="client-name" *ngFor="let client of model.clients" [value]="client">
                              {{client.clientName}}
                          </mat-option>
                          <mat-option *ngIf="model.clients?.length === 0" [disabled]="true">
                            No results for "{{clientFilterCtrl.value}}."
                          </mat-option>
                      </div>
                  </mat-select>
              </mat-form-field>
        </div>

        <button data-cy='continue-button' id="signInBtn" mat-flat-button [disabled]="!selectedClientCtrl.valid || loading" (click)="onClientSelect()"
          class="mat-primary btn-continue">Continue
          <mat-spinner *ngIf="loading" class="small-loader" diameter="20" strokeWidth="2"></mat-spinner>
        </button>

    </div>
    </div>
    <div class="col-7 welcome-section">
      <h1>WELCOME <br />TO {{brand.companyName}}</h1>
    </div>
  </main>

  </div>
