import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BenefitPlanFundingSourceAndSchedule, BenefitPlanState, FundingPostingType } from '@models/configuration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BenefitPlanQuery } from '../benefit-plan/benefit-plan.query';
import {
  BenefitPlanFundingSourceAndScheduleState,
  BenefitPlanFundingSourceAndScheduleStore,
} from './benefit-plan-funding-source-and-schedule.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitPlanFundingSourceAndScheduleQuery extends QueryEntity<BenefitPlanFundingSourceAndScheduleState> {
  public constructor(
    protected store: BenefitPlanFundingSourceAndScheduleStore,
    private benefitPlanQuery: BenefitPlanQuery,
  ) {
    super(store);
  }

  public canViewPODReports(): Observable<boolean> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (entity) => entity.fundingPosting === FundingPostingType.PointOfDisbursement,
        })),
        switchMap((entities) => {
          const benefitPlanIds = entities.map((entity) => entity.parentId);
          return this.benefitPlanQuery.selectManyWhenLoaded(benefitPlanIds);
        }),
        map((entities) => entities.some((entity) => entity.currentState !== BenefitPlanState.Finalized)),
      );
  }

  public selectAllWhenLoaded(): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByBenefitPlan(benefitPlanId: string): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (entity) => entity.parentId === benefitPlanId,
        })),
      );
  }
}
