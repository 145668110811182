import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Individual } from '@models/profile/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IndividualState, IndividualStore } from './individual.store';

@Injectable({
  providedIn: 'root',
})

export class IndividualQuery extends QueryEntity<IndividualState> {
  public selectActiveWhenLoaded(): Observable<Individual> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public selectAllWhenLoaded(): Observable<Individual[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public getActiveId(): string {
    return super.getActiveId() as string;
  }

  public constructor(protected store: IndividualStore) {
    super(store);
  }

}
