import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { ChainType, MatchType, ParentType, SearchCriteria } from '@models/configuration/model';
import { Division, Payroll } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DivisionStore, PayrollStore } from 'src/app/state';

import { CoreService, OrderDirection } from '../models/clux/enum';
import { SearchQuery } from '../models/clux/model';
import { Uri } from '../utils/uri';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public constructor(
    private divisionStore: DivisionStore,
    private http: HttpClient,
    private payrollStore: PayrollStore,
  ) {}

  /**
   * Retrieve the divisions and store in Akita. This function takes no action if the data already exists in Akita.
   * @param clientId: The ID of the client
   */
  public loadDivisions(clientId: string): Observable<void> {
    const criteria: SearchCriteria = [{
      key: 'parentId',
      value: clientId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: ParentType.CLIENT,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }];
    const query: SearchQuery = {
      orderBy: 'name',
      orderDirection: OrderDirection.ASC,
    };
    const uri = new Uri(`/profile/${clientId}/configuration/division/search`, CoreService.ProfileConfiguration, query);
    const request$ = this.http.post<Division[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((divisions) => this.divisionStore.set(divisions)),
      );

    return cacheable(this.divisionStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  /**
   * Retrieve the divisions and store in Akita. This function takes no action if the data already exists in Akita.
   * @param clientId: The ID of the client
   */
  public loadPayroll(clientId: string): Observable<void> {
    const criteria: SearchCriteria = [{
      key: 'parentId',
      value: clientId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: ParentType.CLIENT,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }];
    const uri = new Uri(`/profile/${clientId}/configuration/payroll/search`, CoreService.ProfileConfiguration);
    const request$ = this.http.post<Payroll[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((payroll) => this.payrollStore.set(payroll)),
      );

    return cacheable(this.payrollStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }
}
