import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BrandId } from 'src/app/shared/models/clux/enum';
import { CommonConstants } from '../constants/common.constant';
import { BrandService } from '../services/brand.service';
@Injectable({
  providedIn: 'root',
})
export class BrandGuard implements CanActivate {
  private readonly WHITELISTED_URLS = [
    `/${CommonConstants.appRoutes.netSuiteSSORedirect}`,
  ];

  public constructor(
    private brandService: BrandService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.brandService.getBrand() === BrandId.Unknown && !this.WHITELISTED_URLS.includes(state.url.replace(/\?.+/, ''))) {
      return this.router.parseUrl('/no-brand');
    }
    return true;
  }
}
