import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { ClientByContact, MatchType, SearchCriteria, SupportRequestStatus } from '@models/dashboard/model';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { ClientByContactStore, CurrentUserQuery } from 'src/app/state';
import { CommonConstants } from '../constants/common.constant';
import { CoreService } from '../models/clux/enum';
import { Uri } from '../utils/uri';
import { UtilityService } from './utility.service';
import { BrandService } from './brand.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public constructor(
    private clientByContactStore: ClientByContactStore,
    private currentUserQuery: CurrentUserQuery,
    private http: HttpClient,
    private readonly brandService: BrandService,
    private readonly utilityService: UtilityService,
  ) {}

  /**
   * Retrieve basic client info associated with the specified contact email and store in Akita.
   * This function takes no action if the data already exists in Akita.
   */
  public loadClientsByContact(): Observable<void> {
    const email = this.currentUserQuery.getEmailAddress();
    const tpaPartner = this.brandService.getTpaPartner();

    if (!email) {
      throw new Error('contact email not found');
    }

    if (!tpaPartner) {
      throw new Error('could not determine tpa');
    }

    const criteria: SearchCriteria = [
      {
        key: 'contactEmail',
        value: email,
        matchType: MatchType.EXACT,
      },
      {
        key: 'tpaPartner',
        value: tpaPartner,
        matchType: MatchType.EXACT,
      },
    ];

    const uri = new Uri('/profile/*/clientsByContact/search', CoreService.Dashboard);
    const request$ = this.http.post<ClientByContact[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((contacts) => this.clientByContactStore.set(contacts)),
      );

    return cacheable(this.clientByContactStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  /**
   * Dashboard query to get the disableSupportRequest value from the Instance table to determine if support request creation functionality is available
   * @returns An observable emitting the boolean value of disableSupportRequest from the Instance
   */
  public getIsSupportRequestDisabled(): Observable<boolean> {
    const query = {
      take: 1,
      orderDirection: 'desc',
      orderBy: 'updated',
    };

    const url = new Uri(
      `/profile/*/supportRequestStatus/search`,
      CoreService.Dashboard,
      query,
    );
    return this.http
      .post<SupportRequestStatus[]>(url.toString(), [])
      .pipe(
        catchError((err) => {
          this.utilityService.showGrowelMessage(
            CommonConstants.errorCodes.ErrorFromApi,
            false,
            false,
          );
          return throwError(err);
        }),
        map((res) => res[0].disableSupportRequest),
      );
  }
}
