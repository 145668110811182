import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PaymentPlan } from '@models/configuration/model';

export interface PaymentPlanStoreState extends EntityState<PaymentPlan, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'PaymentPlan', idKey: 'externalId'})
export class PaymentPlanStore extends EntityStore<PaymentPlanStoreState, PaymentPlan> {
  public constructor() {
    super();
  }
}
