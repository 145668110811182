import { Routes } from '@angular/router';

import { AuthDistributorGuard } from '../default/auth/_guards/auth-distributor.guard';
import { AuthGuard } from '../default/auth/_guards/auth.guard';
import { DistributorComponent } from '../default/distributor/distributor.component';
import { AccessGuard } from '../shared/access/access.guard';
import { NoBrandComponent } from '../shared/components/no-brand/no-brand.component';
import { NoContentComponent } from '../shared/components/no-content/no-content.component';
import { CommonConstants } from '../shared/constants/common.constant';
import { BrandGuard } from '../shared/guards/brand.guard';
import { LayoutComponent } from '../shared/layout/layout.component';

export const ROUTES: Routes = [
  {
    path: CommonConstants.appRoutes.login,
    loadChildren: () => import('../default/auth/auth.module').then((module) => module.AuthModule),
    canActivate: [BrandGuard],
  },
  {
    path: '',
    loadChildren: () => import('../default/auth/auth.module').then((module) => module.AuthModule),
    canActivate: [BrandGuard],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: CommonConstants.appRoutes.settings.settingsBase,
        loadChildren: () => import('../default/settings/settings.module').then((module) => module.SettingsModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.enroll.enrollBase,
        loadChildren: () => import('../default/employees/employees.module').then((module) => module.EmployeesModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.transactions.transactionBase,
        loadChildren: () => import('../default/transactions/transactions.module').then((module) => module.TransactionsModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.files.filesBase,
        loadChildren: () => import('../default/files/files.module').then((module) => module.FilesModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.plan.planBase,
        loadChildren: () => import('../default/benefit/plans.module').then((module) => module.PlansModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.support.supportBase,
        loadChildren: () => import('../default/support/support.module').then((module) => module.SupportModule),
        canActivate: [AuthGuard],
      },
      {
        path: CommonConstants.appRoutes.overview.overviewBase,
        loadChildren: () => import('../default/overview/overview.module').then((module) => module.OverviewModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.reports.reportsBase,
        loadChildren: () => import('../default/reports/reports.module').then((module) => module.ReportsModule),
        canActivate: [AuthGuard, AccessGuard],
      },
      {
        path: CommonConstants.appRoutes.sitemap.sitemapBase,
        loadChildren: () => import('../default/sitemap/sitemap.module').then((module) => module.SitemapModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: CommonConstants.appRoutes.distributor,
    component: DistributorComponent,
    data: { title: 'Distributor' },
    canActivate: [AuthDistributorGuard],
  },
  {
    path: CommonConstants.appRoutes.nobrand,
    component: NoBrandComponent,
  },
  {
    path: '**',
    component: NoContentComponent,
    canActivate: [AuthGuard, AccessGuard],
  },
];
