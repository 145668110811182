import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { ClientByContact } from '@models/dashboard/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ClientByContactState, ClientByContactStore } from './client-by-contact.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'clientName',
  sortByOrder: Order.ASC,
})
export class ClientByContactQuery extends QueryEntity<ClientByContactState> {
  public constructor(protected store: ClientByContactStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<ClientByContact[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectBySearchTerm(searchTerm: string): Observable<ClientByContact[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (clientByContact) => clientByContact.clientName.toLowerCase().indexOf(searchTerm) > -1,
        })),
      );
  }
}
