import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Model } from 'src/app/shared/models/clux/model-legacy';
declare let ga: (command: string, fields: string, fieldsObject?: string | Model.EventCategory) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  public constructor(private router: Router) {
    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        ga('set', 'page', e.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  public eventEmitter(eventCategory: Model.EventCategory): void {
    ga('send', 'event', eventCategory);
  }

}
