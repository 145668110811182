import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PayrollSchedule } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PayrollScheduleState, PayrollScheduleStore } from './payroll-schedule.store';

@Injectable({
  providedIn: 'root',
})
export class PayrollScheduleQuery extends QueryEntity<PayrollScheduleState> {
  public constructor(protected store: PayrollScheduleStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<PayrollSchedule[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByBenefitPlan(benefitPlanId: string): Observable<PayrollSchedule[]> {
    return this.selectAll({
      filterBy: (payrollSchedule) => payrollSchedule.parentId === benefitPlanId,
    });
  }

  public selectByPayrollAndBenefitPlan(payrollId: string, benefitPlanId: string): Observable<PayrollSchedule> {
    return this.selectLoading()
      .pipe(
          filter((isLoading) => !isLoading),
          switchMap(() => this.selectAll({
            filterBy: (payrollSchedule) => payrollSchedule.payrollId === payrollId && payrollSchedule.parentId === benefitPlanId,
          })),
          map((payrollSchedules) => {
            return payrollSchedules.length ? payrollSchedules[0] : null;
          }),
      );
  }
}
