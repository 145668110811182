import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthenticationChallengeType } from 'src/app/default/auth/models/authentication-challenge.model';
import { BrowserLocalStorageService } from 'src/app/shared/services/browser-local-storage.service';

import { CurrentUserState } from '../model';

function createInitialState(): CurrentUserState {
  return {
    cognitoPhoneNumber: null,
    email: null,
    rememberedEmail: null,
    accessToken: null,
    idToken: null,
    refreshToken: null,
    authenticationType: AuthenticationChallengeType.Unknown,
    verificationCode: null,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'CurrentUser' })
export class CurrentUserStore extends Store<CurrentUserState> {
  private static readonly rememberUserKey = 'remember';

  public constructor(private localStorageService: BrowserLocalStorageService) {
    super(createInitialState());
    this.setRememberedEmail();
  }

  public forgetUser(): void {
    this.localStorageService.remove(CurrentUserStore.rememberUserKey);
    this.update({
      rememberedEmail: null,
    });
  }

  public rememberUser(emailAddress: string): void {
    if (!emailAddress) {
      this.forgetUser();
      return;
    }

    this.localStorageService.set(CurrentUserStore.rememberUserKey, emailAddress);
    this.update({
      rememberedEmail: emailAddress,
    });
  }

  public reset(): void {
    super.reset();
    this.setRememberedEmail();
  }

  private setRememberedEmail(): void {
    const rememberedEmail = this.localStorageService.get<string>(CurrentUserStore.rememberUserKey) || null;

    this.update({
      rememberedEmail,
    });
  }
}
