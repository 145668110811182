export enum TransactionActivityEndpointSource {
  Account = 'Account',
  Contribution = 'Contribution',
  Expenditure = 'Expenditure',
  PendingEntry = 'PendingEntry',
  PendingRequest = 'PendingRequest',
  PendingTransfer = 'PendingTransfer',
}

export enum PageFilter {
  All = 'All',
  Contributions = 'Contributions',
  Expenditures = 'Expenditures',
  Buy = 'Buy',
  Sell = 'Sell',
  MyCash = 'MyCash',
  CardDeclineProtection = 'CardDeclineProtection',
}

export enum TransactionState {
  Applied = 'Applied',
  Completed = 'Completed',
  Declined = 'Declined',
  Draft = 'Draft',
  Paid = 'Paid',
  PartiallyPaid = 'Partially Paid',
  Pending = 'Pending',
  Posted = 'Posted',
  Rejected = 'Rejected',
  Repaid = 'Repaid',
  Returned = 'Returned',
  Reversed = 'Reversed',
  Submitted = 'Submitted',
  Unknown = 'Unknown',
  VerificationRequired = 'Verification Required',
}

export enum TransactionActivityType {
  Individual = 'Individual',
  Client = 'Client',
}

export enum TransactionActivityEndPointType {
  Account = 'Account',
  Contribution = 'Contribution',
  Expenditure = 'Expenditure',
  PendingEntry = 'PendingEntry',
  PendingRequest = 'PendingRequest',
  PendingTransfer = 'PendingTransfer',
  PendingTrade = 'PendingTrade',
  Trade = 'Trade',
}

export enum TransactionCategory {
  Contribution = 'Contribution',
  Expenditure = 'Expenditure',
}

export enum TransactionStateCategory {
  All,
  Applied,
  Draft,
  Pending,
  Posted,
}

export enum UniqueIdType {
  Entry,
  FundsTransfer,
  Posting,
  Request,
  RequestPayment,
}

export enum TransactionTypeLabel {
  ACH = 'ACH',
  ATM = 'ATM',
  Card = 'Card',
  Carryover = 'Carryover',
  ClaimConneXRequest = 'ClaimConneXRequest',
  CompanyCard = 'CompanyCard',
  Contribution = 'Contribution',
  ContributionReversal = 'ContributionReversal',
  Donation = 'Donation',
  Expenditure = 'Expenditure',
  InvestmentBuy = 'InvestmentBuy',
  InvestmentSell = 'InvestmentSell',
  Manual = 'Manual',
  Online = 'Online',
  PayTheProvider = 'PayTheProvider',
  PictureToPay = 'PictureToPay',
  Reimbursement = 'Reimbursement',
  ReimbursementRequest = 'ReimbursementRequest',
  Repayment = 'Repayment',
  Rollover = 'Rollover',
  Transfer = 'Transfer',
}
