import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { CurrentUserQuery } from 'src/app/state';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  public constructor(
    private authenticationService: AuthenticationService,
    private currentUserQuery: CurrentUserQuery,
    private raygunService: RaygunService,
    ) { }

  // tslint:disable-next-line:no-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthenticatedUrl = this.isAuthenticatedUrl(request.url);

    if (!isAuthenticatedUrl) {
      return next.handle(request);
    }

    return of(this.currentUserQuery.isJwtTokenExpired())
      .pipe(
        switchMap((isTokenExpired) => this.getValidSession(isTokenExpired)),
        map((isValidSession) => this.addTokenToRequest(isValidSession, request)),
        catchError((err) => {
          this.raygunService.logError('Error in jwt-interceptor.service.ts:intercept()', { error: err });
          return of({ request } as AddTokenToRequestResult);
        }),
        switchMap((result) => {
          if (result.requestWithAuthHeader) {
            return next.handle(result.requestWithAuthHeader);
          }
          return EMPTY;
        }),
      );
  }

  // tslint:disable-next-line:no-any
  private addTokenToRequest(isValidSession: boolean, request: HttpRequest<any>): AddTokenToRequestResult {
    if (!isValidSession) {
      return { request };
    }

    const jwtToken = this.currentUserQuery.getJwtToken();
    if (!jwtToken) {
      return { request };
    }

    const requestWithAuthHeader = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return {
      request,
      requestWithAuthHeader,
    };
  }

  private async getValidSession(isTokenExpired: boolean): Promise<boolean> {
    if (isTokenExpired) {
      const refreshSessionResult = await this.authenticationService.refreshSession();
      return refreshSessionResult.isValidSession;
    }
    return true;
  }

  private isAuthenticatedUrl(url: string): boolean {
    const anonymousUBAEndpoints = [
      '/profile/v1/profile/profileType/activeClientIdp/search',
      /\/security\/v1\/auth0\/code\/.+\/email$/,
      '/security/v1/netSuiteSSO/oauth2/authorize',
    ];

    return Object
      .keys(environment.services)
      .some((key) => url.includes(environment.services[key].endpoint))
      && !anonymousUBAEndpoints.some((anonymousEndpoint) => !!url.match(anonymousEndpoint));
  }
}

interface AddTokenToRequestResult {
  // tslint:disable-next-line:no-any
  request: HttpRequest<any>;
  // tslint:disable-next-line:no-any
  requestWithAuthHeader?: HttpRequest<any>;
}
