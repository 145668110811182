export interface SignUpUser {
  email: string;
  firstName: string;
  lastName: string;
  mobile?: string;
  templateView?: SignUpTemplateType;
  userData?: string;
}

export enum SignUpTemplateType {
  verifyEmail = 'verify-email',
  verifyMobile = 'verify-mobile',
  verify2FAMobile = 'verify-2FA-Mobile',
  userDetails = 'userDetails',
  fillform = 'fillform',
  enterMobile = 'enterMobile',
  successSignup = 'successSignup',
}
