import { BrandResource } from 'src/app/shared/models/clux/model';

export const tascResources: BrandResource = {
  brandCssClass: 'brand-tasc',
  cardLegalText: `This Mastercard is administered by TASC, a registered agent of Pathward®, N.A. Use of this card is authorized as set forth in your Cardholder Agreement. The TASC Card is issued by Pathward, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.`,
  companyCard: 'TASC card',
  companyFullName: 'Total Administrative Services Corporation',
  companyName: 'TASC',
  companyNameCopyright: 'Total Administrative Services Corporation (TASC)',
  contactHours: 'Monday - Friday, 8:00 a.m. - 5:00 p.m. <br>all time zones',
  documentationContentType: 'clientDocumentation',
  employerId: 'TASC ID',
  faviconFileName: 'favicon-tasc.ico',
  faqContentType: 'faqContentClux',
  individualId: 'TASC ID',
  logoFileName: 'logo-tasc.png',
  phoneNumber: '800-422-4661',
  productName: 'Universal Benefit Account',
  supportRequestPlaceholder: 'Enter Individual ID',
  supportRequestExternalId: 'Individual ID',
  supportRequestEmployerId: 'Employer TASC ID',
  termsOfUseFileName: 'terms-tasc.html',
};
