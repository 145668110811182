import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TextFieldComponent } from '@app/default/employees/add-employee/shared/individual-form/text/text-field.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  ForgotPasswordFederatedModalComponent,
} from 'src/app/default/auth/forget-password/forgot-password-federated-modal/forgot-password-federated-modal.component';
import {
  FederatedRedirectModalComponent,
} from 'src/app/default/auth/login/federated-redirect-modal/federated-redirect-modal.component';
import { JwtInterceptor } from 'src/app/default/auth/services/jwt-interceptor.service';
import {
  SignUpFederatedModalComponent,
} from 'src/app/default/auth/signup/create-password/sign-up-federated-modal/sign-up-federated-modal.component';
import { DateFieldComponent } from 'src/app/default/employees/add-employee/shared/individual-form/date/date-field.component';
import {
  SigninSecurityFederatedModalComponent,
} from 'src/app/default/settings/signin-security/signin-security-federated-modal/signin-security-federated-modal.component';

import { CheckboxTemplateComponent } from '../../shared/components/checkbox-template/checkbox-template.component';
import { FilterPopupComponent } from '../../shared/components/filter-popup/filter-popup.component';
import { FilterComponent } from '../../shared/components/filter/filter.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { TascGrowelComponent } from '../../shared/components/tasc-growel/tasc-growel.component';
import { AccessService } from '../access/access.service';
import { HasPermissionDirective } from '../access/has-permission.directive';
import { AccordionMessagesComponent } from '../components/accordion-messages/accordion-messages.component';
import { ActionsSnowmanMenuComponent } from '../components/actions-snowman-menu/actions-snowman-menu.component';
import { AddEnrollEmployeeComponent } from '../components/add-enroll-employee/add-enroll-employee.component';
import { CustomFileUploadComponent } from '../components/custom-file-upload/custom-file-upload.component';
import { DateInputComponent } from '../components/date-input/date-input.component';
import { DatePickerComponent } from '../components/date-picker/date-picker.component';
import { DeleteConfirmationComponent } from '../components/delete-confirmation/delete-confirmation.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { DynamicFormChildComponent } from '../components/dynamic-form/dynamic-form-child/dynamic-form-child.component';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { ElectConfirmationComponent } from '../components/elect-confirmation/elect-confirmation.component';
import { FiltersCheckboxComponent } from '../components/filters-dynamic/filters-checkbox/filters-checkbox.component';
import { FiltersDatesComponent } from '../components/filters-dynamic/filters-dates/filters-dates.component';
import { FiltersComponent } from '../components/filters-dynamic/filters.component';
import {
  FormDirtyDeactivateDialogComponent,
} from '../components/form-dirty-deactivate-confirm-dialog/form-dirty-deactivate-confirm-dialog.component';
import { GrowlerComponent } from '../components/growler/growler.component';
import { AlertMessageDetailComponent } from '../components/header/alert-message-detail/alert-message-detail.component';
import { AlertsWindowComponent } from '../components/header/alerts-window/alerts-window.component';
import { LoadMoreSimpleComponent } from '../components/load-more-simple/load-more-simple.component';
import { LoadMoreComponent } from '../components/load-more/load-more.component';
import { ModalComponent } from '../components/modal/modal.component';
import { MoreInfoLinkComponent } from '../components/more-info-link/more-info-link.component';
import { MoreInfoComponent } from '../components/more-info/more-info.component';
import { NetSuiteModalComponent } from '../components/net-suite-modal/net-suite-modal.component';
import { OverlayLoaderComponent } from '../components/overlay-loader/overlay-loader.component';
import { OverspentConfirmationComponent } from '../components/overspent-confirmation/overspent-confirmation.component';
import { PlayVideosComponent } from '../components/play-videos/play-videos.component';
import { PopoverTooltipComponent } from '../components/popover-tooltip/popover-tooltip.component';
import { RecordsPerPageFilterComponent } from '../components/records-per-page-filter/records-per-page-filter.component';
import { SectionLoaderComponent } from '../components/section-loader/section-loader.component';
import { SkeletonLoaderComponent } from '../components/skeleton-loader/skeleton-loader.component';
import { ButtonRowComponent } from '../components/template/button-template/button-template-helpers/button-row/button-row.component';
import { ButtonTemplateComponent } from '../components/template/button-template/button-template.component';
import { SideTabTemplateComponent } from '../components/template/side-tab-template/side-tab-template.component';
import { DialogComponent } from '../components/upload-file/upload-file-messages/dialog.component';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { VideosComponent } from '../components/videos/videos.component';
import { AddHtmlDirective } from '../directives/add-html';
import { DateMaskDirective } from '../directives/date-mask.directive';
import { FocusLostDirective } from '../directives/focus-lost.directive';
import { HighlightDirective } from '../directives/highlight.directive';
import { InfiniteScrollDirective } from '../directives/infinite-scroll.directive';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';
import { OverlayDirective } from '../directives/overlay.directive';
import { RestrictDollarDecimalPointDirective } from '../directives/restrict-dollar-decimal-point.directive';
import { RestrictPercentDecimalPointDirective } from '../directives/restrict-percent-decimal-point.directive';
import { RouteTransformDirective } from '../directives/route-transform.directive';
import { TooltipWhenEllipsisDirective } from '../directives/tooltip-when-ellipsis.directive';
import { TooltipDirective } from '../directives/tooltip.directive';
import { LayoutComponent } from '../layout/layout.component';
import { AccountNumberPipe } from '../pipes/account-number.pipe';
import { BenefitPlanStatePipe } from '../pipes/benefit-plan-state.pipe';
import { DayOfMonthPipe } from '../pipes/day-of-month.pipe';
import { IndividualGenderPipe } from '../pipes/individual-gender.pipe';
import { NameByIdPipe } from '../pipes/name-by-id.pipe';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { OverviewChartPeriodPipe } from '../pipes/overview-chart-period.pipe';
import { PaymentDetailsPipe } from '../pipes/payment-details.pipe';
import { PricingTableProrationPipe } from '../pipes/pricing-table-proration.pipe';
import { RemittanceEntryDetailsDisplayPipe } from '../pipes/remittance-entry-details-display.pipe';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { SafeHtmlPipe } from '../pipes/safeHtml.pipe';
import { TierCoveragePipe } from '../pipes/tierCoverage.pipe';
import { ToCasePipe } from '../pipes/toCase.pipe';
import { ToPluralPipe } from '../pipes/toPlural.pipe';
import { TransactionAmountClassPipe } from '../pipes/transaction-amount-class.pipe';
import { TransactionAmountPipe } from '../pipes/transaction-amount.pipe';
import { TransactionTypePipe } from '../pipes/transaction-type.pipe';
import { ErrorInterceptor } from '../utils/errorInterceptor/error.interceptor';
import { RouteContextProviderService } from './../utils/routecontextprovider.service';
import { MaterialSharedModule } from './material-shared.module';

const SHARE_PROVIDERS = [
  AccessService,
  RouteContextProviderService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialSharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgbDatepickerModule,
    MatTreeModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    NgxMatSelectSearchModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FooterComponent,
    MaterialSharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    TascGrowelComponent,
    HighlightDirective,
    TooltipDirective,
    OverlayDirective,
    ModalComponent,
    NumbersOnlyDirective,
    RestrictDollarDecimalPointDirective,
    RestrictPercentDecimalPointDirective,
    FilterComponent,
    FilterPopupComponent,
    CheckboxTemplateComponent,
    FederatedRedirectModalComponent,
    ForgotPasswordFederatedModalComponent,
    SignUpFederatedModalComponent,
    SigninSecurityFederatedModalComponent,
    AddEnrollEmployeeComponent,
    UploadFileComponent,
    DialogComponent,
    MoreInfoComponent,
    NgxPaginationModule,
    PopoverTooltipComponent,
    OrderByPipe,
    AccountNumberPipe,
    DayOfMonthPipe,
    PaymentDetailsPipe,
    BenefitPlanStatePipe,
    OverviewChartPeriodPipe,
    NgbDatepickerModule,
    DatePickerComponent,
    DropdownComponent,
    FocusLostDirective,
    InfiniteScrollDirective,
    IndividualGenderPipe,
    MoreInfoLinkComponent,
    AddHtmlDirective,
    DeleteConfirmationComponent,
    CustomFileUploadComponent,
    SafeHtmlPipe,
    NetSuiteModalComponent,
    RouteTransformDirective,
    HasPermissionDirective,
    AccordionMessagesComponent,
    VideosComponent,
    PlayVideosComponent,
    PricingTableProrationPipe,
    RemittanceEntryDetailsDisplayPipe,
    LoadMoreComponent,
    OverlayLoaderComponent,
    FormDirtyDeactivateDialogComponent,
    RecordsPerPageFilterComponent,
    DateInputComponent,
    NgxMatSelectSearchModule,
    SafeUrlPipe,
    SectionLoaderComponent,
    SkeletonLoaderComponent,
    ToCasePipe,
    ToPluralPipe,
    TierCoveragePipe,
    TransactionAmountClassPipe,
    TransactionAmountPipe,
    TransactionTypePipe,
    ButtonTemplateComponent,
    SideTabTemplateComponent,
    DynamicFormComponent,
    DateFieldComponent,
    DynamicFormChildComponent,
    DateMaskDirective,
    LoadMoreSimpleComponent,
    GrowlerComponent,
    ActionsSnowmanMenuComponent,
    NameByIdPipe,
    ElectConfirmationComponent,
    TextFieldComponent,
    ButtonRowComponent,
    TooltipWhenEllipsisDirective,
    FiltersComponent,
    FiltersCheckboxComponent,
    FiltersDatesComponent,
    OverspentConfirmationComponent,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    AlertsWindowComponent,
    AlertMessageDetailComponent,
    TascGrowelComponent,
    HighlightDirective,
    TooltipDirective,
    OverlayDirective,
    ModalComponent,
    NumbersOnlyDirective,
    RestrictDollarDecimalPointDirective,
    RestrictPercentDecimalPointDirective,
    FilterComponent,
    FilterPopupComponent,
    CheckboxTemplateComponent,
    FederatedRedirectModalComponent,
    ForgotPasswordFederatedModalComponent,
    SignUpFederatedModalComponent,
    SigninSecurityFederatedModalComponent,
    AddEnrollEmployeeComponent,
    UploadFileComponent,
    DialogComponent,
    MoreInfoComponent,
    PopoverTooltipComponent,
    OrderByPipe,
    AccountNumberPipe,
    DayOfMonthPipe,
    PaymentDetailsPipe,
    BenefitPlanStatePipe,
    OverviewChartPeriodPipe,
    DropdownComponent,
    DatePickerComponent,
    FocusLostDirective,
    InfiniteScrollDirective,
    IndividualGenderPipe,
    MoreInfoLinkComponent,
    AddHtmlDirective,
    DeleteConfirmationComponent,
    CustomFileUploadComponent,
    SafeHtmlPipe,
    NetSuiteModalComponent,
    RouteTransformDirective,
    AccordionMessagesComponent,
    HasPermissionDirective,
    VideosComponent,
    PricingTableProrationPipe,
    PlayVideosComponent,
    LoadMoreComponent,
    OverlayLoaderComponent,
    FormDirtyDeactivateDialogComponent,
    RecordsPerPageFilterComponent,
    RemittanceEntryDetailsDisplayPipe,
    DateInputComponent,
    SafeUrlPipe,
    SectionLoaderComponent,
    SkeletonLoaderComponent,
    ToCasePipe,
    ToPluralPipe,
    TierCoveragePipe,
    TransactionAmountClassPipe,
    TransactionAmountPipe,
    TransactionTypePipe,
    SideTabTemplateComponent,
    ButtonTemplateComponent,
    DynamicFormComponent,
    DateFieldComponent,
    DynamicFormChildComponent,
    DateMaskDirective,
    LoadMoreSimpleComponent,
    GrowlerComponent,
    ActionsSnowmanMenuComponent,
    NameByIdPipe,
    ElectConfirmationComponent,
    TextFieldComponent,
    ButtonRowComponent,
    TooltipWhenEllipsisDirective,
    FiltersComponent,
    FiltersCheckboxComponent,
    FiltersDatesComponent,
    OverspentConfirmationComponent,
  ],
  providers: [SHARE_PROVIDERS],
  entryComponents: [
    FederatedRedirectModalComponent,
    ForgotPasswordFederatedModalComponent,
    SignUpFederatedModalComponent,
    SigninSecurityFederatedModalComponent,
    DialogComponent,
    DeleteConfirmationComponent,
    MoreInfoComponent,
    NetSuiteModalComponent,
    PlayVideosComponent,
    FormDirtyDeactivateDialogComponent,
  ],
})
export class ShareModule { }
