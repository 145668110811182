import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Client } from '@models/profile/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ClientState, ClientStore } from './client.store';

@Injectable({
  providedIn: 'root',
})
export class ClientQuery extends QueryEntity<ClientState> {
  public constructor(protected store: ClientStore) {
    super(store);
  }

  public getActiveId(): string {
    return super.getActiveId() as string;
  }

  public selectActiveWhenLoaded(): Observable<Client> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }
}
