import { ElectionProfileType } from '@models/account/model';

export enum VerifyPostingGroupingType {
  ScheduledDate,
  Individual,
}

export interface EntryDetails {
  entryId: string;
  loading: boolean;
  editable: boolean;
  amount: number;
  electionProfileType: ElectionProfileType;
  hasError?: boolean;
  errorMessages?: string[];
  bypassWarning?: boolean;
}

export interface RowEntries {
  benefitAccountId: string;
  planId: string;
  planName: string;
  client?: EntryDetails;
  participant?: EntryDetails;
  accountInPropagatingDateEdits?: boolean;
}

export interface VerifyPostingItemViewModel {
  actionRequired?: boolean;
  expanded: boolean;
  fullName: string;
  individualId: string;
  individualExternalId: string;
  menuIsOpen?: boolean;
  scheduledDate: string;
  clientTotal: number;
  participantTotal: number;
  entries: RowEntries[];
}
