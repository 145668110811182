import { BrandResource } from 'src/app/shared/models/clux/model';

export const basicResources: BrandResource = {
  brandCssClass: 'brand-basic',
  cardLegalText: `This Mastercard is administered by BASIC. Use of this card is authorized as set forth
  in your cardholder agreement. The BASIC card is issued by Pathward®, N.A., Member FDIC, pursuant
  to license by Mastercard International.`,
  companyCard: 'BASIC card',
  companyFullName: 'Benefit Administrative Services International Corporation',
  companyName: 'BASIC',
  companyNameCopyright: 'BASIC Benefits, LLC',
  contactHours: 'Monday - Friday, 9:00 a.m. to 7:00 p.m. ET',
  documentationContentType: 'clientDocumentationBasic',
  employerId: 'Employer ID',
  faviconFileName: 'favicon-basic.ico',
  faqContentType: 'faqContentBasic',
  individualId: 'Individual ID',
  logoFileName: 'logo-basic.png',
  phoneNumber: '800-372-3539',
  productName: 'Consumer Driven Accounts',
  supportRequestPlaceholder: 'Enter Individual ID',
  supportRequestExternalId: 'Individual ID',
  supportRequestEmployerId: 'Employer ID',
  termsOfUseFileName: 'terms-basic.html',
};
