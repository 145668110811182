import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BenefitService } from '@app/default/benefit/services/benefit.service';
import { PaymentPlanService } from '@app/default/benefit/services/payment-plan.service';
import { Division } from '@models/profileConfiguration/model';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FilesService } from 'src/app/default/files/shared/components/services/files.service';
import { BrandId } from 'src/app/shared/models/clux/enum';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { BenefitPlanFundingSourceAndScheduleQuery, BenefitPlanQuery, ClientQuery, ContactQuery, DivisionQuery } from 'src/app/state';

import { SharedService } from '../../../shared/services/shared.service';
import { Categories } from '../../constants/analyticsCategory.constant';
import { HoldStatus } from '../../models/clux/enum';
import { BrandService } from '../../services/brand.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { NetSuiteModalComponent } from '../net-suite-modal/net-suite-modal.component';
import { EmitterService } from './../../../shared/services/emitter.service';
import { CommonConstants } from './../../constants/common.constant';
import { AlertsWindowComponent } from './alerts-window/alerts-window.component';

enum HeaderDropdowns {
  Account = 'account',
  Employees = 'employees',
  Alerts = 'alerts',
  Offerings = 'offerings',
  Files = 'files',
  Funding = 'funding',
  Transactions = 'transactions',
  Reports = 'reports',
  Settings = 'settings',
  Support = 'support',
  Invoices = 'invoices',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('alertsWindow') public alertsWindow: AlertsWindowComponent;
  public userName: string;
  public openDropdown: HeaderDropdowns | null;
  public HeaderDropdowns = HeaderDropdowns;
  public unreadCount: number = 0;
  public isNetSuite: boolean;
  public divisions$: Observable<Division[]>;
  public isClasses: boolean;
  public customizationLogo: Model.CustomizationLogo;
  public isCardAllowed: boolean;
  public readonly brand = this.brandService.getBrandResources();
  public readonly brandId = this.brandService.getBrand();
  public BrandId = BrandId;
  public isFileSettingAllowed: boolean;
  public displayPODReports: Observable<boolean>;
  public displayVaccineReport: Observable<boolean>;
  @Output() private toggleAlert = new EventEmitter<boolean>();
  private serviceSubscriptions = new Subscription();
  private isBrandingLogo: boolean;

  public hasBenefitPlan$ = this.benefitService.hasBenefitPlan();
  public hasPaymentPlan$ = this.paymentPlanService.hasPaymentPlan();

  public constructor(
    private clientQuery: ClientQuery,
    private contactQuery: ContactQuery,
    public dialog: MatDialog,
    private divisionQuery: DivisionQuery,
    private emitterService: EmitterService,
    private sharedService: SharedService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private brandService: BrandService,
    private filesService: FilesService,
    private benefitPlanFundingSourceAndScheduleQuery: BenefitPlanFundingSourceAndScheduleQuery,
    private benefitPlanQuery: BenefitPlanQuery,
    private benefitService: BenefitService,
    private paymentPlanService: PaymentPlanService,
  ) { }

  public ngOnInit(): void {
    const client = this.clientQuery.getActive();
    const contact = this.contactQuery.getActive();
    this.isNetSuite = false;
    this.userName = contact.firstName;
    this.isNetSuite = contact && (contact.userAccessRoles.some((r) => CommonConstants.netSuiteUserAccessRoles && CommonConstants.netSuiteUserAccessRoles.indexOf(r) >= 0));
    this.setFileSettingMenu();

    this.divisions$ = this.divisionQuery.selectAllWhenLoaded();
    this.displayPODReports = this.benefitPlanFundingSourceAndScheduleQuery.canViewPODReports();
    this.displayVaccineReport = this.benefitPlanQuery.hasVaccinePlan();

    if (client) {
      this.isClasses = client.allowEligibilityClasses;
      this.isCardAllowed  = client.allowCard;
      let message = '';

      switch (this.getHoldStatus()) {
        case HoldStatus.Manual:
          this.showMessage(CommonConstants.errorCodes.manualHoldExist);
          break;
        case HoldStatus.System:
        case HoldStatus.ManualAndSystem:
          const systemHoldReason = client.systemHoldReason ? client.systemHoldReason.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : '';
          message = `Your account is on hold due to ${systemHoldReason}. Card and reimbursement activity will resume after the hold is cleared.`;
          this.showMessage(message);
          break;
        case HoldStatus.All:
        case HoldStatus.SystemAndException:
        case HoldStatus.ExceptionAndManual:
          this.showMessage(CommonConstants.errorCodes.manualAndSystemHoldExists);
          break;
        default:
          break;
      }
    }
    this.customizationLogo = { url: '', isCustomBranding: false };
    this.getCustomizationByClientId();
  }

  public toggleAlerts(): void {
    this.onOpenDropdown(HeaderDropdowns.Alerts);
    this.toggleAlert.emit(this.openDropdown === HeaderDropdowns.Alerts);

    if (this.openDropdown === HeaderDropdowns.Alerts) {
      this.alertsWindow.focus();
    }
  }

  public onUpdateUnreadCount(count: number): void {
    this.unreadCount = count;
  }

  public openDialog(data: string): void {
    /**
     * Track 'NetSuiteRedirect' event
     */
    const eventCategory: Model.EventCategory = Object.assign({}, Categories.EventCategories.NetSuiteRedirect);
    eventCategory.eventAction = eventCategory.eventNameMapping[data + 'Action'];
    eventCategory.eventLabel = eventCategory.eventNameMapping[data + 'Label'];
    this.googleAnalyticsService.eventEmitter(eventCategory);

    const dialogRef: MatDialogRef<NetSuiteModalComponent, string[]> = this.dialog.open(NetSuiteModalComponent, {
      data: {
        cameFrom: data,
      },
    });
  }

  public triggerGAevent(): void {
    this.openDropdown = null;
    this.googleAnalyticsService.eventEmitter(Categories.EventCategories.FileSettingRedirect);
  }

  public onOpenDropdown(page?: HeaderDropdowns): void {
    this.openDropdown = this.openDropdown === page ? null : page;
  }

  private getHoldStatus(): HoldStatus {
    const client = this.clientQuery.getActive();

    if (client.manualHold && !client.systemHold && !client.holdException) {
      return HoldStatus.Manual;
    } else if (!client.manualHold && client.systemHold && !client.holdException) {
      return HoldStatus.System;
    } else if (!client.manualHold && !client.systemHold && client.holdException) {
      return HoldStatus.Exception;
    } else if (client.manualHold && client.systemHold && !client.holdException) {
      return HoldStatus.ManualAndSystem;
    } else if (!client.manualHold && client.systemHold && client.holdException) {
      return HoldStatus.SystemAndException;
    } else if (client.manualHold && !client.systemHold && client.holdException) {
      return HoldStatus.ExceptionAndManual;
    } else if (client.manualHold && client.systemHold && client.holdException) {
      return HoldStatus.All;
    }
  }

  /**
   * to show message once on login
   * hide on page refresh
   */
  private showMessage(message: string): void {
    if (!sessionStorage.getItem(CommonConstants.localKeys.showHoldGrowler)) {
      sessionStorage.setItem(CommonConstants.localKeys.showHoldGrowler, JSON.stringify(true));
      this.emitterService.on(CommonConstants.emmiterKeys.loginErrors)
        .emit(this.emitterService.getEmitterMessage(message, 'warning'));
    }
  }

  private getCustomizationByClientId(): void {
    const clientId = this.clientQuery.getActiveId();
    this.serviceSubscriptions.add(this.sharedService.getCustomizationByClientId(clientId)
      .pipe(
        switchMap((res) => {
          this.isBrandingLogo = res.data.length && res.data[0].brandingLogoId && res.data[0].brandingEnabled ? true : false;
          if (this.isBrandingLogo) {
            return this.sharedService.getLogo(clientId, res.data[0].brandingLogoId);
          } else {
            return of('');
          }
        }),
      )
      .subscribe((url) => {
        this.customizationLogo.url = url;
        this.customizationLogo.isCustomBranding = this.isBrandingLogo && (this.customizationLogo.url !== '');
      }),
    );
  }

  private setFileSettingMenu(): void {
    const contact = this.contactQuery.getActive();
    const isUserAllowed = contact && (contact.userAccessRoles.some((r) => CommonConstants.fileSettingAccessRoles && CommonConstants.fileSettingAccessRoles.indexOf(r) >= 0));
    if (isUserAllowed) {
      this.serviceSubscriptions.add(this.filesService.getClaimConnex().subscribe((res) => {
        this.isFileSettingAllowed = res?.length > 0 && res[0].allowed;
      }));
    }
  }
}
