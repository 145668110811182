export const basicURLs: string[] = [
    'localhost',

    'basic-d1-clux.tasconline.com', // D1
    'basic-d2-clux.tasconline.com', // D2
    'basic-d3-clux.tasconline.com', // D3
    'basic-d4-clux.tasconline.com', // D4
    'basic-d5-clux.tasconline.com', // D5
    'basic-d6-clux.tasconline.com', // D6
    'basic-d7-clux.tasconline.com', // D7
    'basic-d8-clux.tasconline.com', // D8

    'basic-t1-clux.tasconline.com', // T1
    'basic-t2-clux.tasconline.com', // T2
    'basic-t3-clux.tasconline.com', // T3
    'basic-t4-clux.tasconline.com', // T4
    'basic-t5-clux.tasconline.com', // T5

    'basic-l1-clux.tasconline.com', // L1
    'basic-l2-clux.tasconline.com', // L2
    'basic-l3-clux.tasconline.com', // L3
    'basic-l4-clux.tasconline.com', // L4

    'basic-s1-clux.uba-stage.com', // S1
    'basic-s2-clux.tasconline.com', // S2
    'basic-s3-clux.uba-stage.com', // S3
    'basic-s4-clux.uba-stage.com', // S4
    'basic-s5-clux.uba-stage.com', // S5
    'basic-s6-clux.tasconline.com', // S6

    'cdaclient.basiconline.com', // Prod
];
