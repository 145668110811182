import { Injectable } from '@angular/core';
import { EntityState, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { BankAccount, PaymentAccountType } from '@models/account/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BankAccountStore } from './bank-account.store';

export interface BankAccountState extends EntityState<BankAccount, string> { }

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class BankAccountQuery extends QueryEntity<BankAccountState> {
  public constructor(protected store: BankAccountStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<BankAccount[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectBankAccounts(): Observable<BankAccount[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((accounts) => accounts.filter((account) => account.paymentAccountType === PaymentAccountType.BankAccount)),
      );
  }

  public selectEntityWhenLoaded(bankAccountId: string): Observable<BankAccount> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(bankAccountId)),
      );
  }
}
