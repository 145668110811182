import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Contact } from 'src/app/shared/models/uba/profile/model';

export interface ContactState extends EntityState<Contact, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Contact' })
export class ContactStore extends EntityStore<ContactState> {
  public constructor() {
    super();
  }
}
