import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EligibleEligibilityEventState, EligibleEligibilityEventStore } from './eligible-eligibility-event.store';

@Injectable({
  providedIn: 'root',
})
export class EligibleEligibilityEventQuery extends QueryEntity<EligibleEligibilityEventState> {
  public constructor(
    protected store: EligibleEligibilityEventStore,
    ) {
    super(store);
  }
}
