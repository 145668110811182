import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EligibilityEventOfferingEnriched, EligibilityEventOfferingState } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EligibilityEventOfferingStore, EligibilityEventOfferingStoreState } from './eligibility-event-offering.store';

@Injectable({
  providedIn: 'root',
})
export class EligibilityEventOfferingQuery extends QueryEntity<EligibilityEventOfferingStoreState> {
  public constructor(protected store: EligibilityEventOfferingStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<EligibilityEventOfferingEnriched[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByStateWhenLoaded(states: EligibilityEventOfferingState[]): Observable<EligibilityEventOfferingEnriched[]> {
    return this.selectAllWhenLoaded().pipe(
      map((eligibilityEventOfferings) =>
        eligibilityEventOfferings.filter((eeo) => states.includes(eeo.currentState as EligibilityEventOfferingState)),
      ),
    );
  }

  public selectByState(states: EligibilityEventOfferingState[]): Observable<EligibilityEventOfferingEnriched[]> {
    return this.selectAll().pipe(
      map((eligibilityEventOfferings) =>
        eligibilityEventOfferings.filter((eeo) => states.includes(eeo.currentState as EligibilityEventOfferingState)),
      ),
    );
  }

  public selectByEvent(eventId: string): Observable<EligibilityEventOfferingEnriched[]>  {
    return this.selectAllWhenLoaded().pipe(
      map((offerings) => offerings.filter((o) => o.parentId === eventId)),
    );
  }
}
