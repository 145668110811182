import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { version } from '../../../../../package.json';
import { BrandService } from '../../services/brand.service';
import { EmitterService } from '../../services/emitter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public extraFooterMargin$: Observable<boolean> = this.sharedService.extraFooterMargin;
  public currentYear: number = new Date().getFullYear();
  public readonly brand = this.brandService.getBrandResources();
  public readonly version = version;

  public constructor(
    private sharedService: EmitterService,
    private brandService: BrandService,
  ) { }

}
