import { BrandResource, BrandResources } from 'src/app/shared/models/clux/model';

import { basicResources } from './basic/basic.resources';
import { tascResources } from './tasc/tasc.resources';

export const brandResources: BrandResources = {
    BASIC: basicResources,
    TASC: tascResources,
    Unknown: {} as BrandResource,
  };
