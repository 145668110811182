import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { Contact, UserAccessRoleType } from '@models/profile/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ContactState, ContactStore } from './contact.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'created',
  sortByOrder: Order.DESC,
})
export class ContactQuery extends QueryEntity<ContactState> {
  public constructor(protected store: ContactStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Contact[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public getActiveId(): string {
    return super.getActiveId() as string;
  }

  public selectActiveWhenLoaded(): Observable<Contact> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public selectPrimaryContacts(): Observable<Contact[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (contact) => contact.primaryContact,
        })),
      );
  }

  public selectAdminContacts(): Observable<Contact[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (contact) => contact.userAccessRoles.includes(UserAccessRoleType.CU1),
        })),
      );
  }

  public selectByEmail(email: string): Observable<Contact> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((contacts) => {
          return contacts.find((contact) => contact.email.toLowerCase() === email.toLowerCase());
        }),
      );
  }
}
