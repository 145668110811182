<ng-container>
<div class="wrapper">
  <div class="header">
    {{externalRouteMessage}}
  </div>
  <input type="text" readonly class="sub-header" value="Please check your popup blocker settings.">
</div>
<div class="dialog-footer mt-7 text-center">
  <button mat-flat-button class="mat-secondary" id="btn-cancel" type="button" (click)="onCancel()">CANCEL</button>
  <button mat-flat-button class="mat-primary ml-5" id="btn-continue" (click)="onContinue()">CONTINUE</button>
</div>
</ng-container>
