import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { AppType, UserAccessRoleType } from '@models/profile/model';
import { GroupPermission, MatchType, QueryCriteria } from '@models/security/model';
import { merge, Observable, of, throwError } from 'rxjs';
import { map, mapTo, mergeMap, reduce } from 'rxjs/operators';
import { GroupPermissionStore } from 'src/app/state';
import { GroupPermissionModel } from 'src/app/state/model';
import { v4 as uuid } from 'uuid';

import { CoreService } from '../models/clux/enum';
import { Uri } from '../utils/uri';
import { BrowserService } from './browser.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  public constructor(
    private browserService: BrowserService,
    private groupPermissionStore: GroupPermissionStore,
    private http: HttpClient,
    private utilityService: UtilityService,
    ) {}

  public loadGroupPermissions(groups: UserAccessRoleType[]): Observable<void> {
    if (!groups || !groups.length) {
      return throwError('Contact is not assigned to any roles');
    }
    const filterCriteria = {
      key: 'id',
      value: '',
      matchType: MatchType.EXACT,
    };
    const criteria: QueryCriteria = {
      index: 'app-index',
      searchCriteria: [{
        key: 'app',
        value: 'UBAGUI_CLUX',
        matchType: MatchType.EXACT,
      }],
      filterCriteria: [filterCriteria],
      projectionExpression: 'allowedActions',
    };
    const uri = new Uri('/profile/*/groupPermission/search', CoreService.Security);
    const requests$ = groups.map((group) => {
      const groupCriteria = { ...criteria };
      groupCriteria.filterCriteria = [{
        ...filterCriteria,
        value: group,
      }];
      return this.http.post<GroupPermission[]>(uri.toString(), groupCriteria);
    });

    const mergedRequests$ = merge(...requests$)
      .pipe(
        mergeMap((request) => of(request)),
        reduce((allTransactions, subsetTransactions) => allTransactions.concat(subsetTransactions)),
        map((groupPermissions) => groupPermissions.map((groupPermission) => {
         return {...groupPermission, id: uuid()} as GroupPermissionModel;
        })),
        withTransaction((groupPermissions) => {
          this.groupPermissionStore.set(groupPermissions);
        }),
      );

    return cacheable(this.groupPermissionStore, mergedRequests$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }

  public getEmailFromAuth0Code(code: string, state: string): Observable<string> {
    const origin = this.browserService.getLocationOrigin();
    const pathname = this.browserService.getLocationPathname();
    const options = {
      params: {
        uri: `${origin}${pathname}`,
        resource: AppType.CU,
        client: state,
      },
    };
    const uri = new Uri(`/auth0/code/${code}/email`, CoreService.Security);
    return this.http.get<{email: string}>(uri.toString(), options)
      .pipe(
        map((response) => response?.email),
      );
  }

  public initiateNetSuiteSSO(payload: { queryParams: { [key: string]: string }, auth: string }): Observable<string> {
    const uri = new Uri('/netSuiteSSO/oauth2/authorize', CoreService.Security);
    return this.http.post<{ redirect_uri: string }>(uri.toString(), payload).pipe(
      map((res) => res?.redirect_uri),
    );
  }
}
