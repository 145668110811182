import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Individual } from 'src/app/shared/models/uba/profile/model';

export interface IndividualState extends EntityState<Individual, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Individual' })
export class IndividualStore extends EntityStore<IndividualState, Individual> {
  public constructor() {
    super();
  }
}
