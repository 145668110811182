import { Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { cacheable, withTransaction } from '@datorama/akita';
import { Model } from '@legacymodels';
import { SearchCriteria } from '@models/configuration/model';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { Individual, IndividualCommand } from 'src/app/shared/models/uba/profile/model';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { EmploymentInfoQuery } from 'src/app/state/employment-info';
import { IndividualQuery, IndividualStore } from 'src/app/state/individual';
@Injectable({
  providedIn: 'root',
})
export class IndividualService {
  public constructor(
    private readonly individualStore: IndividualStore,
    private readonly individualQuery: IndividualQuery,
    private readonly serviceFactory: ServiceFactory,
    private readonly employmentInfoQuery: EmploymentInfoQuery,
    private utilityService: UtilityService,
  ) { }

  /**
   * Temporary function while we still use local storage in some places to store individual.
   *
   * @param individualId
   */
  public getAndSetActiveIndividual(individualId: string): Observable<void> {
    return this.getIndividualById(individualId).pipe(
      first(),
      map((individual) => {
        this.individualStore.add(individual);
        this.individualStore.setActive(individual.id);
        return null;
      }),
      catchError((err) => {
        this.utilityService.showGrowelMessage(
          CommonConstants.errorCodes.ErrorFromApi,
          false,
          false,
        );
        return throwError(err);
      }),
    );
  }

  public getIndividualById(individualId: string): Observable<Individual> {
    const url: string = stringFormat(CommonConstants.getPath.profile.personalInformation, {
      individualId,
    });

    return this.serviceFactory.query<Individual>(
      CommonConstants.getPath.profile.serviceKey,
      url,
    );
  }

  public updateIndividual(
    data: Individual,
    profileId: string,
    IndvCommandName: string = null,
    clientId: string = null,
  ): Observable<null> {
    const commandName = IndvCommandName ? IndvCommandName : `${data.currentState}To${data.currentState}`;
    const command: IndividualCommand = {
      ...this.serviceFactory.createCommand(
        data,
        commandName,
      ),
      args: {
        clientId,
      },
    };
    const url: string = stringFormat(
      CommonConstants.getPath.profile.addPersonalInfo,
      {
        profileId,
        command: commandName,
      },
    );

    return this.serviceFactory
      .queryPut<null>(CommonConstants.getPath.profile.serviceKey, url, command)
      .pipe(
        // getByIndividualId wont work after creation since employment info doesn't exist yet
        map(() => {
          this.individualStore.updateActive(data);
          return null;
        }),
        catchError((err) => {
          this.utilityService.showGrowelMessage(
            CommonConstants.errorCodes.ErrorFromApi,
            false,
            false,
          );

          return throwError(err);
        }),
      );
  }

  // Search individual
  public searchIndividual(
    bodyParams: Model.SearchCriteria,
    take: number = 0,
    offset: number = 0,
    orderBy: string = 'created',
    orderDirection: string = 'desc',
  ): Observable<Individual[]> {
    const searchParams: Model.SearchParams = {
      take,
      skip: offset,
      orderBy,
      orderDirection,
    };
    return this.serviceFactory
      .search<Individual[]>(
        CommonConstants.getPath.profile.serviceKey,
        CommonConstants.getPath.profile.individualSearch,
        bodyParams,
        null,
        searchParams,
      )
      .pipe(
        map(({ data }) => data),
        withTransaction((individuals) => {
          this.individualStore.set(individuals);
          return individuals;
        }),
      );
  }

  // Search for duplicate individual email
  public searchForDuplicateEmail(
    bodyParams: Model.SearchCriteria,
    take: number = 0,
    offset: number = 0,
  ): Observable<Individual[]> {
    const searchParams: Model.SearchParams = {
      take,
      skip: offset,
      orderBy: 'created',
      orderDirection: 'desc',
    };
    return this.serviceFactory
      .search<Individual[]>(
        CommonConstants.getPath.profile.serviceKey,
        CommonConstants.getPath.profile.individualSearch,
        bodyParams,
        null,
        searchParams,
      )
      .pipe(map(({ data }) => data));
  }

  public setActiveIndividual(individualId: string): void {
    this.individualStore.setActive(individualId);
  }
}
