import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PaymentPlan, PaymentPlanState } from '@models/configuration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Dates } from 'src/app/shared/utils/dates';
import { PaymentPlanStore, PaymentPlanStoreState } from './payment-plan.store';

@Injectable({
  providedIn: 'root',
})

export class PaymentPlanQuery extends QueryEntity<PaymentPlanStoreState> {

  public constructor(protected store: PaymentPlanStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<PaymentPlan[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectEntityWhenLoaded(id: string): Observable<PaymentPlan> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectEntity(id)),
      );
  }

  public selectActiveWhenLoaded(): Observable<PaymentPlan> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectActive()),
      );
  }

  public getActiveId(): string {
    return super.getActiveId() as string;
  }

  public selectPlanById(id: string): Observable<PaymentPlan> {
    return this.selectAll().pipe(
      map((paymentPlans) => paymentPlans.find((plan) => plan.id === id)),
    );
  }

  public selectPlansWithinRenewalLeadTime(): Observable<PaymentPlan[]> {
    return this.selectAllWhenLoaded().pipe(
      map((plans) => plans
        .filter((plan) => {
          const renewalDate = Dates.fromString(plan.planEndDate).subtract(plan.renewalLeadTimeDays, 'days');
          const dateIsInRangeOfRenewed = Dates.isSameOrAfter(Dates.todaysDate(), renewalDate);

          const canBeRenewedStates = [
              PaymentPlanState.Setup,
              PaymentPlanState.Initiated,
              PaymentPlanState.Active,
          ];

          if (
              canBeRenewedStates.includes(plan.currentState as PaymentPlanState) &&
              dateIsInRangeOfRenewed &&
              !plan.hasBeenRenewed
          ) {
              return true;
          }

          return false;
        }),
      ),
    );
  }

  public selectHasPlansWithinRenewalLeadTime(): Observable<boolean> {
    return this.selectPlansWithinRenewalLeadTime().pipe(
      map((plans) => Boolean(plans.length)),
    );
  }
}
