export const tascURLs: string[] = [
    'localhost',

    'd1514nrkn9yya2.cloudfront.net', // d1
    'd357uz0blvk80v.cloudfront.net', // d2
    'd1xart4qokdip3.cloudfront.net', // d3
    'd3ohk3iet9raes.cloudfront.net', // d4
    'd1hvppwkwxve0j.cloudfront.net', // d5
    'd12byqns1vkxgn.cloudfront.net', // d6
    'd13300eydl86ud.cloudfront.net', // d7
    'd3nzfclpj1ejnr.cloudfront.net', // d8
    'd3f1q761hdokce.cloudfront.net', // d9

    'd33k742rcd77bw.cloudfront.net', // t1
    'd2a4jfndyyqexe.cloudfront.net', // t2
    'dfqekt240lu1p.cloudfront.net',  // t3
    'dlnvbbpmlz55s.cloudfront.net',  // t4
    'd2h5iqhar35yx3.cloudfront.net', // t5

    'd1nwn0beq9kpjk.cloudfront.net', // o1
    'dxdbfzxprqvse.cloudfront.net', // o2
    'd3vezdoki5lm9q.cloudfront.net', // o3
    'd15n7fx1wvvkys.cloudfront.net', // o4
    'd2h6ccilt2jrmq.cloudfront.net', // o5
    'dp1x4pngniwiz.cloudfront.net', // o6
    'd3bayjgo2xx6ra.cloudfront.net', // o7
    'd3job59e63nujd.cloudfront.net', // o8

    'd3tlrfedohnqa.cloudfront.net', // l1
    'd1izaxp5cuppxj.cloudfront.net', // l2
    'dh95r21friefl.cloudfront.net', // l3
    'd21zi2y88ua52j.cloudfront.net', // s1
    'dj3dr4l0adfcn.cloudfront.net', // s2
    'd1pgwb61ptaxrw.cloudfront.net', // s3
    'd2sqxdt6k94sj8.cloudfront.net', // s4
    'd2vvkar8liiem1.cloudfront.net', // s5
    'acm-clux2.tasconline.com', // s6
    'd2jfl9bxv7un79.cloudfront.net', // s7

    'ubaclient.tasconline.com', // prod 1
    'd3q2ijiczamdqh.cloudfront.net', // prod 3
   ];
