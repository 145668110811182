import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mapTo,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BankAccount,
  ChainType,
  MatchType,
  ParentType,
  SearchCriteria,
} from 'src/app/shared/models/uba/account/model';
import { ClientQuery } from 'src/app/state';
import { BankAccountQuery, BankAccountStore } from 'src/app/state/bank-account';
import { CommonConstants } from '../constants/common.constant';

import { CoreService } from '../models/clux/enum';
import { BankAccountSearchQuery } from '../models/clux/model';
import { Uri } from '../utils/uri';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  public constructor(
    private bankAccountQuery: BankAccountQuery,
    private bankAccountStore: BankAccountStore,
    private clientQuery: ClientQuery,
    private http: HttpClient,
    private readonly utilityService: UtilityService,
  ) {}

  private readonly subject = new BehaviorSubject<BankAccount[]>([]);
  public readonly bankAccounts$ = this.subject.asObservable();

  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.loadingSubject.asObservable();

  public getBankAccounts(): Observable<BankAccount[]> {
    return this.loadBankAccounts().pipe(
      switchMap(() => this.bankAccountQuery.selectBankAccounts()),
    );
  }

  public getBankAccount(id: string): Observable<BankAccount> {
    return this.loadBankAccounts().pipe(
      switchMap(() => this.bankAccountQuery.selectEntityWhenLoaded(id)),
    );
  }

  public getAllPaymentMethods(): Observable<BankAccount[]> {
    return this.loadBankAccounts().pipe(
      switchMap(() => this.bankAccountQuery.selectAllWhenLoaded()),
    );
  }

  private loadBankAccounts(): Observable<void> {
    const client = this.clientQuery.getActive();
    const searchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: client.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.CLIENT,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const query: BankAccountSearchQuery = { includeInactive: true };
    const uri = new Uri(
      `/profile/${client.id}/bankAccount/search`,
      CoreService.Account,
      query,
    );
    const request$ = this.http
      .post<BankAccount[]>(uri.toString(), searchCriteria)
      .pipe(withTransaction((accounts) => this.bankAccountStore.set(accounts)));

    this.loadingSubject.next(true);

    return cacheable(this.bankAccountStore, request$, { emitNext: true }).pipe(
      mapTo(null),
      finalize(() => this.loadingSubject.next(false)),
    );
  }

  public refreshByParentIds(parentIds: string[]): void {
    const searchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: parentIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];

    const query: BankAccountSearchQuery = { includeInactive: true };

    const uri = new Uri(
      `/profile/*/bankAccount/search`,
      CoreService.Account,
      query,
    );

    this.http.post<BankAccount[]>(uri.toString(), searchCriteria).pipe(
      catchError((err) => {
        this.utilityService.showGrowelMessage(
          CommonConstants.errorCodes.ErrorFromApi,
          false,
          false,
        );
        return throwError(err);
      }),
      tap((data) => {
        this.subject.next(data);
      }),
    ).subscribe();
  }
}
