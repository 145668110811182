import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { HttpDEBUGInterceptor } from './debug-endpoints';
import { DistributorComponent } from './default/distributor/distributor.component';
import { RaygunModule } from './raygun/raygun.module';
import { AppRoutingModule } from './routing/app.routing.module';
import { NoContentComponent } from './shared/components/no-content/no-content.component';
import { ShareModule } from './shared/modules/shared.module';
import { DEFAULT_TIMEOUT, HttpTimeoutInterceptor } from './shared/utils/httpTimeoutInterceptor/httpTimeout.interceptor';
import { TimeoutService } from './timeout.service';
@NgModule({
  declarations: [
    AppComponent,
    NoContentComponent,
    DistributorComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ShareModule,
    AppRoutingModule,
    RaygunModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [TimeoutService,
    [
      { provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true },
      // @ts-ignore
      ...environment.enableDebugInterceptors ? [{ provide: HTTP_INTERCEPTORS, useClass: HttpDEBUGInterceptor, multi: true }] : [],
    ],
    [{ provide: DEFAULT_TIMEOUT, useValue: 300000 }]],

  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(@Optional() @SkipSelf() self: AppModule) {
    if (self) {
      throw new Error(
        'AppModule is already loaded. Do not import AppModule.');
    }
  }
}
