import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CommonConstants } from '../constants/common.constant';
import { parseRoute } from './access.helper';
import { AccessService } from './access.service';

@Injectable({ providedIn: 'root' })

export class AccessGuard implements CanActivate {

  public constructor(
    private router: Router,
    private accessService: AccessService,
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentRouteUrl = state.url;

    const theParam = 'requestId';
    const isParam = _route.paramMap.get(theParam);
    if (isParam && currentRouteUrl.includes(isParam)) {
      currentRouteUrl = currentRouteUrl.replace(isParam, `:id`);
    }
    // Route parsing expects the urls to match exactly.
    // UUIDs need to be stripped off in order for a URL with a UUID to be directly accessed
    currentRouteUrl = parseRoute(currentRouteUrl);
    return this.accessService.hasPageAccess(currentRouteUrl)
      .pipe(
        switchMap((isPageAccessible) => {
          if (!isPageAccessible && this.accessService.isHomePage(currentRouteUrl)) {
            return this.accessService.getLandingPageForCurrentUser()
              .pipe(
                map((landingPage) => {
                  return { isPageAccessible, landingPage };
                }),
              );
          } else {
            return of({ isPageAccessible, landingPage: null as string });
          }
        }),
        map(({ isPageAccessible, landingPage }) => {
          if (isPageAccessible) {
            return true;
          }
          if (landingPage) {
            return this.router.parseUrl(landingPage);
          }
          const lastRoute = sessionStorage.getItem(CommonConstants.localKeys.userLastRoute);
          if (currentRouteUrl === parseRoute(lastRoute || '')) {
            return true;
          }
          if (lastRoute) {
            return this.router.parseUrl(lastRoute);
          }
          return false;
        }),
      );
  }
}
