import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { cacheable, withTransaction } from '@datorama/akita';
import { CommunicationRegistry, CommunicationRequest, CommunicationRequestType, CommunicationTemplate, CommunicationTemplateState, CommunicationType, ParentType as CParentType } from '@models/communication/model';
import dayjs from 'dayjs';
import { chain, cloneDeep, orderBy, partition, uniq, uniqBy } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
  throwError,
  zip,
} from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import { TransactionActivityEndPointType } from 'src/app/default/employees/enum';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { USStates } from 'src/app/shared/model/usStates.model';
import { CoreService } from 'src/app/shared/models/clux/enum';
import {
  IsElectionChangeValidQuery,
  IsElectionChangeValidResult,
  SearchPayrollDatesResponse,
  SearchResults,
} from 'src/app/shared/models/clux/model';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import {
  BenefitAccount,
  BenefitAccountBalance,
  BenefitAccountEnrollment,
  BenefitAccountState,
  BenefitElectionScheduleCriteria,
  BenefitElectionSourceAndSchedules,
  BenefitPlanCoverageType,
  Criteria,
  DependentBenefitAccess,
  DependentBenefitAccessCommandType,
  Election,
  ElectionCalculationMethod,
  ElectionCommandType,
  ElectionProfileType,
  ElectionState,
  EnrollmentSourceType,
  EntityBase,
  Entry,
  EntryType,
  FeatureAccount,
  GroupCriteria,
  HideFromType,
  ParentType,
} from 'src/app/shared/models/uba/account/model';
import {
  BenefitPlan,
  BenefitPlanFundingSourceAndSchedule,
  BenefitPlanState,
  BenefitPlanType,
  ChainType,
  ElectionLimit,
  FundingSourceType,
  MatchType,
} from 'src/app/shared/models/uba/configuration/model';
import {
  Dependent,
  DependentState,
  Individual,
} from 'src/app/shared/models/uba/profile/model';
import {
  Document,
  DocumentCategoryType,
  DocumentSourceType,
  EmploymentInfo,
  EmploymentInfoState,
  FundsTransferCriterion,
  ParentType as PGParentType,
  PayrollSchedule,
} from 'src/app/shared/models/uba/profileConfiguration/model';
import { CardService } from 'src/app/shared/services/card.service';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { CommandFactory } from 'src/app/shared/utils/command-factory';
import { RouteContextProviderService } from 'src/app/shared/utils/routecontextprovider.service';
import { Transitions } from 'src/app/shared/utils/transitions';
import { Uri } from 'src/app/shared/utils/uri';
import { ContactQuery, IndividualQuery } from 'src/app/state';
import { BenefitPlanQuery } from 'src/app/state/benefit-plan';
import { BenefitPlanFundingSourceAndScheduleStore } from 'src/app/state/benefit-plan-funding-source-and-schedule';
import {
  PayrollScheduleQuery,
  PayrollScheduleStore,
} from 'src/app/state/payroll-schedule';
import { v4 as uuid } from 'uuid';
import { Dates } from '../../../shared/utils/dates';
import { EmployeeDocumentDetail, GenerateCommunicationTemplateDocumentRequest } from '../employee-details/employee-letters/employee-letters.models';
import {
  States,
  TradeActivity,
  TradeActivityQueryParameters,
  TradeActivityViewContainer,
} from '../model';
import { IndividualService } from './individual.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  private isBusySubject = new BehaviorSubject<boolean>(false);
  public isBusy$: Observable<boolean> = this.isBusySubject.asObservable();
  private getEmployeeDocumentByEmployeeIdSubject =
    new BehaviorSubject<{ page: number, offset: number, searchCriteria: Model.SearchCriteria }>(null);
  public getEmployeeDocumentByEmployeeId$ = this.getEmployeeDocumentByEmployeeIdSubject.asObservable();
  public getEmployeeDocumentByEmployeeIdResult$ = this.getEmployeeDocumentByEmployeeId$
    .pipe(
      filter((request) => (!!request) && (request !== undefined)),
      switchMap((request) => {
        if (!request) {
          return of({ hasMore: false, data: [] });
        }
        return this.getEmployeeDocumentByEmployeeId(request.page, request.offset, request.searchCriteria);
      }),
      shareReplay(),
    );

  private generateCommunicationTemplateDocumentSubject = new BehaviorSubject<GenerateCommunicationTemplateDocumentRequest>(null);
  public generateCommunicationTemplateDocument$ = this.generateCommunicationTemplateDocumentSubject.asObservable();

  private isDownloadingLeterSubject = new BehaviorSubject<boolean>(false);
  public isDownloadingLetter$ = this.isDownloadingLeterSubject.asObservable();

  private downloadLetterSubject = new BehaviorSubject<Document>(null);
  public downloadLetter$ = this.downloadLetterSubject.asObservable();

  public downloadLetterResult$ = this.downloadLetter$
    .pipe(
      filter((document: Document) => (document !== null)),
      tap(() => this.isDownloadingLeterSubject.next(true)),
      switchMap((document) => {
        return this.downloadLetter(document);
      }),
      tap(() => this.resetDownloadLetterDocument()),
      catchError((err) => {
        tap(() => this.resetDownloadLetterDocument());
        return of('failure');
      }),
    );

  public generateCommunicationTemplateDocumentResult$ = this.generateCommunicationTemplateDocument$
    .pipe(
      filter((generateLetterTemplateDocumentRequest: GenerateCommunicationTemplateDocumentRequest) =>
        (!!generateLetterTemplateDocumentRequest) && (generateLetterTemplateDocumentRequest !== undefined) && (generateLetterTemplateDocumentRequest !== null)),
      tap(() => this.isBusySubject.next(true)),
      switchMap((generateLetterTemplateDocumentRequest) => {
        if (generateLetterTemplateDocumentRequest.individualIds) {
          return forkJoin(generateLetterTemplateDocumentRequest.individualIds.map((individualId) =>
            this.generateCommunicationRequest(individualId, generateLetterTemplateDocumentRequest.requestType),
          ));
        } else {
          return of([]);
        }
      }),
      tap((response) => {
        this.resetGenerateLetterTemplateDocument();
      }),
      catchError((err) => {
        this.isBusySubject.next(false);
        this.resetGenerateLetterTemplateDocument();
        return of(false);
      }),
    );

  public dependentSelection$ = new Subject<Model.DependentSelection>();
  public individualEffectiveDateUpdate$ = new Subject<string>();
  public dependentInformation$: Observable<Model.DependentInformation>;
  private dependentInformationStoreInit: Model.DependentInformation = {
    accounts: null,
    dependents: null,
    associations: null,
  };
  private dependentSelectionInit: Model.DependentSelection = {
    isDepSelectionValid: true,
    isDepSelectionDirty: false,
  };
  private dependentInformationStore$: BehaviorSubject<Model.DependentInformation> =
    new BehaviorSubject(this.dependentInformationStoreInit);
  private readonly getPendingEntryTypes = [
    EntryType.ManualRefund,
    EntryType.MyCashManualRefund,
    EntryType.PreprocessedDisbursementApproved,
  ];

  private getIndividualDependentsSubject = new BehaviorSubject<string>(null);
  public getIndividualDependents$ = this.getIndividualDependentsSubject.asObservable();

  public getIndividualDependentsResult$ = this.getIndividualDependents$
    .pipe(
      filter((individualId: string) => (!!individualId) && (individualId !== undefined)),
      switchMap((individualId) => {
        return this.getDependents(individualId);
      }),
    );

  public constructor(
    private benefitPlanFundingSourceAndScheduleStore: BenefitPlanFundingSourceAndScheduleStore,
    private benefitPlanQuery: BenefitPlanQuery,
    private cardService: CardService,
    private contactQuery: ContactQuery,
    private http: HttpClient,
    private routeContextProviderService: RouteContextProviderService,
    private serviceFactory: ServiceFactory,
    private payrollScheduleQuery: PayrollScheduleQuery,
    private payrollScheduleStore: PayrollScheduleStore,
    private commandFactory: CommandFactory,
    private readonly individualService: IndividualService,
    private readonly individualQuery: IndividualQuery,
  ) {
    this.dependentInformationStore$ = new BehaviorSubject(
      this.dependentInformationStoreInit,
    );
    this.dependentInformation$ = this.dependentInformationStore$.asObservable();
  }

  public resetDependentInformationStore(): void {
    this.dependentInformationStore$.next(this.dependentInformationStoreInit);
  }

  public updateDependentInformationStore(): Observable<void> {
    return this.getDependentInformation().pipe(
      map((data) => {
        this.dependentInformationStore$.next(data);
        return null;
      }),
    );
  }

  public resetDependentSelection(): void {
    this.dependentSelection$.next(this.dependentSelectionInit);
  }

  public getEmployeesList(
    serviceKey: string,
    basePath: string,
    requestPayload: Model.SearchCriteria,
    queryParams: Model.QueryParams,
    searchParams: Model.SearchParams,
    getTotalCount: boolean,
  ): Observable<Model.EmployeeAccounts> {
    const contact = this.contactQuery.getActive();

    return this.serviceFactory
      .search<Model.ManageGridData[]>(
        serviceKey,
        basePath,
        requestPayload,
        queryParams,
        searchParams,
        true,
      )
      .pipe(
        map((employeesResponse) => {
          const employees: Model.ManageGridData[] = employeesResponse.data;
          const employeeIds: string = this.getEmployeeIds(employees);
          const totalCount: number = employeesResponse.totalCount;
          return { employees, totalCount, employeeIds };
        }),
        switchMap((res) =>
          // Get all benefit accounts for all employees
          this.serviceFactory
            .search<BenefitAccount[]>(
              CommonConstants.getPath.account.serviceKey,
              CommonConstants.getPath.account.benifitAccounts,
              this.getRequestPayload(
                CommonConstants.getPath.account.BenifitKey,
                res.employeeIds,
                CommonConstants.getPath.account.benifiSearchType,
              ),
              { clientId: contact.clientId },
            )
            .pipe(
              map((benifitAccountsRes) => {
                return benifitAccountsRes.data;
              }),
              map((benefitAccounts) => {
                const employees: Model.ManageGridData[] = res.employees;
                const totalCount: number = res.totalCount;
                return { employees, totalCount, benefitAccounts };
              }),
            ),
        ),
      );
  }

  public getRequestPayload(
    key: string,
    value: string,
    type?: MatchType | string,
  ): Model.SearchCriteria {
    const payload: Model.SearchCriteria = [
      {
        key,
        value: value ? value : '',
        matchType: type ? (type as MatchType) : MatchType.EXACT,
      },
    ];
    return payload;
  }

  public getApproveList(
    requestPayload: Model.SearchCriteria,
    enrollBenefitsProfileId: string,
    page: number,
    offset: number,
  ): Observable<Model.ApproveList> {
    const contact = this.contactQuery.getActive();
    const pendingEmployeesResponse = this.serviceFactory.search<
      Model.ApproveGridData[]
    >(
      CommonConstants.getPath.account.serviceKey,
      CommonConstants.getPath.account.enrollBenefits,
      requestPayload,
      { profileId: enrollBenefitsProfileId },
      {
        take: page,
        skip: offset,
        orderBy: 'individualLastName',
        orderDirection: 'asc',
      },
      true,
    );
    const getPathBenefits: Model.ConfigurationPath =
      CommonConstants.getPath.configuration;
    const benefitPayload: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: contact.clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'CLIENT',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'requireEnrollmentApproval',
        value: 'true',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    const benefitPlansList = this.serviceFactory
      .search<Model.BenefitPlanData[]>(
        getPathBenefits.serviceKey,
        CommonConstants.getPath.configuration.benefits,
        benefitPayload,
        { profileId: contact.id },
        {
          take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
          skip: 0,
          orderBy: 'offeringName',
          orderDirection: 'asc',
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );

    return combineLatest([pendingEmployeesResponse, benefitPlansList]).pipe(
      map(
        (
          combinedResult: [
            Model.SearchResults<Model.ApproveGridData[]>,
            Model.BenefitPlanData[]
          ],
        ) => {
          const pendingEmps: Model.ApproveGridData[] = combinedResult[0].data;
          const benefitMap = new Map();
          const benefitFilter: Model.EmployeeTransactionFilteroptions[] = [];
          const benefitPlanIds: string[] = [];
          combinedResult[1].forEach(
            (benefit: Model.BenefitAccountEnrollmentViewModel) => {
              benefitMap.set(benefit.name, benefit);
              benefitFilter.push({ name: benefit.name, id: benefit.id });
            },
          );

          pendingEmps.forEach((pendingEmp) => {
            if (benefitMap.has(pendingEmp.planName)) {
              benefitPlanIds.push(benefitMap.get(pendingEmp.planName).id);
              pendingEmp.electionScheduleType = benefitMap.get(
                pendingEmp.planName,
              ).electionScheduleType;
            }
          });
          // to iterate on emps to gather multiple benefit accounts into single emp
          const pendingEnrollMap: Map<string, BenefitAccountEnrollment[]> =
            new Map();
          pendingEmps.forEach((enroll) => {
            if (pendingEnrollMap.has(enroll.individualId)) {
              const mapValue: BenefitAccountEnrollment[] = pendingEnrollMap.get(
                enroll.individualId,
              );

              const updatedMapValue: BenefitAccountEnrollment[] = [
                ...mapValue.slice(0, mapValue.length),
                enroll,
              ];
              pendingEnrollMap.set(enroll.individualId, updatedMapValue);
            } else {
              const tempArray: BenefitAccountEnrollment[] = [];
              tempArray.push(enroll);
              pendingEnrollMap.set(enroll.individualId, tempArray);
            }
          });

          const finalEnrollApproveResult: Model.ApproveGridData[] = [];
          pendingEnrollMap.forEach((enrollMapValue) => {
            const tempEnrollObj: Model.ApproveGridData = {
              benefitAccounts: enrollMapValue.slice(0, enrollMapValue.length),
              id: enrollMapValue[0].id,
              parentId: enrollMapValue[0].parentId,
              individualId: enrollMapValue[0].individualId,
              individualFullName: enrollMapValue[0].individualFullName,
              detailRow: false,
              selected: false,
              actionRow: false,
            };
            finalEnrollApproveResult.push(tempEnrollObj);
          });

          return {
            pendingEmployees: finalEnrollApproveResult,
            totalCount: combinedResult[0].totalCount,
            benefitFilter,
            benefitIds: benefitPlanIds,
          };
        },
      ),
      switchMap((response) => {
        const sourceAndSchedulePayload: Criteria[] = [
          {
            key: 'parentId',
            value: response.benefitIds.join('|'),
            matchType: MatchType.IN,
            chainType: ChainType.AND,
          },
          {
            key: 'parentType',
            value: 'BENEFIT_PLAN',
            matchType: MatchType.EXACT,
            chainType: ChainType.AND,
          },
          {
            key: 'fundingSource',
            value:
              FundingSourceType.ClientDirect +
              '|' +
              FundingSourceType.ClientToPlan,
            matchType: MatchType.IN,
            chainType: ChainType.AND,
          },
        ];
        return this.serviceFactory
          .search<Model.FundingData[]>(
            CommonConstants.getPath.configuration.serviceKey,
            CommonConstants.getPath.configuration.fundingSourceAndSchedule,
            sourceAndSchedulePayload,
            {
              profileId: contact.id,
              benefitPlanId: '*',
            },
          )
          .pipe(
            map((res) => {
              return res.data;
            }),
            map((fundingAndSourceResponse) => {
              const employerColumnShow: boolean =
                fundingAndSourceResponse.length > 0 ? true : false;
              return {
                pendingEmployees: response.pendingEmployees,
                totalCount: response.totalCount,
                benefitFilter: response.benefitFilter,
                employerColumnShow,
              };
            }),
          );
      }),
    );
  }

  /**
   * This function makes separate API calls for each benefit plan grouped by the 'effectiveDate'.
   * @param benefitPlanDateGroup The grouping returned from benefitPlanService.getEffectiveDateGrouping
   */
  public loadBenefitPlanFundingSources(
    benefitPlanDateGroup: Model.BenefitPlanQueryEffectiveDateGrouping[],
  ): Observable<void> {
    if (!benefitPlanDateGroup.length) {
      this.benefitPlanFundingSourceAndScheduleStore.set([]);
      return of(null);
    }
    const requests = benefitPlanDateGroup.map((group) =>
      this.getFundingSourceAndSchedules(
        group.benefitPlanIds,
        group.effectiveDate,
      ),
    );
    const request$ = zip(...requests).pipe(
      map((bpfss) =>
        new Array<BenefitPlanFundingSourceAndSchedule>().concat(...bpfss),
      ),
      withTransaction((bpfss) =>
        this.benefitPlanFundingSourceAndScheduleStore.set(bpfss),
      ),
    );
    return cacheable(this.benefitPlanFundingSourceAndScheduleStore, request$, {
      emitNext: true,
    }).pipe(mapTo(null));
  }

  public getFundingSourceAndSchedules(
    benefitPlanIds: string[],
    effectiveDate: string = 'ALL',
  ): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    const contact = this.contactQuery.getActive();
    const sourceAndSchedulePayload: Criteria[] = [
      {
        key: 'parentId',
        value: benefitPlanIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.BENEFIT_PLAN,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    return this.serviceFactory
      .search<BenefitPlanFundingSourceAndSchedule[]>(
        CommonConstants.getPath.configuration.serviceKey,
        CommonConstants.getPath.configuration.fundingSourceAndSchedule,
        sourceAndSchedulePayload,
        {
          profileId: contact.id,
          benefitPlanId: '*',
        },
        { effectiveDate },
      )
      .pipe(map(({ data }) => data));
  }

  /**
   * This function makes separate API calls for each benefit plan grouped by the 'effectiveDate'.
   * @param benefitPlanDateGroup The grouping returned from benefitPlanService.getEffectiveDateGrouping
   */
  public loadPayrollSchedulesByPlan(
    benefitPlanDateGroup: Model.BenefitPlanQueryEffectiveDateGrouping[],
  ): Observable<void> {
    if (!benefitPlanDateGroup.length) {
      this.payrollScheduleStore.set([]);
      return of(null);
    }
    const requests = benefitPlanDateGroup.map((group) =>
      this.getPayrollSchedules(group.benefitPlanIds),
    );
    const request$ = zip(...requests).pipe(
      map((payrollSchedules) =>
        new Array<PayrollSchedule>().concat(...payrollSchedules),
      ),
      withTransaction((payrollSchedules) =>
        this.payrollScheduleStore.set(payrollSchedules),
      ),
    );
    return cacheable(this.payrollScheduleStore, request$, {
      emitNext: true,
    }).pipe(mapTo(null));
  }

  public getBenefitPlans(ids?: string[]): Observable<BenefitPlan[]> {
    const contact = this.contactQuery.getActive();
    const benefitPlans = this.benefitPlanQuery.getAll();
    if (benefitPlans && benefitPlans.length) {
      return of(
        ids
          ? benefitPlans.filter((plan) => ids.indexOf(plan.id) >= 0)
          : benefitPlans,
      );
    } else {
      const getPath = CommonConstants.getPath.configuration;
      const clientId = contact.clientId;
      const plansServiceUrl = stringFormat(
        CommonConstants.getPath.configuration.benefitPlans,
        {
          profileId: contact?.id || '*',
        },
      );
      const requestPayload: GroupCriteria[] = [
        {
          key: 'parentId',
          value: clientId,
          matchType: MatchType.EXACT,
          chainType: ChainType.AND,
        },
        {
          key: 'parentType',
          value: 'CLIENT',
          matchType: MatchType.EXACT,
          chainType: ChainType.AND,
        },
      ];
      return this.serviceFactory
        .search<BenefitPlan[]>(
          getPath.serviceKey,
          plansServiceUrl,
          requestPayload,
        )
        .pipe(
          map((res) =>
            ids
              ? res.data.filter((plan) => ids.indexOf(plan.id) >= 0)
              : res.data,
          ),
        );
    }
  }

  public updateEnrollments(
    approveData: BenefitAccountEnrollment[],
    status: string,
  ): Observable<EntityBase[]> {
    const approveArray: Array<Observable<EntityBase>> = [];
    if (approveData) {
      approveData.forEach((data) => {
        const baseUrl: string = stringFormat(
          CommonConstants.getPath.account.benifitAccountInfo,
          {
            profileId: data.parentId,
            benifitAccountId: data.id,
          },
        );
        approveArray.push(
          this.serviceFactory
            .query(CommonConstants.getPath.account.serviceKey, baseUrl)
            .pipe(
              switchMap((getListResult: EntityBase) => {
                getListResult.lastTransition =
                  CommonConstants.commands.PendingApprovalTo + status;
                getListResult.currentState = status;
                const approveRequestPayload: Model.Command =
                  this.serviceFactory.createCommand(
                    getListResult,
                    CommonConstants.commands.PendingApprovalTo + status,
                  );
                const benefitServiceUrl: string = stringFormat(
                  CommonConstants.getPath.account.approveBenefits,
                  {
                    profileId: getListResult.parentId,
                    benefitAccountId: getListResult.id,
                    commandName:
                      CommonConstants.commands.PendingApprovalTo + status,
                  },
                );
                return this.serviceFactory
                  .queryPut<void>(
                    CommonConstants.getPath.account.serviceKey,
                    benefitServiceUrl,
                    approveRequestPayload,
                  )
                  .pipe(mapTo(getListResult));
              }),
            ),
        );
      });
      return forkJoin(approveArray);
    }
  }

  public getAllPendingEnrollments(
    clientId: string,
    profileId: string,
    page: number,
  ): Observable<EntityBase[]> {
    const requestPayload: Model.SearchCriteria = [
      {
        key: 'clientId',
        value: clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'PendingApproval',
        matchType: MatchType.EXACT,
      },
    ];
    return this.serviceFactory
      .search<EntityBase[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.enrollBenefits,
        requestPayload,
        { profileId },
        {
          take: page,
          skip: 0,
          orderBy: 'individualLastName',
          orderDirection: 'asc',
        },
      )
      .pipe(map((res) => res.data));
  }

  // BCWP 2703
  public savePersonalInfoAndEmploymentInfo(
    individualData: Individual,
    employmentData: EmploymentInfo,
    IndvCommandName: string,
    employerCommandName: string,
  ): Observable<EmploymentInfo> {
    const { clientId } = this.contactQuery.getActive();

    return this.individualService
      .updateIndividual(individualData, clientId, IndvCommandName, clientId)
      .pipe(
        switchMap(() => this.saveEmploymentInformation(employmentData, employerCommandName),
        ),
        catchError((e) => {
          this.routeContextProviderService.navigateTo([
            CommonConstants.appRoutes.enroll.enrollBase,
            CommonConstants.appRoutes.enroll.managebase,
          ]);
          return throwError(e);
        }),
      );
  }

  // BCWP 162 add employee personal information
  public savePersonalInformationService(
    data: Individual,
    commandName: string,
    bodyParams: Model.SearchCriteria,
  ): Observable<Model.ExistingEmploymentInfo> {
    // Adding new Employee
    if (commandName === CommonConstants.commands.StartToActive) {
      return this.individualService.searchIndividual(bodyParams, 1, 0).pipe(
        switchMap((empInfo) => {
          if (empInfo && empInfo.length > 0) {
            const personalInfo: Individual = empInfo[0];
            // tslint:disable-next-line:no-shadowed-variable
            const SearchCriteria: Model.SearchCriteria = [
              {
                key: 'externalId',
                value: personalInfo.externalId,
                matchType: MatchType.EXACT,
              },
              {
                key: 'currentState',
                value: personalInfo.currentState,
                matchType: MatchType.EXACT,
              },
            ];
            const search: Model.SearchParams = { take: 1, skip: 0, orderBy: 'created', orderDirection: 'desc'};
            return this.searchEmploymentInfo(SearchCriteria, search).pipe(
              map((value) => {
                if (value && value.length > 0) {
                  return {
                    employmentInfo: value[0],
                    personalInfo,
                  };
                } else {
                  return {
                    personalInfo,
                  };
                }
              }),
            );
          } else {
            sessionStorage.setItem(
              CommonConstants.localKeys.addedIndividualInfo,
              JSON.stringify(data),
            );
            return of({
              personalInfo: data,
            });
          }
        }),
      );
    } else {
      // Editing existing Employee
      return this.individualService
        .updateIndividual(data, data.id, undefined)
        .pipe(
          switchMap(() => this.individualQuery.selectActive()),
          first(),
          map((individual) => ({ personalInfo: individual })),
        );
    }
  }

  public searchEmploymentInfo(
    bodyParams: Model.SearchCriteria,
    searchParams: Model.SearchParams,
  ): Observable<EmploymentInfo[]> {
    const queryParams: Model.QueryParams = {
      userId: this.contactQuery.getActiveId(),
    };
    return this.serviceFactory
      .search<EmploymentInfo[]>(
        CommonConstants.getPath.profile.configurationKey,
        CommonConstants.getPath.profile.employmentList,
        bodyParams,
        queryParams,
        searchParams,
      )
      .pipe(map(({ data }) => data));
  }

  public countEmploymentInfo(): Observable<number> {
    const contact = this.contactQuery.getActive();

    const url: string = stringFormat(
      CommonConstants.getPath.profile.getEmployementInfoCountByParentId,
      {
        parentId: contact.clientId,
      },
    );

    return this.serviceFactory.query<{ count: number}>(
      CommonConstants.getPath.profile.configurationKey,
      url,
    )
    .pipe(map((x) => x.count));
  }

  // Add employment info
  public saveEmploymentInformation(
    data: EmploymentInfo,
    commandName: string,
  ): Observable<EmploymentInfo> {
    const parentId: string = data.parentId;
    const employmentInfoId: string = data.id;
    const command: Model.Command = this.serviceFactory.createCommand(
      data,
      commandName,
    );
    const employmentInfoServiceUrl: string = stringFormat(
      CommonConstants.getPath.profile.addEmploymentInfo,
      {
        parentId,
        employmentInfoId,
        command: commandName,
      },
    );
    return this.serviceFactory.queryPut(
      CommonConstants.getPath.profile.configurationKey,
      employmentInfoServiceUrl,
      command,
    );
  }

  // Get employment info
  public getEmploymentInfo(
    parentId: string,
    employmentInfoId: string,
  ): Observable<EmploymentInfo> {
    const url: string = stringFormat(
      CommonConstants.getPath.profile.getEmployementInfo,
      {
        parentId,
        employmentInfoId,
      },
    );
    return this.serviceFactory.query(
      CommonConstants.getPath.profile.configurationKey,
      url,
    );
  }

  public getEmployeePersonalInfo(
    serviceKey: string,
    basePath: string,
  ): Observable<Individual> {
    return this.serviceFactory.query(serviceKey, basePath);
  }

  /**
   * Returns payroll schedules by passing plans
   */
  public getPayrollSchedulesByPlans(
    plans: Array<BenefitPlan | string>,
  ): Observable<PayrollSchedule[]> {
    const profileId = this.contactQuery.getActiveId();

    const plansIds = plans.map((p) => (typeof p === 'object' ? p.id : p));
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: plansIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'BENEFIT_PLAN',
        matchType: MatchType.EXACT,
      },
    ];
    return this.serviceFactory
      .search<PayrollSchedule[]>(
        CommonConstants.getPath.profile.configurationKey,
        CommonConstants.getPath.profile.payrollSchedule,
        searchCriteria,
        { profileId },
        {
          take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
          skip: 0,
          orderBy: 'payrollId',
          orderDirection: 'asc',
          effectiveDate: 'ALL',
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public getStates(): States[] {
    return Object.entries(USStates).map(([key, value]) => ({
      id: key,
      name: value,
    }));
  }

  public saveElectionData(
    election: Election,
    command: ElectionCommandType = ElectionCommandType.StartToPending,
    useQueue: boolean = true,
  ): Observable<void> {
    if (Transitions.fromState(command) === 'Start') {
      election.id = uuid();
    }
    if (election.currentState === ElectionState.PendingApproval) {
      command = ElectionCommandType.StartToPendingApproval;
    }
    const data = this.commandFactory.createCommand(election, command);
    const basePath = stringFormat(
      CommonConstants.getPath.account.saveElection,
      {
        electionId: election.id,
        command,
      },
    );

    const params: Model.Headers = useQueue
      ? { headers: { 'x-uba-route': 'queue' } }
      : undefined;

    return this.serviceFactory.queryPut(
      CommonConstants.getPath.account.serviceKey,
      basePath,
      data,
      params,
    );
  }

  /**
   * Add or update Benefit Account,
   * Add or update Client and Individual Election,
   * Add or update Individual's info,
   * Add or update Dependent associations,
   * @param individual Individual Info
   * @param benefitAccount Benefit account to be added or updated
   * @param elections Elections to be added or updated
   * @param dependentSelection Array of dependents and eligibilityStartDate
   * @param isNewEnrollment Is new enrollment or editing current enrollment ?
   * @param ssnValue Individual's SSN
   */
  public addOrUpdateEnrollment(
    individual: Individual,
    benefitAccount: BenefitAccount,
    elections: Election[] = [],
    dependentSelection: Model.DependentSelection,
    isNewEnrollment: boolean,
    ssnValue: string,
    existingElections: Election[] = [],
  ): Observable<void> {
    // Add Benefit account and Elections
    return this.createBenefitAccount(
      individual.id,
      benefitAccount,
      isNewEnrollment,
      elections,
    ).pipe(
      switchMap(() => {
        const serviceArray = [];

        // Update Elections for already enrolled plans
        if (elections && !isNewEnrollment) {
          serviceArray.push(this.updateElections(elections, existingElections));
        }

        // Update SSN if missing/required
        if (!individual.socialSecurityNumber && ssnValue) {
          individual.socialSecurityNumber = `${ssnValue.substring(
            0,
            3,
          )}-${ssnValue.substring(3, 5)}-${ssnValue.substring(5, 9)}`;
          serviceArray.push(
            this.individualService.updateIndividual(individual, individual.id),
          );
        }

        // Add/Update dependent associations
        if (dependentSelection?.isAddOrUpdate) {
          const selectedDependents = dependentSelection.selectedDependents;

          // New associations
          selectedDependents.addAssociations.forEach((selection) => {
            serviceArray.push(
              this.addDependentAssociation(
                selection.dependent.id,
                benefitAccount,
                selection.eligibilityStartDate,
                dependentSelection.eligibilityEndDate,
              ),
            );
          });

          // Remove associations
          selectedDependents.removeAssociations.forEach((selection) => {
            serviceArray.push(
              this.updateDependentAssociation(
                selection.association,
                DependentBenefitAccessCommandType.ActiveToInactive,
              ),
            );
          });

          // Update associations
          selectedDependents.updateAssociations.forEach((selection) => {
            const association = Object.assign({}, selection.association);
            association.eligibilityStartDate = selection.eligibilityStartDate;
            association.eligibilityEndDate = selection.eligibilityEndDate;
            serviceArray.push(
              this.updateDependentAssociation(
                association,
                DependentBenefitAccessCommandType.ActiveToActive,
              ),
            );
          });
        }

        if (serviceArray.length) {
          return combineLatest(serviceArray).pipe(map(() => of(null)));
        } else {
          return of(null);
        }
      }),
    );
  }

  public prepareBenefitAccountBaseData(
    res: PayrollSchedule[],
    effectiveDate: string,
    clientPayrollId: string,
    parentId: string,
    planData: Model.EnrollEmployeeBenefitPlanViewModel,
    employmentInfo: EmploymentInfo,
  ): BenefitAccount {
    const contact = this.contactQuery.getActive();
    const payrollScheduleId = res.find(
      (ps) => ps.payrollId === clientPayrollId,
    );
    const returnObject: BenefitAccount = {
      id: uuid(),
      parentId,
      parentType: ParentType.INDIVIDUAL,
      version: 1,
      createdBy: `${contact.firstName} ${contact.lastName}`,
      createdById: contact.id,
      created: new Date().toISOString(),
      updatedBy: `${contact.firstName} ${contact.lastName}`,
      updatedById: contact.id,
      updated: Dates.now(),
      currentState: planData.requireEnrollmentApproval
        ? BenefitAccountState.PendingApproval
        : BenefitAccountState.Enrolled,
      lastTransition: planData.requireEnrollmentApproval
        ? 'StartToPendingApproval'
        : 'StartToEnrolled',
      clientId: contact.clientId,
      planId: planData.id,
      planName: planData.name,
      planDescription: planData.description,
      planStartDate: planData.planStartDate,
      planEndDate: planData.planEndDate,
      hireDate: employmentInfo.hireDate,
      benefitEffectiveDate: effectiveDate,
      ...(planData.scheduleEndDate
        ? { scheduleEndDate: planData.scheduleEndDate }
        : { eligibilityEndDate: planData.finalExpenseDate }),
      requestTypes: [],
      soaAccountId: '',
      carryoverDeclined: false,
      excludeFromBilling: false,
      enrollmentSource: EnrollmentSourceType.ClientWeb,
      clientScheduleFirstDate: effectiveDate,
      individualScheduleFirstDate: effectiveDate,
    };
    if (payrollScheduleId) {
      returnObject.payrollScheduleId = payrollScheduleId.id;
    }
    return returnObject;
  }

  public prepareBenefitAccountElectionBaseData(
    id: string,
    currentState: string,
    type: ElectionProfileType,
    amount: number,
    proratedAmount: number,
    effectiveDate: string,
    planData: Model.EnrollEmployeeBenefitPlanViewModel,
    calculationMethod: ElectionCalculationMethod,
    coverageTier?: BenefitPlanCoverageType,
  ): Election {
    const contact = this.contactQuery.getActive();
    return {
      id: uuid(),
      parentId: id,
      parentType: ParentType.BENEFIT_ACCOUNT,
      version: 1,
      createdBy: `${contact.firstName} ${contact.lastName}`,
      createdById: contact.id,
      created: new Date().toISOString(),
      updatedBy: `${contact.firstName} ${contact.lastName}`,
      updatedById: contact.id,
      updated: Dates.now(),
      currentState,
      lastTransition: `StartTo${currentState}`,
      electionProfileType: type,
      electionScheduleType: planData.electionScheduleType,
      amount,
      proratedAmount,
      bypassEnabled: false,
      effectiveDate,
      calculationMethod,
      coverageTier,
    };
  }

  public getElectionInfo(
    benefitAccount: BenefitAccount,
    benefitPlan: BenefitPlan,
  ): Observable<Election[]> {
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: benefitAccount.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: [
          ElectionState.Active,
          ElectionState.Pending,
          ElectionState.PendingApproval,
        ].join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];
    return this.serviceFactory
      .search<Election[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.electionData,
        searchCriteria,
        { profileId: this.contactQuery.getActiveId() },
        {
          take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
          skip: 0,
          orderBy: 'created',
          orderDirection: 'desc',
        },
      )
      .pipe(
        map((res) => {
          if (res?.data.length) {
            return res.data;
          }

          const theElection: Election[] = [
            {
              id: uuid(),
              currentState:
                benefitAccount.currentState ===
                  BenefitAccountState.PendingApproval
                  ? ElectionState.PendingApproval
                  : ElectionState.Pending,
              calculationMethod:
                this.getDefaultElectionCalculationMethod(benefitPlan),
              lastTransition: `StartTo${benefitAccount.currentState ===
                BenefitAccountState.PendingApproval
                ? ElectionState.PendingApproval
                : ElectionState.Pending
                }`,
              electionProfileType: ElectionProfileType.Individual,
              electionScheduleType: benefitPlan.electionScheduleType,
              effectiveDate: benefitAccount.benefitEffectiveDate,
              parentType: ParentType.BENEFIT_ACCOUNT,
              parentId: benefitAccount.id,
              amount: null,
            },
          ];
          return theElection;
        }),
      );
  }

  public getDefaultElectionCalculationMethod(
    benefitPlan: BenefitPlan,
  ): ElectionCalculationMethod {
    return benefitPlan.planType === BenefitPlanType.TieredPayouts
      ? ElectionCalculationMethod.System
      : ElectionCalculationMethod.User;
  }

  public getAccountBalancesInfo(
    individualId: string,
  ): Observable<Model.AccountElection> {
    const contact = this.contactQuery.getActive();
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.account.benefitAccountBalanceSearch,
      {
        profileId: individualId,
        clientId: contact.clientId,
      },
    );
    return this.serviceFactory
      .query<BenefitAccountBalance[]>(
        CommonConstants.getPath.account.serviceKey,
        baseUrl,
      )
      .pipe(
        switchMap((accounts: BenefitAccountBalance[]) => {
          const benefitIds: string[] = accounts.map(
            (a: BenefitAccountBalance) => a.planId,
          );
          return this.getActiveBenefitPlanData(benefitIds).pipe(
            switchMap((data: Model.BenefitPlanHSAParticipantDirect) => {
              const benefitAccountBalances: BenefitAccountBalance[] = [];
              accounts = this.filterBenefitAccounts(data.plans, accounts);
              const benefitPlanNonHSAIds: string[] =
                data.benefitNonHSAPLans.map((a: BenefitPlan) => a.id);
              const benefitPlanNonParticipantDirect: string[] =
                data.benefitNonParticipantDirectPlans.map(
                  (a: BenefitPlan) => a.id,
                );
              const benefitAccountNonParticipantDirect: BenefitAccountBalance[] =
                [];
              const benefitPlanNonParticipantDirectFundingSourceIds: string[] =
                data.benefitNonParticipantDirectFUndingSourcePlans.map(
                  (a: BenefitPlan) => a.id,
                );
              accounts.forEach(
                (account: Model.BenefitAccountBalanceViewModel) => {
                  account.isParticipantDirect = true;
                  account.isHSAFundingWithParticipantDirect = false;
                  account.isHiddenHSA = false;
                  if (benefitPlanNonHSAIds.indexOf(account.planId) >= 0) {
                    benefitAccountBalances.push(account);
                  }
                  if (
                    benefitPlanNonParticipantDirect.indexOf(account.planId) >= 0
                  ) {
                    account.isParticipantDirect = false;
                    benefitAccountNonParticipantDirect.push(account);
                  }
                  if (
                    benefitPlanNonParticipantDirectFundingSourceIds.indexOf(
                      account.planId,
                    ) >= 0 &&
                    benefitPlanNonHSAIds.indexOf(account.planId) < 0
                  ) {
                    account.isHSAFundingWithParticipantDirect = true;
                  }
                  if (
                    data.plans.some(
                      (plan) =>
                        account.planId === plan.id &&
                        plan.hideBenefitAccountActivityFromClients,
                    )
                  ) {
                    account.isHiddenHSA = true;
                  }
                },
              );
              const benefitAccountIds: string[] = accounts.map(
                (a: BenefitAccountBalance) => a.id,
              );
              //  const benefitIds: Array<string> = accounts.map((a: Model.BenefitAccountBalance) => a.planId);
              const serviceArray = [];
              // serviceArray.push(this.getActiveBenefitPlanData(benefitIds));
              serviceArray.push(this.searchElections(benefitAccountIds));
              serviceArray.push(
                this.getTransactions(benefitAccountBalances, individualId),
              );
              serviceArray.push(this.getDeductions(individualId));
              serviceArray.push(this.getPayrollSchedulesByPlans(data.plans));
              return combineLatest(serviceArray).pipe(
                map(
                  ([
                    elections,
                    contributionExpData,
                    entryBenefitAccountSummaries,
                    payrollSchedules,
                  ]: [
                      Election[],
                      Model.BenefitAccountPostingSummaryViewModel[],
                      Model.EntryBenefitAccountSummary[],
                      PayrollSchedule[]
                    ]) => {
                    const deductionData: Model.EntryBenefitAccountSummary[] =
                      entryBenefitAccountSummaries.filter(
                        (
                          benefitAccountSumm: Model.EntryBenefitAccountSummary,
                        ) => {
                          const validDeductionIds: string[] =
                            benefitAccountBalances.map(
                              (balancesData: BenefitAccountBalance) =>
                                balancesData.planId,
                            );
                          return (
                            validDeductionIds.indexOf(
                              benefitAccountSumm.benefitPlanId,
                            ) >= 0
                          );
                        },
                      );
                    return {
                      elections,
                      benefitAccountNonParticipantDirect,
                      accounts,
                      contributionExpData,
                      plans: data,
                      deductionData,
                      payrollSchedules,
                    };
                  },
                ),
              );
            }),
          );
        }),
      );
  }

  /**
   * Get benefit accounts belonging to the specified employee for the current client.
   */
  public getIndividualAndBenefitsAccounts(
    selectedEmployee: EmploymentInfo,
  ): Observable<Model.IndividualAndBenefitsAccounts> {
    const contact = this.contactQuery.getActive();
    const clientId = contact.clientId;
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: selectedEmployee.individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'INDIVIDUAL',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'hideFrom',
        value: [HideFromType.Client, HideFromType.ParticipantAndClient].join(
          '|',
        ),
        matchType: MatchType.NOT_IN,
        chainType: ChainType.AND,
      },
      {
        key: 'clientId',
        value: contact.clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.OR,
        groupCriteria: [
          {
            key: 'clientId',
            value: contact.clientId,
            matchType: MatchType.IS_NULL,
            chainType: ChainType.OR,
          },
        ],
      },
    ];
    return this.serviceFactory
      .search<BenefitAccount[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.benifitAccounts,
        searchCriteria,
        { clientId },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
        switchMap((accounts) => {
          return this.individualService
            .getIndividualById(selectedEmployee.individualId)
            .pipe(
              map((individual) => {
                return { accounts, individual };
              }),
            );
        }),
      );
  }

  public getBenefitPlanOverallAmounts(
    criteria: Model.ElectionLimitSearchCriteria,
  ): Observable<ElectionLimit[]> {
    const today = Dates.today();

    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: criteria.offeringId,
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];
    if (criteria.benefitPlanEffectiveDate) {
      // calculate effective start date
      searchCriteria.push({
        key: 'applyToExistingBenefitPlans',
        value: '1',
        matchType: MatchType.EXACT,
        chainType: ChainType.OR,
        groupCriteria: [
          {
            key: 'effectiveStartDate',
            value: criteria.benefitPlanEffectiveDate,
            matchType: MatchType.LESS_THAN_EQUAL,
            chainType: ChainType.AND,
          },
        ],
      });
      if (criteria.benefitPlanEffectiveDate <= today) {
        searchCriteria.push({
          key: 'effectiveStartDate',
          value: today,
          matchType: MatchType.LESS_THAN_EQUAL,
          chainType: ChainType.AND,
        });
      }
    } else if (criteria.isCurrentlyEffective) {
      // return records only effective as of today
      searchCriteria.push({
        key: 'effectiveStartDate',
        value: today,
        matchType: MatchType.LESS_THAN_EQUAL,
        chainType: ChainType.AND,
      });
    }

    return this.serviceFactory
      .search<ElectionLimit[]>(
        CommonConstants.getPath.configuration.serviceKey,
        CommonConstants.getPath.configuration.getOverallAmount,
        searchCriteria,
        {
          profileId: criteria.offeringId,
          serviceOfferingId: criteria.offeringId,
        },
        { orderBy: 'effectiveStartDate', orderDirection: 'desc' },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public getBenefitPlan(id: string): Observable<BenefitPlan> {
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.configuration.getBenefitPlan,
      {
        profileId: id,
        benefitPlanId: id,
      },
    );
    return this.serviceFactory.query<BenefitPlan>(
      CommonConstants.getPath.configuration.serviceKey,
      baseUrl,
    );
  }

  public getAmounts(
    account: Model.BenefitAccountBalanceViewModel,
    individualId: string,
  ): Observable<Model.SummaryTableData> {
    const contact = this.contactQuery.getActive();
    const limitCriteria: Model.ElectionLimitSearchCriteria = {
      offeringId: account.benefitPlan.offeringId,
      benefitPlanEffectiveDate: account.benefitPlan.planStartDate,
      isCurrentlyEffective: true,
      latestLimitOnly: true,
    };
    return combineLatest([
      this.getAccountOffering(account.benefitPlan.offeringId),
      this.searchEnteredAccountEntries(individualId, account.id),
      this.getBenefitPlanOverallAmounts(limitCriteria),
      this.getFundingSourcesAndSchedules(contact.clientId, account.planId),
    ]).pipe(
      map(
        ([
          accountOffering,
          accountEntries,
          electionLimit,
          benefitPlanFundingSourceAndSchedule,
        ]) => ({
          plan: account.benefitPlan,
          accountOffering,
          accountEntries,
          electionLimit,
          benefitPlanFundingSourceAndSchedule,
        }),
      ),
    );
  }

  public getFundingSourcesAndSchedules(
    clientId: string,
    benefitPlanId: string,
  ): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    const criteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: benefitPlanId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'BENEFIT_PLAN',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Active',
        matchType: MatchType.EXACT,
      },
    ];

    return this.serviceFactory
      .search<BenefitPlanFundingSourceAndSchedule[]>(
        CommonConstants.getPath.configuration.serviceKey,
        CommonConstants.getPath.configuration.fundingSourceAndSchedule,
        criteria,
        {
          profileId: this.contactQuery.getActiveId(),
          benefitPlanId,
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public setGetEmployeeDocumentByEmployeeIdSubject(request: { page: number, offset: number, searchCriteria: Model.SearchCriteria }): void {
    this.getEmployeeDocumentByEmployeeIdSubject.next(request);
  }

  public getEmployeeDocumentByEmployeeId(
    page: number,
    offset: number,
    searchCriteria: Model.SearchCriteria,

  ): Observable<SearchResults<Document[]>> {
    const contact = this.contactQuery.getActive();
    const queryParams: Model.QueryParams = {
      profileId: '*',
    };

    const searchParams: Model.SearchParams = {
      take: page,
      skip: offset,
      orderBy: 'created',
      orderDirection: 'desc',
    };

    return this.serviceFactory.search<Document[]>(
      CommonConstants.getPath.profile.configurationKey,
      CommonConstants.getPath.profile.documents,
      searchCriteria,
      queryParams,
      searchParams,
      false,
    );

  }

  public getCommunicationTemplates(): Observable<SearchResults<CommunicationTemplate[]>> {
    const contact = this.contactQuery.getActive();
    const queryParams: Model.QueryParams = {
      profileId: '*',
    };

    const searchCriteria: Criteria[] = [
      { key: 'currentState', value: CommunicationTemplateState.Published },
    ];

    return this.serviceFactory.search<CommunicationTemplate[]>(
      CommonConstants.getPath.communication.serviceKey,
      CommonConstants.getPath.profile.communicationTemplate,
      searchCriteria,
      queryParams,
      null,
      false,
    );
  }

  public setGenerateCommunicationTemplateDocumentSubjectNext(generateCommunicationTemplateDocumentRequest: GenerateCommunicationTemplateDocumentRequest): void {
    this.generateCommunicationTemplateDocumentSubject.next(generateCommunicationTemplateDocumentRequest);
  }

  public resetGenerateLetterTemplateDocument(): void {
    this.generateCommunicationTemplateDocumentSubject.next(null);
    this.isBusySubject.next(false);
  }

  public generateCommunicationRequest(individualId: string, requestType: CommunicationRequestType): Observable<boolean> {
    const communicationRequest: CommunicationRequest = {
      id: uuid(),
      parentType: CParentType.INDIVIDUAL,
      parentId: individualId,
      communicationRequestType: requestType,
      recipientId: individualId,
    };

    const commandName: string = 'StartToRequested';
    const commandData: Model.Command = this.serviceFactory.createCommand(
      communicationRequest,
      commandName,
    );
    const communicationRequestId = communicationRequest.id;

    const url: string = stringFormat(
      CommonConstants.getPath.communication.communicationRequestURI,
      { communicationRequestId, commandName },
    );

    return this.serviceFactory.queryPut(
      CommonConstants.getPath.communication.serviceKey,
      url,
      commandData,
    );
  }

  public getCommunicationTypes(): Observable<CommunicationType[]> {
    const getPath = CommonConstants.getPath.communication;
    const comTypeServiceUrl = CommonConstants.getPath.communication.communicationTypesURI;
    const requestPayload: GroupCriteria[] = [
      {
        key: 'parentType',
        value: 'INSTANCE',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Active',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    return this.serviceFactory
      .search<CommunicationType[]>(
        getPath.serviceKey,
        comTypeServiceUrl,
        requestPayload,
      )
      .pipe(map((res) => res.data));
  }

  public getCommunicationRegistry(): Observable<CommunicationRegistry[]> {
    const getPath = CommonConstants.getPath.communication;
    const comRegistryUrl = CommonConstants.getPath.communication.communicationRegistryURI;
    const requestPayload: GroupCriteria[] = [
      {
        key: 'parentType',
        value: 'COMMUNICATION_TYPE',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Active',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    return this.serviceFactory
      .search<CommunicationRegistry[]>(
        getPath.serviceKey,
        comRegistryUrl,
        requestPayload,
      )
      .pipe(map((res) => res.data));
  }

  public setDownloadLetterSubjectNext(document: Document): void {
    this.downloadLetterSubject.next(document);
  }

  public resetDownloadLetterDocument(): void {
    this.downloadLetterSubject.next(null);
    this.isDownloadingLeterSubject.next(false);
    this.isBusySubject.next(false);
  }

  public downloadLetter(document: Document): Observable<Model.signedUrlModel | string> {
    const uri = new Uri(
      `/profile/${document.parentId}/configuration/document/downloadUrl/${document.id}`,
      CoreService.ProfileConfiguration,
    );
    return this.http.get<Model.signedUrlModel>(uri.toString())
      .pipe(
        catchError((err) => {
          return of('failure');
        }),
      );
  }

  public getEmployeeListByClient(
    offset: number,
    page: number,
    searchCriteria: Model.SearchCriteria,
    employeeByClientGroupingFlag: boolean = false,
  ): Observable<Model.SearchResults<Model.EmployeesByClient[]>> {
    const contact = this.contactQuery.getActive();
    const queryParams: Model.QueryParams = {
      profileId: contact.clientId,
    };
    const searchParams: Model.SearchParams = {
      skip: offset,
      take: page,
      employeeByClientGrouping: employeeByClientGroupingFlag
        ? 'BenefitAccount'
        : null,
      orderBy: 'individualCreated',
      orderDirection: 'desc',
    };
    return this.serviceFactory.search<Model.EmployeesByClient[]>(
      CommonConstants.getPath.dashboard.serviceKey,
      CommonConstants.getPath.dashboard.employeesByClient,
      searchCriteria,
      queryParams,
      searchParams,
      true,
    );
  }

  public getEmployeeListByBenefitAccountGroup(
    serviceArray: Array<
      Observable<Model.SearchResults<Model.EmployeesByClient[]>>
    >,
  ): Observable<Array<Model.SearchResults<Model.EmployeesByClient[]>>> {
    return combineLatest(serviceArray);
  }

  public mapEmployeeInfo(
    info: Model.EmployeesByClient,
  ): Model.SharedEmployeeInfo {
    const empInfo = {
      id: info.employeeId,
      individualId: info.individualId,
      externalId: info.individualTascId,
      individualName: info.individualFullName,
      clientPayrollId: info.employeeClientPayrollId,
      parentId: info.clientId,
      eligibilityClassId: info.employeeEligibilityClassId,
      hireDate: info.employeeHireDate,
      employmentStatus: info.employeeStatus,
    };
    this.individualService.setActiveIndividual(info.individualId);
    return empInfo;
  }

  /**
   * Provides Dependents, Dependent-benefitAccount-associations and related benefitAccounts
   * @param individualId Individual's id for which data to be fetched
   */
  public getDependentInformation(
    getAccounts: boolean = true,
    forBCS: boolean = false,
  ): Observable<Model.DependentInformation> {
    let dependents: Dependent[];
    return this.individualQuery.selectActiveId().pipe(
      first(),
      switchMap((individualId: string) => this.getDependents(individualId).pipe(
        switchMap((dependentsResp) => {
          dependents = dependentsResp.data;
          const dependentIds = dependents.map((d) => d.id);
          return dependentIds.length && !forBCS
            ? this.getAssociationsAndAccounts(dependentIds, getAccounts)
            : of({
              accounts: [],
              associations: [],
            } as Model.AssociationsAndAccounts);
        }),
        map((associations) => {
          const data = { dependents, ...associations };
          this.dependentInformationStore$.next(data);
          return data;
        }),
      )));
  }

  public getActiveDependents(): Observable<Dependent[]> {
    return combineLatest([
      this.dependentSelection$,
      this.dependentInformation$,
    ]).pipe(
      map(([selection, information]) => {
        if (!selection.selectedDependents) {
          return [];
        }
        const newDependents =
          this.getNewDependentsWithActiveAssociation(selection);
        const existingDependents =
          this.getExistingDependentsWithActiveAssociation(
            selection,
            information,
          );
        return uniq([...newDependents, ...existingDependents]);
      }),
    );
  }

  /**
   * Adds dependent association by passing
   * @param dependent Associated Dependent
   * @param benefitAccount Associated Benefit account
   * @param eligibilityStartDate Dependent's eligibility start date; selected by client
   * @param eligibilityEndDate Dependent's eligibility end date
   */
  public addDependentAssociation(
    dependentId: string,
    benefitAccount: BenefitAccount,
    eligibilityStartDate: string,
    eligibilityEndDate: string,
  ): Observable<void> {
    const payload: DependentBenefitAccess = {
      id: uuid(),
      benefitAccountId: benefitAccount.id,
      eligibilityStartDate, // eligibilityStartDate selected by client
      eligibilityEndDate, // eligibilityEndDate selected by client
      parentType: ParentType.DEPENDENT,
      parentId: dependentId,
    };
    return this.updateDependentAssociation(
      payload,
      DependentBenefitAccessCommandType.StartToActive,
    );
  }

  /**
   * Updates dependent association
   * @param payload Association
   * @param commandName of type DependentBenefitAccessCommandType
   */
  public updateDependentAssociation(
    payload: DependentBenefitAccess,
    commandName: DependentBenefitAccessCommandType,
  ): Observable<void> {
    const profileId = this.contactQuery.getActiveId();
    const commandData: Model.Command = this.serviceFactory.createCommand(
      payload,
      commandName,
    );
    const url: string = stringFormat(
      CommonConstants.getPath.account.saveDependentAssociation,
      { profileId, commandName },
    );
    return this.serviceFactory.queryPut(
      CommonConstants.getPath.account.serviceKey,
      url,
      commandData,
    );
  }

  /**
   * Updates dependent
   * @param dependent Dependent to be updated
   * @param transition Dependent command
   * @returns Updated dependent
   */
  public updateDependent(
    dependent: Dependent,
    transition: string,
  ): Observable<Dependent> {
    const command = this.serviceFactory.createCommand(dependent, transition);
    const url = stringFormat(
      CommonConstants.getPath.profile.addUpdateDependent,
      {
        dependentId: dependent.id,
        commandName: transition,
      },
    );
    return this.serviceFactory
      .queryPut(CommonConstants.getPath.profile.serviceKey, url, command)
      .pipe(
        switchMap(() => transition !== `${DependentState.Active}To${DependentState.Removed}` ? this.getDependent(dependent.id) : of(null)),
        tap((updatedDependent) => {
          const newData = { ...this.dependentInformationStore$.getValue() };
          if (transition === `${DependentState.Start}To${DependentState.Active}`) {
            newData.dependents.push(updatedDependent);
          } else if (transition === `${DependentState.Active}To${DependentState.Active}`) {
            newData.dependents = newData.dependents.filter((d) => d.id !== dependent.id);
            newData.dependents.push(dependent);
          } else if (transition === `${DependentState.Active}To${DependentState.Removed}`) {
            newData.dependents = newData.dependents.filter((d) => d.id !== dependent.id);
          }
          this.dependentInformationStore$.next(newData);
        }),
      );
  }

  public getIsElectionChangeValid(
    election: Election,
    electionSum?: number,
  ): Observable<IsElectionChangeValidResult> {
    const query: IsElectionChangeValidQuery = {
      accountId: election.parentId,
      effectiveDate: election.effectiveDate,
      electionProfileType: election.electionProfileType,
      amount: election.amount,
      calculationMethod: election.calculationMethod,
      electionSum,
    };
    const uri = new Uri(
      `/profile/*/benefitAccount/${election.parentId}/isElectionChangeValid`,
      CoreService.Account,
      query,
    );
    return this.http.get<IsElectionChangeValidResult>(uri.toString());
  }

  public transferOnDemandFunds(
    transferData: FundsTransferCriterion,
    individualId: string,
  ): Observable<null> {
    const command = this.serviceFactory.createCommand(
      transferData,
      'StartToActive',
    );
    const url = stringFormat(CommonConstants.getPath.profile.fundsTransfer, {
      individualId,
      fundsTransferDataId: transferData.id,
      commandName: 'StartToActive',
    });
    return this.serviceFactory.queryPut(
      CommonConstants.getPath.profile.configurationKey,
      url,
      command,
    );
  }

  public getOnDemandFundsData(
    selectedEmployee: EmploymentInfo,
  ): Observable<Model.OnDemandFundsData> {
    return this.getIndividualAndBenefitsAccounts(selectedEmployee).pipe(
      switchMap(({ accounts, individual }) => {
        const planIds = accounts.map((acc) => acc.planId);
        return this.getFundingSourceAndSchedules(planIds).pipe(
          map(
            (
              fundingSourcesAndSchedules: BenefitPlanFundingSourceAndSchedule[],
            ) => ({
              benefitAccounts: accounts,
              individual,
              fundingSourcesAndSchedules,
            }),
          ),
        );
      }),
    );
  }

  /**
   * Makes a <currentState>To<currentState> transition on a benefit account to update it with the values passed in as an object.
   */
  public updateBenefitAccount(
    benefitAccount: BenefitAccount,
  ): Observable<void> {
    const commandName = `${benefitAccount.currentState}To${benefitAccount.currentState}`;
    const url = new Uri(
      `/profile/${benefitAccount.parentId}/benefitAccount/${benefitAccount.id}/command/${commandName}`,
      CoreService.Account,
    );

    const command = this.commandFactory.createCommand(
      benefitAccount,
      commandName,
    );

    return this.http.put<void>(url.toString(), command);
  }

  /**
   * Gets a benefit account from the API using the specified ID.
   */
  public getBenefitAccountById(
    individualId: string,
    benefitAccountId: string,
  ): Observable<BenefitAccount> {
    const uri = new Uri(
      `/profile/${individualId}/benefitAccount/${benefitAccountId}`,
      CoreService.Account,
    );
    return this.http.get<BenefitAccount>(uri.toString());
  }

  public getTascCardData(
    employeeId: string,
  ): Observable<Model.IndividualDependentInfo> {
    return this.getBenefitAccountswithPlans(employeeId).pipe(
      switchMap((result) => {
        if (
          result.benefitPlans?.length > 0 &&
          result.benefitAccounts?.length > 0 &&
          this.checkForFutureDisbursableDate(
            result.benefitAccounts,
            result.benefitPlans,
          )
        ) {
          return of({
            noCardsAvailable: true,
          });
        }
        return this.listDependentsWithCards(employeeId);
      }),
    );
  }

  /**
   * Returns boolean based on dependent to be shown/hidden on UI
   * @param dep Dependent
   */
  public isDependentAllowedToShow(dep: Dependent): boolean {
    // Show if not deceased or not hidden from individual
    return (
      dep.currentState !== DependentState.Deceased &&
      !dep.isHiddenFromIndividual
    );
  }

  public getBenefitPlansEligibleForEnrollment(
    employmentInfo: EmploymentInfo,
  ): Observable<Model.EmployeeAndEnrollmentData> {
    let enrollmentData: Model.EmployeeAndEnrollmentData = {};
    return zip(
      this.getPlansEligibleForEnrollment(employmentInfo.individualId),
      this.payrollScheduleQuery.selectAllWhenLoaded(),
      this.getIndividualAndBenefitsAccounts(employmentInfo),
    ).pipe(
      map(([eligiblePlans, payrollSchedules, individualBenefitAccounts]) => {
        enrollmentData = { ...individualBenefitAccounts };
        return { eligiblePlans, payrollSchedules };
      }),
      switchMap(({ eligiblePlans, payrollSchedules }) => {
        return zip(
          of(eligiblePlans),
          of(payrollSchedules),
          this.getFundingSourceAndSchedules(
            eligiblePlans.map(({ id }) => id),
          ),
          this.getBenefitPlanRequestTypes(eligiblePlans),
        );
      }),
      map(([eligiblePlans, payrollSchedules, benefitPlanFundingSourceAndSchedules, requestTypes]) => {
        let planViewModels = eligiblePlans.map((benefitPlan) => {
          const planVM = cloneDeep(benefitPlan) as Model.EnrollEmployeeBenefitPlanViewModel;
          planVM.insideEnrollmentPeriod = this.isTodayWithinPlanEnrollmentWindow(planVM);
          planVM.isOpenEnded = !planVM.planEndDate;
          planVM.fundingSourcesAndSchedules = benefitPlanFundingSourceAndSchedules.filter((fss) => fss.parentId === planVM.id);
          planVM.payrollSchedules = payrollSchedules.filter((ps) => ps.parentId === planVM.id);
          planVM.payrollsMatchingWithEmployee = planVM.payrollSchedules.filter((ps) => ps.payrollId === employmentInfo?.clientPayrollId);

          if (planVM.currentState === BenefitPlanState.Setup) {
            planVM.isEnrolled = true;
          }
          if (planVM.currentState === BenefitPlanState.Active && planVM.insideEnrollmentPeriod) {
            planVM.isEnrolled = true;
            planVM.disableInFlowAfterSuccess = true;
          }
          if (!enrollmentData.accounts.some((ba) => ba.planId === planVM.id)) {
            planVM.isEnrolled = false;
          }
          return planVM;
        });

        planViewModels = orderBy(planViewModels, 'planStartDate');
        enrollmentData.eligiblePlans = planViewModels;
        enrollmentData.payrollSchedules = payrollSchedules;
        enrollmentData.showNoPayrollIdError = this.isAllPayrollPlans(
          employmentInfo,
          planViewModels,
        );
        enrollmentData.requestTypes =
          requestTypes as Model.BenefitPlanRequestType[];
        return enrollmentData;
      }),
    );
  }

  /**
   * This function handles all the logic to check for if today falls between the enrollment start & end dates.
   * Open ended plans are handled a little differently, due to not having a year associated for the enrollment start & end dates.
   * @param plan The plan we are checking for eligible enrollment.
   */
  public isTodayWithinPlanEnrollmentWindow(plan: BenefitPlan): boolean {
    const today = Dates.today();
    // Open Ended Plan:
    if (!plan.planEndDate) {
      const { firstDate, secondDate } = this.getOpenEndedEnrollmentRanges(plan);
      return (
        Dates.isBetween(today, firstDate.startDate, firstDate.endDate) ||
        Dates.isBetween(today, secondDate.startDate, secondDate.endDate)
      );
    }
    // Regular Plans:
    return Dates.isBetween(
      today,
      plan.enrollmentStartDate,
      plan.enrollmentEndDate,
    );
  }

  /**
   * This will return an array of BenefitPlanQueryEffectiveDateGrouping that will give you the necessary effectiveDate with the associated benefit plan ids.
   * @param benefitPlans The benefit plans you would like to be grouped by the date needed to pull the correct schedules for enrollment.
   */
  public getBenefitPlansGroupedByEffectiveDate(
    benefitPlans: BenefitPlan[],
  ): Model.BenefitPlanQueryEffectiveDateGrouping[] {
    // Mapping/filter helpers:
    const isOpenEndedAndPlanStartInPast = (plan: BenefitPlan) =>
      !plan.planEndDate && Dates.isBefore(plan.planStartDate, Dates.now());
    const toIds = (plan: BenefitPlan) => plan.id;
    const joinIds = (value: { effectiveDate: string; ids: string[] }) =>
      value.ids;

    const [
      openEndedPlansStartingInThePast,
      regularPlansOrOpenEndedPlansStartingInFuture,
    ] = partition(benefitPlans, isOpenEndedAndPlanStartInPast);

    // NOTE: For plans that start in the future we can place the same logic for getting the BPFSS/PayrollSchedules the same as regular plans.
    const openEndedPlanCurrentScheduleGrouping = chain(
      openEndedPlansStartingInThePast,
    )
      .groupBy('scheduleEndDate')
      .map((plans, scheduleEndDate) => ({
        effectiveDate: this.getCurrentScheduleStartDate(scheduleEndDate),
        ids: plans.map(toIds),
      }))
      .value();

    const openEndedPlanNextScheduleGrouping = chain(
      openEndedPlansStartingInThePast,
    )
      .groupBy('scheduleEndDate')
      .map((plans, scheduleEndDate) => ({
        effectiveDate: this.getNextScheduleStartDate(scheduleEndDate),
        ids: plans.map(toIds),
      }))
      .value();

    const openEndedGrouping = openEndedPlanCurrentScheduleGrouping.concat(
      openEndedPlanNextScheduleGrouping,
    );

    const regularPlanGrouping = chain(
      regularPlansOrOpenEndedPlansStartingInFuture,
    )
      .groupBy('planStartDate')
      .map((plans, planStartDate) => ({
        effectiveDate: planStartDate,
        ids: plans.map(toIds),
      }))
      .value();

    return chain(openEndedGrouping.concat(regularPlanGrouping))
      .groupBy('effectiveDate')
      .map((objects, groupedDate) => ({
        effectiveDate: groupedDate,
        benefitPlanIds: Array<string>().concat(...objects.map(joinIds)),
      }))
      .value();
  }

  /**
   * This will add one day to the date and use the current year and return a date string with format (YYYY-MM-DD).
   * @param scheduleEndDate The scheduleEndDate from the BenefitPlan.
   */
  public getCurrentScheduleStartDate(scheduleEndDate: string): string {
    const date = Dates.fromString(scheduleEndDate, 'MM/DD');
    if (date.format('MM/DD') === '12/31') {
      return date.add(1, 'day').subtract(1, 'year').format('YYYY-MM-DD');
    } else {
      return date.add(1, 'day').format('YYYY-MM-DD');
    }
  }

  public getNextScheduleStartDate(scheduleEndDate: string): string {
    return Dates.fromString(this.getCurrentScheduleStartDate(scheduleEndDate))
      .add(1, 'year')
      .format('YYYY-MM-DD');
  }

  public getBenefitAccountswithPlans(
    individualId: string,
  ): Observable<Model.BenefitAcccountsWithPlans> {
    return this.getBenefitAccounts(individualId).pipe(
      switchMap((accounts: BenefitAccount[]) => {
        const ids: string[] = accounts.map((record) => record.planId);
        return this.getBenefitPlans(ids).pipe(
          map((plans) => {
            const benefitAccountsWithPlans: Model.BenefitAcccountsWithPlans = {
              benefitPlans: plans,
              benefitAccounts: accounts,
            };
            return benefitAccountsWithPlans;
          }),
        );
      }),
    );
  }

  /**
   * Gets the feature accounts belonging to the specified employee. MyCash will be the one where name='IAB'
   * and Advance (CDP) will be name='PPTAB'.
   */
  public getFeatureAccounts(
    individualId: string,
  ): Observable<FeatureAccount[]> {
    const contact = this.contactQuery.getActive();

    const uri = new Uri(
      `/profile/${individualId}/client/${contact.clientId}/accounts/accountType/feature`,
      CoreService.Account,
    );

    return this.http
      .get<FeatureAccount[]>(uri.toString())
      .pipe(
        map((featureAccounts) =>
          Array.isArray(featureAccounts) ? featureAccounts : [],
        ),
      );
  }

  public getPayrollDates(
    benefitPlanId: string,
    payrollScheduleId: string,
    effectiveDate: string,
  ): Observable<SearchPayrollDatesResponse> {
    const url = new Uri(
      `/profile/${this.contactQuery.getActiveId()}/configuration/benefitPlan/${benefitPlanId}/calculatePayrollDates`,
      CoreService.Configuration,
      {
        payrollScheduleId,
        effectiveDate,
      },
    );

    return this.http.get<SearchPayrollDatesResponse>(url.toString());
  }

  private getEmployeeIds(employees: Model.ManageGridData[]): string {
    let employeeIds = '';
    if (employees && employees.length) {
      employees.forEach((employee, i) => {
        employeeIds = employeeIds.concat(employee.individualId);
        if (i !== employees.length - 1) {
          employeeIds = employeeIds.concat('|');
        }
      });
    }
    return employeeIds;
  }

  public filterBenefitAccounts(
    plans: BenefitPlan[],
    accounts: BenefitAccountBalance[],
  ): BenefitAccountBalance[] {
    accounts = accounts.filter(
      (shownAccount) =>
        shownAccount.hideFrom === HideFromType.None ||
        shownAccount.hideFrom === HideFromType.Participant,
    );
    return accounts.filter((account) =>
      plans.some((plan) => account.planId === plan.id),
    );
  }

  private getBenefitAccounts(
    id: string,
    type?: string,
    matchType?: MatchType,
  ): Observable<BenefitAccount[]> {
    const contact = this.contactQuery.getActive();
    return this.serviceFactory
      .search<BenefitAccount[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.benifitAccounts,
        this.getRequestPayload(
          type || CommonConstants.getPath.account.BenifitKey,
          id,
          matchType || MatchType.EXACT,
        ),
        { clientId: contact.clientId },
      )
      .pipe(map(({ data }) => data));
  }

  private createBenefitAccount(
    id: string,
    benefitAccountData: BenefitAccount,
    isNew: boolean = false,
    election: Election[],
  ): Observable<void> {
    const basePath = stringFormat(
      CommonConstants.getPath.account.benefitAccountsUpdate,
      {
        profileId: id,
        command: isNew
          ? benefitAccountData.lastTransition
          : `${benefitAccountData.currentState}To${benefitAccountData.currentState}`,
        benefitAccountId: benefitAccountData.id,
      },
    );
    benefitAccountData.lastTransition = isNew
      ? benefitAccountData.lastTransition
      : `${benefitAccountData.currentState}To${benefitAccountData.currentState}`;
    const data = this.serviceFactory.createCommand(
      benefitAccountData,
      isNew
        ? benefitAccountData.lastTransition
        : `${benefitAccountData.currentState}To${benefitAccountData.currentState}`,
    );

    if (isNew) {
      election.forEach((elec: Election) => {
        if (elec.electionProfileType === ElectionProfileType.Individual) {
          data.individualElection = elec;
        } else if (elec.electionProfileType === ElectionProfileType.Client) {
          data.clientElection = elec;
        }
      });
    }

    return this.serviceFactory.queryPut<void>(
      CommonConstants.getPath.account.serviceKey,
      basePath,
      data,
    );
  }

  private getPostingFundingAndSchedules(
    plans: BenefitPlan[],
  ): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    const contact = this.contactQuery.getActive();
    const postings: Array<Observable<BenefitPlanFundingSourceAndSchedule[]>> =
      [];
    // let bodyParams: Model.SearchCriteria;
    plans
      .map((p) => p.id)
      .forEach((planId) => {
        const bodyParams = [
          {
            key: 'parentId',
            value: planId,
            matchType: MatchType.EXACT,
            chainType: ChainType.AND,
          },
          {
            key: 'parentType',
            value: 'BENEFIT_PLAN',
            matchType: MatchType.EXACT,
          },
        ];
        postings.push(
          this.serviceFactory
            .search<BenefitPlanFundingSourceAndSchedule[]>(
              CommonConstants.getPath.configuration.serviceKey,
              CommonConstants.getPath.configuration.fundingSourceAndSchedule,
              bodyParams,
              {
                profileId: contact.clientId,
                benefitPlanId: planId,
              },
            )
            .pipe(
              map((res) => {
                return res.data;
              }),
            ),
        );
      });
    return combineLatest(postings).pipe(
      map((fundingSourceAndSchedule) => [].concat(...fundingSourceAndSchedule)),
    );
  }

  public getActiveBenefitPlanData(
    ids: string[],
  ): Observable<Model.BenefitPlanHSAParticipantDirect> {
    const contact = this.contactQuery.getActive();
    const getPath = CommonConstants.getPath.configuration;
    const clientId = contact.clientId;
    const plansServiceUrl = stringFormat(
      CommonConstants.getPath.configuration.benefitPlans,
      {
        profileId: contact?.id || '*',
      },
    );
    const requestPayload: GroupCriteria[] = [
      {
        key: 'id',
        value: ids.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentId',
        value: clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'CLIENT',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: [
          BenefitPlanState.Active,
          BenefitPlanState.Draft,
          BenefitPlanState.FinalizingAccounts,
          BenefitPlanState.FinalizingPlan,
          BenefitPlanState.Finalized,
          BenefitPlanState.FinalizingError,
          BenefitPlanState.GracePeriod,
          BenefitPlanState.Initiated,
          BenefitPlanState.PropagatingDateEdits,
          BenefitPlanState.RolloverProcessing,
          BenefitPlanState.RolloverProcessingPreCheck,
          BenefitPlanState.RunOut,
          BenefitPlanState.Reconciliation,
          BenefitPlanState.Setup,
          BenefitPlanState.RolloverComplete,
          BenefitPlanState.CardsOrdered,
          BenefitPlanState.FinalizationPreCheck,
          BenefitPlanState.Removed,
          BenefitPlanState.Start,
        ].join('|'),
        matchType: MatchType.IN,
      },
    ];
    return this.serviceFactory
      .search<BenefitPlan[]>(
        getPath.serviceKey,
        plansServiceUrl,
        requestPayload,
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
        switchMap((data: BenefitPlan[]) => {
          data = data.filter((plan: BenefitPlan) => {
            return plan.parentId === contact.clientId;
          });
          const benefitNonHSAPLans: BenefitPlan[] = data.filter(
            (plan) => !plan.hideBenefitAccountActivityFromClients,
          );
          // const benefitNonHSAPLans: Model.BenefitPlan[] = data.filter(plan => plan.groupingTag !== 'HSA');

          const benefitPlanIds = data.map((plan) => plan.id);
          return this.getFundingSourceAndSchedules(benefitPlanIds).pipe(
            map((fundingSource: BenefitPlanFundingSourceAndSchedule[]) => {
              const destArray = uniqBy(
                fundingSource,
                (x: BenefitPlanFundingSourceAndSchedule) => {
                  return x.parentId;
                },
              );
              const obj: {
                [key: string]: BenefitPlanFundingSourceAndSchedule[];
              } = {};
              destArray.forEach(
                (property: BenefitPlanFundingSourceAndSchedule) => {
                  obj[property.parentId] = [];
                },
              );
              const benefitNonParticipantDirectPlans: BenefitPlan[] = [];
              const benefitNonParticipantDirectFundingSourcePlans: BenefitPlan[] =
                [];
              fundingSource.forEach((source) => {
                obj[source.parentId].push(source);
              });
              data.forEach((plan: BenefitPlan) => {
                if (
                  obj[plan.id]?.length === 1 &&
                  obj[plan.id][0].fundingSource !== 'ParticipantDirect'
                ) {
                  benefitNonParticipantDirectPlans.push(plan);
                }
                if (obj[plan.id]?.length > 1) {
                  if (
                    plan.hideBenefitAccountActivityFromClients &&
                    obj[plan.id].some(
                      (record) => record.fundingSource === 'ParticipantDirect',
                    )
                  ) {
                    benefitNonParticipantDirectFundingSourcePlans.push(plan);
                  }
                  benefitNonParticipantDirectPlans.push(plan);
                }
              });

              return {
                benefitNonHSAPLans,
                benefitNonParticipantDirectFUndingSourcePlans: benefitNonParticipantDirectFundingSourcePlans,
                benefitNonParticipantDirectPlans,
                plans: data,
              };
            }),
          );
        }),
      );
  }

  private getTransactions(
    benefitAccountBalances: BenefitAccountBalance[],
    employeeId: string,
  ): Observable<Model.BenefitAccountPostingSummaryViewModel[]> {
    if (benefitAccountBalances && benefitAccountBalances.length) {
      const SOAIds: string[] = benefitAccountBalances
        .filter((data: BenefitAccountBalance) => data.soaAccountId)
        .map((data: BenefitAccountBalance) => data.soaAccountId);
      return this.getBenefitAccountPostingSummaries(SOAIds, employeeId);
    } else {
      return of([]);
    }
  }

  public getTradeActivity(
    employeeId: string,
    query: TradeActivityQueryParameters,
  ): Observable<TradeActivity[]> {
    const body = [
      {
        key: 'parentType',
        value: 'BENEFIT_ACCOUNT',
        matchType: 'EXACT',
        chainType: 'AND',
      },
      {
        key: 'individualId',
        value: employeeId,
        matchType: 'EXACT',
        chainType: 'AND',
      },
    ];
    const URL = new Uri(
      `/profile/${employeeId}/tradeActivity/search`,
      CoreService.Dashboard,
      query,
    );
    return this.http.post<TradeActivity[]>(URL.toString(), body);
  }

  public getAllTradeActivities(employeeId: string): TradeActivityViewContainer {
    const pendingQuery: TradeActivityQueryParameters = {
      transactionType: TransactionActivityEndPointType.PendingTrade,
    };
    const postedQuery: TradeActivityQueryParameters = {
      transactionType: TransactionActivityEndPointType.Trade,
    };

    return {
      pending: this.getTradeActivity(employeeId, pendingQuery),
      posted: this.getTradeActivity(employeeId, postedQuery),
    };
  }

  private getDeductions(
    individualId: string,
  ): Observable<Model.EntryBenefitAccountSummary[]> {
    const clientId = this.contactQuery.getActive().clientId;
    const criteria = [
      {
        key: 'individualId',
        value: individualId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'entryType',
        value: 'PayrollPosting',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'entryCurrentState',
        value:
          'Entered|Published|Scheduled|PendingPublished|Refunded|Reversed|Settled',
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'entryFundingSource',
        value: 'ParticipantToClient',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'clientId',
        value: clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.OR,
        groupCriteria: [
          {
            key: 'clientId',
            value: clientId,
            matchType: MatchType.IS_NULL,
            chainType: ChainType.OR,
          },
        ],
      },
    ];
    return this.serviceFactory
      .search<Model.EntryBenefitAccountSummary[]>(
        CommonConstants.getPath.dashboard.serviceKey,
        CommonConstants.getPath.dashboard.accountSummaryDeduction,
        criteria,
        { profileId: individualId },
      )
      .pipe(map((res) => res.data));
  }

  private getBenefitPlanRequestTypes(
    plans: BenefitPlan[],
  ): Observable<Model.BenefitPlanRequestType[]> {
    const contact = this.contactQuery.getActive();
    const plansServiceUrl = stringFormat(
      CommonConstants.getPath.configuration.requestType,
      {
        profileId: contact?.id || '*',
      },
    );
    const requestPayload: GroupCriteria[] = [
      {
        key: 'parentId',
        value: plans.map((p) => p.id).join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'BENEFIT_PLAN',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    return this.serviceFactory
      .search<Model.BenefitPlanRequestType[]>(
        CommonConstants.getPath.configuration.serviceKey,
        plansServiceUrl,
        requestPayload,
      )
      .pipe(map((res) => res.data));
  }

  private getPlansEligibleForEnrollment(individualId: string): Observable<BenefitPlan[]> {
    const query = {
      enroller: 'client',
    };
    const url = new Uri(`/profile/${individualId}/configuration/eligibleBenefitPlan/search`, CoreService.Configuration, query);
    return this.http.post<BenefitPlan[]>(url.toString(), null);
  }

  private getPayrollSchedules(
    benefitPlanIds: string[],
    effectiveDate: string = 'ALL',
  ): Observable<PayrollSchedule[]> {
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: benefitPlanIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: 'BENEFIT_PLAN',
        matchType: MatchType.EXACT,
      },
    ];
    return this.serviceFactory
      .search<PayrollSchedule[]>(
        CommonConstants.getPath.profile.configurationKey,
        CommonConstants.getPath.profile.payrollSchedule,
        searchCriteria,
        { profileId: this.contactQuery.getActiveId() },
        {
          take: Number.MAX_SAFE_INTEGER,
          skip: 0,
          orderBy: 'payrollId',
          orderDirection: 'asc',
          effectiveDate,
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  private getBenefitAccountPostingSummaries(
    benefitAccountSOAIds: string[],
    profileId: string,
  ): Observable<Model.BenefitAccountPostingSummaryViewModel[]> {
    return from(benefitAccountSOAIds).pipe(
      mergeMap((benefitAccountSOAId: string) => {
        const baseUrl: string = stringFormat(
          CommonConstants.getPath.account.getTransactionExp,
          {
            profileId,
            benefitAccountId: benefitAccountSOAId,
          },
        );
        return this.serviceFactory
          .query(CommonConstants.getPath.account.serviceKey, baseUrl)
          .pipe(
            map(
              (
                benefitAccountPostingSummary: Model.BenefitAccountPostingSummaryViewModel,
              ) => {
                const benefitAccountPostingSummaryViewModel: Model.BenefitAccountPostingSummaryViewModel = {
                  id: benefitAccountSOAId,
                  ...benefitAccountPostingSummary,
                };
                return benefitAccountPostingSummaryViewModel;
              },
            ),
          );
      }),
      toArray(),
    );
  }

  private searchElections(
    benefitAccountIds: string[],
    sortColumn: string = 'created',
    sortDirection: string = 'asc',
  ): Observable<Election[]> {
    const electionSearchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: benefitAccountIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Inactive',
        matchType: MatchType.NOT,
      },
    ];
    return this.serviceFactory
      .search<Election[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.electionSearch,
        electionSearchCriteria,
        null,
        {
          take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
          skip: 0,
          orderBy: sortColumn,
          orderDirection: sortDirection,
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  private searchEnteredAccountEntries(
    individualId: string,
    benefitAccountId: string,
  ): Observable<Entry[]> {
    const electionSearchCriteria: Model.SearchCriteria = [
      {
        key: 'entryType',
        value: 'ClientContribution|ClientBureauContribution',
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Entered',
        matchType: MatchType.EXACT,
      },
    ];
    return this.serviceFactory
      .search<Entry[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.listAccountEntries,
        electionSearchCriteria,
        {
          individualId,
          benefitAccountId,
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  private getAccountOffering(id: string): Observable<ElectionLimit> {
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.configuration.getOffering,
      {
        profileId: id,
        serviceOfferingId: id,
      },
    );
    return this.serviceFactory.query<ElectionLimit>(
      CommonConstants.getPath.configuration.serviceKey,
      baseUrl,
    );
  }

  private getElectionSourceAndSchedulesDetails(
    benefitAccountId: string,
    individualId: string,
    clientId: string,
    planId: string,
    payrollScheduleId: string,
    individualDate?: string,
    clientDate?: string,
    individualElectionAmount?: number,
    clientElectionAmount?: number,
  ): Observable<BenefitElectionSourceAndSchedules[]> {
    const criteria: BenefitElectionScheduleCriteria = {
      planId,
      payrollScheduleId,
      individualElectionAmount,
      clientElectionAmount,
      clientId,
      benefitAccountId: benefitAccountId === '*' ? undefined : benefitAccountId,
    };
    if (individualDate) {
      criteria.individualScheduleFirstDate = individualDate;
    }
    if (clientDate) {
      criteria.clientScheduleFirstDate = clientDate;
    }

    return this.serviceFactory
      .search<BenefitElectionSourceAndSchedules[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.postPayrollSchedule,
        criteria,
        {
          profileId: this.contactQuery.getActiveId(),
          benefitAccountId: criteria.benefitAccountId,
        },
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public getIndvidualsDependents(
    individualId: string,
  ): Observable<Dependent[]> {
    let dependents: Dependent[];
    return this.getDependents(individualId)
      .pipe(
        switchMap((dependentsResp) => {
          dependents = dependentsResp.data;
          return dependents as Dependent[];
        }),
        map((depedents) => {
          return dependents;
        }),
      );
  }

  public setGetIndividualDependentsSubject(individualId: string): void {
    this.getIndividualDependentsSubject.next(individualId);
  }
  public getIndividualDocumentDetails(employmentInformation: Model.EmploymentInformation): Observable<{ hasMore: boolean, documents: Document[], dependents: Dependent[], documentDetails: EmployeeDocumentDetail[] }> {
    return combineLatest(
      [
        this.getEmployeeDocumentByEmployeeIdResult$,
        this.getIndividualDependentsResult$,
      ],
    ).pipe(
      map(([docs, dep]) => {
        const employeeDocumentDetails = this.mapEmployeeDocumentDetail(employmentInformation.individualName, docs.data, dep.data);

        return ({ hasMore: docs.hasMore, documents: docs.data, dependents: dep.data, documentDetails: employeeDocumentDetails });

      }),
    );
  }

  private mapEmployeeDocumentDetail(individualName: string, employeeDocuments: Document[], emoployeeDocumentRecipients: Dependent[]): EmployeeDocumentDetail[] {
    let employeeDocumentDetails: EmployeeDocumentDetail[] = [];

    employeeDocumentDetails = employeeDocuments.map((employeeDocument) => {
      let employeeDocumentDetail: EmployeeDocumentDetail;
      let recipientId: string = employeeDocument.parentId;
      let recipientFullName: string = individualName;

      if (employeeDocument.dependentId) {
        const documentDependent: Dependent = emoployeeDocumentRecipients.find((emoployeeDocumentRecipient) => emoployeeDocumentRecipient.id === employeeDocument.dependentId);
        if (documentDependent) {
          recipientId = documentDependent.id;
          recipientFullName = documentDependent.fullName;
        }
      }

      employeeDocumentDetail = {
        document: employeeDocument,
        recipientId,
        recipientFullName,
      };
      return employeeDocumentDetail;
    });

    return employeeDocumentDetails;
  }

  /**
   * Provides all dependents added under an individual
   * @param individualId Individual's id
   */
  private getDependents(
    individualId: string,
  ): Observable<Model.SearchResults<Dependent[]>> {
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: individualId,
        matchType: MatchType.EXACT,
      },
      {
        key: 'isHiddenFromIndividual',
        value: 'true',
        matchType: MatchType.NOT,
      },
    ];
    const searchparams: Model.SearchParams = {
      take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
      skip: 0,
      orderBy: 'created',
      orderDirection: 'desc',
    };
    return this.serviceFactory.search<Dependent[]>(
      CommonConstants.getPath.profile.serviceKey,
      CommonConstants.getPath.profile.getDependents,
      searchCriteria,
      null,
      searchparams,
    );
  }

  /**
   * Get a dependent by id
   * @param individualId Individual's id
   */
  private getDependent(
    dependentId: string,
  ): Observable<Dependent> {
    return this.serviceFactory.query<Dependent>(
      CommonConstants.getPath.profile.serviceKey,
      stringFormat(CommonConstants.getPath.profile.getDependent, {
        dependentId,
      }),
    );
  }

  /**
   * Provides dependent-benefit-account-associations and related benefit-accounts
   * @dependentIds Array of Dependent ids
   * @isGetAccounts Booleans depending upon if benefit-accounts need to be fetched / false by default
   */
  private getAssociationsAndAccounts(
    dependentIds: string[],
    isGetAccounts: boolean = false,
  ): Observable<Model.AssociationsAndAccounts> {
    let associations: DependentBenefitAccess[];
    const profileId = this.contactQuery.getActiveId();
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: dependentIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.DEPENDENT,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    return this.serviceFactory
      .search<DependentBenefitAccess[]>(
        CommonConstants.getPath.account.serviceKey,
        CommonConstants.getPath.account.getAssociations,
        searchCriteria,
        { profileId },
      )
      .pipe(
        switchMap((associationsResp) => {
          associations = associationsResp.data;
          const benefitAccountIds = associations.map((t) => t.benefitAccountId);
          return isGetAccounts
            ? this.getBenefitAccounts(
              benefitAccountIds.join('|'),
              'id',
              MatchType.IN,
            )
            : of([]);
        }),
        map((accounts) => {
          return { accounts, associations };
        }),
      );
  }

  private listDependentsWithCards(
    individualId: string,
  ): Observable<Model.IndividualDependentInfo> {
    return this.getDependents(individualId).pipe(
      switchMap(({ data: dependents }) => {
        const activeDependentIds: string[] = dependents
          .filter(
            (dependent) => dependent.currentState === DependentState.Active,
          )
          .map((dependent) => dependent.id);
        return combineLatest([
          this.cardService.getCards([individualId]),
          this.cardService.getCards(activeDependentIds),
          this.individualService.getIndividualById(individualId),
        ]).pipe(
          map(([individualCards, dependentsCards, individualInfo]) => ({
            dependents,
            dependentsCards,
            individualCards,
            individualInfo,
          })),
        );
      }),
    );
  }

  private checkForFutureDisbursableDate(
    accounts: BenefitAccount[],
    plans: BenefitPlan[],
  ): boolean {
    return accounts.every(
      (account: BenefitAccountBalance) =>
        plans.some(
          (plan) =>
            plan.id === account.planId &&
            plan.disbursableDateMandatoryForDisbursements,
        ) && this.isFutureOrNullDate(account.disbursableDate),
    );
  }

  private isFutureOrNullDate(date: string): boolean {
    return !date || dayjs(date, 'YYYY-MM-DD').isAfter(new Date());
  }

  /**
   * Shows 'no payroll' error if all plans have payroll and individual has no payroll
   */
  private isAllPayrollPlans(
    employmentInfo: EmploymentInfo,
    allPlans: Model.EnrollEmployeeBenefitPlanViewModel[],
  ): boolean {
    const employeeHasPayroll = !!employmentInfo?.clientPayrollId;
    const payrollPlans = allPlans.filter((plan) => plan.payrollSchedules?.length);
    const isAllPayrollPlans = payrollPlans.length === allPlans.length;
    if (isAllPayrollPlans && !employeeHasPayroll) {
      return true;
    }
    return false;
  }

  private isEmployeeStatusValidForPlan(
    allowedEnrollmentStates: string[],
    currentStatus: EmploymentInfoState,
  ): boolean {
    let mappedStatus: string = currentStatus;
    if (currentStatus === EmploymentInfoState.Active) {
      mappedStatus = 'Employed';
    }
    return allowedEnrollmentStates.includes(mappedStatus);
  }

  /**
   * This function returns the two enrollment periods we need to check for open ended plans ONLY.
   * We need these two ranges & it is dependent upon todays date.
   * EXAMPLE:  If today is 2020-01-01 and enrollment spans between 09/01 thru 02/01, the enrollmentStart/End date always takes the current year (2020).
   * So, our enrollment dates would span 2020-09-01 to 2020-02-01.  They will always inherit the year that today is in.
   * For the scenario of today being 2020-01-01, we would want to subtract 1 year from the start date to make 2020-01-01 be within the enrollment period.
   * However, if today is 2020-10-05, we would want to add 1 year to the end date to make 2020-10-05 be within the enrollment period.
   * @param plan The benefit plan we are getting enrollment ranges for.
   */
  private getOpenEndedEnrollmentRanges(
    plan: BenefitPlan,
  ): Model.OpenEndedPlanEnrollmentRanges {
    const firstDate = {
      startDate: Dates.fromString(plan.enrollmentStartDate, 'MM/DD'),
      endDate: Dates.fromString(plan.enrollmentEndDate, 'MM/DD'),
    };
    const secondDate = cloneDeep(firstDate);
    if (Dates.isAfter(firstDate.startDate, firstDate.endDate)) {
      firstDate.endDate = firstDate.endDate.add(1, 'year');
      secondDate.startDate = secondDate.startDate.subtract(1, 'year');
    } else if (Dates.isBefore(firstDate.startDate, firstDate.endDate)) {
      secondDate.startDate = secondDate.startDate.add(1, 'year');
      secondDate.endDate = secondDate.endDate.add(1, 'year');
    }
    return { firstDate, secondDate };
  }

  private getContributionEntryTypes(): EntryType[] {
    return [
      EntryType.BenefitAccountBalanceAdjustment,
      EntryType.BenefitAccountBalanceTransfer,
      EntryType.DisbursementRejection,
      EntryType.FundedConversionRolloverContribution,
      EntryType.ManualRefund,
      EntryType.ManualRequestReturn,
      EntryType.MyCashManualRefund,
      EntryType.ParticipantInterestCreditSecondaryBureau,
      EntryType.PaymentAdjustment,
      EntryType.PayrollFundingPlanRollover,
      EntryType.PodFundingPlanRollover,
      EntryType.SellOrderSettlement,
      EntryType.UnfundedConversionRolloverContribution,
    ];
  }

  private getNewDependentsWithActiveAssociation(
    selection: Model.DependentSelection,
  ): Dependent[] {
    const today = Dates.today();
    return selection.selectedDependents.addAssociations
      .filter((association) =>
        Dates.isSameOrBefore(association.eligibilityStartDate, today),
      )
      .map(({ dependent }) => dependent);
  }

  private getExistingDependentsWithActiveAssociation(
    selection: Model.DependentSelection,
    information: Model.DependentInformation,
  ): Dependent[] {
    const today = Dates.today();
    return information.dependents.filter((dependent) => {
      const dba = information.associations.find(
        ({ parentId }) => parentId === dependent.id,
      );
      return (
        dba &&
        Dates.isSameOrBefore(dba.eligibilityStartDate, today) &&
        !selection.selectedDependents.removeAssociations.some(
          (association) => association.dependent.id === dependent.id,
        ) &&
        !selection.selectedDependents.updateAssociations.some(
          (association) =>
            association.dependent.id === dependent.id &&
            Dates.isAfter(association.eligibilityStartDate, today),
        )
      );
    });
  }

  private updateElections(
    newElections: Election[],
    existingElections: Election[],
  ): Observable<void[]> {
    const existingElections$ = existingElections
      .filter(
        ({ currentState }) => currentState !== ElectionState.PendingAmount,
      )
      .map((ele) =>
        this.saveElectionData(
          ele,
          `${ele.currentState}To${ele.currentState}` as ElectionCommandType,
        ),
      );
    const newElections$ = newElections.map((ele) => this.saveElectionData(ele));

    if (existingElections$.length > 0 && newElections$.length > 0) {
      return forkJoin(existingElections$).pipe(
        switchMap(() => forkJoin(newElections$)),
      );
    } else if (existingElections$.length > 0) {
      return forkJoin(existingElections$);
    } else if (newElections$.length > 0) {
      return forkJoin(newElections$);
    } else {
      return of([]);
    }
  }
}
