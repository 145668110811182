import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { parseRoute } from './access.helper';

import { AccessService } from './access.service';

@Directive({
    selector: '[appHasPermission]',
})
export class HasPermissionDirective implements OnDestroy {
    // the role the user must have
    @Input() public permissions: string;

    private currentRoute: string;
    private elseTemplateRef: TemplateRef<Element> = null;
    private thenViewRef: EmbeddedViewRef<Element> = null;
    private elseViewRef: EmbeddedViewRef<Element> = null;
    private subscriptions = new Subscription();

    /**
     * @param {ViewContainerRef} viewContainerRef
     * 	-- the location where we need to render the templateRef
     * @param {TemplateRef<any>} templateRef
     *   -- the templateRef to be potentially rendered
     * @param {AccessService} accessService
     *   -- will give us access to the roles a user has
     */
    public constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<Element>,
        private accessService: AccessService,
    ) {
    }
    @Input() public set appHasPermission(currentRoute: string) {
        this.currentRoute = parseRoute(currentRoute);
        this.updateView();
    }

    @Input() public set appHasPermissionElse(elseTemplateRef: TemplateRef<Element>) {
        this.elseTemplateRef = elseTemplateRef;
        this.elseViewRef = null;
        this.updateView();
    }

    private updateView(): void {
        let conditionResult$: Observable<boolean>;
        // for pages
        if (this.currentRoute.indexOf('/') > -1) {
            conditionResult$ = this.accessService.hasPageAccess(this.currentRoute);
        } else if (this.currentRoute.indexOf('menu') > -1) { // for header menu
            conditionResult$ = this.accessService.hasMenuAccess(this.currentRoute);
        } else { // for features
            conditionResult$ = this.accessService.hasPermission(this.currentRoute);
        }

        const subscription = conditionResult$.pipe(
          first(),
          tap((conditionResult) => {
            if (conditionResult && !this.thenViewRef) {
              this.viewContainerRef.clear();
              this.elseViewRef = null;
              if (this.templateRef) {
                  this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
              }
          } else if (!conditionResult && !this.elseViewRef) {
              this.viewContainerRef.clear();
              this.thenViewRef = null;
              if (this.elseTemplateRef) {
                  this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
              }
          }
          }),
        ).subscribe();
        this.subscriptions.add(subscription);
    }

    public ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }
}
