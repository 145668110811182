export const environment = {
    contentful: {
        space: 'fbth0d1d3tpo',
        accessToken: 'b0b2fa9aba9cfd32562a08d1eb49212ff7ffe2db53af5664307f5d6a7b7231c4',
        environment: 'master',
    },
    name: 'p1',
    timezone: 'America/Chicago',
    production: true,

    auth: {
        cognitoURI: 'https://prod-1-tasc-bam-admin-ui-new.auth.us-east-1.amazoncognito.com/',
        ClientId: '7084dj3aceggh6qvf1ek2drs9k',
        UserPoolId: 'us-east-1_joPxz2hWe'
    },

    logging: {
        apiKey: '0BxPR7kX38K2BZ1FKvfhw',
        debugMode: false
    },

    services: {
        producerId: '90cc2cdd-ddd5-4cec-810e-60c31d093c5e',
        placeholderParentIdForTopLevelEntities: 'c706ca24-02a3-465f-aed2-6be67eb2d0d5',
        idp: {
            endpoint: 'https://uzz3gtbyua.execute-api.us-east-1.amazonaws.com/test/auth/v1'
        },
        core: {
            endpoint: 'https://kzv8c2g0o4.execute-api.us-east-1.amazonaws.com/test'
        },
        communication: {
            endpoint: 'https://p1-api.tascadmin.com/communication/v1'
        },
        configuration: {
            endpoint: 'https://p1-api.tascadmin.com/configuration/v1'
        },
        profile: {
            endpoint: 'https://p1-api.tascadmin.com/profile/v1'
        },
        profileConfiguration: {
            endpoint: 'https://p1-api.tascadmin.com/profileconfiguration/v1'
        },
        account: {
            endpoint: 'https://p1-api.tascadmin.com/account/v1'
        },
        request: {
            endpoint: 'https://p1-api.tascadmin.com/request/v1'
        },
        lookup: {
            endpoint: 'https://p1-api.tascadmin.com/lookup/v1'
        },
        card: {
            endpoint: 'https://p1-api.tascadmin.com/card/v1'
        },
        file: {
            endpoint: 'https://p1-api.tascadmin.com/file/v1'
        },
        security: {
            endpoint: 'https://p1-api.tascadmin.com/security/v1'
        },
        support: {
            endpoint: 'https://p1-api.tascadmin.com/support/v1'
        },
        dashboard: {
            endpoint: 'https://p1-api.tascadmin.com/dashboard/v1'
        },
        report: {
            endpoint: 'https://p1-api.tascadmin.com/report/v1'
        },
        paymentAccount: {
            endpoint: 'https://p1-api.tascadmin.com/paymentAccount/v1'
        }
    },

    netSuite: {
        companyId: '3290460',
        baseUrl: 'https://3290460.app.netsuite.com/app/login/secure/oidcprivate.nl',
        cluxHost: 'd3byia5tpzkesl.cloudfront.net',
        landingUrl: {
            billing: 'https://3290460.app.netsuite.com/app/accounting/transactions/transactionlist.nl?Transaction_TYPE=@ALL@',
            invoice: 'https://3290460.app.netsuite.com/app/accounting/transactions/transactionlist.nl?Transaction_TYPE=CustInvc',
            payment: 'https://3290460.app.netsuite.com/app/accounting/transactions/transactionlist.nl?Transaction_TYPE=CustPymt',
            creditMemo: 'https://3290460.app.netsuite.com/app/accounting/transactions/transactionlist.nl?Transaction_TYPE=CustCred',
      },
    },
};
