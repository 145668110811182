export class Clone {
  /**
   * Creates a deep copy of the given object.
   * @param input The object to clone.
   */
  public static deep<T extends object>(input: T): T {
    return JSON.parse(JSON.stringify(input));
  }

  /**
   * Creates a shallow copy of the given object.
   * @param input The object to clone.
   */
  public static shallow<T extends object>(input: T): T {
    if (Array.isArray(input)) {
      return [...input] as T;
    }
    return Object.assign({}, input);
  }
}
