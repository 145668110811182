import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EligibilityEventSummaryData } from '@clux-models';
import { EventType } from '@models/communication/model';
import { ClientViewEligibilityEventSummary, ClientViewEligibilityEventSummaryStatus } from '@models/dashboard/model';
import { Observable, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Dates } from './shared/utils/dates';
import { IndividualQuery } from './state';
import { EligibilityEventQuery } from './state/eligibility-event';
import { EligibleEligibilityEventQuery } from './state/eligible-eligibility-event';
import { OfferingPriceQuery } from './state/offering-price';

function mockResponseBody(parentId: string): unknown[] {
  return [{
    id: uuid(),
    parentId,
  }];
}

/**
 * !!! ONLY ENABLED IN D ENVIRONMENTS !!!
 *
 * This is useful for mocking out responses to endpoints.
 * You can achieve just about any flow with this. This class is in the main module and
 * thus will persist with the app, so you can set class variables to track things
 * i.e. how many calls / do different things on second call / store previous call body /
 * store another endpoint call to return data that depends on that endpoints response / etc.
 *
 * See the example endpoint on how you would intercept domain/exampleEndpoint/{parentId}
 * and return a response with parentId equal to the call.
 *
 *
 */
@Injectable()
export class HttpDEBUGInterceptor implements HttpInterceptor {
  public eligibilityEvent$: Observable<EligibilityEventSummaryData[]> = this.individualQuery.selectActive().pipe(
    switchMap((individual) => this.eligibilityEventQuery.getEligibilityEventSummaryData(individual?.id)),
  );
  public hasPaymentAccount: boolean;

  public constructor(
    public individualQuery: IndividualQuery,
    public eeEvent: EligibleEligibilityEventQuery,
    public eligibilityEventQuery: EligibilityEventQuery,
    public priceQuery: OfferingPriceQuery,
  ) { }
  public hasCalledPreview = false;
  // tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    if (url) {
      if (url.includes('exampleEndpoint')) {

        const urlParts = url.split('/');
        const parentId = urlParts[urlParts.length - 1];

        // Either return a custom HTTP Response or call next.handle to make the network call.
        return of(new HttpResponse({
          body: mockResponseBody(parentId),
        })).pipe(
          delay(1000),
        );
      }
    }

    // if (url.includes('/eligibilityEventsByClient/search')) {
    //   const body = [];
    //   for (let index = 0; index < 101; index++) {
    //     const mockNumber = index + 1;
    //     const status = Object.keys(ClientViewEligibilityEventSummaryStatus);
    //     const rndInt = Math.floor(Math.random() * 3) + 1;
    //     const element: ClientViewEligibilityEventSummary = {
    //       individualId: index.toString(),
    //       firstName: 'Mock First Name ' + mockNumber,
    //       lastName: 'Mock Last Name ' + mockNumber,
    //       eventType: index % 2 === 0 ? 'Termination' : 'Loan Qualification',
    //       eventDate: Dates.addDays(Dates.today(), index),
    //       status: status[rndInt] as ClientViewEligibilityEventSummaryStatus,
    //       eligibilityEventId: uuid(),
    //       paymentAccountGroupId: uuid(),
    //     };
    //     body.push(element);
    //   }
    //   const queryParams = req.url.split('search?')[1];
    //   const skip = queryParams.split('&')[0].split('=')[1];
    //   const take = queryParams.split('&')[1].split('=')[1] + skip;
    //   return of(new HttpResponse({
    //     headers: new HttpHeaders({
    //       auroraCountTableItems: body.length.toString(),
    //     }),
    //     body: body.splice(+skip, +take),
    //   })).pipe(
    //     tap(() => {
    //       // tslint:disable-next-line: no-console
    //       console.log('TODO - HttpDEBUGInterceptor: req', req);
    //     }),
    //     delay(3000),
    //   );
    // }

    return next.handle(req);
  }
}
