import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Division } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { DivisionState, DivisionStore } from './division.store';

@Injectable({
  providedIn: 'root',
})
export class DivisionQuery extends QueryEntity<DivisionState> {
  public constructor(protected store: DivisionStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<Division[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }
}
