import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
  public constructor(private sanitizer: DomSanitizer) {}

  public transform(content: string): string {
    const match: string[] = content.match(/[\w\W]*<body\s*\w*>([\w\W]*)<\/body>[\w\W]*/);
    if (match) {
      content = this.sanitizer.sanitize(SecurityContext.HTML, match[1]);
      return content;
    } else {
      return '';
    }
  }
}
