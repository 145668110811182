import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PaymentAccountGroup } from '@models/paymentAccount/model';
import { ActivePaymentAccountGroupStore } from './active-payment-account.store';

@Injectable({ providedIn: 'root' })
export class ActivePaymentAccountGroupQuery extends Query<PaymentAccountGroup> {
  public constructor(protected store: ActivePaymentAccountGroupStore) {
    super(store);
  }

}
