import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserAccessRoleType } from '@models/profile/model';
import { AuthenticationChallengeType } from 'src/app/default/auth/models/authentication-challenge.model';
import { CurrentUserState } from '../model';
import { CurrentUserStore } from './current-user.store';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserQuery extends Query<CurrentUserState> {
  public isLoggedIn$ = this.select((state) => !!state.email && !!state.idToken);

  public constructor(protected store: CurrentUserStore) {
    super(store);
  }

  public getCognitoPhoneNumber(): string {
    return this.get((state) => state.cognitoPhoneNumber);
  }

  public getEmailAddress(): string {
    return this.get((state) => state.email);
  }

  public getAuthenticationType(): AuthenticationChallengeType {
    return this.get((state) => state.authenticationType);
  }

  public getJwtToken(): string {
    const idToken = this.get((state) => state.idToken);
    if (idToken && idToken.getJwtToken) {
      return idToken.getJwtToken();
    }

    return null;
  }

  // tslint:disable-next-line: no-any
  public getDecodedToken(): { [id: string]: any; } {
    const idToken = this.get((state) => state.idToken);
    if (idToken && idToken.payload) {
      return idToken.payload;
    }

    return null;
  }

  public getUserGroupsFromToken(): UserAccessRoleType[] {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) {
      return [];
    }
    const userGroupsString = decodedToken['custom:idp_group']?.replace(/[\[\] ]/g, '') as string;
    if (userGroupsString.length > 0) {
      return userGroupsString.split(',') as UserAccessRoleType[];
    } else {
      return [];
    }
  }

  /**
   * Extract the currently scoped client ID from the auth token. Will be present when:
   * (1) Contact is authenticated and is associated with a single client.
   * (2) Contact is authenticated, is associated with more than one client, and has selected the desired client.
   */
  public getClientIdFromToken(): string {
    const decodedToken = this.getDecodedToken();
    return decodedToken && decodedToken['custom:client_id'];
  }

  /**
   * Extract the number of clients the authenticated user is associated with from the auth token.
   * This returns a value ONLY when:
   * (1) User is associated with more than one client AND
   * (2) User has not yet selected the desired client
   */
  public getNumberOfClientsFromToken(): number {
    const decodedToken = this.getDecodedToken();
    return decodedToken && decodedToken['custom:clients'];
  }

  public getRememberedEmailAddress(): string {
    return this.get((state) => state.rememberedEmail);
  }

  public getVerificationCode(): string {
    return this.get((state) => state.verificationCode);
  }

  public isJwtTokenExpired(): boolean {
    const state = this.getValue();
    return !state || !state.email || !state.idToken || !state.idToken.getExpiration || state.idToken.getExpiration() <= Date.now() / 1000;
  }

  public isRememberUserEnabled(): boolean {
    return !!this.getRememberedEmailAddress();
  }

  private get<T>(project: (state: CurrentUserState) => T): T {
    const state = this.getValue();
    if (state) {
      return project(state);
    }

    return null;
  }
}
