import { Injectable } from '@angular/core';
import { BrandId } from 'src/app/shared/models/clux/enum';
import { BrandResource } from 'src/app/shared/models/clux/model';
import { brandResources } from 'src/brand/brandResources';
import { basicURLs } from '../../../brand/basic/basic.urls';
import { tascURLs } from '../../../brand/tasc/tasc.urls';
import { BrowserService } from './browser.service';
import { TPAPartnerType } from '@models/profile/model';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private readonly brandId = this.determineBrand();

  public constructor(
    private browserService: BrowserService,
  ) { }

  public getBrand(): BrandId {
    return this.brandId;
  }

  public getBrandResources(): BrandResource {
    return brandResources[this.brandId];
  }

  public getTpaPartner(): TPAPartnerType | undefined {
    const key = Object.keys(TPAPartnerType).find((tpaPartnerType) => tpaPartnerType.toLowerCase() === this.brandId.toLowerCase());
    return TPAPartnerType[key];
  }

  private determineBrand(): BrandId {
    const hostname = this.browserService.getLocationHostname();
    if (tascURLs.includes(hostname)) {
      return BrandId.TASC;
    }

    if (basicURLs.includes(hostname)) {
      return BrandId.BASIC;
    }
    return BrandId.Unknown;
  }
}
