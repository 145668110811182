import {
  Component,
  Input,
  OnChanges,
  OnDestroy, SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { CommonConstants } from '../../constants/common.constant';
import { EmitterService } from '../../services/emitter.service';
import { SharedService } from '../../services/shared.service';
import { RouteContextProviderService } from '../../utils/routecontextprovider.service';

@Component({
  selector: 'app-tasc-growel',
  templateUrl: './tasc-growel.component.html',
  styleUrls: ['./tasc-growel.component.scss'],
})
export class TascGrowelComponent implements OnChanges, OnDestroy {
  public growlProp: Model.growlProps[] = [];
  @Input() private growlProps: Model.growlProps;
  private subscriptions = new Subscription();

  public constructor(
    private shareService: SharedService,
    private emmiter: EmitterService,
    private router: Router,
    private routecontextprovider: RouteContextProviderService,
  ) {

    this.subscriptions.add(router.events.subscribe((val) => {
      this.CloseAllGrowel();
    }));

    this.subscriptions.add(this.emmiter.closeGrowl.subscribe((action) => {
      if (action) {
        this.CloseAllGrowel();
      }
    }));

  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(obj: SimpleChanges): void {

    if (obj.growlProps.currentValue && obj.growlProps.currentValue && !this.checkForRepeatedGrowler(this.growlProps)) {
      this.shareService.growlProperties.push({
        message: this.growlProps.message,
        type: this.growlProps.type,
        link: this.growlProps.link,
        growelVisibility: this.growlProps.message ? true : false,
        postManuallyLink: this.growlProps.postManuallyLink,
      });
    }

    if (this.shareService.growlProperties) {
      this.shareService.growlProperties.forEach((element, index) => {
       if (element.growelVisibility && element.type && typeof (element.type) === 'boolean') {
         setTimeout(() => {
            this.closeGrowel(index);
          }, 7000);
        }
      });
    }

    this.growlProp = this.shareService.growlProperties;
  }

  public checkForRepeatedGrowler(growl: Model.growlProps): boolean {
    const index = this.shareService.growlProperties.findIndex((x) => x.message === growl.message && x.type === growl.type && x.growelVisibility === true);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  // close the growl
  public closeGrowel(index: number): void {
    if (this.growlProp.length > 0 && this.shareService.growlProperties.length > 0) {
      this.growlProp[index].growelVisibility = false;
      this.shareService.growlProperties[index].growelVisibility = false;
      // console.log('Growel Hidden');
    }
  }
  public CloseAllGrowel(): void {
    if (this.shareService.growlProperties.length > 0) {
      this.shareService.growlProperties.forEach((element) => {
        element.growelVisibility = false;
      });
    }
  }

  public undoChanges(): void {
    this.shareService.sendMessage('true');
    this.emmiter.on(CommonConstants.emmiterKeys.isHeader).emit(true);
  }

  public postNow(): void {
    this.shareService.showPastSchedule = true;
    this.routecontextprovider.navigateTo([
      CommonConstants.appRoutes.plan.planBase,
      CommonConstants.appRoutes.plan.postManually,
    ]);
  }
}
