import { Model } from 'src/app/shared/models/clux/model-legacy';

export class Categories {
  public static EventCategories: Model.EventCategories = {
    SignUp: {
        eventCategory: 'signUpPage',
        eventAction: 'signUp',
        eventLabel: 'Sign up',
        eventValue: null,
    },
    SignInSuccess: {
        eventCategory: 'signInPage',
        eventAction: 'signIn',
        eventLabel: 'Sign in',
        eventValue: null,
    },
    SignInFail: {
        eventCategory: 'signInPage',
        eventAction: 'signIn',
        eventLabel: 'Sign in fail',
        eventValue: null,
    },
    SelectClient: {
        eventCategory: 'distributorPage',
        eventAction: 'selectClient',
        eventLabel: 'Distributor selects client',
        eventValue: null,
    },
    AddSingleEmployee: {
        eventCategory: 'addEmployeesPage',
        eventAction: 'addSingleEmployee',
        eventLabel: 'Add single employee',
        eventValue: null,
    },
    AddMultipleEmployees: {
        eventCategory: 'addEmployeesPage',
        eventAction: 'addMultipleEmployees',
        eventLabel: 'Add multiple employees by file upload',
        eventValue: null,
    },
    OfferingPriceAdjustment: {
        eventCategory: 'addEmployeesPage',
        eventAction: 'offeringPriceAdjustment',
        eventLabel: 'Make an adjustment on a payment price',
        eventValue: null,
    },
    PasswordReset: {
        eventCategory: 'signInPage',
        eventAction: 'forgotPassword',
        eventLabel: 'Forget password reset',
        eventValue: null,
    },
    PersonalInfoEdit: {
        eventCategory: 'viewEmployeePage',
        eventAction: 'editEmployeePersonalInfo',
        eventLabel: 'Update employee personal information',
        eventValue: null,
    },
    EmploymentInfoEdit: {
        eventCategory: 'viewEmployeePage',
        eventAction: 'editEmployeeEmploymentInfo',
        eventLabel: 'Update employee information',
        eventValue: null,
    },
    EmployeeAccountSummary: {
        eventCategory: 'employeeAccountsSummary',
        eventAction: 'editElection',
        eventLabel: 'Midyear election changes',
        eventValue: null,
    },
    TascCardReissue: {
        eventCategory: 'tascCardRequestPage',
        eventAction: 'reissueExistingCard',
        eventLabel: null,
        eventValue: null,
    },
    TascCardRequest: {
        eventCategory: 'tascCardRequestPage',
        eventAction: 'requestCardForDependent',
        eventLabel: 'Request a card for a dependent',
        eventValue: null,
    },
    ApproveEnrollment: {
        eventCategory: 'approveEnrollmentPage',
        eventAction: 'enrollmentApprove',
        eventLabel: 'Approve enrollments',
        eventValue: null,
    },
    RejectEnrollment: {
        eventCategory: 'approveEnrollmentPage',
        eventAction: 'enrollmentReject',
        eventLabel: 'Reject enrollments',
        eventValue: null,
    },
    PVRAmountEdit: {
        eventCategory: 'pvrPage',
        eventAction: 'pvrAmountEdit',
        eventLabel: 'Edit PVR online',
        eventValue: null,
    },
    PVRDownload: {
        eventCategory: 'pvrPage',
        eventAction: 'pvrFileDownload',
        eventLabel: 'Download PVR file',
        eventValue: null,
    },
    PVRUpload: {
        eventCategory: 'pvrPage',
        eventAction: 'pvrFileUpload',
        eventLabel: 'Upload PVR file',
        eventValue: null,
    },
    PVRPerPageCount: {
        eventCategory: 'pvrPage',
        eventAction: 'recordsPerPageChange',
        eventLabel: 'Records per page changed to - ',
        eventValue: null,
    },
    ManualPosting: {
        eventCategory: 'postManuallyPage',
        eventAction: 'manualPosting',
        eventLabel: 'Post manually',
        eventValue: null,
    },
    PastPosting: {
        eventCategory: 'postManuallyPage',
        eventAction: 'showPastScheduledPostings',
        eventLabel: 'Show past scheduled postings',
        eventValue: null,
    },
    ContactUsCategorySubcategory: {
        eventCategory: 'contactUsPage',
        eventAction: 'submitSupportRequest',
        eventLabel: 'Submit support request - ',
        eventValue: null,
    },
    AddDependent: {
        eventCategory: 'dependent',
        eventAction: 'addDependent',
        eventLabel: 'Add dependent',
        eventValue: null,
    },
    DeleteDependent: {
        eventCategory: 'dependent',
        eventAction: 'deleteDependent',
        eventLabel: 'Delete dependent',
        eventValue: null,
    },
    EditDependent: {
      eventCategory: 'dependent',
      eventAction: 'editDependent',
      eventLabel: 'Edit dependent',
      eventValue: null,
  },
    RemoveEmployee: {
        eventCategory: 'removeEmployeesPage',
        eventAction: 'removeEmployee',
        eventLabel: 'Remove employee',
        eventValue: null,
    },
    DataFileUpload: {
        eventCategory: 'dataFilesPage',
        eventAction: 'fileUpload',
        eventLabel: 'Data file upload of type - ',
        eventValue: null,
    },
    DataFileDownload: {
        eventCategory: 'dataFilesPage',
        eventAction: 'fileDownload',
        eventLabel: 'Data file download of type - ',
        eventValue: null,
    },
    MyDocumentsUpload: {
        eventCategory: 'myDocumentsPage',
        eventAction: 'fileUpload',
        eventLabel: 'My document file upload',
        eventValue: null,
    },
    MyDocumentsDownload: {
        eventCategory: 'myDocumentsPage',
        eventAction: 'fileDownload',
        eventLabel: 'My document file download',
        eventValue: null,
    },
    TemplatesDownload: {
        eventCategory: 'templatesPage',
        eventAction: 'fileDownload',
        eventLabel: 'Template file download',
        eventValue: null,
    },
    ManageUsersEditAccessExisting: {
        eventCategory: 'manageUsersPage',
        eventAction: 'editUser',
        eventLabel: 'Edit client contact',
        eventValue: null,
    },
    ManageUsersDelete: {
        eventCategory: 'manageUsersPage',
        eventAction: 'deleteUser',
        eventLabel: 'Delete client contact',
        eventValue: null,
    },
    ManageUsersAdd: {
        eventCategory: 'manageUsersPage',
        eventAction: 'addUser',
        eventLabel: 'Add client contact as usertype - ',
        eventValue: null,
    },
    TwoFAOn: {
        eventCategory: 'signInAndSecurityPage',
        eventAction: 'twoFAOn',
        eventLabel: 'Two factor authentication - On',
        eventValue: null,
    },
    TwoFAOff: {
        eventCategory: 'signInAndSecurityPage',
        eventAction: 'twoFAOff',
        eventLabel: 'Two factor authentication - Off',
        eventValue: null,
    },
    ChangePassword: {
        eventCategory: 'signInAndSecurityPage',
        eventAction: 'changePassword',
        eventLabel: 'Password change',
        eventValue: null,
    },
    ChangeMobileNumber: {
        eventCategory: 'signInAndSecurityPage',
        eventAction: 'changeMobile',
        eventLabel: 'Change mobile',
        eventValue: null,
    },
    SubmitSupportRequest: {
        eventCategory: 'contactUsPage',
        eventAction: 'submitSupportRequest',
        eventLabel: 'Submit support request - ',
        eventValue: null,
    },
    ReportRequest: {
        eventCategory: 'reportsPage',
        eventAction: 'reportRequest',
        eventLabel: 'Request report of type - ',
        eventValue: null,
    },
    ReplySupportRequest: {
      eventCategory: 'contactUsPage',
      eventAction: 'replySupportRequest',
      eventLabel: 'Reply support request - ',
      eventValue: null,
    },
    ReportDownload: {
        eventCategory: 'reportsPage',
        eventAction: 'reportDownload',
        eventLabel: 'Download report of type - ',
        eventValue: null,
    },
    FundingTransactionsPerPageCount: {
        eventCategory: 'fundingTransactionsPage',
        eventAction: 'recordsPerPageChange',
        eventLabel: 'Records per page changed to - ',
        eventValue: null,
    },
    OpenFaq: {
        eventCategory: 'supportPage',
        eventAction: 'support',
        eventLabel: 'FAQs - expand ',
        eventValue: null,
    },
    VideoOpen: {
        eventCategory: 'supportPage',
        eventAction: 'support',
        eventLabel: 'User manuals and Videos - Play ',
        eventValue: null,
    },
    UserGuideOpen: {
      eventCategory: 'supportPage',
      eventAction: 'support',
      eventLabel: 'User manuals and Videos - Download ',
      eventValue: null,
    },
    NetSuiteRedirect: {
        eventCategory: 'netSuite',
        eventAction: null,
        eventLabel: null,
        eventValue: null,
        eventNameMapping: {
            billingLabel: 'Open Billing Transactions',
            invoiceLabel: 'Open Invoices',
            paymentLabel: 'Open Payment',
            creditMemoLabel: 'Open Credit Memo',
            billingAction: 'openBillingTransactions',
            invoiceAction: 'openInvoices',
            paymentAction: 'openPayment',
            creditMemoAction: 'openCreditMemo',
        },
    },
    ViewEmployeePerPageCount: {
        eventCategory: 'viewEmployeePage',
        eventAction: 'recordsPerPageChange',
        eventLabel: 'Records per page changed to - ',
        eventValue: null,
    },
    EnrollSingleEmployee: {
        eventCategory: 'enrollEmployeePage',
        eventAction: 'enrollSingleEmployee',
        eventLabel: 'Enroll single employee',
        eventValue: null,
    },
    EnrollDependents: {
        eventCategory: 'enrollEmployeePage',
        eventAction: 'enrollDependents',
        eventLabel: 'Enroll depednents',
        eventValue: null,
    },
    AddDependentFromEnrollment: {
        eventCategory: 'enrollEmployee',
        eventAction: 'addDependent',
        eventLabel: 'Add dependent from enroll employee',
        eventValue: null,
    },
    EnrollMultipeEmployees: {
        eventCategory: 'enrollEmployeePage',
        eventAction: 'enrollMultipleEmployees',
        eventLabel: 'Enroll multiple employees by file upload',
        eventValue: null,
    },
    AddFundsToBenfitAccount: {
        eventCategory: 'viewEmployeePage',
        eventAction: 'addFundsToBenefitAccount',
        eventLabel: 'On-demand transfer into benefit accounts',
        eventValue: null,
    },
    AddDisbursableDateToBenfitAccount: {
        eventCategory: 'employeeAccountsSummaryPage',
        eventAction: 'addDisbursableDateToBenefitAccount',
        eventLabel: 'Add disbursable date into benefit account',
        eventValue: null,
    },
    EditDisbursableDateOfBenfitAccount: {
        eventCategory: 'employeeAccountsSummaryPage',
        eventAction: 'editDisbursableDateOfBenefitAccount',
        eventLabel: 'Update disbursable date of benefit account',
        eventValue: null,
    },
    FileSettingRedirect: {
        eventCategory: 'FileSettingsPage',
        eventAction: 'FileSettings',
        eventLabel: 'Open file Settings Page',
        eventValue: null,
    },
    AddDependentAssociation: {
        eventCategory: 'employeeAccountsSummaryPage',
        eventAction: 'addDependentAssociation',
        eventLabel: 'Add Dependent Association',
        eventValue: null,
    },
    EditDependentAssociation: {
        eventCategory: 'employeeAccountsSummaryPage',
        eventAction: 'editDependentAssociation',
        eventLabel: 'Edit Dependent Association',
        eventValue: null,
    },
    EditDependentEffectiveDate: {
        eventCategory: 'employeeAccountsSummaryPage',
        eventAction: 'editDependentEffectiveDate',
        eventLabel: 'Edit dependent benefit effective date',
        eventValue: null,
    },
    EmployeeInvestments: {
        eventCategory: 'EmployeeInvestmentsPage',
        eventAction: 'Employee Investments',
        eventLabel: 'Open Employee Investments Page',
        eventValue: null,
    },
  };
}
