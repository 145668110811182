import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import {
  Attachment,
  AttachmentCommandType,
  AttachmentState,
  FileAttachmentMetadata,
  ParentType,
} from 'src/app/shared/models/uba/file/model';
import {
  Document,
  DocumentCategoryType,
  DocumentCommandType,
  DocumentState,
  EntityBase,
  ParentType as ProfileConfigurationParentType,
} from 'src/app/shared/models/uba/profileConfiguration/model';
import { ContactQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';
import {
  Attachment as CommentAttachments,
  SupportRequestComments,
  SupportRequestCommentsCommand,
} from '../models/uba/support/model';

import { BenefitElectionScheduleCriteria } from '@models/account/model';
import { Criteria } from '@models/configuration/model';
import { SupportRequestSearchFilters } from '@models/support/model';
import { isArray } from 'lodash/fp';
import { environment } from '../../../environments/environment';
import { CommonConstants } from '../constants/common.constant';
import { Dates } from '../utils/dates';
import { Transitions } from '../utils/transitions';

/**
 * Provides a common generic service for making calls to web services
 */

@Injectable({
  providedIn: 'root',
})
export class ServiceFactory {
  public constructor(
    private contactQuery: ContactQuery,
    private http: HttpClient,
  ) { }

  /**
   * Calls a query against a given microservice.
   * @param  {string} entityType      The entitytype for the specific microservice
   * @param  {any} entity
   * @param  {string}
   * @return             The resulting call response
   */
  public query<T>(
    service: string,
    basePath: string,
    queryParams?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      responseType?: 'json';
      withCredentials?: boolean;
      reportProgress?: boolean;
      params?: HttpParams | {
        [param: string]: string | string[];
      };
    }): Observable<T> {
    return this.http.get<T>(this.getUrlByKey(service, basePath), queryParams);
  }
  /**
   * Note: Only use this function to call s3 file urls
   * @param {string} url
   * @param {HttpHeaders} [headers]
   * @returns {Observable<any>}
   * @memberof ServiceFactory
   */
  public getFileContent(
    url: string,
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    },
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    return this.http.get(url, { headers, responseType: 'blob' as 'json' });
  }

  public searchExternal<T>(
    service: string,
    basePath: string,
    bodyParams: Model.SearchCriteria | BenefitElectionScheduleCriteria | Criteria,
    headers: HttpHeaders = null,
    queryParams: Model.QueryParams = null,
    searchParams: Model.SearchParams = null,
    getTotalCount: boolean = false): Observable<Model.SearchResults<T>> {
    const { serviceUrl, isPagination, requiredNumberOfRecords } = this.getSearchParams(
      service,
      basePath,
      queryParams,
      searchParams,
      getTotalCount,
    );
    return this.http.post<T>(this.getUrlByKey(service, serviceUrl), bodyParams, {
      observe: 'response', headers: headers ? headers : {},
      // tslint:disable-next-line:no-any
    }).pipe(map((res: HttpResponse<T> | any) => {
      const searchResults: Model.SearchResults<T> = {
        data: isPagination ? res.body.slice(0, requiredNumberOfRecords) : res.body,
        hasMore: isPagination ? (res.body.length > requiredNumberOfRecords) : false,
      };
      if (getTotalCount) {
        searchResults.totalCount = +res.headers.get('auroraCountTableItems');
      }
      return searchResults;
    }));
  }

  public queryPut<T>(
    service: string,
    basePath: string,
    // tslint:disable-next-line:no-any
    requestPayload: any,
    queryParams?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      responseType?: 'json';
      withCredentials?: boolean;
      reportProgress?: boolean
    },
    fullURL?: string,
  ): Observable<T> {
    const url: string = fullURL ? fullURL : this.getUrlByKey(service, basePath);
    return this.http.put<T>(url, requestPayload, queryParams);
  }

  public queryPutHttpResponse(
    service: string,
    basePath: string,
    // tslint:disable-next-line:no-any
    requestPayload: any,
    queryParams?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      responseType?: 'json';
      withCredentials?: boolean;
      reportProgress?: boolean
    },
    fullURL?: string,
  ): Observable<HttpResponse<object>> {
    const url: string = fullURL ? fullURL : this.getUrlByKey(service, basePath);
    return this.http.put(url, requestPayload, {
      ...queryParams,
      observe: 'response',
    });
  }

  /**
   * @param bodyParams Model.BenefitElectionScheduleCriteria - for custom ElectionSourceAndSchedules criteria, Model.Criteria - for auth.service -> groupPermission
   */
  public search<T>(
    service: string,
    basePath: string,
    bodyParams: SearchBodyParams,
    queryParams: Model.QueryParams = null,
    searchParams: Model.SearchParams = null,
    getTotalCount: boolean = false,
  ): Observable<Model.SearchResults<T>> {
    const { serviceUrl, isPagination, requiredNumberOfRecords } = this.getSearchParams(
      service,
      basePath,
      queryParams,
      searchParams,
      getTotalCount,
    );
    return this.http.post<T>(this.getUrlByKey(service, serviceUrl), bodyParams, {
      observe: 'response',
      // tslint:disable-next-line:no-any
    }).pipe(map((res: HttpResponse<T> | any) => {
      const searchResults: Model.SearchResults<T> = {
        data: isPagination && isArray(res.body) ? res.body.slice(0, requiredNumberOfRecords) : res.body,
        hasMore: isPagination && isArray(res.body) ? (res.body.length > requiredNumberOfRecords) : false,
      };

      if (getTotalCount) {
        searchResults.totalCount = +res.headers.get('auroraCountTableItems');
      }
      return searchResults;
    }));
  }

  public searchData<T>(
    service: string,
    basePath: string,
    bodyParams: Model.SearchCriteria | BenefitElectionScheduleCriteria | Criteria,
    queryParams: Model.QueryParams = null,
    searchParams: Model.SearchParams = null,
    getTotalCount: boolean = false,
  ): Observable<Model.SearchResults<T>> {
    const { serviceUrl, isPagination, requiredNumberOfRecords } = this.getSearchParams(
      service,
      basePath,
      queryParams,
      searchParams,
      getTotalCount,
    );
    return this.http.post<T>(this.getUrlByKey(service, serviceUrl), bodyParams, {
      observe: 'response',
      // tslint:disable-next-line:no-any
    }).pipe(map((res: HttpResponse<T> | any) => {
      const searchResults: Model.SearchResults<T> = {
        data: isPagination ? res.body.data.slice(0, requiredNumberOfRecords) : res.body.data,
        hasMore: isPagination ? (res.body.data.length > requiredNumberOfRecords) : false,
      };
      if (getTotalCount) {
        searchResults.totalCount = res.body.totalCount;
      }
      return searchResults;
    }));
  }

  public post<T>(
    service: string,
    basePath: string,
    bodyParams: Model.RequestPayload[] | Model.SearchCriteria | FileAttachmentMetadata,
    responseType?: string,
    // TODO we will remove any, when we will change all serach used component
    // tslint:disable-next-line:no-any
  ): Observable<HttpEvent<T> | any> {
    // tslint:disable-next-line:no-any
    const respType: any = responseType ? responseType : 'body';
    return this.http.post<T>(this.getUrlByKey(service, basePath), bodyParams, {
      observe: respType,
    });
  }

  public uploadFile<T>(
    file: File,
    serviceKey: string,
    basePath: string,
    filePayload: Model.FilePayload,
  ): Observable<T> {
    const uploadHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.queryPut(serviceKey, basePath, filePayload.command).pipe(
      switchMap(() =>
        this.getUploadUrl(serviceKey, basePath, filePayload.fileInfo.id),
      ),
      switchMap((uploadUrl) =>
        this.queryPut<T>(
          null,
          null,
          file,
          { headers: uploadHeaders, reportProgress: true },
          uploadUrl,
        ),
      ),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  // tslint:disable-next-line:typedef no-any
  public createCommand(fileData: any, commandType: string, updateData = true) {
    const contact = this.contactQuery.getActive();
    const fullName = `${contact.firstName} ${contact.lastName}`;
    if (updateData) {
      this.updateData(fileData, commandType, Dates.now(), fullName, contact.id);
    }
    // tslint:disable-next-line:no-any
    const command: any = {
      id: uuid(),
      version: 1,
      producerId: environment.services.producerId,
      eventCorrelationId: uuid(),
      type: commandType,
      created: Dates.now(),
      createdBy: fullName,
      createdById: contact.id,
      data: fileData,
    };
    return command;
  }

  public createFileInfo(fileData: Model.FileData): Model.FileInfo {
    const contact = this.contactQuery.getActive();
    const fileInfo: Model.FileInfo = {
      id: uuid(),
      parentId: contact.clientId,
      fileName: fileData.renameFile,
      fileType: fileData.fileType,
      isTest: fileData.isTest,
      currentState: CommonConstants.commands.Pending,
      parentType: CommonConstants.getPath.file.parentType,
      version: 1,
    };
    return fileInfo;
  }

  public uploadAttachment(file: File, profileId: string, fileType: string, fileName: string, currentState: string, lastTransition: string, parentType: ParentType, parentId: string): Observable<Attachment> {
    let metadata: FileAttachmentMetadata;
    const attachment: Attachment = this.prepareAttachment(fileType, fileName, currentState, lastTransition, parentType, parentId);
    const attachmentUrl: string = stringFormat(CommonConstants.getPath.file.createAttachment, {
      profileId,
      commandName: AttachmentCommandType.StartToUploaded,
    });
    const attachmentCommand: Model.Command = this.createCommand(attachment, AttachmentCommandType.StartToUploaded);
    return this.queryPut(CommonConstants.getPath.file.serviceKey, attachmentUrl, attachmentCommand).pipe(map((response) => {
      return { attachment, file };
    }), switchMap((attachmentResponse: { attachment: Attachment; file: File }) => {
      metadata = {
        fileAttachmentId: attachmentResponse.attachment.id,
        fileAttachmentType: attachmentResponse.attachment.attachmentType,
        name: attachmentResponse.attachment.friendlyFileName,
        parentId: attachmentResponse.attachment.parentId,
        parentType: attachmentResponse.attachment.parentType,
      };
      const url: string = stringFormat(CommonConstants.getPath.file.uploadAttachment, {
        profileId,
        fileAttachmentId: metadata.fileAttachmentId,
      });
      return this.post(CommonConstants.getPath.file.serviceKey, url, metadata);
    }), switchMap((fileResponse: string) => {
      const uploadHeaders: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'x-amz-meta-name': `${fileName}`,
        'x-amz-meta-parentId': `${metadata.parentId}`,
        'x-amz-meta-parentType': `${metadata.parentType}`,
      });
      return this.queryPut(null, null, file, { headers: uploadHeaders, reportProgress: true }, fileResponse);
    }), map((s3Response) => {
      return attachment;
    }));
  }

  public createReplyCommand(fileData: string, attachmentInfo?: Attachment[]): SupportRequestCommentsCommand {
    let commentAttachments: CommentAttachments[];
    if (attachmentInfo && attachmentInfo.length > 0) {
      commentAttachments = attachmentInfo.map(
        (c): Attachment => ({filePath: c.filePath, attachmentType: c.attachmentType, friendlyFileName: c.friendlyFileName}));
    }
    const contact = this.contactQuery.getActive();
    const fullName = `${contact.firstName} ${contact.lastName}`;
    let requestBody: SupportRequestComments;
    if (attachmentInfo.length > 0) {
      requestBody = {
        commentText: `${fileData}`,
        attachments: commentAttachments,
      };
    } else {
      requestBody = {
        commentText: `${fileData}`,
      };
    }
    return {
      id: uuid(),
      producerId: environment.services.producerId,
      created: Dates.now(),
      createdBy: fullName,
      createdById: contact.id,
      data: requestBody,
    };
  }

  public uploadDocument(file: File, profileId: string, fileName: string, fileType: string, fileDiscription?: string): Observable<Document> {
    const document: Document = this.prepareDocument(fileName, DocumentCommandType.StartToActive, fileDiscription || '');
    return this.uploadAttachment(file, profileId, fileType, fileName, AttachmentState.Uploaded, AttachmentCommandType.StartToUploaded, ParentType.DOCUMENT, document.id).pipe(switchMap((attachment) => {
      document.documentAttachmentId = attachment.id;
      return this.saveDocument(document, DocumentCommandType.StartToActive);
    }));
  }

  // save document
  public saveDocument(document: Document, commandName: DocumentCommandType): Observable<Document> {
    const profileId: string = document.parentId;
    const url: string = stringFormat(CommonConstants.getPath.profile.createDocumentRecord, {
      profileId,
      commandName,
      documentId: document.id,
    });
    const command: Model.Command = this.createCommand(document, document.lastTransition);
    return this.queryPut(CommonConstants.getPath.profile.configurationKey, url, command);
  }

  private getSearchParams(
    service: string,
    basePath: string,
    queryParams: Model.QueryParams = null,
    searchParams: Model.SearchParams = null,
    getTotalCount: boolean = false): Model.ServiceParams {
    let serviceUrl = '';
    let baseUrl = '';
    let searchUrl = '';
    let requiredNumberOfRecords = 0;
    let isPagination = false;

    /**
     * Get basepath without search query
     */
    baseUrl = basePath;
    /**
     * Update baseUrl with queryParams
     */
    baseUrl = queryParams ? stringFormat(baseUrl, queryParams) : baseUrl;
    /**
     * Check searchParams
     * if 'page' is present; set isPagination flag and increment 'page'
     * set requiredNumberOfRecords
     * Create searchUrl
     */
    if (searchParams) {
      searchUrl = this.createSearchUrl(searchParams);
    }
    if (searchParams && searchParams.take) {
      isPagination = true;
      requiredNumberOfRecords = searchParams.take;
      if (searchParams.take >= 2) {
        searchParams.take += 1;
      }
      searchUrl = this.createSearchUrl(searchParams);
    }
    /**
     * Create serviceUrl using searchUrl and baseUrl
     */
    serviceUrl = `${baseUrl}${searchUrl}`;
    /**
     * Append getTotalCount
     */
    serviceUrl = this.appendTotalCountParam(serviceUrl, getTotalCount);

    return { serviceUrl, isPagination, requiredNumberOfRecords };

  }

  /**
   * This methods updates created and updated fileds by checking commandType
   */
  // tslint:disable-next-line:no-any
  private updateData<T extends EntityBase>(data: T, commandType: string, date: string, userName: string, userId: string): void {
    data.lastTransition = commandType;
    data.currentState = Transitions.toState(commandType);
    if (Transitions.fromState(commandType) === 'Start') {
      data.created = date;
      data.createdBy = userName;
      data.createdById = userId;
    }
    data.updated = date;
    data.updatedBy = userName;
    data.updatedById = userId;
    if (!data.version) {
      data.version = 1;
    }
  }

  private getUploadUrl(
    serviceKey: string,
    basePath: string,
    fileInfoId: string,
  ): Observable<string> {
    const servicePath: string = stringFormat(CommonConstants.getPath.file.upload, {
      userId: this.contactQuery.getActiveId(),
      fileId: fileInfoId,
    });

    return this.query(
      CommonConstants.getPath.file.serviceKey,
      servicePath,
    ).pipe(
      map((resp: Model.URLInfo) => {
        return resp.url;
      }),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }

  public getUrlByKey(serviceKey: string, basePath: string): string {
    // tslint:disable-next-line:no-any
    return (environment.services as any)[serviceKey].endpoint.concat(basePath);
  }

  private prepareAttachment(fileType: string, fileName: string, currentState: string, lastTransition: string, parentType: ParentType, parentId: string): Attachment {
    const attachment: Attachment = {
      id: uuid(),
      attachmentType: fileType,
      currentState,
      lastTransition,
      friendlyFileName: fileName,
      parentId,
      filePath: '',
      parentType,
      version: 1,
      configuration: {
        convertToImage: false,
        generateThumbnail: false,
      },
    };
    return attachment;
  }

  private prepareDocument(fileName: string, documentCommandType: DocumentCommandType, fileDescription?: string): Document {
    const contact = this.contactQuery.getActive();
    const document: Document = {
      name: fileName,
      documentType: '',
      currentState: DocumentState.Active,
      category: DocumentCategoryType.Client,
      documentVersion: '1',
      description: fileDescription ? fileDescription : '',
      documentAttachmentId: '',
      parentType: ProfileConfigurationParentType.CLIENT,
      parentId: contact.clientId,
      lastTransition: documentCommandType,
      id: uuid(),
      created: Dates.now(),
      createdBy: `${contact.firstName} ${contact.lastName}`,
      createdById: contact.id,
      version: 1,
      updated: Dates.now(),
      updatedBy: `${contact.firstName} ${contact.lastName}`,
      updatedById: contact.id,
    };
    return document;
  }

  private appendTotalCountParam(serviceUrl: string, getTotalCount: boolean): string {
    let finalServiceUrl = '';
    const urls = serviceUrl.split('?');
    const totalCountParam = `getTotalCount=${getTotalCount}`;
    if (urls.length > 1) {
      finalServiceUrl = `${serviceUrl}&${totalCountParam}`;
    } else {
      finalServiceUrl = `${serviceUrl}?${totalCountParam}`;
    }
    return finalServiceUrl;
  }

  private createSearchUrl(searchParams: Model.SearchParams): string {
    let searchBase = '';
    const paramKeys = Object.keys(searchParams);
    paramKeys.forEach((key, index) => {
      searchBase = index === 0 ? `?${key}={${key}}` : `${searchBase}&${key}={${key}}`;
    });
    return stringFormat(searchBase, searchParams);
  }

}

type SearchBodyParams = Model.SearchCriteria | BenefitElectionScheduleCriteria | Criteria | SupportRequestSearchFilters;

/*
getAll(queryParams:any): Observable<any[]> {
    return this.httpClient
      .get<Model[]>(`${this.url}/${this.endpoint}`)
  }
public create(param: Model): Observable<any> {
    return this.httpClient
      .post<Model>(`${this.url}/${this.endpoint}`, param)
}

getOne(id: number, queryParams:any): Observable<any> {
    return this.httpClient
    .get<Model>(`${this.url}/${this.endpoint}/${id}`)
}
*/
