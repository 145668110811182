<header>
  <nav class="navbar navbar-expand-lg mb-0 navbar-light" role="navigation">
    <div>
      <div class="navbar-header tasc-navbar-header ml-5">
        <a tabindex="0" role="button" (keyup.enter)="$event.target.click()" routerLink="/plan/plans" id="tascHome">
          <img [alt]="brand.companyName" src="assets/img/{{brand.logoFileName}}" title="Home" />
        </a>
      </div>
    </div>
    <div *ngIf="customizationLogo?.isCustomBranding" class="vertical-separator"></div>
    <div *ngIf="customizationLogo?.isCustomBranding" class="cobranding">
      <div class="cobranding-logo-wrapper">
        <img alt="cobrand-logo" src="{{customizationLogo.url}}" title="Cobranding" />
      </div>
    </div>
    <div class="ml-auto">
      <div class="row mr-0 pr-0">
        <div class="col-12">
          <div class="navbar-collapse collapse quick-link">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link p-4">
                  <span (click)="toggleAlerts()" tabindex="0" role="button" (keyup.enter)="$event.target.click()">
                    <span class="unreadCount" *ngIf="unreadCount > 0">{{unreadCount}}</span>
                    <img src="../../../../assets/img/Green.svg" class="alert-icon" alt="" />Alerts
                  </span>
                </a>
              </li>
              <li class="nav-item">
                <a data-cy="settings-nav-item" class="nav-link p-4" tabindex="0" role="button"
                (keyup.enter)="$event.target.click()"
                (mouseenter)="$event.target.click()"
                (mouseleave)="onOpenDropdown()"
                (click)="onOpenDropdown(HeaderDropdowns.Settings)"
                id="settingLink"
                  routerLinkActive="active">Settings
                  <ul data-cy="settings-list-items" class="menu-list"
                    [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Settings }">
                    <li data-cy="settings-profile" *appHasPermission="'/settings/profile'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);" routerLink="/settings/profile">
                      <a (click)="openDropdown = null;" id="profileLink" class="pr-5">Profile</a>
                    </li>
                    <li *appHasPermission="'/settings/manage-users'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);" routerLink="/settings/manage-users">
                      <a (click)="openDropdown = null;" id="manage-users" class="pr-5">Manage users</a>
                    </li>
                    <ng-container *ngIf="hasBenefitPlan$ | async">
                      <li *appHasPermission="'/settings/payroll-schedule'" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);"
                        routerLink="/settings/payroll-schedule">
                        <a (click)="openDropdown = null;" id="payroll-schedule" class="pr-5">Payroll
                          schedule
                        </a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="isClasses">
                      <li data-cy="settings-classes" *appHasPermission="'/settings/classes'" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);" routerLink="/settings/classes">
                        <a (click)="openDropdown = null;" id="classes" class="pr-5">Classes</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="(divisions$ | async) as divisions">
                      <ng-container *ngIf="divisions.length > 0">
                        <li data-cy="settings-divisions" *appHasPermission="'/settings/divisions'" tabindex="0"
                          role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);"
                          routerLink="/settings/divisions">
                          <a (click)="openDropdown = null" id="divisions" class="pr-5">Divisions</a>
                        </li>
                      </ng-container>
                    </ng-container>
                    <li *appHasPermission="'/settings/bank-accounts'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);"
                      routerLink="/settings/bank-accounts">
                      <a (click)="openDropdown = null;" id="bank-accounts" class="pr-5">Bank accounts</a>
                    </li>
                    <li *appHasPermission="'/settings/signin-security'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Settings);"
                      routerLink="/settings/signin-security">
                      <a (click)="openDropdown = null;" id="signin-security" class="pr-5">Sign in &
                        security
                      </a>
                    </li>
                    <li class="d-none">
                      <a>User Access</a>
                    </li>
                  </ul>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-4" tabindex="0" role="button"
                (keyup.enter)="$event.target.click()"
                (mouseenter)="$event.target.click()"
                (mouseleave)="onOpenDropdown()"
                (click)="onOpenDropdown(HeaderDropdowns.Support)"
                id="supportLink"
                  data-cy="support-link" routerLinkActive="active">Support
                  <ul class="menu-list" data-cy="support-hover" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Support }">
                    <li *appHasPermission="'/support/support-requests'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Support);"
                      routerLink="/support/support-requests">
                      <a #supportRequests id="supportRequestsLink" class="pr-5" (click)="openDropdown = null;">Support
                        Requests</a>
                    </li>
                    <li *appHasPermission="'/support/support-user-guides-and-videos'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Support);"
                      routerLink="/support/support-user-guides-and-videos">
                      <a id="supportUserGuidesAndVideos" data-cy="user-guides-link" class="pr-5"
                        (click)="openDropdown = null;">Help Content
                      </a>
                    </li>
                    <li *appHasPermission="'/support/add-support'" tabindex="0" role="button"
                      (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Support);"
                      routerLink="/support/add-support">
                      <a id="supportContactUs" data-cy="contact-us-link" class="pr-5"
                        (click)="openDropdown = null;">Contact Us
                      </a>
                    </li>
                  </ul>
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link p-4" tabindex="0" role="button" aria-label="Toggle Dropdown"
                  (keyup.enter)="$event.target.click()"
                  (mouseenter)="onOpenDropdown()"
                  (mouseleave)="onOpenDropdown()"
                  (click)="onOpenDropdown()">
                  {{userName}}
                  <i class="icon-dropdown" aria-hidden="true"></i>
                  <span class="sr-only">Toggle Dropdown</span>
                </a>
                <ul class="dropdown-menu">
                  <li class="dropdown-item">
                    <a routerLink="/logout">Sign out</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mr-0 pr-0">
        <div class="col-12">
          <div class="navbar-collapse collapse main-menu pr-2">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" *appHasPermission="'menuOverview'">
                <a class="nav-link py-4" tabindex="0" role="button"
                  (mouseenter)="onOpenDropdown()"
                  (mouseleave)="onOpenDropdown()"
                  (keyup.enter)="$event.target.click()"
                  (click)="onOpenDropdown()"
                  routerLinkActive="active" routerLink="/home" id="menuOverview">Overview</a>
              </li>
              <li class="nav-item" *appHasPermission="'menuEmployee'" data-cy="employee-menu">
                <a class="nav-link py-4" tabindex="0" role="button"
                  (keyup.enter)="$event.target.click()"
                  (mouseenter)="$event.target.click()"
                  (mouseleave)="onOpenDropdown()"
                  (click)="onOpenDropdown(HeaderDropdowns.Employees)"
                  routerLinkActive="active" id="menuEmployees">Employees
                  <ul class="menu-list" data-cy="employee-menu-list" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Employees }">
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                      routerLink="/employees/manage/add" *appHasPermission="'/employees/manage/add'">
                      <a id="add-employees" (click)="openDropdown = null;">Add employees</a>
                    </li>
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                      *appHasPermission="'/employees/manage'" routerLink="/employees/manage">
                      <a (click)="openDropdown = null;" id="manageEmp">View employees</a>
                    </li>
                    <ng-container *ngIf="hasBenefitPlan$ | async">
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                        routerLink="/employees/enroll" *appHasPermission="'/employees/enroll'">
                        <a (click)="openDropdown = null;" id="enrollEmp">Enroll employees</a>
                      </li>
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                        routerLink="/employees/approve" *appHasPermission="'/employees/approve'">
                        <a (click)="openDropdown = null;" id="approveEnrollment">Approve enrollment</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="hasPaymentPlan$ | async">
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                        *appHasPermission="'/employees/view-events'" routerLink="/employees/view-events">
                        <a (click)="openDropdown = null;" id="empEvents">View Events</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="(displayVaccineReport | async) as displayVax">
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                        routerLink="/employees/vaccine" *appHasPermission="'/employees/vaccine'">
                        <a (click)="openDropdown = null;" id="vaccineTracking">Vaccine Tracking</a>
                      </li>
                    </ng-container>
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Employees);"
                      *appHasPermission="'/employees/enroll-employee'" class="d-none"
                      routerLink="/employees/enroll-employee">
                      <a (click)="openDropdown = null;" id="approveEnrollment">Enroll Grid</a>
                    </li>
                  </ul>
                </a>
              </li>
              <li class="nav-item" *appHasPermission="'menuPlans'">
                <a class="nav-link py-4" tabindex="0" role="button" data-cy="home-nav-plans"
                (keyup.enter)="$event.target.click()"
                (mouseenter)="$event.target.click()"
                (mouseleave)="onOpenDropdown()"
                (click)="onOpenDropdown(HeaderDropdowns.Offerings)"
                  routerLinkActive="active" id="benefitPlans">Offerings
                  <ul class="menu-list" data-cy="plans-menu-list" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Offerings }">
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Offerings);"
                      routerLink="/plan/plans" *appHasPermission="'/plan/plans'">
                      <a (click)="openDropdown = null;" id="view-plans" data-cy="sub-nav-view-plans">View plans</a>
                    </li>
                    <ng-container *ngIf="hasBenefitPlan$ | async">
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Offerings);"
                        routerLink="/plan/verify" *appHasPermission="'/plan/verify'">
                        <a (click)="openDropdown = null;" (keyup.enter)="$event.target.click()"
                          id="verify-posting-menu">Verify posting (PVR)</a>
                      </li>
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Offerings);"
                        routerLink="/plan/summary" *appHasPermission="'/plan/summary'">
                        <a (click)="openDropdown = null;" (keyup.enter)="$event.target.click()"
                          id="posting-summary-menu">Posting summary</a>
                      </li>
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Offerings);"
                        routerLink="/plan/post-manually" *appHasPermission="'/plan/post-manually'">
                        <a (click)="openDropdown = null;" (keyup.enter)="$event.target.click()"
                          id="post-manually-menu">Post manually</a>
                      </li>
                      <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Offerings);"
                        routerLink="/plan/terminate" *appHasPermission="'/plan/terminate'">
                        <a (click)="openDropdown = null;" (keyup.enter)="$event.target.click()"
                          id="remove-employee-menu">Remove employees</a>
                      </li>
                    </ng-container>
                  </ul>
                </a>
              </li>

              <li *appHasPermission="'menuFunding'" class="nav-item">
                <a
                  (keyup.enter)="$event.target.click()"
                  (mouseenter)="$event.target.click()"
                  (mouseleave)="onOpenDropdown()"
                  (click)="onOpenDropdown(HeaderDropdowns.Transactions)"
                  class="nav-link py-4"
                  tabindex="0"
                  role="button"
                  data-cy="funding-menu"
                  routerLinkActive="active"
                  id="menuFunding"
                >Transactions
                <ul class="menu-list" data-cy="transactions-menu-list" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Transactions }">
                  <ng-container *ngIf="hasBenefitPlan$ | async">
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Transactions);"
                      routerLink="/transactions/funding" *appHasPermission="'/transactions/funding'" data-cy="funding-transaction-menu">
                      <a (click)="openDropdown = null;" id="funding-transactions-menu">Funding Transactions</a>
                    </li>
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Transactions);"
                      routerLink="/transactions/fund-from-cash-account" *appHasPermission="'/transactions/fund-from-cash-account'" data-cy="fund-from-cash-account-menu">
                      <a (click)="openDropdown = null;" id="fund-from-cash-account-menu">Fund From Cash Account</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="hasPaymentPlan$ | async">
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Transactions);"
                      routerLink="/transactions/remittance-account" *appHasPermission="'/transactions/remittance-account'" data-cy="remittance-account-menu">
                      <a (click)="openDropdown = null;" id="remittance-account-menu">Remittance</a>
                    </li>
                  </ng-container>
                </ul>
              </a>
              </li>
              <li class="nav-item" *appHasPermission="'menuFiles'">
                <a class="nav-link py-4" tabindex="0" role="button"
                  (keyup.enter)="$event.target.click()"
                  (mouseenter)="$event.target.click()"
                  (mouseleave)="onOpenDropdown()"
                  (click)="onOpenDropdown(HeaderDropdowns.Files)"
                  routerLinkActive="active"
                  id="menuFiles">Files
                  <ul class="menu-list" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Files }">
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Files);"
                      routerLink="/files/data-files" *appHasPermission="'/files/data-files'">
                      <a (click)="openDropdown = null;" id="data-files-menu">Data Files</a>
                    </li>
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Files);"
                      routerLink="/files/templates" *appHasPermission="'/files/templates'">
                      <a (click)="openDropdown = null;" id="templates-menu">Templates</a>
                    </li>
                    <li tabindex="0" role="button" (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Files);"
                      routerLink="/files/documents" *appHasPermission="'/files/documents'">
                      <a (click)="openDropdown = null;" id="my-documents-menu">My Documents</a>
                    </li>
                    <ng-container *ngIf="isFileSettingAllowed">
                      <li *appHasPermission="'/files/file-settings'" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Files);" routerLink="/files/file-settings">
                        <a (click)="triggerGAevent();" id="templates-menu">File Settings</a>
                      </li>
                    </ng-container>
                  </ul>
                </a>
              </li>
              <li class="nav-item" *appHasPermission="'menuReports'">
                <a class="nav-link py-4" tabindex="0" role="button"
                   routerLink="/reports">Reports</a>
              </li>
              <li class="nav-item" *appHasPermission="'menuBilling'">
                <a class="nav-link py-4" tabindex="0" role="button"
                  (keyup.enter)="$event.target.click()"
                  (mouseenter)="$event.target.click()"
                  (mouseleave)="onOpenDropdown()"
                  (click)="onOpenDropdown(HeaderDropdowns.Invoices)"
                  routerLinkActive="active" id="menuTransactions">Invoices
                  <ul class="menu-list" [ngClass]="{'nav-menu-keyboard-hover': openDropdown === HeaderDropdowns.Invoices }">
                    <span *ngIf="isNetSuite">
                      <li (click)="openDialog('billing')" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Invoices);">
                        <a (click)="openDropdown = null;">Invoice Transactions
                          <img class="ml-2" alt="" src="../../../../assets/img/open-new-tab.svg">
                        </a>
                      </li>
                      <li (click)="openDialog('invoice')" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Invoices);">
                        <a (click)="openDropdown = null;">Invoices
                          <img class="ml-2" alt="" src="../../../../assets/img/open-new-tab.svg">
                        </a>
                      </li>
                      <li (click)="openDialog('payment')" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Invoices);">
                        <a (click)="openDropdown = null;">Payment
                          <img class="ml-2" alt="" src="../../../../assets/img/open-new-tab.svg">
                        </a>
                      </li>
                      <li (click)="openDialog('creditMemo')" tabindex="0" role="button"
                        (keyup.enter)="$event.target.click(); onOpenDropdown(HeaderDropdowns.Invoices);">
                        <a (click)="openDropdown = null;">Credit Memo
                          <img class="ml-2" alt="" src="../../../../assets/img/open-new-tab.svg">
                        </a>
                      </li>
                    </span>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

<div class="alert-list">
  <app-alerts-window #alertsWindow [showWindow]="openDropdown === HeaderDropdowns.Alerts" (closeAlert)="toggleAlerts()"
    (unreadCount)="onUpdateUnreadCount($event)" role="dialog"></app-alerts-window>
</div>
