import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ClientByContact } from '@models/dashboard/model';
import { Contact } from '@models/profile/model';
import { Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Categories } from 'src/app/shared/constants/analyticsCategory.constant';
import { BrandService } from 'src/app/shared/services/brand.service';
import { ErrorNotificationService } from 'src/app/shared/services/error-notification.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ClientByContactQuery, ContactQuery, CurrentUserQuery } from 'src/app/state';

import { AuthenticationService } from '../auth/services/authentication.service';
import { CommonConstants } from './../../shared/constants/common.constant';

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.scss'],
})
export class DistributorComponent implements OnInit, OnDestroy {
  public selectedClient: string;
  public loading: boolean;
  public contactName: string;
  public numberOfClients: number;
  public contacts$: Observable<Contact[]>;
  public allClients$: Observable<ClientByContact[]>;
  public bodyTag: HTMLElement;
  public selectedClientContact: Contact;

  /** control for the selected client */
  public selectedClientCtrl: FormControl = new FormControl('', [Validators.required]);

  /** control for the MatSelect filter keyword */
  public clientFilterCtrl: FormControl = new FormControl();

  /** list of clients filtered by search keyword */
  public filteredClients$ = new Observable<ClientByContact[]>();
  public readonly brand = this.brandService.getBrandResources();

  @ViewChild('singleSelect') public singleSelect: MatSelect;
  private subscriptions = new Subscription();

  public constructor(
    private authenticationService: AuthenticationService,
    private clientByContactQuery: ClientByContactQuery,
    private contactQuery: ContactQuery,
    public renderer: Renderer2,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private errorNotificationService: ErrorNotificationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private brandService: BrandService,
    private utilityService: UtilityService,
    private currentUserQuery: CurrentUserQuery,
    ) { }

  public ngOnInit(): void {
    this.subscriptions.add(this.errorNotificationService.errorNotification.subscribe((err) => {
      this.loading = false;
      this.utilityService.showGrowelMessage(this.sharedService.getMessageByBrand(CommonConstants.errorCodes.apiFailure), false);
    }));
    this.contacts$ = this.contactQuery.selectAllWhenLoaded()
      .pipe(
        tap((contacts) => {
          if (contacts.length) {
            const currentContact = contacts.find((contact) =>
              contact.email === this.currentUserQuery.getEmailAddress(),
            );
            this.contactName = `${currentContact.firstName} ${currentContact.lastName}`;
          }
        }),
      );
    this.allClients$ = this.clientByContactQuery.selectAllWhenLoaded()
      .pipe(
        first(),
        tap((clients) => this.numberOfClients = clients.length),
      );
    /** load the initial clients list */
    this.filteredClients$ = this.allClients$;
    /** listen for search field value changes */
    this.subscriptions.add(this.clientFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterClients();
      }));
  }

  /**
   * This method is trigerred when user selects the client and clicks on continue.
   * This calls the token service to get the new token based on the selected client.
   * @returns {void}
   * @memberof DistributorComponent
   */
  public async onClientSelect(): Promise<void> {
    this.selectedClientContact = this.contactQuery.getAll().find((contact) => contact.parentId === this.selectedClientCtrl.value.clientId);
    await this.authenticateAndRedirectUser();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async authenticateAndRedirectUser(): Promise<void> {
    this.loading = true;
    this.googleAnalyticsService.eventEmitter(Categories.EventCategories.SelectClient);
    const clientId = this.selectedClientCtrl.value.clientId;
    const authenticationResult = await this.authenticationService.signInWithStoredCredentials(clientId);
    await this.authenticationService.navigateBasedOnAuthentication(authenticationResult);
    this.loading = false;
  }

  private filterClients(): void {
    // get the search keyword
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClients$ = this.clientByContactQuery.selectAllWhenLoaded().pipe(first());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter clients
    this.filteredClients$ = this.clientByContactQuery.selectBySearchTerm(search).pipe(first());
  }

}
