import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { BenefitAccountSummaryByBenefitPlan, IndividualsAndDependents, ParticipantEntry } from '@models/dashboard/model';
import dayjs from 'dayjs';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { BasePlanType, CoreService, OrderDirection } from 'src/app/shared/models/clux/enum';
import { BenefitPlanSummaryData, Pagination, SearchQuery } from 'src/app/shared/models/clux/model';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import {
  BenefitAccount,
  BenefitAccountState,
  ChainType,
  Criteria,
  Election,
  ElectionState,
  Entry,
  EntryState,
  EntryType,
  HideFromType,
  MatchType,
  ParentType,
  ScheduleAdjustment,
  ScheduleAdjustments,
  ScheduleAdjustmentsCommandType,
  ScheduleType,
  SearchCriteria,
} from 'src/app/shared/models/uba/account/model';
import {
  BenefitPlan,
  BenefitPlanFundingSourceAndSchedule,
  BenefitPlanPaymentTier,
  BenefitPlanState,
  ContributionPostingType,
  FundingPostingType,
  FundingSourceType,
} from 'src/app/shared/models/uba/configuration/model';
import { EmploymentInfo } from 'src/app/shared/models/uba/profileConfiguration/model';
import { BankAccountService } from 'src/app/shared/services/bank-account.service';
import { PaymentSourceAccountService } from 'src/app/shared/services/payment-source-account.service';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { Clone } from 'src/app/shared/utils/clone';
import { CommandFactory } from 'src/app/shared/utils/command-factory';
import { Uri } from 'src/app/shared/utils/uri';
import { ContactQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';

import { VerifyPostingGridViewModelMapper } from '../mappers';
import { PlanData } from '../model/plan-data-model';
import { VerifyPostingGroupingType, VerifyPostingItemViewModel } from '../model/verify-posting-view-model';
import { PlanService } from './plan.service';

@Injectable({
  providedIn: 'root',
})
export class BenefitService {
  public selectedRecord: PlanData;
  public openEntriesForIndividualNotification: Subject<{ individualId: string, name: string }> = new Subject<{ individualId: string, name: string }>();
  public selectedPlan: BenefitPlanSummaryData = undefined;
  private resetPageNotify: Subject<boolean> = new Subject<boolean>();
  private expandChange: EventEmitter<boolean> = new EventEmitter();

  private readonly entryTypeToScheduleType: { [Type in EntryType]?: ScheduleType } = {
    [EntryType.ParticipantContribution]: ScheduleType.Contribution,
    [EntryType.ClientContribution]: ScheduleType.Contribution,
    [EntryType.PayrollPosting]: ScheduleType.Deduction,
    [EntryType.ClientFunding]: ScheduleType.Funding,
  };

  public constructor(
    private bankAccountService: BankAccountService,
    private contactQuery: ContactQuery,
    private paymentSourceAccountService: PaymentSourceAccountService,
    private commandFactory: CommandFactory,
    private serviceFactory: ServiceFactory,
    private http: HttpClient,
    private planService: PlanService,
  ) { }

  public get resetPageNotifyObservable(): Observable<boolean> {
    return this.resetPageNotify.asObservable();
  }

  public emitExpandChangeEvent(expand: boolean): void {
    this.expandChange.emit(expand);
  }
  public getExpandChangeEmitter(): EventEmitter<boolean> {
    return this.expandChange;
  }
  public resetPageNotifyOther(data: boolean): void {
    this.resetPageNotify.next(data);
  }

  public hasBenefitPlan(): Observable<boolean> {
    return this.getBenefitPlanSummaryData().pipe(
      first(),
      map((benefitPlans) => benefitPlans.length > 0),
    );
  }

  public getBenefitPlanSummaryData(): Observable<BenefitPlanSummaryData[]> {
    const getPath: Model.ConfigurationPath = CommonConstants.getPath.configuration;
    const contact = this.contactQuery.getActive();
    const clientId: string = contact.clientId;
    const plansServiceUrl: string = stringFormat(CommonConstants.getPath.configuration.benefitPlans, {
      profileId: contact?.id || '*',
    });
    const requestPayload: Model.SearchCriteria = [{
      key: 'parentId',
      value: clientId,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: 'CLIENT',
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    },
    {
      key: 'currentState',
      value: [
        BenefitPlanState.Active,
        BenefitPlanState.Draft,
        BenefitPlanState.FinalizationPreCheck,
        BenefitPlanState.Finalized,
        BenefitPlanState.FinalizingAccounts,
        BenefitPlanState.FinalizingError,
        BenefitPlanState.FinalizingPlan,
        BenefitPlanState.GracePeriod,
        BenefitPlanState.Initiated,
        BenefitPlanState.PropagatingDateEdits,
        BenefitPlanState.RolloverProcessing,
        BenefitPlanState.RolloverProcessingPreCheck,
        BenefitPlanState.RunOut,
        BenefitPlanState.Reconciliation,
        BenefitPlanState.Setup,
        BenefitPlanState.RolloverComplete,
        BenefitPlanState.CardsOrdered,
        BenefitPlanState.Removed,
        BenefitPlanState.Start,
      ].join('|'),
      matchType: MatchType.IN,
    }];

    return this.getBenefitAccounts(getPath.serviceKey, plansServiceUrl, requestPayload, true);
  }

  public getBenefitAccounts(serviceKey: string, basePath: string, requestPayload: Model.SearchCriteria, isTotalEnrolledCount: boolean = false): Observable<BenefitPlanSummaryData[]> {
    const contact = this.contactQuery.getActive();
    const accountSummaryByBPqueryParam = {
      profileId: contact.clientId,
    };

    const accountSummaryByBPsearchParam = {
      take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
      skip: 0,
    };

    const benefitAccountCurrentState: BenefitAccountState[] = [
      BenefitAccountState.Active,
      BenefitAccountState.ActiveNonDisbursable,
      BenefitAccountState.Finalizing,
      BenefitAccountState.Finalized,
      BenefitAccountState.GracePeriod,
      BenefitAccountState.Initiated,
      BenefitAccountState.RolloverProcessing,
      BenefitAccountState.RunOut,
      BenefitAccountState.Reconciliation,
      BenefitAccountState.RolloverComplete,
      BenefitAccountState.ALOAWithoutBenefits,
      BenefitAccountState.ALOAWithBenefits,
      BenefitAccountState.Closed,
      BenefitAccountState.Enrolled,
      BenefitAccountState.IdVerificationPending,
      BenefitAccountState.PendingApproval,
      BenefitAccountState.Start,
    ];
    const accountSummaryByBPRequestPayload: Criteria[] = [
      {
        key: 'clientId',
        value: contact.clientId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'benefitAccountCurrentState',
        value: benefitAccountCurrentState.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'hideFrom',
        value: [
          HideFromType.Client,
          HideFromType.ParticipantAndClient,
        ].join('|'),
        matchType: MatchType.NOT_IN,
      },
    ];
    const benefitAccountSummaryMap = new Map();
    if (!isTotalEnrolledCount) {
      return this.serviceFactory.search<BenefitPlanSummaryData[]>(serviceKey, basePath, requestPayload).pipe(map(
        (res) => {
          return res.data;
        },
      ));
    } else {
      return combineLatest([
        this.serviceFactory.search<BenefitPlanSummaryData[]>(serviceKey, basePath, requestPayload).pipe(map(
          (res) => {
            return res.data;
          },
        )),
        this.serviceFactory.search<BenefitAccountSummaryByBenefitPlan[]>(
          CommonConstants.getPath.dashboard.serviceKey,
          CommonConstants.getPath.dashboard.benefitAccountSummaryByBenefitPlan,
          accountSummaryByBPRequestPayload,
          accountSummaryByBPqueryParam,
          accountSummaryByBPsearchParam,
        ).pipe(map(
          (res) => {
            return res.data;
          },
        )),
      ]).pipe(
        map((benefitCombineResponse: [BenefitPlanSummaryData[], BenefitAccountSummaryByBenefitPlan[]]) => {
          const benefitPlans: BenefitPlanSummaryData[] = benefitCombineResponse[0];
          const accountSummaryByBP: BenefitAccountSummaryByBenefitPlan[] = benefitCombineResponse[1];
          if (accountSummaryByBP) {
            accountSummaryByBP.forEach((benefitSummary) => {
              benefitAccountSummaryMap.set(benefitSummary.benefitPlanId, benefitSummary.benefitAccountCount);
            });
          }
          if (benefitPlans) {
            benefitPlans.forEach((benefitPlan) => {
              benefitPlan.employeeCount = benefitAccountSummaryMap.has(benefitPlan.id) ? benefitAccountSummaryMap.get(benefitPlan.id) : 0;
              benefitPlan.basePlanType = BasePlanType.Benefit;
            });
          }
          return benefitPlans;
        }),
      );
    }
  }

  // Search benefit accounts bcwp 210
  public searchBenefitAccounts(parentId: string, id: string): Observable<Model.RemoveEmployeeResponse> {
    const contact = this.contactQuery.getActive();
    const requestPayload: Criteria[] = [
      {
        key: 'parentId',
        value: id,
        matchType: MatchType.EXACT,
      },
      {
        key: 'currentState',
        value: [
          BenefitAccountState.Active,
          BenefitAccountState.GracePeriod,
          BenefitAccountState.RunOut,
          BenefitAccountState.Enrolled,
          BenefitAccountState.Initiated,
          BenefitAccountState.IdVerificationPending,
          BenefitAccountState.TOUAgreementPending,
          BenefitAccountState.PendingApproval,
          BenefitAccountState.PropagatingDateEdits,
          BenefitAccountState.ActiveNonDisbursable,
          BenefitAccountState.ALOAWithBenefits,
          BenefitAccountState.ALOAWithoutBenefits].join('|'),
        matchType: MatchType.IN,
      },
      {
        key: 'clientId',
        value: parentId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'hideFrom',
        value: [
          HideFromType.Client,
          HideFromType.ParticipantAndClient,
        ].join('|'),
        matchType: MatchType.NOT_IN,
      },
    ];

    const benefitAccountsCountSearchParam = {
      take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
      skip: 0,
      orderBy: 'planName',
      orderDirection: 'asc',
    };

    return this.serviceFactory.search<BenefitAccount[]>(
      CommonConstants.getPath.account.serviceKey,
      CommonConstants.getPath.account.benefitAccountsCount,
      requestPayload,
      { profileId: id },
      benefitAccountsCountSearchParam,
    ).pipe(
      map((res) => res.data),
      switchMap((benefitAccounts: BenefitAccount[]) => {
        if (benefitAccounts && benefitAccounts.length > 0) {
          const serviceArray: Array<Observable<Model.RemoveEmployeeResponse>> = benefitAccounts.map((account) => {
            const bodyParams: Criteria[] = [
              {
                key: 'parentId',
                value: account.planId,
                matchType: MatchType.EXACT,
                chainType: ChainType.AND,
              },
              {
                key: 'parentType',
                value: ParentType.BENEFIT_PLAN,
                matchType: MatchType.EXACT,
              },
            ];
            return this.serviceFactory.search<Model.RemoveEmployeeResponse>(
              CommonConstants.getPath.configuration.serviceKey,
              CommonConstants.getPath.configuration.fundingSourceAndSchedule,
              bodyParams,
              {
                profileId: contact.clientId,
                benefitPlanId: account.planId,
              },
            ).pipe(map((res) => res.data));
          });
          if (serviceArray.length === 0) {
            return of({ benefitAccounts });
          } else {
            return combineLatest(serviceArray).pipe(
              map((combineResult) => ({
                  fundingAndSchedules: combineResult,
                  benefitAccounts,
              })),
            );
          }
        } else {
          return of({ benefitAccounts });
        }
      }),
    );
  }

  public individualEmploymentSearch(
    searchCriteria: Model.SearchCriteria,
    largerRes?: boolean,
  ): Observable<EmploymentInfo[]> {
    const contact = this.contactQuery.getActive();
    const userId: string = contact?.id || '*';
    const queryParams: Model.QueryParams = {
      userId,
    };
    const searchparams: Model.SearchParams = {
      take: largerRes ? 25 : CommonConstants.individualSearchResultCount,
      skip: 0,
      orderBy: 'created',
      orderDirection: 'desc',
    };
    return this.serviceFactory.search<EmploymentInfo[]>(
      CommonConstants.getPath.profile.configurationKey,
      CommonConstants.getPath.profile.employmentList,
      searchCriteria,
      queryParams,
      searchparams,
    ).pipe(map(
      (res) => {
        return res.data;
      },
    ));
  }

  public getIndividualsAndDependents(query: SearchQuery, profileId: string): Observable<IndividualsAndDependents[]> {

    const url = new Uri(
        `/profile/${profileId}/individualsAndDependents/search`,
        CoreService.Dashboard,
        query,
    );

    return this.http.post<IndividualsAndDependents[]>(url.toString(), null, { observe: 'response' }).pipe(
        map((response) => response.body),
    );
}

  public setSelectedRecord(record: BenefitPlanSummaryData): void {
    this.selectedPlan = record;
    sessionStorage.setItem(CommonConstants.localKeys.currentBP, JSON.stringify(record));
    this.planService.updateSelectedPlanNotification(this.selectedPlan);
  }

  public getSelectedRecord(): BenefitPlanSummaryData {
    if (!this.selectedPlan) {
      this.selectedPlan = JSON.parse(sessionStorage.getItem(CommonConstants.localKeys.currentBP));
      this.planService.updateSelectedPlanNotification(this.selectedPlan);
    }
    return this.selectedPlan;
  }

  public getPostingDownloadFileUrl(date: string): Observable<Model.URLInfo> {
    const basePath: string = stringFormat(
      CommonConstants.getPath.account.payrollPostingReport,
      {
        profileId: this.contactQuery.getActive().clientId,
        date,
      },
    );
    return this.serviceFactory.query(CommonConstants.getPath.account.serviceKey, basePath);
  }

  public getBenefitPlanFundingMethods(paymentSourceId: string): Observable<Model.FundingDataType[]> {
      return this.paymentSourceAccountService.getPaymentSourceAccount(paymentSourceId)
      .pipe(
        switchMap((paymentSourceAccount) => this.bankAccountService.getBankAccount(paymentSourceAccount.paymentAccountId)),
        map((bankAccount) => [Clone.deep(bankAccount)] as Model.FundingDataType[]),
      );
  }

  public updateEmployeeTerminationDate(employmentInfo: Model.IndividualData, benefitPlans: Model.EmployeeBenefitAccount[], isTerminated: boolean = false): Observable<void[]> {
    const serviceArray: Array<Observable<void>> = [];
    const terminateBasePath: string = stringFormat(
      CommonConstants.getPath.profile.employmentInfoUpdate,
      {
        profileId: employmentInfo.parentId,
        commandName: `${employmentInfo.currentState}To${employmentInfo.currentState
          }`,
        employmentHistoryId: employmentInfo.id,
      },
    );
    employmentInfo = this.serviceFactory.createCommand(
      employmentInfo,
      `${employmentInfo.currentState}To${employmentInfo.currentState}`,
    );

    if (benefitPlans && benefitPlans.length) {
      benefitPlans.forEach((benefitAccountData) => {
        serviceArray.push(this.updateBenefitAccountEligibilityDate(benefitAccountData));
      });
      return combineLatest(serviceArray)
        .pipe(
          switchMap((benefitResponse) => {
            if (!isTerminated) {
              return this.serviceFactory.queryPut<void[]>(
                CommonConstants.getPath.profile.configurationKey,
                terminateBasePath,
                employmentInfo,
              );
            } else {
              return of([]);
            }
          }),
        );

    } else if (!isTerminated) {
      return this.serviceFactory.queryPut(
        CommonConstants.getPath.profile.configurationKey,
        terminateBasePath,
        employmentInfo,
      );
    }
  }

  public updateBenefitAccountEligibilityDate(benefitAccountData: Model.EmployeeBenefitAccount): Observable<void> {
    if (benefitAccountData.minDate) {
      delete benefitAccountData.minDate;
    }
    if (benefitAccountData.payrollLastDate) {
      delete benefitAccountData.payrollLastDate;
    }
    const basePath: string = stringFormat(
      CommonConstants.getPath.account.benefitAccountsUpdate,
      {
        profileId: benefitAccountData.parentId,
        command: `${benefitAccountData.currentState}To${benefitAccountData.currentState}`,
        benefitAccountId: benefitAccountData.id,
      },
    );
    benefitAccountData = this.serviceFactory.createCommand(
      benefitAccountData,
      `${benefitAccountData.currentState}To${benefitAccountData.currentState}`,
    );

    return this.serviceFactory.queryPut(
      CommonConstants.getPath.account.serviceKey,
      basePath,
      benefitAccountData,
    );
  }

  public savePlanPostingData(requestData: Model.PostPlanData, planIds: string[], benefitPlansRequestPayload: Model.SearchCriteria, benefitPlanServicePath: string, userSelectedDate: string): Observable<Model.ManualPostingData> {
    const constant: Model.AccountPath = CommonConstants.getPath.account;
    const planData: Model.Command = this.serviceFactory.createCommand(requestData, requestData.currentState);
    const basePath: string = stringFormat(constant.manualPosting, {
      profileId: requestData.parentId,
      commandName: requestData.currentState,
    });
    const params = { headers: { 'x-uba-route': 'queue' }};
    return this.serviceFactory.queryPut(constant.serviceKey, basePath, planData, params)
      .pipe(
        switchMap((postResponse) => {
          return this.getManualPostingData(CommonConstants.getPath.configuration.serviceKey, benefitPlanServicePath, benefitPlansRequestPayload, userSelectedDate);
        }),
      );
  }

  public getBenefitPlanDetails(searchBenefitPlanCriteria: Model.SearchCriteria): Observable<{benefitPlans: BenefitPlan[], calendarDatesToDisplay: string[]}> {
    return this.getBenefitPlanData(searchBenefitPlanCriteria)
      .pipe(
        switchMap((benefitPlans) => {

          if (benefitPlans.length === 0) {
            return of (benefitPlans);
          }

          const postings: Array<Observable<BenefitPlanFundingSourceAndSchedule[]>> = [];

          const bpIds = benefitPlans.map((bp) => bp.id);

          // NOT SURE WHY WE ARE CHUNKING CALLS TO BPFSS SEARCH AND NOT THE ENTRY SEARCH LATER
          while (bpIds.length > 0) {
            const bpIdChunk = bpIds.splice(0, CommonConstants.uuidJoinLimit);
            postings.push(this.searchBPFSSByPlanIds(bpIdChunk));
          }

          return combineLatest(postings)
            .pipe(
              map((combineResult) => {
                  const planIdsWithoutParticipentDirect = new Set(combineResult.flat()
                    .filter((funding) => funding.fundingSource !== 'ParticipantDirect')
                    .map((funding) => funding.parentId));

                  return benefitPlans.filter((plan) => planIdsWithoutParticipentDirect.has(plan.id));
              }),
            );
        }),
        switchMap((benefitPlans) => {
          if (benefitPlans.length === 0) {
            return of({ benefitPlans, calendarDatesToDisplay: [] });
          }

          const basePath: string = stringFormat(CommonConstants.getPath.account.entryPostingDates, { profileId: this.contactQuery.getActive().clientId });
          return this.serviceFactory.query<string[]>(CommonConstants.getPath.account.serviceKey, basePath)
            .pipe(
              map((postingDates) => ({ benefitPlans, calendarDatesToDisplay: postingDates.sort() })),
            );
        }),
      );
  }

  public searchBPFSSByPlanIds(benefitPlanIds: string[], searchParams?: Model.SearchParams): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    const clientId = this.contactQuery.getActive().clientId;

    return this.serviceFactory.search<BenefitPlanFundingSourceAndSchedule[]>(
      CommonConstants.getPath.configuration.serviceKey,
      CommonConstants.getPath.configuration.fundingSourceAndSchedule,
      [
        {
          key: 'parentId',
          value: benefitPlanIds.join('|'),
          matchType: MatchType.IN,
          chainType: ChainType.AND,
        },
        {
          key: 'parentType',
          value: 'BENEFIT_PLAN',
          matchType: MatchType.EXACT,
          chainType: ChainType.AND,
        },
      ],
      {
        profileId: clientId,
        benefitPlanId: '*',
      },
      searchParams,
    ).pipe(map(
      (res) => {
        return res.data;
      },
    ));
  }

  public getPlansWithDates(): Observable<{ benefitPlans: BenefitPlan[]; calendarDatesToDisplay: string[]; }> {
    const bpSearchCriteria = [
      {
        key: 'parentId',
        value: this.contactQuery.getActive().clientId,
        chainType: ChainType.AND,
        matchType: MatchType.EXACT,
      },
      {
        key: 'parentType',
        value: ParentType.CLIENT,
        chainType: ChainType.AND,
        matchType: MatchType.EXACT,
      },
      {
        key: 'currentState',
        value: [
          BenefitPlanState.Active,
          BenefitPlanState.Draft,
          BenefitPlanState.FinalizingAccounts,
          BenefitPlanState.FinalizingPlan,
          BenefitPlanState.FinalizationPreCheck,
          BenefitPlanState.Finalized,
          BenefitPlanState.FinalizingError,
          BenefitPlanState.GracePeriod,
          BenefitPlanState.Initiated,
          BenefitPlanState.PropagatingDateEdits,
          BenefitPlanState.RolloverProcessing,
          BenefitPlanState.RolloverProcessingPreCheck,
          BenefitPlanState.RunOut,
          BenefitPlanState.Reconciliation,
          BenefitPlanState.Setup,
          BenefitPlanState.RolloverComplete,
          BenefitPlanState.CardsOrdered,
          BenefitPlanState.Removed,
          BenefitPlanState.Start,
        ].join('|'),
        chainType: ChainType.AND,
        matchType: MatchType.IN,
      },
    ];

    return this.getBenefitPlanData(bpSearchCriteria)
    .pipe(
      switchMap((benefitPlans) => {
        if (benefitPlans.length === 0) {
          return of({ benefitPlans, calendarDatesToDisplay: [] });
        }

        const basePath: string = stringFormat(CommonConstants.getPath.account.entryPostingDates, { profileId: this.contactQuery.getActive().clientId });

        return this.serviceFactory.query<string[]>(CommonConstants.getPath.account.serviceKey, basePath)
        .pipe(
          map((postingDates) => ({ benefitPlans, calendarDatesToDisplay: postingDates.sort() })),
        );
      }),
    );
  }

  public getVerifyPostingData(
    params: Pagination & { scheduledDate?: string, individualId?: string },
    groupingType: VerifyPostingGroupingType,
  ): Observable<{ tableData: VerifyPostingItemViewModel[]; totalCount: number; scheduleMap: Map<string, BenefitPlanFundingSourceAndSchedule[]>; }> {
    const clientId = this.contactQuery.getActive().clientId;

    const searchCriteria: SearchCriteria = [
      {
        key: 'clientId',
        value: clientId,
        chainType: ChainType.AND,
        matchType: MatchType.EXACT,
      },
      {
        key: 'entryState',
        value: [EntryState.Settled, EntryState.Entered, EntryState.Published, EntryState.PendingPublished, EntryState.Scheduled].join('|'),
        chainType: ChainType.AND,
        matchType: MatchType.IN,
      },
      ...(!params.scheduledDate ? [] : [{
        key: 'scheduledDate',
        value: params.scheduledDate,
        chainType: ChainType.AND,
        matchType: MatchType.EXACT,
      }]),
      ...(!params.individualId ? [] : [{
        key: 'individualId',
        value: params.individualId,
        chainType: ChainType.AND,
        matchType: MatchType.EXACT,
      }]),
    ];

    const queryStringParams: SearchQuery = {
      orderBy: 'scheduledDate',
      orderDirection: OrderDirection.DESC,
      take: params.take,
      skip: params.skip,
    };

    return this.getVerifyPostingParticipants(searchCriteria, clientId, queryStringParams)
    .pipe(
      switchMap((verifyPostingData) => {
        const planIds = Array.from(new Set(verifyPostingData.map(({ planId }) => planId)));

        return (planIds.length === 0
          ? of([] as BenefitPlanFundingSourceAndSchedule[])
          : this.searchBPFSSByPlanIds(planIds, { effectiveDate: 'ALL' })
        )
        .pipe(map((schedules) => {
          const scheduleMap = schedules.reduce((sMap, currentSchedule) =>
            sMap.set(currentSchedule.parentId, [...(sMap.get(currentSchedule.parentId) || []), currentSchedule]),
            new Map<string, BenefitPlanFundingSourceAndSchedule[]>(),
          );
          return { scheduleMap, verifyPostingData };
        }));

      }),
      map(({ scheduleMap, verifyPostingData }) => {
        return {
          tableData: VerifyPostingGridViewModelMapper.mapDBModelToUIModel(
            verifyPostingData.filter((e) => this.includeVerifyPostingViewModel(scheduleMap.get(e.planId))),
            scheduleMap,
            groupingType,
          ),
          totalCount: (groupingType === VerifyPostingGroupingType.Individual
            ? verifyPostingData[0]?.totalDatesCount
            : verifyPostingData[0]?.totalIndividualsCount) || 0,
          scheduleMap,
        };
      }),
    );
  }

  public getBenefitPlanData(requestPayload: Model.SearchCriteria): Observable<BenefitPlan[]> {
    const basePath: string = stringFormat(CommonConstants.getPath.configuration.benefitPlans, { profileId: this.contactQuery.getActiveId() || '*' });

    return this.serviceFactory.search<BenefitPlan[]>(CommonConstants.getPath.configuration.serviceKey, basePath, requestPayload).pipe(map(
      (res) => {
        return res.data;
      },
    ));
  }

  public getManualPostingData(serviceKey: string, basePath: string, planRequestPayload: Model.SearchCriteria, userSelectedDate?: string): Observable<Model.ManualPostingData> {
    const summaryRequestPayload: Model.SearchCriteria = [
      {
        key: 'entryType',
        value: [
          EntryType.ParticipantContribution,
          EntryType.ClientContribution,
          EntryType.ParticipantToClientFunding,
          EntryType.ClientFunding,
          EntryType.ClientPodFunding,
          EntryType.PayrollPosting,
        ].join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'planId',
        value: '',
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: 'Scheduled',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const calendarDatesToDisplay: Set<string> = new Set();

    return this.serviceFactory.search<BenefitPlan[]>(serviceKey, basePath, planRequestPayload)
      .pipe(
        map((res) => {
          return res.data;
        }),
        map((benefitResponse) => {
          const benefits: BenefitPlan[] = benefitResponse;
          const benefitIds: string[] = [];
          benefits.map((benefit) => {
            benefitIds.push(benefit.id);
          });
          return { benefits, benefitIds };
        }),
        switchMap((result) => {
          summaryRequestPayload[1].value = result.benefitIds.join('|');
          return this.serviceFactory.search<Model.SummaryData[]>(
            CommonConstants.getPath.account.serviceKey,
            CommonConstants.getPath.account.entrySummary,
            summaryRequestPayload,
            null,
            {
              skip: 0,
              take: Model.MaxIntegerValue.MAX_SAFE_INTEGER,
            },
          )
            .pipe(
              map((res) => {
                return res.data;
              }),
              map((summaryResponse) => {
                let filterDate: string;
                let selectedDate: string;
                summaryResponse.forEach((item: Model.SummaryData) => {
                  calendarDatesToDisplay.add(item.scheduledDate);
                });
                const dates: string[] = Array.from(calendarDatesToDisplay).sort();
                if (userSelectedDate) {
                  filterDate = dayjs(userSelectedDate, 'MM/DD/YYYY').format(
                    'YYYY-MM-DD',
                  );
                  selectedDate = userSelectedDate;
                } else {
                  let startdate: dayjs.Dayjs = dayjs();
                  startdate = startdate.subtract(1, 'day');
                  const startdateString: string = startdate.format('YYYY-MM-DD');
                  filterDate = dates.find(
                    (ele) => Date.parse(ele) > Date.parse(startdateString),
                  );
                  selectedDate = filterDate && dayjs(filterDate, 'YYYY-MM-DD').format(
                    'MM/DD/YYYY',
                  );
                }
                const filterDataByDate: Model.SummaryData[] = filterDate && summaryResponse && summaryResponse.filter((item: Model.SummaryData) => item.scheduledDate === filterDate);

                const benefitPlanIds: Set<string> = new Set();
                if (filterDataByDate) {
                  filterDataByDate.forEach((summary) => {
                    benefitPlanIds.add(summary.planId);
                  });
                }
                return {
                  benefitPlans: result.benefits,
                  postingSummary: summaryResponse,
                  selectedDate,
                  selectedDatePlans: Array.from(benefitPlanIds),
                  selectedDateSummary: filterDataByDate,
                  calenderDatesToDisplay: dates,
                };
              }),
            );
        }),
      );
  }

  public getElectionsBatch(benefitAccountIds: string[]): Observable<Record<string, Election[]>> {
    const contact = this.contactQuery.getActive();
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: benefitAccountIds.join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
      {
        key: 'currentState',
        value: [ElectionState.Active, ElectionState.Pending].join('|'),
        matchType: MatchType.IN,
        chainType: ChainType.AND,
      },
    ];
    const url = new Uri(`/profile/${contact.id}/election/search`, CoreService.Account, { orderBy: 'effectiveDate', orderDirection: 'desc' }).toString();

    return (benefitAccountIds.length === 0 ? of([] as Election[]) : this.http.post<Election[]>(url, searchCriteria))
      .pipe(
        map((elections) => elections.reduce((agg, election) => {
          if (!agg[election.parentId]) {
            agg[election.parentId] = [];
          }
          agg[election.parentId].push(election);
          return agg;
        }, {} as Record<string, Election[]>)),
      );
  }

  public getVerifyPostingParticipants(requestPayload: Criteria[], clientId: string, searchParams: SearchQuery): Observable<ParticipantEntry[]> {
    const url = new Uri(`/profile/${clientId}/participantEntry/search`, CoreService.Dashboard, searchParams).toString();

    return this.http.post<ParticipantEntry[]>(url, requestPayload);
  }

  public getBenefitEmployeesList(requestPayload: Model.SearchCriteria, profileId: string, page: number, offset: number): Observable<Model.SearchResults<Model.EmployeesList[]>> {
    return this.serviceFactory.search<Model.EmployeesList[]>(
      CommonConstants.getPath.dashboard.serviceKey,
      CommonConstants.getPath.dashboard.employeesByBenefitPlan,
      requestPayload,
      { profileId },
      { take: page, skip: offset, orderBy: 'employeeName', orderDirection: 'asc' },
      true,
    );
  }

  public getPaymentTier(benefitPlanId: string): Observable<BenefitPlanPaymentTier[]> {
    const contact = this.contactQuery.getActive();
    const requestPayload: Criteria[] = [{
      key: 'parentId',
      value: benefitPlanId,
      matchType: MatchType.IN,
      chainType: ChainType.AND,
    }, {
      key: 'parentType',
      value: ParentType.BENEFIT_PLAN,
      matchType: MatchType.EXACT,
      chainType: ChainType.AND,
    },
    ];
    return this.serviceFactory.search<BenefitPlanPaymentTier[]>(
      CommonConstants.getPath.configuration.serviceKey,
      CommonConstants.getPath.configuration.paymentTier,
      requestPayload,
      { profileId: contact.id, benefitPlanId },
    ).pipe(map((res) => {
      return res.data;
    }));
  }

  public getPVREntries(benefitAccountId: string): Observable<Entry[]> {
    const requestPayload: Criteria[] = [
      {
        key: 'parentId',
        value: benefitAccountId,
        matchType: MatchType.EXACT,
      },
      {
        key: 'entryType',
        value: [
          EntryType.PayrollPosting,
          EntryType.ClientContribution,
          EntryType.ClientBureauContribution,
          EntryType.ClientFunding,
          EntryType.ClientNegativeFunding,
        ].join('|'),
        matchType: MatchType.IN,
      },
      {
        key: 'currentState',
        value: [EntryState.Reversed, EntryState.ReversalPublished, EntryState.Rejected].join('|'),
        matchType: MatchType.NOT_IN,
      },
    ];
    const url = new Uri(`/profile/*/benefitAccount/${benefitAccountId}/entry/search`, CoreService.Account).toString();

    return this.http.post<Entry[]>(url, requestPayload);
  }

  public getPVREntriesBatch(benefitAccountIds: string[]): Observable<Record<string, Entry[]>> {
    const requestPayload: Criteria[] = [
      {
        key: 'parentId',
        value: benefitAccountIds.join('|'),
        matchType: MatchType.IN,
      },
      {
        key: 'entryType',
        value: [
          EntryType.PayrollPosting,
          EntryType.ClientContribution,
          EntryType.ClientBureauContribution,
          EntryType.ClientFunding,
          EntryType.ClientNegativeFunding,
        ].join('|'),
        matchType: MatchType.IN,
      },
      {
        key: 'currentState',
        value: [EntryState.Reversed, EntryState.ReversalPublished, EntryState.Rejected].join('|'),
        matchType: MatchType.NOT_IN,
      },
    ];
    const url = new Uri(`/profile/*/entry/search`, CoreService.Account).toString();

    return (benefitAccountIds.length === 0 ? of([] as Entry[]) : this.http.post<Entry[]>(url, requestPayload))
      .pipe(
        map((entries) => entries.reduce((agg, entry) => {
          if (!agg[entry.parentId]) {
            agg[entry.parentId] = [];
          }
          agg[entry.parentId].push(entry);

          return agg;
        }, {} as Record<string, Entry[]>)),
      );
  }

  public isEntryEditable(individualId: string, entryId: string): Observable<boolean> {
    const url = new Uri(`/profile/${individualId}/entry/${entryId}/isEditable`, CoreService.Account).toString();
    return this.http.get<{ entryId: string, isEditable: boolean }>(url).pipe(
      map(({ isEditable }) => isEditable),
    );
  }

  public batchUpdateEntries(entries: Entry[], fundingSourceAndScheduleId: string): Observable<object> {
    if (!entries || entries.length === 0) {
      return of(null);
    }

    const url = new Uri(`/profile/*/benefitAccount/${entries[0].parentId}/adjustSchedule/command/StartToProcessing`, CoreService.Account);

    const adjustments: ScheduleAdjustment[] = entries.map((entry) => ({
      scheduledDate: entry.scheduledDate,
      amount: entry.amount,
      scheduleType: this.entryTypeToScheduleType[entry.entryType],
    }));
    const adjustmentsCommandData: ScheduleAdjustments = {
      id: uuid(),
      parentId: uuid(),
      parentType: ParentType.INSTANCE,
      fundingSourceAndScheduleId,
      adjustments,
      electionChange: false, // this will trigger recalc after processing adjustments
    };
    const body = this.commandFactory.createCommand(adjustmentsCommandData, ScheduleAdjustmentsCommandType.StartToProcessing);

    return this.http.put(url.toString(), body, { headers: { 'x-uba-route': 'queue' } });
  }

  private includeVerifyPostingViewModel(schedules: BenefitPlanFundingSourceAndSchedule[] = []): boolean {
    if (schedules.length === 0) {
      return false;
    }

    const clientSchedule = this.getClientSchedule(schedules);

    if (!clientSchedule && this.getParticipantSchedule(schedules)) {
      return true;
    }

    if (
      clientSchedule.contributionPosting === ContributionPostingType.AnnualSchedule &&
      [FundingPostingType.ContributionSchedule, FundingPostingType.CustomSchedule].includes(clientSchedule.fundingPosting) &&
      !this.getParticipantToClientSchedule(schedules)
    ) {
      return false;
    }

    if (clientSchedule.contributionPosting === ContributionPostingType.UserInitiated && !this.getParticipantToClientSchedule(schedules)) {
      return false;
    }

    return true;
  }

  private getClientSchedule(schedules: BenefitPlanFundingSourceAndSchedule[] = []): BenefitPlanFundingSourceAndSchedule | null {
    return schedules.find(({ fundingSource }) => [FundingSourceType.ClientDirect, FundingSourceType.ClientToPlan].includes(fundingSource));
  }

  private getParticipantSchedule(schedules: BenefitPlanFundingSourceAndSchedule[] = []): BenefitPlanFundingSourceAndSchedule | null {
    return schedules.find(({ fundingSource }) => [FundingSourceType.ParticipantDirect, FundingSourceType.ParticipantToClient].includes(fundingSource));
  }

  private getParticipantToClientSchedule(schedules: BenefitPlanFundingSourceAndSchedule[] = []): BenefitPlanFundingSourceAndSchedule | null {
    return schedules.find(({ fundingSource }) => FundingSourceType.ParticipantToClient === fundingSource);
  }
}
