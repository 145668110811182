import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RaygunService } from './raygun/raygun.service';
import { CommonConstants } from './shared/constants/common.constant';
import { BrandId } from './shared/models/clux/enum';
import { BrandService } from './shared/services/brand.service';
import { SharedService } from './shared/services/shared.service';
import { PrefetchService } from './state/prefetch.service';
import { TimeoutService } from './timeout.service';

const anonymousPages: string[] = [
  CommonConstants.appRoutes.login,
  CommonConstants.appRoutes.logout,
  CommonConstants.appRoutes.signupBase,
  CommonConstants.appRoutes.twofaBase,
  CommonConstants.appRoutes.forgotpassword,
  CommonConstants.appRoutes.distributor,
  CommonConstants.appRoutes.twofactorAuthentication,
  CommonConstants.appRoutes.resetpassword,
  CommonConstants.appRoutes.nobrand,
  CommonConstants.appRoutes.netSuiteSSORedirect,
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly brand = this.brandService.getBrandResources();
  private readonly brandId = this.brandService.getBrand();
  private subscriptions = new Subscription();

  private timeoutSubscription = new Subscription();
  private idleSubscription = new Subscription();

  public constructor(
    private brandService: BrandService,
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private prefetchService: PrefetchService,
    private raygunService: RaygunService,
    private renderer: Renderer2,
    private router: Router,
    private sharedService: SharedService,
    private title: Title,
    private timeoutService: TimeoutService,
  ) {}

  public ngOnInit(): void {
    const prefetchSubscription = this.prefetchService.prefetchDataOnAuthentication().subscribe();
    this.subscriptions.add(prefetchSubscription);

    this.timeoutService.setMaxIdleTime(location.hostname === 'localhost' ? 120 : 15);
    this.timeoutService.setMaxTimeoutTime(60 * 12);

    this.title.setTitle(this.brandId !== BrandId.Unknown ? `Welcome to ${this.brand.companyName}` : '');

    this.router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationError) {
            this.raygunService.logError('Navigation error', { event });
          }
          if (event instanceof ActivationEnd && event.snapshot.data.title) {
            this.title.setTitle(event.snapshot.data.title);
          }
          return event;
        }),
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          this.renderer.setAttribute(this.document.getElementById('favicon'), 'href', `assets/img/${this.brand.faviconFileName}`);
          const currentRoute: string = event.url.split('/')[1].replace(/\?.+/, '');
          this.setLastRoute(event.url);

          this.stopIdleTimer();
          if (this.isAnonymousPage(currentRoute)) {
            // not to show multiple growel for non signIn component ;
            this.sharedService.growlProperties = [];
            this.stopTimeoutTimer();
            this.renderer.addClass(document.body, 'login');
          } else {
            this.startIdleTimer();
            this.startTimeoutTimer();
            this.renderer.removeClass(document.body, 'login');
          }
        }),
      ).subscribe();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private stopIdleTimer(): void {
    this.idleSubscription.unsubscribe();
    this.timeoutService.stopIdleTimer();
  }

  private stopTimeoutTimer(): void {
    this.timeoutSubscription.unsubscribe();
    this.timeoutService.stopTimeoutTimer();
  }

  private startIdleTimer(): void {
    this.timeoutService.startIdleTimer();
    this.idleSubscription = this.timeoutService.watchIdle().subscribe(async () => {
      await this.logout();
    });
    this.subscriptions.add(this.idleSubscription);
  }

  private startTimeoutTimer(): void {
    if (!this.timeoutService.hasSetTimeoutTimer) {
      this.timeoutService.startTimeoutTimer();
      this.timeoutSubscription = this.timeoutService.watchTimeout().subscribe(async () => {
        await this.logout();
      });
      this.subscriptions.add(this.timeoutSubscription);
    }
  }

  /**
   * Returns true when the current page is one that does not require authentication (e.g. login/sign-up)
   * @param currentRoute The name of the current page
   */
  private isAnonymousPage(currentRoute: string): boolean {
    let returnBox: boolean;
    anonymousPages.forEach((element) => {
      if (element === currentRoute || currentRoute === '') {
        returnBox = true;
      } else {
        return false;
      }
    });
    return returnBox;
  }

  private setLastRoute(route: string): void {
    sessionStorage.setItem(CommonConstants.localKeys.userLastRoute, route);
  }

  private async logout(): Promise<void> {
    this.matDialog.closeAll();
    await this.router.navigate(['/logout']);
  }
}
