import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PaymentAccountGroup } from '@models/paymentAccount/model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ActivePaymentAccountGroup' })
export class ActivePaymentAccountGroupStore extends Store<PaymentAccountGroup> {
  public constructor() {
    super({});
  }
}
