import { Component, OnInit } from '@angular/core';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { CommonConstants } from '../constants/common.constant';
import { EmitterService } from '../services/emitter.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public isAlertOpen: boolean;
  public tascGrowlObj: Model.growlProps;

  public constructor(
    private _emitter: EmitterService,
  ) { }

  public ngOnInit(): void {
    this.isAlertOpen = false;
    this._emitter
    .on(CommonConstants.emmiterKeys.loginErrors)
    .subscribe((msgObj: Model.growlProps) => {
      if (msgObj) {
        this.tascGrowlObj = msgObj;
      }
    });
  }

  public onToggleAlert(isAlertOpen: boolean): void {
    this.isAlertOpen = isAlertOpen;
  }
}
