<div data-cy="tasc-growler" *ngFor="let prop of growlProp; let i=index;">
        <div class="tasc-growl" id="tasc-growl-id"
                [ngClass]="{'tasc-warning': prop.type === 'warning','tasc-error': prop.type === false, 'tasc-success': (prop.type === true || prop.type === 'covid-link')}"
                *ngIf="prop.growelVisibility">
                <span [innerHTML]="prop.message" appRouteTransform></span>
                <span *ngIf="prop.link" class="undo-link"><a [routerLink]="[prop.link]">Posting Summary</a></span>
                <span *ngIf="prop.undo" class="undo-link"><a (click)="undoChanges()" tabindex="0" role="button"
                                (keyup.enter)="$event.target.click()">Undo</a></span>
                <span *ngIf="prop.postManuallyLink" class="post-manually-link"><a (click)="postNow()" tabindex="0"
                                role="button" (keyup.enter)="$event.target.click()">POST
                                NOW</a></span>
                <span *ngIf="!prop.type || prop.type === 'warning'  || prop.type === 'covid-link'" class="close-growl"
                        id="close-growl-id" title="close" (click)="closeGrowel(i)" tabindex="0" role="button" data-cy="close-growler"
                        (keyup.enter)="$event.target.click()"></span>
        </div>
</div>
