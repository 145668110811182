import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { ClientInactivationEvent, MatchType, SearchCriteria } from '@models/configuration/model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TopicSubTopicInformation } from 'src/app/shared/models/clux/model';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { ServiceFactory } from 'src/app/shared/services/service.factory';

import { RouteContextProviderService } from '../../../app/shared/utils/routecontextprovider.service';
import { CommonConstants } from '../constants/common.constant';
import { CoreService } from '../models/clux/enum';
import { Attachment } from '../models/uba/file/model';
import { Customization } from '../models/uba/profileConfiguration/model';
import { Uri } from '../utils/uri';
import { BrandService } from './brand.service';

 export interface InternalStateType {
  /* tslint:disable-next-line */
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public showPastSchedule: boolean = false;
  public showPostManuallyGrowl: boolean = true;
  public growlProperties: Model.growlProps[] = [];
  public _state: InternalStateType = {};
  // tslint:disable-next-line:no-any
  public checkboxOption: any[] = [];
  public employmentInformation: Model.EmploymentInformation;
  public supportInformation: TopicSubTopicInformation;
  public resetEnrollPage = new Subject();
  public stateNotification = new BehaviorSubject<boolean>(true);
  private readonly brand = this.brandService.getBrandResources();
  private messageSource: Subject<string | boolean | Model.EmitSelected> = new Subject<Model.EmitSelected | boolean | string>();

  public constructor(
    public routecontextprovider: RouteContextProviderService,
    private serviceFactory: ServiceFactory,
    private brandService: BrandService,
    private http: HttpClient,
  ) { }

  public resetPage(): void {
    this.resetEnrollPage.next();
  }

  public get(prop?: string): InternalStateType {
    /**
     * Use our state getter for the clone.
     */
    const state: InternalStateType = this._state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  public set(prop: string, value: string): string {
    /**
     * Internally mutate our state.
     */
    return this._state[prop] = value;
  }

  public sendMessage(message: string): void {
    this.messageSource.next(message);
  }

  public getMessage(): Observable<Model.EmitSelected | boolean | string> {
    return this.messageSource.asObservable();
  }

  public setClearFilter(state: Model.EmitSelected | boolean): void {
    this.messageSource.next(state);
  }

  public getClearFilter(): Observable<string | boolean | Model.EmitSelected> {
    return this.messageSource.asObservable();
  }

  public getCustomizationByClientId(clientId: string): Observable<Model.SearchResults<Customization[]>> {
    const searchCriteria = [{
      key: 'parentId',
      value: clientId,
    }];
    return this.serviceFactory.search<Customization[]>(
      CommonConstants.getPath.profile.configurationKey,
      CommonConstants.getPath.profile.getCustomization,
      searchCriteria,
      { profileId: clientId },
      {
        skip: 0,
        take: 1,
        orderBy: 'created',
        orderDirection: 'desc',
      },
    );
  }
  public getLogo(profileId: string, attachmentId: string): Observable<string> {
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.file.downloadFile,
      {
        profileId,
        attachmentId,
      },
    );
    return this.serviceFactory.query<Attachment>(
      CommonConstants.getPath.file.serviceKey,
      baseUrl,
    ).pipe(switchMap((attachment: Attachment) => {
      return this.getSignedURL(profileId, attachment).pipe(map((url: string) => {
        return url;
      }));
    }));
  }

  public notifyState(currentStateStatus: boolean): void {
    this.stateNotification.next(currentStateStatus);
  }

  public getMessageByBrand(msg: string): string {
    return stringFormat(msg, {companyName: this.brand.companyName});
  }

  /**
   * Gets inactivation details that include scheduled inactivation date
   * which does not exist on the Client entity.
   */
  public getClientInactivationEvent(clientId: string): Observable<ClientInactivationEvent> {
    const criteria: SearchCriteria = [
        { key: 'clientId', value: clientId, matchType: MatchType.EXACT },
    ];

    const url = new Uri(`/profile/${clientId}/configuration/clientInactivationEvent/search`, CoreService.Configuration);
    return this.http.post<ClientInactivationEvent[]>(url.toString(), criteria).pipe(
        map((results) =>  results[0]),
    );
  }

  private getSignedURL(profileId: string, attachment: Attachment): Observable<string> {
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.file.getSignedUrl,
      {
        profileId,
        attachmentId: attachment.id,
        contentType: attachment.attachmentType,
        fileName: attachment.friendlyFileName,
      },
    );
    return this.serviceFactory.query<string>(
      CommonConstants.getPath.file.serviceKey,
      baseUrl,
    );
  }
}
