import { Injectable } from '@angular/core';
import { BenefitPlanSummaryData, PaymentPlanSummaryData } from '@clux-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasePlanType } from 'src/app/shared/models/clux/enum';
import { PlanData } from '../model/plan-data-model';

@Injectable({
  providedIn: 'root',
})
export class PlanService {

  public readonly selectedPlanUpdateNotificationSubject = new BehaviorSubject<PlanData>({} as PlanData);
  public readonly selectedPlanUpdateNotification$: Observable<PlanData> = this.selectedPlanUpdateNotificationSubject.asObservable();

  public updateSelectedPlanNotification(selectedPlan: BenefitPlanSummaryData | PaymentPlanSummaryData): void {
    let planData: PlanData;
    if (this.isBenefitPlan(selectedPlan)) {
      planData = {
        id: selectedPlan.id,
        name: selectedPlan.name,
        planStartDate: selectedPlan.planStartDate,
        planEndDate: selectedPlan.planEndDate,
        externalId: selectedPlan.externalId,
        updatedAt: selectedPlan.updated,
        basePlanType: BasePlanType.Benefit,
        requireEnrollmentApproval: selectedPlan.requireEnrollmentApproval,
        allowClientApproval: selectedPlan.allowClientApproval,
      };
    } else if (this.isPaymentPlan(selectedPlan)) {
      planData = {
        id: selectedPlan.id,
        name: selectedPlan.name,
        planStartDate: selectedPlan.planStartDate,
        planEndDate: selectedPlan.planEndDate,
        externalId: selectedPlan.externalId,
        updatedAt: selectedPlan.updated,
        basePlanType: BasePlanType.Payment,
      };
    }
    if (planData) {
      this.selectedPlanUpdateNotificationSubject.next(planData);
    }
  }

  public isBenefitPlan(plan: BenefitPlanSummaryData | PaymentPlanSummaryData): plan is BenefitPlanSummaryData {
    return  plan.basePlanType === BasePlanType.Benefit;
  }

  public isPaymentPlan(plan: BenefitPlanSummaryData | PaymentPlanSummaryData): plan is PaymentPlanSummaryData {
    return plan.basePlanType === BasePlanType.Payment;
  }

}
