import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { maxBy } from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/clux/enum';
import {
  ChainType,
  Document,
  DocumentAction,
  DocumentActionCommandType,
  DocumentActionState,
  DocumentActionType,
  DocumentActionUserType,
  DocumentCategoryType,
  DocumentState,
  MatchType,
  ParentType,
  SearchCriteria,
} from 'src/app/shared/models/uba/profileConfiguration/model';
import { BrandService } from 'src/app/shared/services/brand.service';
import { CommandFactory } from 'src/app/shared/utils/command-factory';
import { Dates } from 'src/app/shared/utils/dates';
import { Uri } from 'src/app/shared/utils/uri';
import {
  ContactQuery,
  CurrentUserQuery,
  DocumentActionQuery,
  DocumentActionStore,
  TermsOfUseQuery,
  TermsOfUseStore,
} from 'src/app/state';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class TermsOfUseService {
  private readonly brand = this.brandService.getBrandResources();

  public constructor(
    private brandService: BrandService,
    private commandFactory: CommandFactory,
    private contactQuery: ContactQuery,
    private currentUserQuery: CurrentUserQuery,
    private documentActionQuery: DocumentActionQuery,
    private documentActionStore: DocumentActionStore,
    private http: HttpClient,
    private termsOfUseQuery: TermsOfUseQuery,
    private termsOfUseStore: TermsOfUseStore,
  ) { }

  public hasSignedTermsOfUse(): Observable<boolean> {
    return this.getTermsOfUse()
      .pipe(
        switchMap((termsOfUse) => this.documentActionQuery.hasSignedDocument(termsOfUse.id)),
      );
  }

  public getTermsOfUse(): Observable<Document> {
    return this.termsOfUseQuery.selectActiveWhenLoaded();
  }

  public signTermsOfUse(): Observable<void> {
    const termsOfUse = this.termsOfUseQuery.getActive();
    const emailAddress = this.currentUserQuery.getEmailAddress();
    return this.signDocument(termsOfUse.id, this.contactQuery.getActiveId(), emailAddress);
  }

  public getTermsOfUseDocument(): Observable<Document> {
    const termsOfUseSearchCriteria: SearchCriteria = [
      { key: 'parentType', value: ParentType.INSTANCE, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'currentState', value: DocumentState.Active, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'category', value: DocumentCategoryType.TASC, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'effectiveDate', value: Dates.today(), matchType: MatchType.LESS_THAN_EQUAL, chainType: ChainType.AND },
      { key: 'name', value: `CLUX TOU ${this.brand.companyName}`, matchType: MatchType.CONTAINS },
    ];
    const relativeURL = `/profile/*/configuration/document/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    return this.http.post<Document[]>(uri.toString(), termsOfUseSearchCriteria)
      .pipe(
        map((documents) => {
          const termsOfUseDocuments = documents.filter((document) => document.name && document.name.startsWith('CLUX TOU') && Dates.isSameOrBefore(document.effectiveDate, Dates.now()));
          const latestTermsOfUseDocument = maxBy(termsOfUseDocuments, (document) => document.effectiveDate + document.updated);
          return latestTermsOfUseDocument;
        }),
        withTransaction((document) => {
          if (document) {
            this.termsOfUseStore.set([document]);
            this.termsOfUseStore.setActive(document.id);
          } else {
            this.termsOfUseStore.set([]);
          }
        }),
      );
  }

  public getSignedDocumentActions(documentId: string): Observable<DocumentAction[]> {
    const contactId = this.contactQuery.getActiveId();

    const searchCriteria: SearchCriteria = [
      { key: 'parentId', value: contactId, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'parentType', value: ParentType.CLIENT_CONTACT, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'currentState', value: DocumentActionState.Active, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'type', value: DocumentActionType.Accepted, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'documentId', value: documentId, matchType: MatchType.EXACT },
    ];
    const relativeURL = `/profile/*/configuration/documentAction/search`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    return this.http.post<DocumentAction[]>(uri.toString(), searchCriteria)
      .pipe(
        withTransaction((signedDocuments) => this.documentActionStore.set(signedDocuments)),
      );
  }

  private signDocument(documentId: string, contactId: string, emailAddress: string): Observable<void> {
    const entity: DocumentAction = {
      id: uuid(),
      parentId: contactId,
      parentType: ParentType.CLIENT_CONTACT,
      type: DocumentActionType.Accepted,
      documentId,
      username: emailAddress,
      userType: DocumentActionUserType.Participant,
    };
    const commandName = DocumentActionCommandType.StartToActive;
    const relativeURL = `/profile/*/configuration/documentAction/${entity.id}/command/${commandName}`;
    const uri = new Uri(relativeURL, CoreService.ProfileConfiguration);
    const command = this.commandFactory.createCommand(entity, commandName);
    return this.http.put<void>(uri.toString(), command)
      .pipe(
        withTransaction(() => this.documentActionStore.add(entity)),
      );
  }
}
