import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import dayjs from 'dayjs';
import { Model } from '../models/clux/model-legacy';
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Encapsulates date creation and handling
 */
export class Dates {
    /**
     * The date format for strings
     * This value can be overriden at runtime in consuming applications
     */
    public static DATE_FORMAT: string = 'YYYY-MM-DD';

    /**
     * The default timezone for all dates
     * This value can be overriden at runtime in consuming applications
     */
    private static TIMEZONE: string = 'America/Chicago';

    /**
     * The minimum date for DOB
     * This value can be overriden at runtime in consuming applications
     */
    public static MIN_DATE: string = '1900-01-01';

    /**
     * Returns true if date is same or before other regardless of time
     * See https://momentjs.com/docs/#/manipulating/start-of/ as reference
     * @param date The first date to compare
     * @param other The second date to compare
     */
    public static isSameOrAfter(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
      return Dates.toDayJS(date).isSameOrAfter(Dates.toDayJS(other), 'day');
    }

    /**
     * Returns true if date is same or before other regardless of time
     * See https://day.js.org/docs/en/query/is-same-or-before as reference
     * @param date The first date to compare
     * @param other The second date to compare
     */
    public static isSameOrBefore(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
      return Dates.toDayJS(date).isSameOrBefore(Dates.toDayJS(other), 'day');
    }

    /**
     * Returns true if date matches other regardless of time
     * See https://day.js.org/docs/en/query/is-same as reference
     * @param date The first date to compare
     * @param other The second date to compare
     */
    public static isSame(date: dayjs.ConfigType, other: dayjs.ConfigType, unit: dayjs.OpUnitType = 'day'): boolean {
      return Dates.toDayJS(date).isSame(Dates.toDayJS(other), unit);
    }

    /**
     * Returns the UTC date and time string representation of right now
     */
    public static now(): string {
       return this.todaysDate().toISOString();
    }

    /**
     * Gets the year of the given date.
     * @param date The date to get the year for.
     * @returns The year
     */
    public static getYear(date: dayjs.ConfigType): string {
      return Dates.toDayJS(date).tz(Dates.TIMEZONE).format('YYYY');
    }

    /**
     * Gets the month difference between two dates.
     * @param startDate start date.
     * @param endDate end date.
     * @returns month difference
     */
     public static getMonthDifference(startDate: dayjs.ConfigType, endDate: dayjs.ConfigType): number {
      return Dates.toDayJS(endDate).tz(Dates.TIMEZONE).diff(startDate, 'month');
    }

    /**
     * Returns true if date is before other regardless of time
     * See https://day.js.org/docs/en/query/is-before as reference
     * @param date The first date to compare
     * @param other The second date to compare
     */
    public static isBefore(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
      return Dates.toDayJS(date).isBefore(Dates.toDayJS(other), 'day');
    }

    /**
     * Returns true if date is after other regardless of time
     * See https://day.js.org/docs/en/query/is-after as reference
     * @param date The first date to compare
     * @param other The second date to compare
     */
    public static isAfter(date: dayjs.ConfigType, other: dayjs.ConfigType): boolean {
      return Dates.toDayJS(date).isAfter(Dates.toDayJS(other), 'day');
    }

    /**
     * Returns true if date is between the given start and end dates
     * See https://day.js.org/docs/en/plugin/is-between as reference
     * @param date The date being checked
     * @param startOfRange The start of the date range to check
     * @param endOfRange The end of the date range to check
     * @param inclusivity Indicates whether to include the start and end of the date range when checking
     */
    public static isBetween(date: dayjs.ConfigType, startOfRange: dayjs.ConfigType, endOfRange: dayjs.ConfigType, inclusivity: Model.DayJsInclusivity = Model.DayJsInclusivity.InclusiveStartInclusiveEnd): boolean {
      return Dates.toDayJS(date)
        .isBetween(Dates.toDayJS(startOfRange), Dates.toDayJS(endOfRange), 'day', inclusivity);
    }

    /**
     * Given a list of dates returns the latest date or undefined if there is no valid date
     * @param {string[]} dates List of dates
     * @returns {string} The latest date
     */
     public static maxDate(dates: string[]): string {
      if (!Array.isArray(dates) || !dates.length) {
        return undefined;
      }
      return dates.reduce((date1, date2) => {
        const date1Valid = dayjs(date1).isValid();
        const date2Valid = dayjs(date2).isValid();
        if ((!date1 || !date1Valid) && (!date2 || !date2Valid)) {
          return undefined;
        } else if (!date1 || !date1Valid) {
          return date2;
        } else if (!date2 || !date2Valid) {
          return date1;
        }

        return Dates.isSameOrAfter(date1, date2) ? date1 : date2;
      });
    }

    /**
     * Given a list of dates returns the earliest date or undefined if there is no valid date
     * @param {string[]} dates List of dates
     * @returns {string} The earliest valid date
     */
    public static minDate(dates: string[]): string {
      if (!Array.isArray(dates) || !dates.length) {
        return undefined;
      }

      return dates.reduce((date1, date2) => {
        const date1Valid = dayjs(date1).isValid();
        const date2Valid = dayjs(date2).isValid();
        if ((!date1 || !date1Valid) && (!date2 || !date2Valid)) {
          return undefined;
        } else if (!date1 || !date1Valid) {
          return date2;
        } else if (!date2 || !date2Valid) {
          return date1;
        }

        return Dates.isSameOrBefore(date1, date2) ? date1 : date2;
      });
    }

    /**
     * Returns the string representation of today in the configured timezone
     */
    public static today(): string {
      return Dates.todaysDate().format(Dates.DATE_FORMAT);
    }

    /**
     * Returns a Moment representing todays date in the configured timezone
     */
    public static todaysDate(): dayjs.Dayjs {
        return dayjs().tz(Dates.TIMEZONE);
    }

    public static formatTo(date: string, formatTo: string, formatFrom: string = this.DATE_FORMAT): string {
      return dayjs(date, formatFrom).format(formatTo);
    }

    /**
     * Returns a Dayjs representing the given date
     * @param date The string formatted date
     * @param format The format of the date string
     */
    public static fromString(date: string, format: string = Dates.DATE_FORMAT): dayjs.Dayjs {
      const strictlyParsedDate = dayjs(date, format, true);
      if (!strictlyParsedDate.isValid()) {
        return strictlyParsedDate;
      }

      return dayjs.tz(date, format, Dates.TIMEZONE);
    }

    /**
     * Returns an ISO 8601 date string in the configured timezone
     * @param date A date string or Dayjs object
     */
    public static format(date: dayjs.ConfigType, format: string = Dates.DATE_FORMAT): string {
      return Dates.toDayJS(date).format(format);
    }

    /**
     * Retruns an ISO 8601 date time string in the configured timezon
     * @param datetime A datetime string or Dayjs object
     */
    public static formatDatetime(datetime: dayjs.ConfigType): string {
      return Dates.toDayJS(datetime).format();
    }

    /**
     * Returns a Date representing the given date
     * @param date The string formatted date
     * @param format The format of the date string
     */
    public static toDate(date: string, format: string = Dates.DATE_FORMAT): Date {
      return Dates.toDayJS(Dates.toDayJS(date).format(format)).tz(Dates.TIMEZONE).toDate();
    }

    /**
     * Divides the provided date range into months and returns start and end dates for each month
     * @param {string} startDate Start date of the range to split
     * @param {string} endDate End date of the range to split
     * @returns A list of months described by their start and end dates
     */
    public static splitIntoMonths(startDate: string, endDate: string): Array<{ startOfMonth: string; endOfMonth: string; }> {
      let start = Dates.toDayJS(startDate);
      const end = Dates.toDayJS(endDate);
      const months: Array<{ startOfMonth: string; endOfMonth: string; }> = [];
      while (start.isBefore(end) || start.month() === end.month()) {
        months.push({
          startOfMonth: start.startOf('month').format(Dates.DATE_FORMAT),
          endOfMonth: start.endOf('month').format(Dates.DATE_FORMAT),
        });
        start = start.add(1, 'month');
      }

      return months;
    }

    public static addDays(date: string, daysToAdd: number): string | null {
      const newDate = Dates.toDayJS(date);
      if (dayjs.isDayjs(newDate)) {
        return newDate.add(daysToAdd, 'day').format(Dates.DATE_FORMAT);
      }

      return;
    }

    public static addMonths(date: string, monthsToAdd: number): string | null {
      const newDate = Dates.toDayJS(date);
      if (dayjs.isDayjs(newDate)) {
        return newDate.add(monthsToAdd, 'months').format(Dates.DATE_FORMAT);
      }

      return;
    }

    public static endOfMonth(date: string): string | null {
      const newDate = Dates.toDayJS(date);
      if (dayjs.isDayjs(newDate)) {
        return newDate.endOf('month').format(Dates.DATE_FORMAT);
      }

      return;
    }

    public static addMilliseconds(date: string, milliseconds: number): string {
      return dayjs.tz(date).add(milliseconds, 'millisecond').format(Dates.DATE_FORMAT);
    }

    public static subtractDays(date: string, days: number): string {
      return dayjs.tz(date).subtract(days, 'day').format(Dates.DATE_FORMAT);
    }

    public static subtractYears(date: string, years: number): string {
      return dayjs.tz(date).subtract(years, 'year').format(Dates.DATE_FORMAT);
    }

    public static subtractMilliseconds(date: string, milliseconds: number): string {
      return dayjs.tz(date).subtract(milliseconds, 'millisecond').format(Dates.DATE_FORMAT);
    }

    public static endOfDay(date: string, format?: string): string {
      const endOfDay = dayjs.tz(date).endOf('day');
      return format ? endOfDay.format(format) : endOfDay.format();
    }

    private static toDayJS(date: dayjs.ConfigType): dayjs.Dayjs {
      if (dayjs.isDayjs(date)) {
        return date.tz(Dates.TIMEZONE);
      }
      return dayjs.tz(date, Dates.TIMEZONE);
    }
}
