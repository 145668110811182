import { Injectable } from '@angular/core';
import { CommandBase as ICommandBase, EntityBase as AccountEntityBase } from '@models/account/model';
import { EntityBase as CardEntityBase } from '@models/card/model';
import { EntityBase as CommunicationEntityBase } from '@models/communication/model';
import { EntityBase as ConfigurationEntityBase } from '@models/configuration/model';
import { EntityBase as FileEntityBase } from '@models/file/model';
import { EntityBase as LookupEntityBase } from '@models/lookup/model';
import { EntityBase as ProfileEntityBase } from '@models/profile/model';
import { EntityBase as ProfileConfigurationEntityBase } from '@models/profileConfiguration/model';
import { EntityBase as ReportEntityBase } from '@models/report/model';
import { EntityBase as RequestEntityBase } from '@models/request/model';
import { EntityBase as SecurityEntityBase } from '@models/security/model';
import { EntityBase as SupportEntityBase } from '@models/support/model';
import { ContactQuery } from 'src/app/state';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environments/environment';
import { Clone } from './clone';
import { Dates } from './dates';
import { Transitions } from './transitions';

@Injectable({
  providedIn: 'root',
})
export class CommandFactory {
  public constructor(
    private contactQuery: ContactQuery,
  ) {}

  public createCommand<T extends EntityBase>(data: T, commandType: string): CommandBase<T> {
    const contact = this.contactQuery.getActive();
    const date: string = Dates.now();
    const fullName: string = `${contact.firstName} ${contact.lastName}`;
    const userId: string = contact.id;
    const updatedData = this.updateData(data, commandType, date, fullName, userId);

    return {
      id: uuid(),
      version: 1,
      producerId: environment.services.producerId,
      eventCorrelationId: uuid(),
      created: date,
      createdBy: fullName,
      createdById: userId,
      type: commandType,
      data: updatedData,
    };
  }

  private updateData<T extends EntityBase>(data: T, commandType: string, date: string, fullName: string, userId: string): T {
    const clone = Clone.deep(data);
    clone.lastTransition = commandType;
    clone.currentState = Transitions.toState(commandType);
    if (Transitions.fromState(commandType) === 'Start' || Transitions.fromState(commandType) === 'Set') {
      clone.created = date;
      clone.createdBy = fullName;
      clone.createdById = userId;
    }

    clone.updated = date;
    clone.updatedBy = fullName;
    clone.updatedById = userId;

    if (!clone.version) {
      clone.version = 1;
    }

    return clone;
  }
}

export interface CommandBase<T> extends ICommandBase {
  data?: T;
}

type EntityBase = AccountEntityBase
  | CardEntityBase
  | CommunicationEntityBase
  | ConfigurationEntityBase
  | FileEntityBase
  | LookupEntityBase
  | ProfileEntityBase
  | ProfileConfigurationEntityBase
  | ReportEntityBase
  | RequestEntityBase
  | SecurityEntityBase
  | SupportEntityBase;
