import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { GroupPermissionModel } from '../model';

export interface GroupPermissionState extends EntityState<GroupPermissionModel, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'GroupPermission' })
export class GroupPermissionStore extends EntityStore<GroupPermissionState> {
  public constructor() {
    super();
  }
}
