import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ClientByContact } from '@models/dashboard/model';

export interface ClientByContactState extends EntityState<ClientByContact, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ClientByContact', idKey: 'clientId' })
export class ClientByContactStore extends EntityStore<ClientByContactState> {
  public constructor() {
    super();
  }
}
