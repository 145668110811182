import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteContextProviderService {
  public constructor(private router: Router, private route: ActivatedRoute) { }

  public navigateTo(routeURL: string[] | string, target?: string): void {
    typeof routeURL === 'object'
      ? this.router.navigate(routeURL)
      : window.open(routeURL, target);
  }

  public getParams(key: string): string {
    return this.route.snapshot.params[key];
  }
  public getQueryParams(key: string): string {
    return this.route.snapshot.queryParams[key];
  }
}
