import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mapTo, skipWhile, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { ContactQuery, ContactStore, CurrentUserQuery } from '.';
import { EmployeesService } from '../default/employees/services/employee.service';
import { OverviewService } from '../default/overview/services/overview.service';
import { ConfigurationService } from '../shared/services/configuration.service';
import { DashboardService } from '../shared/services/dashboard.service';
import { ProfileService } from '../shared/services/profile.service';
import { SecurityService } from '../shared/services/security.service';
import { UtilityService } from '../shared/services/utility.service';
import { TermsOfUseService } from '../terms-of-use/services/terms-of-use.service';
import { BenefitPlanQuery } from './benefit-plan';

@Injectable({
  providedIn: 'root',
})
export class PrefetchService {
  public constructor(
    private benefitPlanQuery: BenefitPlanQuery,
    private configurationService: ConfigurationService,
    private contactQuery: ContactQuery,
    private contactStore: ContactStore,
    private currentUserQuery: CurrentUserQuery,
    private dashboardService: DashboardService,
    private employeesService: EmployeesService,
    private overviewService: OverviewService,
    private profileService: ProfileService,
    private router: Router,
    private securityService: SecurityService,
    private termsOfUseService: TermsOfUseService,
    private utilityService: UtilityService,
  ) { }

  public prefetchDataOnAuthentication(): Observable<boolean> {
    return this.currentUserQuery.isLoggedIn$
      .pipe(
        skipWhile((isLoggedIn) => !isLoggedIn),
        distinctUntilChanged(),
        switchMap((isLoggedIn) => {
          if (isLoggedIn) {
            const clientId = this.currentUserQuery.getClientIdFromToken();
            const refreshedData$ = clientId ? this.prefetchData(clientId) : this.loadContactsAndClientSummary();
            return refreshedData$.pipe(
              map(() => true),
            );
          }

          return of(false);
        }),
      );
  }

  private loadContactsAndClientSummary(): Observable<void> {
    return zip(
      this.profileService.loadContacts(this.currentUserQuery.getEmailAddress()),
      this.dashboardService.loadClientsByContact(),
    ).pipe(
      mapTo(null),
    );
  }

  private prefetchData(clientId: string): Observable<void> {
    return zip(
      this.profileService.loadContactsForClient(clientId),
      this.profileService.loadClient(clientId),
    )
      .pipe(
        switchMap(() => this.contactQuery.selectByEmail(this.currentUserQuery.getEmailAddress())),
        filter((contact) => !!contact && this.contactQuery.getActiveId() !== contact.id),
        tap((contact) => this.contactStore.setActive(contact.id)),
        switchMap(() => this.prefetchHighPriorityData(clientId)),
        catchError(async (error) => {
          if (error?.indexOf && error.indexOf('401 OK') > 0) {
            this.utilityService.setApiErrorMessage('No authorization exists. Please see your account\'s primary contact for access.');
          }
          await this.router.navigate(['/logout']);
          return throwError(error);
        }),
        mapTo(null),
      );
  }

  private prefetchHighPriorityData(clientId: string): Observable<void> {
    return zip(
        this.securityService.loadGroupPermissions(this.currentUserQuery.getUserGroupsFromToken()),
        this.configurationService.loadDivisions(clientId),
        this.configurationService.loadPayroll(clientId),
        this.overviewService.loadBenefitPlans(clientId),
      )
      .pipe(
        switchMapTo(zip(
          this.benefitPlanQuery.selectAllWhenLoaded(),
          this.termsOfUseService.getTermsOfUseDocument(),
        )),
        switchMap(([benefitPlans, termsOfUseDocument]) => {
          const benefitPlansGroupedByEffectiveDate = this.employeesService.getBenefitPlansGroupedByEffectiveDate(benefitPlans);
          return zip(
            this.employeesService.loadBenefitPlanFundingSources(benefitPlansGroupedByEffectiveDate),
            this.employeesService.loadPayrollSchedulesByPlan(benefitPlansGroupedByEffectiveDate),
            this.termsOfUseService.getSignedDocumentActions(termsOfUseDocument.id),
          );
        }),
        mapTo(null),
      );
  }
}
