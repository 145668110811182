import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ContactQuery, GroupPermissionQuery } from 'src/app/state';
import { CommonConstants } from '../constants/common.constant';

import { AccessRoles, headerMenuMap, landingPageForRoles, userRoleAccessMap } from './access.constant';
import { mapAllowedUrls } from './access.helper';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  private homePageUrl: string = landingPageForRoles[AccessRoles.Administrator];

  public constructor(
    private contactQuery: ContactQuery,
    private groupPermissionQuery: GroupPermissionQuery,
  ) {}

  public hasPermission(permissions: string): Observable<boolean> {
    if (userRoleAccessMap[permissions]) {
      return this.getCurrentUserRoles()
        .pipe(
          map((userRoles) => {
            if (userRoles) {
              const found = userRoleAccessMap[permissions].some((permissionRoles) => userRoles.indexOf(permissionRoles) >= 0);
              return found;
            }
            return false;
          }),
        );
    } else {
      return of(false);
    }
  }

  public hasPageAccess(currentRouteUrl: string): Observable<boolean> {
    return this.hasPageUrl(currentRouteUrl);
  }

  public hasMenuAccess(menu: string): Observable<boolean> {
    return this.groupPermissionQuery.getAllowedPaths()
      .pipe(
        map((allowedUrls) => {
          if (headerMenuMap[menu] && allowedUrls) {
            const found = headerMenuMap[menu].some((headerMenu) => allowedUrls.indexOf(headerMenu) >= 0);
            return found;
          } else {
            return false;
          }
        }),
      );
  }

  public isHomePage(currentRouteUrl: string): boolean {
    return currentRouteUrl === this.homePageUrl;
  }

  public getLandingPageForCurrentUser(): Observable<string> {
    let landingPage: string;
    let isHomePage: boolean;
    return this.getCurrentUserRoles()
      .pipe(
        map((currentUserRoles) => {
          if (currentUserRoles) {
            currentUserRoles.forEach((currentUserRole) => {
              landingPage = landingPageForRoles[currentUserRole];
              if (!isHomePage && this.isHomePage(landingPage)) {
                isHomePage = true;
              }
            });
            if (!isHomePage) {
              // If it is not home page then set the landing page of higher role landing page
              landingPage = landingPageForRoles[currentUserRoles[0]];
            }
          }
          return landingPage ? landingPage :  '';
        }),
      );
  }

  /**
   * Current user roles provided by server api
   */
  private getCurrentUserRoles(): Observable<string[]> {
    return this.contactQuery.selectActiveWhenLoaded()
      .pipe(
        map((contact) => contact.userAccessRoles),
      );
  }

  private hasPageUrl(pageUrl: string): Observable<boolean> {
    return this.groupPermissionQuery.getAllowedPaths()
      .pipe(
        first(),
        map((allowedUrls) => {
          if (allowedUrls) {
            // TODO: need to remove when reports route is added in uba
            const reportsRoute = 'reports';
            allowedUrls.push(reportsRoute);
            return mapAllowedUrls(allowedUrls).indexOf(pageUrl.slice(1)) >= 0;
          }
          return false;
        }),
      );
  }
}
