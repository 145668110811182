<div class="list" *ngIf="showWindow">
  <ul class="list-alert-head">
    <li class="alert-window-padding-reset" (click)="closeAlerts()">
      <span *ngIf="windowUnreadCount > 0" class="unreadCount">{{windowUnreadCount}}</span>
      <img alt="" src="../../../../assets/img/Green.svg" class="alert-icon" />
      Alerts
      <span #closeBtn class="cross-button" tabindex="0" role="button" (keyup.enter)="closeAlerts()"><img alt="Close"
          src="../../../../assets/img/close-green.svg" class="caret-icon" aria-hidden="true" /><span
          class="sr-only">Close Alerts</span></span>
    </li>
  </ul>
  <ul class="list-alert-items" *ngIf="!showListLoader && alertsData?.length == 0">
    <li class="no-alerts">
      You don't have any alerts.
    </li>
  </ul>
  <ul class="list-alert-items" *ngIf="!showListLoader">
    <li *ngFor="let alert of alertsData; let i = index" id="alert.alertId">
      <a tabindex="0" role="button" (keyup.enter)="$event.target.click()" (click)="showAlertDetail(alert)">
        <span class="alert-bullet" [ngClass]="{ 'unread-bullet': !alert.unread }"></span>
        <span class="alert-message" [ngClass]="{ 'unread-message': alert.unread }">{{ alert.alertMessage }}</span>
        <span class="alert-date">{{ alert.alertDateTime | date: 'MM/dd/yyyy' : 'UTC'}}</span>
        <span class="alert-caret">
          <img [ngClass]="{ 'rotate': alert.isMessageShow }" alt="Expand Alerts Dropdown"
            src="../../../../assets/img/dropdown.svg" class="caret-icon" />
        </span>
      </a>
      <app-alert-message-detail *ngIf="alert.isMessageLoaded && alert.isMessageShow"
        [id]="'alert-detail-' + alert.alertId" [messageContent]="alert.messageContent"></app-alert-message-detail>
      <mat-spinner [diameter]="25" class="page-loader" *ngIf="!alert.isMessageLoaded && alert.isMessageShow">
      </mat-spinner>

    </li>
    <li class="see-more" *ngIf="showSeeMore">
      <a tabindex="0" role="button" (keyup.enter)="$event.target.click()" *ngIf="!showSeeMoreLoader"
        (click)="onSeeMore($event)">See more</a>
      <mat-spinner *ngIf="showSeeMoreLoader" [diameter]="20" class="page-loader"></mat-spinner>
    </li>
  </ul>
  <ul class="list-alert-items" *ngIf="showListLoader">
    <li>
      <mat-spinner [diameter]="50" class="page-loader"></mat-spinner>
    </li>
  </ul>
</div>