import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { ChainType, MatchType, ParentType, PaymentSourceAccount, SearchCriteria } from '@models/account/model';
import { Observable } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { ClientQuery, PaymentSourceAccountQuery, PaymentSourceAccountStore } from 'src/app/state';

import { CoreService } from '../models/clux/enum';
import { PaymentSourceAccountSearchQuery } from '../models/clux/model';
import { Uri } from '../utils/uri';

@Injectable({
  providedIn: 'root',
})
export class PaymentSourceAccountService {
  public constructor(
    private clientQuery: ClientQuery,
    private http: HttpClient,
    private paymentSourceAccountQuery: PaymentSourceAccountQuery,
    private paymentSourceAccountStore: PaymentSourceAccountStore,
  ) { }

  public getPaymentSourceAccount(paymentSourceAccountId: string): Observable<PaymentSourceAccount> {
    return this.loadPaymentSourceAccounts()
      .pipe(
        switchMap(() => this.paymentSourceAccountQuery.selectEntityWhenLoaded(paymentSourceAccountId)),
      );
  }

  public getPaymentSourceAccounts(): Observable<PaymentSourceAccount[]> {
    return this.loadPaymentSourceAccounts()
      .pipe(
        switchMap(() => this.paymentSourceAccountQuery.selectAllWhenLoaded()),
      );
  }

  public getActivePaymentSourceAccounts(): Observable<PaymentSourceAccount[]> {
    return this.loadPaymentSourceAccounts()
      .pipe(
        switchMap(() => this.paymentSourceAccountQuery.selectAllActiveWhenLoaded()),
      );
  }

  private loadPaymentSourceAccounts(): Observable<void> {
    const client = this.clientQuery.getActive();
    const searchCriteria: SearchCriteria = [
      {
        key: 'parentId',
        value: client.id,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'parentType',
        value: ParentType.CLIENT,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];

    const query: PaymentSourceAccountSearchQuery = { includeInactive: true };
    const uri = new Uri(`/profile/${client.id}/paymentSourceAccount/search`, CoreService.Account, query);
    const request$ =  this.http.post<PaymentSourceAccount[]>(uri.toString(), searchCriteria)
      .pipe(
        withTransaction((accounts) => this.paymentSourceAccountStore.set(accounts)),
      );

    return cacheable(this.paymentSourceAccountStore, request$, { emitNext: true })
      .pipe(
        mapTo(null),
      );
  }
}
