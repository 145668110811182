import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Division } from '@models/profileConfiguration/model';

export interface DivisionState extends EntityState<Division, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'Division' })
export class DivisionStore extends EntityStore<DivisionState> {
  public constructor() {
    super();
  }
}
