<div class="layout-container">
<app-header (toggleAlert)="onToggleAlert($event)"></app-header>
<app-tasc-growel [growlProps]="tascGrowlObj"></app-tasc-growel>
    <main id="bodyContainer" role="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </main>
<app-footer></app-footer>
<div class="loading-overlay" *ngIf="isAlertOpen"></div>
</div>