import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { SessionStorage } from 'src/app/shared/models/clux/enum';
import { BrowserSessionStorageService } from 'src/app/shared/services/browser-session-storage.service';
import { TermsOfUseService } from 'src/app/terms-of-use/services/terms-of-use.service';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {

  public constructor(
    private authenticationService: AuthenticationService,
    private browserSessionStorageService: BrowserSessionStorageService,
    private raygunService: RaygunService,
    private router: Router,
    private termsOfUseService: TermsOfUseService,
    ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.authenticationService.refreshSession())
      .pipe(
        switchMap((refreshSessionResult) => {
          if (!refreshSessionResult || !refreshSessionResult.isValidSession) {
            this.browserSessionStorageService.set(SessionStorage.Url, _state.url);
            return of(this.router.parseUrl('/logout'));
          }
          return this.termsOfUseService.hasSignedTermsOfUse()
          .pipe(
            map((hasSignedTermsOfUse) => {
              if (!hasSignedTermsOfUse) {
                return this.router.parseUrl('/terms');
              }

              return true;
            }),
          );
        }),
        catchError((error) => {
          this.raygunService.logError('Error in auth.guard.ts:canActivate()', { error });
          return of(this.router.parseUrl('/logout'));
        }),
      );
    }
}
