import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EligibilityEventOffering, EligibilityEventOfferingState, OfferingPrice, OfferingPriceState } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { OfferingPriceStore, OfferingPriceStoreState } from './offering-price.store';

@Injectable({
  providedIn: 'root',
})
export class OfferingPriceQuery extends QueryEntity<OfferingPriceStoreState> {
  public constructor(protected store: OfferingPriceStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<OfferingPrice[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByStateWhenLoaded(states: OfferingPriceState[]): Observable<OfferingPrice[]> {
    return this.selectAllWhenLoaded().pipe(
      map((offeringPrices) =>
        offeringPrices.filter((offeringPrice) => states.includes(offeringPrice.currentState as OfferingPriceState)),
      ),
    );
  }
}
