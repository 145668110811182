import { ErrorHandler, Injectable } from '@angular/core';
import rg4js from 'raygun4js';

import * as packageJson from '../../../package.json';
import { environment } from '../../environments/environment';
import { RaygunService } from './raygun.service';

rg4js('apiKey', environment.logging.apiKey);
rg4js('setVersion', packageJson.version);
rg4js('enableCrashReporting', true);
rg4js('options', {
    excludedHostnames: ['localhost'],
});
rg4js('withTags', ['clux-web', environment.name]);
rg4js('logContentsOfXhrCalls', true);

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
    public constructor(private raygunService: RaygunService) { }
    // tslint:disable-next-line:no-any
    public handleError(e: any): void {
        this.raygunService.logError(e);
    }
}
