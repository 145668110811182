import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { CommonConstants } from '../constants/common.constant';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  private apiErrorMessage: string = CommonConstants.errorCodes.apiFailure;
  private shouldCloseOnTimeoutGrowler: boolean = false;

    public constructor(
        private emitterService: EmitterService,
    ) {

    }

    public showGrowelMessage(
      message: string,
      type: boolean | string,
      undoData?: boolean,
      link?: string,
      postManuallyLink?: boolean,
      closeAfterMs?: number,
    ): void {
      const emitterMsg = this.emitterService.getEmitterMessage(message, type, undoData, link, postManuallyLink);
      this.emitterService
        .on(CommonConstants.emmiterKeys.loginErrors)
        .emit(emitterMsg);
      window.scroll(0, 0);
      if (closeAfterMs) {
        this.shouldCloseOnTimeoutGrowler = true;
        setTimeout(() => {
          if (this.shouldCloseOnTimeoutGrowler) {
            this.emitterService.notifyCloseGrowl(true);
          }
          this.shouldCloseOnTimeoutGrowler = false;
        }, closeAfterMs);
      } else {
        this.shouldCloseOnTimeoutGrowler = false;
      }
    }

    public showGrowlMessage(
      message: string,
      type: boolean | string,
      undoData?: boolean,
      link?: string,
      postManuallyLink?: boolean,
      closeAfterMs?: number): void {
      this.showGrowelMessage(message, type, undoData, link, postManuallyLink, closeAfterMs);
    }

    /**
     * Returns Date string in format 'yyyy-mm-dd' or null if no date is entered
     * @param date Date to be converted in Ngb date format
     */
    public getNgbToStringDateFormat(date: Model.NgbDate): string {
        if (!date) {
          return null;
        }
        const year = date.year;
        const month = ('0' + date.month).slice(-2);
        const day = ('0' + date.day).slice(-2);
        if (String(year).length !== 4 || String(month).length !== 2 || String(day).length !== 2) {
          return null;
        }
        return `${year}-${month}-${day}`;
    }

    /**
     * Returns Date in Ngb date format or null if no date is entered
     * @param date Date to be converted in 'yyyy-mm-dd' format
     */
    public getStringToNgbDateFormat(date: string): Model.NgbDate {
        if (!date) {
          return null;
        }
        const splitDate = date.split('-');
        const year = splitDate[0];
        const month = splitDate[1];
        const day = splitDate[2].split('T')[0];
        if ([+year, +month, +day].includes(Number.NaN)) {
          return null;
        }
        return new NgbDate(+year, +month, +day);
    }

    /**
     * it will set error API error message for Component
     */
    public setApiErrorMessage(errorMessage: string): void {
        this.apiErrorMessage = errorMessage;
    }
    /**
     * it will return error API error message for Component
     */
    public getApiErrorMessage(): string {
        return this.apiErrorMessage;
    }
    /**
     * Returns Date in Ngb date format
     * @param date Date to be converted in 'mm/dd/yyyy' format
     */
    public getNgbDateFromMonthDayYearString(date: string): Model.NgbDate {
        return new NgbDate(+date.split('/')[2], +date.split('/')[0], +date.split('/')[1]);
    }

    public checkSessionStorage<T>(type: string, predicate?: (r: T) => boolean): T[] {
        const storage = JSON.parse(sessionStorage.getItem(type) || '[]');
        if (predicate) {
            return storage.filter(predicate);
        }
        return storage;
      }
}
