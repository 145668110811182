import { EntryState, EntryType, SoaEntryType } from '../models/uba/account/model';
import { BenefitPlan } from '../models/uba/configuration/model';
import { ClientEntryAggregate, EntryPaymentDetails } from '../models/uba/dashboard/model';
import { CurrencyUtil } from '../utils/currency.util';
import { MathUtil } from '../utils/math.util';

export interface ClientEntryAggregateViewModel {
  currentState: string;
  entryType: string;
  benefitPlanName: string;
  className: string;
  amount: number;
  transactionDateTime: string;
  paymentDetails?: EntryPaymentDetails;
}

export class ClientEntryAggregateViewModelMapper {
  public static mapToViewModel(entity: ClientEntryAggregate, benefitPlans: BenefitPlan[]): ClientEntryAggregateViewModel {
    return {
      currentState: ClientEntryAggregateViewModelMapper.currentState(entity),
      entryType: ClientEntryAggregateViewModelMapper.mapTransactionType(entity),
      benefitPlanName: ClientEntryAggregateViewModelMapper.mapBenefitPlanName(entity, benefitPlans),
      amount: ClientEntryAggregateViewModelMapper.mapAmount(entity),
      className: ClientEntryAggregateViewModelMapper.mapClassName(entity),
      transactionDateTime: entity.transactionDateTime,
      paymentDetails: entity?.paymentDetails,
    };
  }

  private static currentState(entity: ClientEntryAggregate): string {
    switch (entity.currentState) {
      case EntryState.Scheduled:
        return 'Scheduled';
      case EntryState.Published:
        return 'Processing';
      case EntryState.Entered:
      case EntryState.Settled:
        return 'Posted';
      case EntryState.Reversed:
        return 'Reversed';
      case EntryState.Rejected:
        return 'Rejected';
      case EntryState.NotPublished:
        return 'Settled';
    }
  }

  private static mapBenefitPlanName(entity: ClientEntryAggregate, benefitPlans: BenefitPlan[]): string | null {
    return benefitPlans.find(({ id }) => entity.planId === id)?.externalName || null;
  }

  private static mapTransactionType(entity: ClientEntryAggregate): string {
    switch (entity.entryType) {
      case EntryType.Reversal:
        return 'Reversal';
      case EntryType.ClientCABContribution:
      case EntryType.DisburseFromCab:
        if (entity.paymentDetails && entity.paymentDetails.paymentSourceType === 'Check') {
          return 'Transfer - Check';
        } else if (entity.paymentDetails && entity.paymentDetails.paymentSourceType === 'Wire') {
          return 'Transfer - Wire';
        } else if (entity.paymentDetails && entity.paymentDetails.paymentSourceType === 'ACH') {
          return 'Transfer - ACH';
        } else {
          return 'Transfer';
        }
      case EntryType.CABBalanceAdjustment:
        return 'Balance adjustment';
      case EntryType.ClientFeeAssessment:
        if (entity.invoiceNumber) {
          return 'Invoice payment';
        } else {
          return 'Fee payment';
        }
      case EntryType.FundFromCAB:
        return 'Plan funding';
      case EntryType.DisbursementRejection:
        return 'Declined transfer';
      case EntryType.ClientNegativeFunding:
        return 'Admin balance adjustment';
      case EntryType.BenefitPlanFinalizationToCab:
        return 'Plan finalization';
      case EntryType.ClientCommitmentFinalization:
        return 'Plan Commitment Finalization';
    }
  }

  private static mapAmount(entity: ClientEntryAggregate): number {
    switch (entity.entryType) {
      case EntryType.ClientCABContribution:
      case EntryType.DisbursementRejection:
      case EntryType.ClientNegativeFunding:
      case EntryType.Reversal:
        return CurrencyUtil.convert(entity.totalAmount);

      case EntryType.DisburseFromCab:
      case EntryType.ClientFeeAssessment:
      case EntryType.FundFromCAB:
        return CurrencyUtil.convert(MathUtil.toNegative(entity.totalAmount));

      case EntryType.BenefitPlanFinalizationToCab:
      case EntryType.ClientCommitmentFinalization:
      case EntryType.CABBalanceAdjustment:
        return CurrencyUtil.convert(entity.soaData.soaEntryType === SoaEntryType.Debit ? MathUtil.toNegative(entity.totalAmount) : entity.totalAmount);
    }
  }

  private static mapClassName(entity: ClientEntryAggregate): string {
    switch (entity.entryType) {
      case EntryType.DisburseFromCab:
      case EntryType.ClientFeeAssessment:
      case EntryType.FundFromCAB:
        return 'negative';

      case EntryType.DisbursementRejection:
      case EntryType.ClientNegativeFunding:
      case EntryType.ClientCABContribution:
      case EntryType.Reversal:
        return 'positive';

      case EntryType.BenefitPlanFinalizationToCab:
      case EntryType.ClientCommitmentFinalization:
      case EntryType.CABBalanceAdjustment:
        return entity.soaData.soaEntryType === SoaEntryType.Debit ? 'negative' : 'positive';
    }
  }
}
