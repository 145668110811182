import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-alert-message-detail',
  templateUrl: './alert-message-detail.component.html',
  styleUrls: ['./alert-message-detail.component.scss'],
})
export class AlertMessageDetailComponent implements OnInit {
  @Input() public messageContent: string;
  public constructor(private alertService: AlertService) { }
  public ngOnInit(): void {
    this.messageContent =  this.messageContent || '';
  }
}
