import { Injectable } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import {
  Attachment,
  FileInfoCommandType,
  FileProcessConfig,
  FileSummary,
  FileType,
} from 'src/app/shared/models/uba/file/model';
import { ChainType, MatchType, ParentType } from 'src/app/shared/models/uba/profile/model';
import { Document, DocumentCommandType, DocumentState } from 'src/app/shared/models/uba/profileConfiguration/model';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ContactQuery } from 'src/app/state';

@Injectable({
  providedIn: 'root',
})
export class FilesService {

  public constructor(
    private contactQuery: ContactQuery,
    private serviceFactroy: ServiceFactory,
    private utilityService: UtilityService,
  ) { }

  public getDocumentsList(parentId: string, clientId: string, offset: number, page: number): Observable<Model.SearchResults<Model.MyDouments[]>> {
    const serviceKey = CommonConstants.getPath.profile.configurationKey;
    const basePath = CommonConstants.getPath.profile.publishedDocuments;
    const queryParams: Model.QueryParams = {
      profileId: clientId,
    };
    const searchParams: Model.SearchParams = {
      take: page,
      skip: offset,
      orderBy: 'created',
      orderDirection: 'desc',
    };
    const requestPayload: Model.SearchCriteria = [{
      key: 'parentId',
      value: parentId,
      matchType: MatchType.EXACT,
      chainType: ChainType.OR,
      groupCriteria: [{
        key: 'parentType',
        value: ParentType.INSTANCE,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      }, {
        key: 'distributionType',
        value: 'Clients',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      }],
    },
    {
      key: 'documentType',
      value: 'TOU',
      matchType: MatchType.NOT,
      chainType: ChainType.AND,
    }];
    return this.serviceFactroy.search<Model.MyDouments[]>(serviceKey, basePath, requestPayload, queryParams, searchParams);
  }

  public getDownloadDocuments(clientId: string, serviceKey: string, serviceBasePath: string): Observable<string> {
    return this.serviceFactroy.query(serviceKey, serviceBasePath)
      .pipe(
        switchMap((response: Attachment) => {
          const common: Model.FileConfiguration = CommonConstants.getPath.file;
          const serviceBase: string = stringFormat(common.getSignedUrl,
            {
              profileId: clientId,
              attachmentId: response.id,
              contentType: 'application/pdf',
              fileName: response.friendlyFileName,
            });
          return this.serviceFactroy.query<string>(common.serviceKey, serviceBase);
        }),
      );
  }

  public deleteDocument(id: string): Observable<Document> {
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.profile.getDocumentRecord,
      {
        profileId: this.contactQuery.getActive().clientId,
        documentId: id,
      },
    );
    return this.serviceFactroy.query(
      CommonConstants.getPath.profile.configurationKey,
      baseUrl,
    ).pipe(switchMap((documentRecord: Document) => {
      const command: DocumentCommandType = `${documentRecord.currentState}To${DocumentState.Removed}` as DocumentCommandType;
      documentRecord.lastTransition = documentRecord.currentState + 'To' + DocumentState.Removed;
      documentRecord.currentState = DocumentState.Removed;
      return this.serviceFactroy.saveDocument(documentRecord, command);
    }));
  }

  /**
   * Gets file summary stats
   * @param fileId
   */
  public getFileSummary(fileId: string): Observable<FileSummary> {
    const profileId = this.contactQuery.getActiveId();
    const baseUrl: string = stringFormat(
      CommonConstants.getPath.file.fileSummary,
      { profileId, fileId },
    );
    return this.serviceFactroy.query(
      CommonConstants.getPath.file.serviceKey,
      baseUrl,
    );
  }

  /**
   * Process file in ActionRequired state
   * @param fileInfo File that need to be processed
   * @param isApproved true/false based on File to be processed to Approved/Cancelled
   */
  public processFile(fileInfo: Model.FileList, isApproved: boolean): Observable<null> {
    const commandName = isApproved ?
      FileInfoCommandType.ActionRequiredToProcessing :
      FileInfoCommandType.ActionRequiredToCancelling;
    const command = this.serviceFactroy.createCommand(fileInfo, commandName);
    const profileId = this.contactQuery.getActiveId();
    const fileId = fileInfo.id;
    const basePath: string = stringFormat(
      CommonConstants.getPath.file.fileProcess,
      { profileId, fileId, commandName },
    );
    return this.serviceFactroy.queryPut(CommonConstants.getPath.file.serviceKey, basePath, command);
  }

  /**
   * get claimConnext details for client
   */
  public getClaimConnex(): Observable<FileProcessConfig[]> {
    const clientId = this.contactQuery.getActive().clientId;
    const searchCriteria: Model.SearchCriteria = [
      {
        key: 'parentId',
        value: clientId,
        matchType: MatchType.EXACT,
      },
      {
        key: 'fileType',
        value: FileType.ClaimConnex,
        matchType: MatchType.EXACT,
      },
    ];
    return this.serviceFactroy.search<FileProcessConfig[]>(
      CommonConstants.getPath.file.serviceKey,
      CommonConstants.getPath.file.fileProcessConfig,
      searchCriteria,
      { clientId },
    ).pipe(map((res) => {
      return res.data;
    }));
  }

}
