import { Injectable, OnDestroy } from '@angular/core';
import { UtilityService } from '@app/shared/services/utility.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export type SkeletonLoaderErrorOptions = {
  suppress: true,
  message?: string,
} | {
  suppress?: false | undefined,
  message: string,
};

interface ObservableItem<T> {
  observableToLoad: Observable<T>;
  value: BehaviorSubject<T>;
  isLoadingSubject: BehaviorSubject<boolean>;
  valueObservable: Observable<T>;
  _stale: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SkeletonLoaderService implements OnDestroy {
  private observables: { [key: string]: ObservableItem<unknown> } = {};

  public constructor(
    private utilityService: UtilityService,
  ) { }

  /**
   * This will add but not load the supplied observable.
   * If you wish refetch data, add a new observable on the same key and trigger load() again
   *
   * @param key string
   * @param observable an observable that makes an API call and completes
   */
   public add<T>(key: string, observable: Observable<T>): ObservableItem<T> {
    const value = new BehaviorSubject(null);
    if (this.observables[key]) {
      this.observables[key].observableToLoad = observable;
      this.observables[key].isLoadingSubject = new BehaviorSubject(false);
      this.observables[key].value = value;
      this.observables[key].valueObservable = value.asObservable();
      this.observables[key]._stale = false;
    } else {
      this.observables[key] = {
        observableToLoad: observable,
        value,
        isLoadingSubject: new BehaviorSubject(false),
        valueObservable: value.asObservable(),
        _stale: false,
      };
    }
    return this.observables[key] as ObservableItem<T>;
  }

  public isLoading$(key: string): Observable<boolean> {
    if (!this.observables[key]) {
      throw new Error('Missing data');
    }
    return this.observables[key].isLoadingSubject.asObservable();
  }

  public setLoading(key: string, isLoading: boolean): void {
    if (!this.observables[key]) {
      throw new Error('Missing data');
    }

    this.observables[key].isLoadingSubject.next(isLoading);
  }

  /**
   * This will load the fetch observable, loading is also handled
   * This function can be called multiple times. If you want to refetch the same data
   * then add() a new observable and call this
   *
   * @param key
   */
  public load(
    key: string,
    errorOptions: SkeletonLoaderErrorOptions = { message: 'Something went wrong.' },
  ): Observable<void> {
    if (!this.observables[key]) {
      throw new Error('Missing data');
    }
    if (this.observables[key]._stale) {
      return;
    }
    this.setLoading(key, true);
    this.observables[key]._stale = true;
    this.observables[key].observableToLoad.pipe(
      tap((result) => {
        this.setLoading(key, false);
        this.observables[key].value.next(result);
      }),
      catchError(() => {
        if (!errorOptions.suppress) {
          this.utilityService.showGrowelMessage(errorOptions.message, false);
        }
        this.setLoading(key, false);
        return of(null);
      }),
    ).subscribe();
  }

  public get$<T>(key: string): Observable<T> {
    return this.getByKey<T>(key).valueObservable;
  }

  public complete(key: string): void {
    const obs = this.getByKey(key);
    obs.isLoadingSubject.complete();
    obs.value.complete();
  }

  public ngOnDestroy(): void {
    Object.keys(this.observables).forEach(this.complete);
  }

  private getByKey<T>(key: string): ObservableItem<T> {
    if (!this.observables[key]) {
      throw new Error('Missing data');
    }
    return this.observables[key] as ObservableItem<T>;
  }
}
