import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EligibilityEventOfferingEnriched } from '@models/profileConfiguration/model';

export interface EligibilityEventOfferingStoreState extends EntityState<EligibilityEventOfferingEnriched, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'EligibilityEventOffering' })
export class EligibilityEventOfferingStore extends EntityStore<EligibilityEventOfferingStoreState> {
  public constructor() {
    super();
  }
}
