import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EmploymentInfo } from '@models/profileConfiguration/model';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { EmploymentInfoState, EmploymentInfoStore } from './employment-info.store';

@Injectable({
  providedIn: 'root',
})

export class EmploymentInfoQuery extends QueryEntity<EmploymentInfoState> {
  public constructor(protected store: EmploymentInfoStore) {
    super(store);
  }

  public employmentInfo$ = this.store.getValue();

  public selectAllWhenLoaded(): Observable<EmploymentInfo[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

}
