// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CommandBase {
  id: string;
  eventCorrelationId: string;
  jobId?: string;
  producerId: string;
  sequenceId?: number;
  version: number;
  type: string;
  created: string;
  createdBy: string;
  createdById: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export interface EntrySOAData {
  advanceFeatureId?: string;
  advanceLimit?: number;
  amountToDisburse?: number;
  benefitPlanId?: string;
  bureauBin?: string;
  cashFeatureId?: string;
  clientId?: string;
  endDate?: string;
  frequency?: FrequencyType;
  individualId?: string;
  newParticipantAdvanceFundingId?: string;
  startDate?: string;
  thresholdAmount?: number;
  soaEntryType?: SoaEntryType;
  sourceBenefitPlanId?: string;
  targetBenefitPlanId?: string;
  investedBalance?: number;
  minimumTradeAmount?: number;
  balanceThreshold?: number;
  dependentId?: string;
  holdingAccountDescriptorId?: string;
  destinationHoldingAccountDescriptorId?: string;
  payeeId?: string;
}

export interface Client extends EntityBase {
  name: string;
  externalId?: string;
  ein?: string;
  depletionOrder?: string[];
  filingStatus?: FilingStatusType;
  otherFilingStatus?: string;
  allowCard: boolean;
  allowDisbursement?: boolean;
  offerAdvanceAccount?: boolean;
  maxAdvanceAmount?: number;
  addresses?: Addresses;
  primaryAddress?: string;
  cardType?: ClientCardType;
  cardMailingOption?: CardMailingOptionType;
  cardSecondLine?: CardSecondLineType;
  allowEligibilityClasses?: boolean;
  waitingPeriod?: number;
  eligibilityEffectiveDate?: EligibilityEffectiveDateType;
  eligibilityClasses?: EligibilityClasses;
  advanceFeatureId?: string;
  cashFeatureId?: string;
  cardPackageId?: string;
  cancellationReason?: CancellationReasonType;
  requestedCancellationDate?: string;
  cancellationProcessedDate?: string;
  customizationPackage?: CustomizationPackageType;
  cardFeePaidBy: CardFeePaidByType;
  naicsCode: string;
  multiEmployerType: boolean;
  employerType?: EmployerType;
  relatedEntities?: string;
  sendToClient: boolean;
  sendToParticipants: boolean;
  manualHold?: boolean;
  manualHoldReason?: ManualHoldReasons;
  manualHoldNote?: string;
  systemHold?: boolean;
  systemHoldReason?: SystemHoldReasons;
  holdException?: boolean;
  holdExceptionReason?: HoldExceptionReasons;
  holdExceptionEndDate?: string;
}

export interface EligibilityClass {
  classId: number;
  eligibilityEffectiveDate: EligibilityEffectiveDateType;
  name: string;
  waitingPeriod: number;
}

export interface ContributionsByClient {
  clientId: string;
  totalEmployeeContributions?: number;
  totalEmployerContributions?: number;
  hasTakeoverFunding: boolean;
}

export interface EnrollableEmployee {
  clientId?: string;
  employeeId?: string;
  employeeName?: string;
  employeeTascId?: string;
  employeeStatus?: EmploymentInfoState;
  employeeHireDate?: string;
  eligibilityClassName?: string;
  divisionName?: string;
  benefitPlanIds?: number[];
}

export interface ReimbursementsByClient {
  clientId?: string;
  totalReimbursements?: number;
  cardReimbursements?: number;
  additionalVerificationReimbursements?: number;
}

export interface EmployeesByBenefitPlan {
  clientId?: string;
  benefitPlanId?: string;
  benefitPlanName?: string;
  employeeId?: string;
  employeeName?: string;
  employeeTascId?: string;
  employeeStatus?: EmploymentInfoState;
  benefitAccountStatus?: BenefitAccountState;
  divisionName?: string;
  eligibilityClassName?: string;
  employeeHireDate?: string;
}

export interface ParticipantEntry {
  scheduledDate?: string;
  individualId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  individualExternalId?: string;
  numberOfBenefitAccounts?: number;
  benefitAccountId?: string;
  benefitAccountState?: string;
  planId?: string;
  planName?: string;
  planDescription?: string;
  clientId?: string;
  clientContributionAmount?: number;
  clientFundingAmount?: number;
  clientContributionsToDate?: number;
  participantDeductionAmount?: number;
  participantDeductionsToDate?: number;
  clientFundingsToDate?: number;
  entryState?: EntryState;
  employeeEntryStates?: EntryState[];
  employerEntryStates?: EntryState[];
  employeeEntryIds?: string[];
  employerEntryIds?: string[];
  employerEntryTypes?: EntryType[];
  totalIndividualsCount?: number;
  totalDatesCount?: number;
}

export interface BenefitAccountEnrollment {
  individualId?: string;
  firstName?: string;
  lastName?: string;
  benefitAccountId?: string;
  numberOfBenefitAccounts?: number;
  planId?: string;
  divisionId?: string;
  eligibilityClassId?: string;
  currentState?: string;
  planName?: string;
  planDescription?: string;
  benefitEffectiveDate?: string;
  clientId?: string;
  clientContributionAmount?: number;
  participantDeductionAmount?: number;
}

export interface EntryElectionSchedule extends EntityBase {
  externalId?: string;
  scheduledDate?: string;
  startDateTime?: string;
  endDateTime?: string;
  transactionDateTime?: string;
  memo?: string;
  isManualCorrection?: boolean;
  override?: boolean;
  allowedRecalculationType?: AllowedRecalculationType;
  entryType?: EntryType;
  eventSource?: string;
  correlationId?: string;
  amount?: number;
  soaData?: string;
  fundingSource?: string;
  postingScheduleType?: string;
  parentReversalId?: string;
  linkId?: string;
  scheduleManually?: boolean;
  paymentSourceId?: string;
  taxYear?: string;
  bureauId?: string;
  externalDocumentId?: string;
  isSetToBeRetroactive?: boolean;
  payeeId?: string;
  paymentDetails?: string;
  clientId?: string;
  planId?: string;
  planName?: string;
  benefitAccountId?: string;
  individualId?: string;
  individualFullName?: string;
  individualLastName?: string;
  individualFirstName?: string;
  individualExternalId?: string;
  electionProfileType?: string;
  electionScheduleType?: string;
  pendingElectionAmount?: number;
  activeElectionAmount?: number;
  recalculateRemainingPostings?: RecalculatePostingsOptionType;
  benefitAccountParentId?: string;
}

export interface TradeActivity {
  tradeId: string;
  individualId: string;
  benefitAccountId: string;
  planName: string;
  description: string;
  createdDate: string;
  settledDate?: string;
  requestedAmount: number;
  settledAmount?: number;
  type: TradeType;
  status?: string;
}

export interface TransactionActivity {
  individualId?: string;
  benefitAccountId?: string;
  soaAccountId?: string;
  planId?: string;
  planName?: string;
  postingId?: string;
  postingType?: PostingType;
  postingAccountId?: string;
  postingAmount?: number;
  postingCurrentState?: PostingState;
  accountType?: AccountType;
  entryId?: string;
  entryParentId?: string;
  entryFundingSource?: FundingSourceType;
  entryType?: EntryType;
  entryFeeType?: string;
  entryCurrentState?: EntryState;
  entryStartDateTime?: string;
  entryEndDateTime?: string;
  entryScheduledDate?: string;
  entryTransactionDateTime?: string;
  entryOriginalPaymentSourceType?: PaymentSourceType;
  entryAmount?: number;
  entryPayeeId?: string;
  entrySoaData?: string;
  entryLinkId?: string;
  entryPaymentSourceId?: string;
  transactionType?: TransactionCategory;
  transactionState?: string;
  requestPaymentId?: string;
  requestPaymentPaymentAmount?: number;
  requestPaymentRequestedAmount?: number;
  requestPaymentPaymentDateTime?: string;
  requestPaymentPaymentStatus?: PaymentStatusType;
  requestId?: string;
  requestCurrentState?: RequestState;
  requestDescription?: string;
  requestRequestedAmount?: number;
  requestType?: string;
  requestMethod?: string;
  requestServiceDate?: string;
  requestSourceId?: string;
  requestExternalClaimId?: string;
  requestSubmissionDateTime?: string;
  requestExpenseSourceName?: string;
  requestIncurredByName?: string;
  requestServiceProvider?: string;
  requestPuxStorageOnlyServiceEndDate?: string;
  requestDeclineReason?: string;
  fundsTransferId?: string;
  fundsTransferAmount?: number;
  fundsTransferSourceId?: string;
  fundsTransferSourceType?: FundsTransferSourceType;
  fundsTransferDestinationId?: string;
  fundsTransferDestinationType?: FundsTransferDestinationType;
  fundsTransferNextEntryDate?: string;
  carryOverLabel?: string;
  groupingTag?: string;
}

export interface TransactionActivitySummary {
  individualId?: string;
  benefitAccountId?: string;
  planId?: string;
  planName?: string;
  transactionType?: TransactionCategory;
  totalAmount?: number;
}

export interface BenefitPlanTierMaxPayout extends EntityBase {
  offeringId: string;
  offeringName: string;
  name: string;
  description?: string;
  planStartDate: string;
  planEndDate?: string;
  electionScheduleType?: string;
  limitMinimum?: number;
  limitMaximum?: number;
  alwaysUseLimitMaximum?: boolean;
  payrollScheduleIds?: string[];
  payrollPostingSchedule?: PayrollPostingScheduleType;
  firstPayrollPostingDate?: FirstPayrollPostingDateType;
  autoPost?: AutoPostType;
  paymentSourceType?: string;
  enrollmentStartDate?: string;
  enrollmentEndDate?: string;
  enrollmentMethods?: EnrollmentMethods;
  requireEnrollmentApproval?: boolean;
  enrollmentApprovalBeforeInitiation?: boolean;
  enrollmentApprovalBeforeInitiationMethods?: EnrollmentMethods;
  enrollmentApprovalAfterInitiation?: boolean;
  enrollmentApprovalAfterInitiationMethods?: EnrollmentMethods;
  gracePeriod: boolean;
  gracePeriodMaximumMonths?: number;
  gracePeriodMaximumDays?: number;
  hasCarryover?: boolean;
  carryoverLabel?: string;
  carryoverCalculationMethod?: CarryoverCalculationMethodType;
  carryoverMaxAmount?: number;
  carryoverEndDate?: string;
  carryoverSourcePlanIds?: string[];
  carryoverDestinationPlanId?: string;
  finalExpenseDate?: string;
  groupingTag: string;
  planInitiationDate?: string;
  cardOrderDate?: string;
  finalizationType?: string;
  sendWelcomeLettersDate?: string;
  eligibilityClasses?: number[];
  autoVerification?: boolean;
  terminationRulesType: string;
  eligibilityEndDateType?: string;
  allowCardSubmission?: boolean;
  advanceAccount?: boolean;
  allowOnlineSubmission?: boolean;
  allowPaperSubmission?: boolean;
  allowElectronicFileSubmission?: boolean;
  runOutType?: string;
  runOutDays?: number;
  terminationRunOutType?: string;
  terminationRunOutDays?: number;
  finalSubmitDate?: string;
  paymentsRequirePriorVerification?: boolean;
  cardAuthorizationOptions?: string[];
  thirdPartyAuthorization?: boolean;
  thirdPartyAuthorizationOptions?: string[];
  remainingRequirements?: string[];
  autoEnrollOnRenewal?: boolean;
  renewalLeadTimeDays?: number;
  renewedFromPlanId?: string;
  hasBeenRenewed?: boolean;
  externalName: string;
  externalDescription: string;
  externalId: string;
  planType?: string;
  externalPayeeNetworks?: string[];
  hasInvestmentAccount?: boolean;
  cashInvestmentInstitutionType?: string;
  investmentInstitutionType?: string;
  areCashDepositAccountsInterestBearing?: boolean;
  cashAccountMinimumBalance?: number;
  investmentRecordKeeperPlanId?: string;
  minimumBuyTradeAmount?: number;
  minimumSellTradeAmount?: number;
  allowSingleSignOnWithInvestmentRecordKeeper?: boolean;
  billingTerminationRunOutType?: string;
  optionalAccountStatesToBill?: string[];
  listBillId?: string;
  paymentSourceId?: string;
  restrictCardIssuance?: boolean;
  clientIsPendingInactivation?: boolean;
  allowClientApproval?: boolean;
  allowAssociatingDependentsToBenefitAccount: boolean;
  requireSSN: boolean;
  disableCardAccessNumberOfDays?: number;
  hasTaxYearContributions?: boolean;
  showTaxYearContributions?: boolean;
  allowContributionsAfterTaxYear?: boolean;
  lastDayContributionsAllowed?: string;
  allowZeroDollarElection?: boolean;
  hasTakeoverPlan?: boolean;
  takeoverFundingType?: string;
  yearToDateScheduleDate?: string;
  secondaryBureauId?: string;
  disburseWithoutRequest?: boolean;
  scheduleEndDate?: string;
  allowExcessContributions?: boolean;
  excessContributionType?: string;
  excessContributionAge?: number;
  maxExcessContribution?: number;
  isIdentityVerificationRequired?: boolean;
  employerLimitMaximum?: number;
  isSubjectToERISA?: boolean;
  regularlyScheduledHoursPerWeek?: string[];
  unionEmployeeStatus?: string;
  investmentAdvisor?: string;
  coverageTiers?: string[];
  fullCoveragePaidThroughDate?: string;
  requireTermsOfUseForDisbursements?: boolean;
  maxPayoutEachPerson?: number;
  maxPayoutSingle?: number;
  maxPayoutSinglePlusOne?: number;
  maxPayoutSinglePlusTwo?: number;
  maxPayoutFamily?: number;
  hideBenefitAccountActivityFromClients: boolean;
}

export type EnrollmentMethods = EnrollmentMethodType[];

export interface EmployeesByClient {
  clientId?: string;
  individualId?: string;
  individualTascId?: string;
  individualFirstName?: string;
  individualLastName?: string;
  individualFullName?: string;
  individualCreated?: string;
  individualPrimaryEmail?: string;
  individualStatus?: string;
  individualIdVerification?: string;
  employeeId?: string;
  employeeCreated?: string;
  employeeStatus?: string;
  employeeEmployeeId?: string;
  employeeHireDate?: string;
  employeeDivisionId?: string;
  employeeSubdivisionId?: string;
  employeeEligibilityClassId?: string;
  eligibilityClassName?: string;
  employeePayrollScheduleName?: string;
  employeeClientPayrollId?: string;
  employeeTerminationDate?: string;
  employeeRetired?: string;
  employeeAverageHoursWorked?: string;
  divisionName?: string;
  divisionSubdivisions?: string;
  planId?: string;
  planName?: string;
  benefitAccountId?: string;
  soaAccountId?: string;
  benefitEffectiveDate?: string;
  clientContributionAmount?: number;
  participantDeductionAmount?: number;
  numberOfBenefitAccounts?: number;
  hasInvestmentAccount?: boolean;
  isNonEmployee?: boolean;
  individualConvertedFromDependentId?: string;
}

export interface IndividualsAndDependents {
  clientId?: string;
  individualId?: string;
  individualTascId?: string;
  individualFirstName?: string;
  individualLastName?: string;
  individualFullName?: string;
  individualCreated?: string;
  individualPrimaryEmail?: string;
  individualStatus?: string;
  individualIdVerification?: string;
  employeeId?: string;
  employeeCreated?: string;
  employeeStatus?: string;
  employeeEmployeeId?: string;
  employeeHireDate?: string;
  employeeDivisionId?: string;
  employeeSubdivisionId?: string;
  employeeEligibilityClassId?: string;
  eligibilityClassName?: string;
  employeePayrollScheduleName?: string;
  employeeClientPayrollId?: string;
  employeeTerminationDate?: string;
  employeeRetired?: string;
  employeeAverageHoursWorked?: string;
  dependents?: string;
  isNonEmployee?: boolean;
  individualConvertedFromDependentId?: string;
}

export interface EntryBenefitAccountSummary {
  individualId: string;
  benefitAccountId: string;
  benefitPlanId: string;
  planName: string;
  entryType: EntryType;
  entryCurrentState: EntryState;
  entryFundingSource?: FundingSourceType;
  entryAmountSum?: number;
}

export interface BenefitAccountSummaryByBenefitPlan {
  clientId: string;
  benefitPlanId: string;
  planName: string;
  benefitPlanRequireEnrollmentApproval?: boolean;
  benefitPlanAllowClientApproval?: boolean;
  benefitAccountCount: number;
}

export interface ClientByContact {
  clientId: string;
  clientExternalId: string;
  clientName: string;
  clientCurrentState: string;
}

export interface IndividualsByClient {
  individualId?: string;
  individualTascId?: string;
  individualFirstName?: string;
  individualLastName?: string;
  individualAddresses?: Address[];
  individualPrimaryEmail?: string;
  individualStatus?: string;
}

export interface ClientViewEligibilityEventSummary {
  firstName: string;
  lastName: string;
  eventType: string;
  eventDate: string;
  status?: ClientViewEligibilityEventSummaryStatus;
  eligibilityEventId: string;
  individualId: string;
  paymentAccountGroupId?: string;
}

export interface FundsTransferAccountInfo {
  fundsTransferCriterionId: string;
  fundsTransferCriterionState: string;
  fundsTransferCriterionNextEntryDate: string;
  fundsTransferCriterionTransactionLockId?: string;
  sourceAccountId?: string;
  sourceAccountType?: string;
  sourceAccountState?: string;
  destinationAccountId?: string;
  destinationAccountType?: string;
  destinationAccountState?: string;
  specialContributionType?: string;
}

export interface EligibilityEvent extends EntityBase {
  eventType: string;
  eventDate: string;
  incurredByProfileId: string;
  incurredById?: string;
  clientId: string;
  paymentAccountGroupId?: string;
  electionDueDate?: string;
  offerSentDate?: string;
  firstResponseDate?: string;
  lastResponseDate?: string;
  hideFrom?: HideFromType;
}

export interface EnrichedEligibilityEvent extends EligibilityEvent {
  individualName?: undefined;
  individualExternalId?: undefined;
  clientName?: undefined;
  clientExternalId?: undefined;
}

export interface EligibilityEventPlanOffer extends EligibilityEvent {
  planName?: undefined;
}

export interface BenefitPlanEntry {
  scheduledDate?: string;
  deferScheduledDate?: string;
  entryType?: EntryType;
  clientId?: string;
  numberOfPlans?: number;
  planId?: string;
  planName?: string;
  planStartDate?: string;
  planEndDate?: string;
  planCurrentState?: string;
  paymentSourceId?: string;
  paymentSourceType?: string;
  offeringId?: string;
  offeringName?: string;
  fundingSource?: string;
  postingScheduleType?: string;
  entryCurrentState?: EntryState;
  contributionAmount?: number;
  contributionCount?: number;
  deductionAmount?: number;
  deductionCount?: number;
  fundingAmount?: number;
  fundingCount?: number;
  yearToDateScheduleDate?: string;
  hasTakeoverPlan?: boolean;
  hasScheduledState?: boolean;
  overfundedAmount?: number;
  overfundedCount?: number;
}

export interface FundingAndRemittanceEntry {
  amount?: number;
  parentId?: string;
  entryId?: string;
  transactionDateTime?: string;
  startDateTime?: string;
  endDateTime?: string;
  entryType?: EntryType;
  currentState?: string;
  paymentDetails?: EntryPaymentDetails;
  bankAccountName?: string;
  bankAccountNumber?: string;
  bankAccountParentId?: string;
  paymentSourceParentId?: string;
  paymentSourceId?: string;
  paymentSourceParentType?: string;
  paymentSourceType?: string;
  paymentOwnerType?: string;
}

export interface RemittanceEntryAggregate {
  entryType?: EntryType;
  entryState?: EntryState;
  scheduledDate?: string;
  payeeId?: string;
  paymentPlanName?: string;
  paymentPlanId?: string;
  payee?: string;
  amount?: number;
  containsReversed?: boolean;
}

export interface RemittanceEntryDetails {
  firstName?: string;
  lastName?: string;
  amount?: number;
  entryState?: EntryState;
}

export interface SupportRequestStatus {
  disableSupportRequest?: boolean;
}

export interface BenefitAccountsByBenefitPlan {
  benefitPlanName?: string;
  benefitPlanId?: string;
  benefitPlanCurrentState?: string;
  benefitPlanStartDate?: string;
  benefitAccountEffectiveDate?: string;
  benefitAccountId?: string;
  benefitAccountCurrentState?: string;
  benefitPlanInvestmentRecordKeeperPlanId?: string;
  benefitAccountParentId?: string;
  benefitPlanParentId?: string;
  benefitPlanSecondaryBureauId?: string;
  benefitPlanInvestmentInstitutionType?: InvestmentFinancialInstitution;
  benefitPlanMinimumBuyTradeAmount?: number;
  benefitPlanMinimumSellTradeAmount?: number;
}

export interface EntriesByFileBuilderPart {
  entryId?: string;
  fileBuilderPartId?: string;
  fileBuilderPartExternalReferenceId?: string;
  entryExternalDocumentId?: string;
}

export interface ContributionsByTaxYear {
  benefitAccountId?: string;
  soaAccountId?: string;
  benefitPlanId?: string;
  taxYear?: string;
  contributionAmount?: number;
}

export interface BenefitAccountRequestTypes extends EntityBase {
  clientId: string;
  planId: string;
  planName: string;
  planDescription?: string;
  planStartDate: string;
  planEndDate?: string;
  hireDate: string;
  benefitEffectiveDate: string;
  eligibilityEndDate?: string;
  clientLastScheduleDate?: string;
  individualLastScheduleDate?: string;
  requestTypes?: RequestTypes;
  soaAccountId?: string;
  balance?: number;
  carryoverDeclined: boolean;
  approvedBy?: string;
  approvedDate?: string;
  payrollScheduleId?: string;
  excludeFromBilling: boolean;
  cashAccountMinimumBalance?: number;
  enrollmentSource?: EnrollmentSourceType;
  clientScheduleFirstDate?: string;
  individualScheduleFirstDate?: string;
  scheduleEndDate?: string;
  lastDayToSubmitExpenses?: string;
  reasonToHoldDisbursements?: string;
  finalCoverageDate?: string;
  fullCoveragePaidThroughDate?: string;
  scheduleChangeDate?: string;
  requestTypeSelections?: RequestTypeSelection[];
}

export interface RequestTypeSelection {
  code?: string;
  expenseType?: string;
  serviceType?: string;
  limit?: number;
}

export interface RequestType {
  expenseType?: string;
  serviceType?: string;
  limit?: number;
}

export type RequestTypes = RequestType[];

export interface Address {
  id?: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country: string;
  zip?: string;
  zipplusfour?: string;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Aggregate {
  key?: string;
  aggregateType?: AggregateType;
  groupBy?: boolean;
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export type AggregateCriteria = Aggregate[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface EntryPaymentDetails {
  checkDate?: string;
  checkNumber?: string;
  transactionId?: string;
  paymentId?: string;
  payeeId?: string;
  planName?: string;
  payeeName?: string;
  dateReceived?: string;
  amountReceived?: number;
  externalMemo?: string;
  paymentSourceType?: PaymentSourceType;
  cabFundingPaymentSummary?: CabFundingPaymentSummary;
  paymentAccountGroupName?: string;
}

export interface CabFundingPaymentSummary {
  clientCABFundingId?: string;
  cabFundingPaymentDetails?: CabFundingPaymentDetail[];
}

export interface CabFundingPaymentDetail {
  planId?: string;
  planName?: string;
  scheduledDate?: string;
  amountSum?: number;
}

export interface ClientEntryAggregate {
  id?: string;
  parentId: string;
  parentType: ParentType;
  created?: string;
  clientId: string;
  payeeId?: string;
  scheduledDate: string;
  transactionDateTime?: string;
  currentState?: EntryState;
  containsReversed?: boolean;
  entryType: EntryType;
  soaData?: EntrySOAData;
  planId: string;
  totalAmount: number;
  count: number;
  memo?: string;
  invoiceNumber?: string;
  paymentDetails?: EntryPaymentDetails;
}

export type ContributionsByClients = ContributionsByClient[];

export type EnrollableEmployees = EnrollableEmployee[];

export type ReimbursementsByClients = ReimbursementsByClient[];

export type ParticipantEntries = ParticipantEntry[];

export type EmployeesByBenefitPlans = EmployeesByBenefitPlan[];

export type BenefitAccountsByBenefitPlans = BenefitAccountsByBenefitPlan[];

export type BenefitAccountEnrollments = BenefitAccountEnrollment[];

export type TransactionActivities = TransactionActivity[];

export type TradeActivities = TradeActivity[];

export type EligibilityClasses = EligibilityClass[];

export type EntriesByFileBuilderParts = EntriesByFileBuilderPart[];

export type EmployeesByClients = EmployeesByClient[];

export type BenefitAccountSummaryByBenefitPlans = BenefitAccountSummaryByBenefitPlan[];

export type ClientsByContact = ClientByContact[];

export type Addresses = Address[];

export enum CardFeePaidByType {
  Participant = 'Participant',
  Client = 'Client',
}

export enum PayrollPostingScheduleType {
  Payroll = 'Payroll',
}

export enum FirstPayrollPostingDateType {
  FirstPayDate = 'FirstPayDate',
}

export enum AutoPostType {
  Yes = 'Yes',
  No = 'No',
}

export enum EligibilityEffectiveDateType {
  FirstDay = 'FirstDay',
  FirstOfMonth = 'FirstOfMonth',
  SameDay = 'SameDay',
}

export enum EnrollmentMethodType {
  EDI = 'EDI',
  OnlineAndMobileEnrollment = 'OnlineAndMobileEnrollment',
  PaperOrPhone = 'PaperOrPhone',
}

export enum EnrollmentSourceType {
  AdminWeb = 'AdminWeb',
  ClientWeb = 'ClientWeb',
  ParticipantWeb = 'ParticipantWeb',
  ParticipantMobile = 'ParticipantMobile',
  File = 'File',
  ApiConnex = 'ApiConnex',
}

export enum ManualHoldReasons {
  Bankruptcy = 'Bankruptcy',
  Finance = 'Finance',
  OutstandingAdminFees = 'OutstandingAdminFees',
  OutstandingFundingInvoice = 'OutstandingFundingInvoice',
  ServiceAgreementCancelled = 'ServiceAgreementCancelled',
}

export enum SystemHoldReasons {
  OutstandingFundingPVR = 'OutstandingFundingPVR',
}

export enum HoldExceptionReasons {
  PaymentInRoute = 'PaymentInRoute',
  ServiceIssue = 'ServiceIssue',
  Tenuous = 'Tenuous',
  ACQNew = 'ACQNew',
  ListBillProvider = 'ListBillProvider',
  FundingViaCheck = 'FundingViaCheck',
  FundingPaymentBuffer = 'FundingPaymentBuffer',
  ExecutiveApproved = 'ExecutiveApproved',
}

export enum InvestmentFinancialInstitution {
  InvestmentFinancialInstitution1 = 'InvestmentFinancialInstitution1',
  InvestmentFinancialInstitution2 = 'InvestmentFinancialInstitution2',
  Voya = 'Voya',
  Principal = 'Principal',
  Matrix = 'Matrix',
  Schwab = 'Schwab',
  BMO = 'BMO',
  VoyaAnnuity = 'VoyaAnnuity',
  BankOfOklahoma = 'BankOfOklahoma',
}

export enum TradeType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum TransactionCategory {
  Expenditure = 'Expenditure',
  Contribution = 'Contribution',
}

export enum HideFromType {
  None = 'None',
  All = 'All',
  ParticipantOnly = 'ParticipantOnly',
  ClientOnly = 'ClientOnly',
  ParticipantAndClientOnly = 'ParticipantAndClientOnly',
}

export enum PaymentSourceType {
  ACH = 'ACH',
  Check = 'Check',
  Wire = 'Wire',
  Card = 'Card',
  Trade = 'Trade',
}

export enum ClientViewEligibilityEventSummaryStatus {
  Draft = 'Draft',
  Offered = 'Offered',
  Responded = 'Responded',
  Expired = 'Expired',
  Active = 'Active',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Ineligible = 'Ineligible',
}

export enum ParentType {
  CLIENT = 'CLIENT',
  REQUEST_PAYMENT = 'REQUEST_PAYMENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  ENTRY = 'ENTRY',
  BENEFIT_ACCOUNT = 'BENEFIT_ACCOUNT',
  BENEFIT_PLAN = 'BENEFIT_PLAN',
  BUREAU = 'BUREAU',
  ADVANCE = 'ADVANCE',
  CARD = 'CARD',
  INSTANCE = 'INSTANCE',
  PROVIDER = 'PROVIDER',
}

export enum FilingStatusType {
  CCorp = 'CCorp',
  SCorp = 'SCorp',
  Partnership = 'Partnership',
  SoleProprietor = 'SoleProprietor',
  NonProfit = 'NonProfit',
  LLC = 'LLC',
  Other = 'Other',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum EmploymentInfoState {
  Active = 'Active',
  Inactive = 'Inactive',
  LeaveOfAbsence = 'LeaveOfAbsence',
  Removed = 'Removed',
  Retired = 'Retired',
  Start = 'Start',
  Terminated = 'Terminated',
  ProcessOnHold = 'ProcessOnHold',
  RemoveHold = 'RemoveHold',
}

export enum BenefitAccountState {
  Active = 'Active',
  ActiveNonDisbursable = 'ActiveNonDisbursable',
  ALOAWithBenefits = 'ALOAWithBenefits',
  ALOAWithoutBenefits = 'ALOAWithoutBenefits',
  Created = 'Created',
  Closed = 'Closed',
  Cobra = 'Cobra',
  Enrolled = 'Enrolled',
  Unenrolled = 'Unenrolled',
  Error = 'Error',
  Finalized = 'Finalized',
  Finalizing = 'Finalizing',
  FinalizingError = 'FinalizingError',
  GracePeriod = 'GracePeriod',
  IdVerificationPending = 'IdVerificationPending',
  TOUAgreementPending = 'TOUAgreementPending',
  Inactive = 'Inactive',
  Initiated = 'Initiated',
  PendingApproval = 'PendingApproval',
  PropagatingDateEdits = 'PropagatingDateEdits',
  Reconciliation = 'Reconciliation',
  Removed = 'Removed',
  RolloverComplete = 'RolloverComplete',
  RolloverProcessing = 'RolloverProcessing',
  RunOut = 'RunOut',
  Start = 'Start',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Upgrade = 'Upgrade',
  Conversion = 'Conversion',
}

export enum RecalculatePostingsOptionType {
  Recalculate = 'Recalculate',
  DoNotRecalculate = 'DoNotRecalculate',
}

export enum PaymentStatusType {
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Returned = 'Returned',
  Reversed = 'Reversed',
  Unpaid = 'Unpaid',
}

export enum ClientCardType {
  Standard = 'Standard',
  Custom = 'Custom',
}

export enum CardMailingOptionType {
  Participant = 'Participant',
}

export enum CardSecondLineType {
  ParticipantTASCID = 'ParticipantTASCID',
}

export enum AggregateType {
  SUM = 'SUM',
  COUNT = 'COUNT',
}

export enum EmployerType {
  PEO = 'PEO',
  ASO = 'ASO',
  MEWA = 'MEWA',
  JointEmployer = 'JointEmployer',
  IntegratedEmployer = 'IntegratedEmployer',
  ControlledGroup = 'ControlledGroup',
  GovernmentalEntity = 'GovernmentalEntity',
  Municipality = 'Municipality',
}

export enum CancellationReasonType {
  NLOBLackOfParticipation = 'NLOBLackOfParticipation',
  NLOBLackOfBenefit = 'NLOBLackOfBenefit',
  NLOBNoLongerQualifiesForThePlan = 'NLOBNoLongerQualifiesForThePlan',
  NLOBNoLongerInBusiness = 'NLOBNoLongerInBusiness',
  MTNAConsolidationOfServices = 'MTNAConsolidationOfServices',
  MTNAPrice = 'MTNAPrice',
  MTNADistributorBrokerRequest = 'MTNADistributorBrokerRequest',
  DWTCustomerService = 'DWTCustomerService',
  DWTPrice = 'DWTPrice',
  DWTDistributorBrokerRecord = 'DWTDistributorBrokerRecord',
  DWTTechnology = 'DWTTechnology',
  DWTOther = 'DWTOther',
  SAPPrice = 'SAPPrice',
  SAPChangeInPlanOfferings = 'SAPChangeInPlanOfferings',
  ICNonPayment = 'ICNonPayment',
  ICPlanNotEnacted = 'ICPlanNotEnacted',
  CancellationReasonUnknown = 'CancellationReasonUnknown',
  TascTermination = 'TascTermination',
  Other = 'Other',
}

export enum CarryoverCalculationMethodType {
  UseFullAvailableBalance = 'UseFullAvailableBalance',
  UseMaxAmount = 'UseMaxAmount',
}

export enum CustomizationPackageType {
  Standard = 'Standard',
  CoBrand = 'Co-Brand',
  Custom = 'Custom',
}

export enum AddressType {
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Other = 'Other',
}

export enum RequestState {
  Any = 'Any',
  Accepted = 'Accepted',
  AreTherePRsRemaining = 'AreTherePRsRemaining',
  AwaitingCustomerInput = 'AwaitingCustomerInput',
  Denied = 'Denied',
  Draft = 'Draft',
  Error = 'Error',
  IsClassified = 'IsClassified',
  IsPaid = 'IsPaid',
  IsValid = 'IsValid',
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Pending = 'Pending',
  PendingAdmin = 'PendingAdmin',
  PendingSettlement = 'PendingSettlement',
  PotentialDuplicate = 'PotentialDuplicate',
  Removed = 'Removed',
  Returned = 'Returned',
  Start = 'Start',
  Submitted = 'Submitted',
  UnpaidFinalized = 'UnpaidFinalized',
  CanAutoPay = 'CanAutoPay',
  CustomerInputReq = 'CustomerInputReq',
  ManualProcessingNeeded = 'ManualProcessingNeeded',
  PaidCustomerInputReq = 'PaidCustomerInputReq',
  PaidVerificationReq = 'PaidVerificationReq',
  PendingHold = 'PendingHold',
  PendingPayment = 'PendingPayment',
  PendingVerified = 'PendingVerified',
  VerificationReq = 'VerificationReq',
}

export enum AccountType {
  BAB = 'BAB',
  BCA = 'BCA',
  BCTA = 'BCTA',
  BTCA = 'BTCA',
  BEA = 'BEA',
  BFA = 'BFA',
  BINPA = 'BINPA',
  CAB = 'CAB',
  CARD_FORCED = 'CARD_FORCED',
  CCTA = 'CCTA',
  CPFA = 'CPFA',
  COCTA = 'COCTA',
  IAB = 'IAB',
  IOA = 'IOA',
  IOAB = 'IOA-B',
  PCTA = 'PCTA',
  PPTAB = 'PPTAB',
  PPTAB_ADVANCE = 'PPTAB_ADVANCE',
  PPSEA = 'PPSEA',
  PSEA = 'PSEA',
  PSIA = 'PSIA',
  PPSIA = 'PPSIA',
  PYAB = 'PYAB',
  TOA = 'TOA',
  IOADEDUCTIBLE = 'IOA-DEDUCTIBLE',
  IOAPAYOUT = 'IOA-PAYOUT',
  TPOOA = 'TPOOA',
}

export enum PostingType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum FrequencyType {
  OneTime = 'OneTime',
  Annual = 'Annual',
  Recurring = 'Recurring',
}

export enum SoaEntryType {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum EntryState {
  Entered = 'Entered',
  NeedsProcessing = 'NeedsProcessing',
  ProcessingError = 'ProcessingError',
  Published = 'Published',
  Scheduled = 'Scheduled',
  Settled = 'Settled',
  Start = 'Start',
  Removed = 'Removed',
  NotPublished = 'NotPublished',
  PendingPublished = 'PendingPublished',
  Reversed = 'Reversed',
  Rejected = 'Rejected',
  Enrich = 'Enrich',
  Refunded = 'Refunded',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Error = 'Error',
  EnrichError = 'EnrichError',
  ReversalPublished = 'ReversalPublished',
  NoPostings = 'NoPostings',
}

export enum PostingState {
  Entered = 'Entered',
  NeedsProcessing = 'NeedsProcessing',
  ProcessingError = 'ProcessingError',
  Start = 'Start',
  Removed = 'Removed',
}

export enum FundingSourceType {
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
}

export enum EntryType {
  ACHConvenienceFee = 'ACHConvenienceFee',
  ACHConvenienceFeeSweepAutomated = 'ACHConvenienceFeeSweepAutomated',
  ACHConvenienceFeeSweepManual = 'ACHConvenienceFeeSweepManual',
  ATMWithdraw = 'ATMWithdraw',
  BenefitAccountBalanceAdjustment = 'BenefitAccountBalanceAdjustment',
  BenefitAccountBalanceAdjustmentBureauContribution = 'BenefitAccountBalanceAdjustmentBureauContribution',
  BenefitAccountBalanceAdjustmentUnfunded = 'BenefitAccountBalanceAdjustmentUnfunded',
  BenefitAccountBalanceTransfer = 'BenefitAccountBalanceTransfer',
  BenefitPlanFinalizationToCab = 'BenefitPlanFinalizationToCab',
  BureauContributionDraw = 'BureauContributionDraw',
  BureauDisbursementDraw = 'BureauDisbursementDraw',
  BureauInterestPayment = 'BureauInterestPayment',
  BuyTradeSettlement = 'BuyTradeSettlement',
  CABBalanceAdjustment = 'CABBalanceAdjustment',
  CardConvenienceFee = 'CardConvenienceFee',
  CardConvenienceFeeSweepAutomated = 'CardConvenienceFeeSweepAutomated',
  CardConvenienceFeeSweepManual = 'CardConvenienceFeeSweepManual',
  CardDisbursement = 'CardDisbursement',
  CardForced = 'CardForced',
  CardRequestPayment = 'CardRequestPayment',
  CardRefund = 'CardRefund',
  CashInvestmentContribution = 'CashInvestmentContribution',
  ClientBenefitPlanFinalization = 'ClientBenefitPlanFinalization',
  ClientBureauContribution = 'ClientBureauContribution',
  ClientCABContribution = 'ClientCABContribution',
  ClientCABFunding = 'ClientCABFunding',
  ClientCommitmentFinalization = 'ClientCommitmentFinalization',
  ClientContribution = 'ClientContribution',
  ClientFeeAssessment = 'ClientFeeAssessment',
  ClientFeeAssessmentSweepAutomated = 'ClientFeeAssessmentSweepAutomated',
  ClientFeeAssessmentSweepManual = 'ClientFeeAssessmentSweepManual',
  ClientFunding = 'ClientFunding',
  ClientFundingConversion = 'ClientFundingConversion',
  ClientNegativeFunding = 'ClientNegativeFunding',
  ClientPodFunding = 'ClientPodFunding',
  ClientRemittanceAccountNegativeAdjustment = 'ClientRemittanceAccountNegativeAdjustment',
  ClientRemittanceAccountPositiveAdjustment = 'ClientRemittanceAccountPositiveAdjustment',
  ClientRemittanceDistribution = 'ClientRemittanceDistribution',
  ConvenienceFee = 'ConvenienceFee',
  DesignatedHoldingAccountChargeback = 'DesignatedHoldingAccountChargeback',
  DesignatedHoldingAccountNegativeAdjustment = 'DesignatedHoldingAccountNegativeAdjustment',
  DesignatedHoldingAccountPositiveAdjustment = 'DesignatedHoldingAccountPositiveAdjustment',
  DisburseFromCab = 'DisburseFromCab',
  DisburseFromPayee = 'DisburseFromPayee',
  DisbursementPreSettlement = 'DisbursementPreSettlement',
  DisbursementPreSettlementRejection = 'DisbursementPreSettlementRejection',
  DisbursementRejection = 'DisbursementRejection',
  DisbursementSettlement = 'DisbursementSettlement',
  DisbursementSettlementRejection = 'DisbursementSettlementRejection',
  DisburseToPayee = 'DisburseToPayee',
  DisburseToPaymentSource = 'DisburseToPaymentSource',
  FeeChargeback = 'FeeChargeback',
  FinancialFileRejection = 'FinancialFileRejection',
  FundedConversionRolloverContribution = 'FundedConversionRolloverContribution',
  FundFromCAB = 'FundFromCAB',
  FundingPreSettlement = 'FundingPreSettlement',
  FundingPreSettlementRejection = 'FundingPreSettlementRejection',
  FundingRejection = 'FundingRejection',
  FundingSettlement = 'FundingSettlement',
  FundingSettlementRejection = 'FundingSettlementRejection',
  HSAOrphanFeeAssessment = 'HSAOrphanFeeAssessment',
  HSAOrphanFeeSweepAutomated = 'HSAOrphanFeeSweepAutomated',
  HSAOrphanFeeSweepManual = 'HSAOrphanFeeSweepManual',
  HsaTransfer = 'HsaTransfer',
  IndividualFeeAssessment = 'IndividualFeeAssessment',
  InvestmentBuyBureau = 'InvestmentBuyBureau',
  InvestmentBuyParticipant = 'InvestmentBuyParticipant',
  InvestmentSellBureau = 'InvestmentSellBureau',
  InvestmentSellParticipant = 'InvestmentSellParticipant',
  InvestmentSweep = 'InvestmentSweep',
  InvestmentSweepBuyFix = 'InvestmentSweepBuyFix',
  InvestmentSweepSellFix = 'InvestmentSweepSellFix',
  ManualRefund = 'ManualRefund',
  ManualRequestPayment = 'ManualRequestPayment',
  ManualRequestRepayment = 'ManualRequestRepayment',
  ManualRequestReturn = 'ManualRequestReturn',
  MyCashBalanceAdjustment = 'MyCashBalanceAdjustment',
  MyCashContribution = 'MyCashContribution',
  MyCashFeeAssessment = 'MyCashFeeAssessment',
  MyCashFeeSweepAutomated = 'MyCashFeeSweepAutomated',
  MyCashFeeSweepManual = 'MyCashFeeSweepManual',
  MyCashManualRefund = 'MyCashManualRefund',
  MycashThresholdDisbursement = 'MycashThresholdDisbursement',
  OperationalContribution = 'OperationalContribution',
  OperationalDeductibleContribution = 'OperationalDeductibleContribution',
  OperationalDeductibleDisbursement = 'OperationalDeductibleDisbursement',
  OperationalDisbursement = 'OperationalDisbursement',
  OperationalPayoutContribution = 'OperationalPayoutContribution',
  OperationalPayoutDisbursement = 'OperationalPayoutDisbursement',
  ParticipantAccountTransfer = 'ParticipantAccountTransfer',
  ParticipantAdvanceContribution = 'ParticipantAdvanceContribution',
  ParticipantAdvanceFunding = 'ParticipantAdvanceFunding',
  ParticipantAdvanceReplenishment = 'ParticipantAdvanceReplenishment',
  ParticipantBalanceFinalization = 'ParticipantBalanceFinalization',
  ParticipantBenefitPlanFinalization = 'ParticipantBenefitPlanFinalization',
  ParticipantBureauContribution = 'ParticipantBureauContribution',
  ParticipantCommitmentFinalization = 'ParticipantCommitmentFinalization',
  ParticipantContribution = 'ParticipantContribution',
  ParticipantDirectCobraFunding = 'ParticipantDirectCobraFunding',
  ParticipantDirectFunding = 'ParticipantDirectFunding',
  ParticipantDirectFundingConversion = 'ParticipantDirectFundingConversion',
  ParticipantFeeAssessment = 'ParticipantFeeAssessment',
  ParticipantHoldingAccountTransfer = 'ParticipantHoldingAccountTransfer',
  ParticipantInterestCreditSecondaryBureau = 'ParticipantInterestCreditSecondaryBureau',
  ParticipantPaymentAccountContribution = 'ParticipantPaymentAccountContribution',
  ParticipantPaymentAccountContributionReversal = 'ParticipantPaymentAccountContributionReversal',
  ParticipantPaymentGroupCardFunding = 'ParticipantPaymentGroupCardFunding',
  ParticipantPaymentGroupFunding = 'ParticipantPaymentGroupFunding',
  ParticipantPaymentGroupRefund = 'ParticipantPaymentGroupRefund',
  ParticipantRollover = 'ParticipantRollover',
  ParticipantToClientFunding = 'ParticipantToClientFunding',
  PayeeRemittanceAccountNegativeAdjustment = 'PayeeRemittanceAccountNegativeAdjustment',
  PayeeRemittanceAccountPositiveAdjustment = 'PayeeRemittanceAccountPositiveAdjustment',
  PayeeRemittanceDistribution = 'PayeeRemittanceDistribution',
  PaymentAdjustment = 'PaymentAdjustment',
  PayrollFundingPlanRollover = 'PayrollFundingPlanRollover',
  PayrollPosting = 'PayrollPosting',
  PaymentAccountClientBaseRemittance = 'PaymentAccountClientBaseRemittance',
  PaymentAccountClientBaseRemittanceReversal = 'PaymentAccountClientBaseRemittanceReversal',
  PaymentAccountClientFeeRemittance = 'PaymentAccountClientFeeRemittance',
  PaymentAccountClientFeeRemittanceReversal = 'PaymentAccountClientFeeRemittanceReversal',
  PaymentAccountClientSurchargeRemittance = 'PaymentAccountClientSurchargeRemittance',
  PaymentAccountClientSurchargeRemittanceReversal = 'PaymentAccountClientSurchargeRemittanceReversal',
  PaymentAccountClientUnremittance = 'PaymentAccountClientUnremittance',
  PaymentAccountPayeeBaseRemittance = 'PaymentAccountPayeeBaseRemittance',
  PaymentAccountPayeeBaseRemittanceReversal = 'PaymentAccountPayeeBaseRemittanceReversal',
  PaymentAccountPayeeFeeRemittance = 'PaymentAccountPayeeFeeRemittance',
  PaymentAccountPayeeFeeRemittanceReversal = 'PaymentAccountPayeeFeeRemittanceReversal',
  PaymentAccountPayeeSurchargeRemittance = 'PaymentAccountPayeeSurchargeRemittance',
  PaymentAccountPayeeSurchargeRemittanceReversal = 'PaymentAccountPayeeSurchargeRemittanceReversal',
  PaymentAccountPayeeUnremittance = 'PaymentAccountPayeeUnremittance',
  PaymentPlanAdminFee = 'PaymentPlanAdminFee',
  PaymentPlanAdminFeeReversal = 'PaymentPlanAdminFeeReversal',
  PaymentPlanAdminFeeSweepAutomated = 'PaymentPlanAdminFeeSweepAutomated',
  PaymentPlanAdminFeeSweepManual = 'PaymentPlanAdminFeeSweepManual',
  PaymentPlanAdminSurcharge = 'PaymentPlanAdminSurcharge',
  PaymentPlanAdminSurchargeReversal = 'PaymentPlanAdminSurchargeReversal',
  PaymentPlanAdminUnremittance = 'PaymentPlanAdminUnremittance',
  PaymentPlanNSFFeeSweepAutomated = 'PaymentPlanNSFFeeSweepAutomated',
  PaymentPlanNSFFeeSweepManual = 'PaymentPlanNSFFeeSweepManual',
  PaymentRejection = 'PaymentRejection',
  PaymentRejectionFee = 'PaymentRejectionFee',
  PodFundingPlanRollover = 'PodFundingPlanRollover',
  PreAuthorizationHold = 'PreAuthorizationHold',
  PreprocessedDisbursementApproved = 'PreprocessedDisbursementApproved',
  PushToDebitCard = 'PushToDebitCard',
  RemittanceStagingAccountNegativeAdjustment = 'RemittanceStagingAccountNegativeAdjustment',
  RemittanceStagingAccountPositiveAdjustment = 'RemittanceStagingAccountPositiveAdjustment',
  Reversal = 'Reversal',
  SellOrderContribution = 'SellOrderContribution',
  SellOrderContributionDraw = 'SellOrderContributionDraw',
  SellOrderFunding = 'SellOrderFunding',
  SellOrderSettlement = 'SellOrderSettlement',
  SweepBinPayeeAccount = 'SweepBinPayeeAccount',
  SweepPaymentProcessorBinAccount = 'SweepPaymentProcessorBinAccount',
  TakeoverDisbursement = 'TakeoverDisbursement',
  TakeoverOperationalDeductibleContribution = 'TakeoverOperationalDeductibleContribution',
  TakeoverOperationalDeductibleDisbursement = 'TakeoverOperationalDeductibleDisbursement',
  TakeoverOperationalPayoutContribution = 'TakeoverOperationalPayoutContribution',
  TakeoverOperationalPayoutDisbursement = 'TakeoverOperationalPayoutDisbursement',
  UndesignatedHoldingAccountChargeback = 'UndesignatedHoldingAccountChargeback',
  UndesignatedHoldingAccountNegativeAdjustment = 'UndesignatedHoldingAccountNegativeAdjustment',
  UndesignatedHoldingAccountPositiveAdjustment = 'UndesignatedHoldingAccountPositiveAdjustment',
  UndesignatedParticipantPaymentGroupCardFunding = 'UndesignatedParticipantPaymentGroupCardFunding',
  UndesignatedParticipantPaymentGroupFunding = 'UndesignatedParticipantPaymentGroupFunding',
  UndesignatedParticipantPaymentGroupRefund = 'UndesignatedParticipantPaymentGroupRefund',
  UnfundedConversionRolloverContribution = 'UnfundedConversionRolloverContribution',
}

export enum FundsTransferSourceType {
  PaymentSource = 'PaymentSource',
  IAB = 'IAB',
  ParticipantDirectFundingBenefitPlan = 'ParticipantDirectFundingBenefitPlan',
  GiveBackAccount = 'GiveBackAccount',
  CAB = 'CAB',
  PPTAB = 'PPTAB',
  BenefitAccountForDisburseWithoutRequest = 'BenefitAccountForDisburseWithoutRequest',
}

export enum FundsTransferDestinationType {
  PaymentSource = 'PaymentSource',
  IAB = 'IAB',
  ParticipantDirectFundingBenefitPlan = 'ParticipantDirectFundingBenefitPlan',
  ParticipantDirectFundingInvestmentBenefitAccount = 'ParticipantDirectFundingInvestmentBenefitAccount',
  Check = 'Check',
  BenefitAccount = 'BenefitAccount',
  InvestmentBenefitAccount = 'InvestmentBenefitAccount',
  ParticipantToClientInvestment = 'ParticipantToClientInvestment',
  ClientDirectInvestment = 'ClientDirectInvestment',
  ParticipantDirectInvestment = 'ParticipantDirectInvestment',
  ClientToPlanInvestment = 'ClientToPlanInvestment',
  ParticipantToClient = 'ParticipantToClient',
  ClientDirect = 'ClientDirect',
  ParticipantDirect = 'ParticipantDirect',
  ClientToPlan = 'ClientToPlan',
  Charity = 'Charity',
  BFA = 'BFA',
}

export enum AllowedRecalculationType {
  Always = 'Always',
  OnElectionChange = 'OnElectionChange',
  Never = 'Never',
}
