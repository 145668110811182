import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ErrorNotification } from '../models/clux/model';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotificationService {
    public errorNotification = new Subject<ErrorNotification>();
    public notifyErrorOthers(notification: ErrorNotification): void {
        this.errorNotification.next(notification);
    }
}
