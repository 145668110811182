import { BenefitAccountState, EntryType, ParticipantEntry } from '@models/dashboard/model';
import { ElectionProfileType, EntryState } from '../../../shared/models/uba/account/model';
import { BenefitPlanFundingSourceAndSchedule, ContributionPostingType, FundingPostingType, FundingSourceType } from '../../../shared/models/uba/configuration/model';
import { EntryDetails, RowEntries, VerifyPostingGroupingType, VerifyPostingItemViewModel } from '../model/verify-posting-view-model';
export class VerifyPostingGridViewModelMapper {
  public static mapDBModelToUIModel(
    entities: ParticipantEntry[],
    scheduleMap: Map<string, BenefitPlanFundingSourceAndSchedule[]>,
    groupingType: VerifyPostingGroupingType,
  ): VerifyPostingItemViewModel[] {
    const result: VerifyPostingItemViewModel[] = [];
    for (const entity of entities) {
      const entries: RowEntries = {
        benefitAccountId: entity.benefitAccountId,
        planId: entity.planId,
        planName: entity.planName,
        accountInPropagatingDateEdits: entity.benefitAccountState === BenefitAccountState.PropagatingDateEdits,
      };

      if (entity.employerEntryStates) {
        const clientAmount = this.getClientAmount(entity, scheduleMap);
        if (clientAmount !== null) {
          const clientEntry: EntryDetails = {
            entryId: entity.employerEntryIds[0],
            amount: clientAmount,
            loading: false,
            editable: entity.employerEntryStates.every((state) => state === EntryState.Scheduled),
            electionProfileType: ElectionProfileType.Client,
            hasError: false,
          };
          entries.client = clientEntry;
        }
      }

      if (entity.employeeEntryStates) {
        const participantEntry: EntryDetails = {
          entryId: entity.employeeEntryIds[0],
          amount: entity.participantDeductionAmount,
          loading: false,
          editable: entity.employeeEntryStates.every((state) => state === EntryState.Scheduled),
          electionProfileType: ElectionProfileType.Individual,
          hasError: false,
        };
        entries.participant = participantEntry;
      }

      if (entries.client || entries.participant) {
        const existingItem = result.find((x) =>
          (groupingType === VerifyPostingGroupingType.ScheduledDate && x.individualExternalId === entity.individualExternalId) ||
          (groupingType === VerifyPostingGroupingType.Individual && x.scheduledDate === entity.scheduledDate),
        );
        if (existingItem) {
          existingItem.entries.push(entries);
          existingItem.clientTotal += entries.client?.amount || 0;
          existingItem.participantTotal += entries.participant?.amount || 0;
        } else {
          result.push({
            entries: [entries],
            clientTotal: entries.client?.amount || 0,
            participantTotal: entries.participant?.amount || 0,
            scheduledDate: entity.scheduledDate,
            actionRequired: false,
            fullName: entity.fullName,
            individualId: entity.individualId,
            individualExternalId: entity.individualExternalId,
            expanded: false,
          });
        }
      }
    }

    return result;
  }

  private static getClientAmount(entity: ParticipantEntry, scheduleMap: Map<string, BenefitPlanFundingSourceAndSchedule[]>): number | null {
    const schedules = scheduleMap.get(entity.planId);
    if (!schedules) {
      return null;
    }

    const hasContribution = entity.employerEntryTypes.some((et) => [EntryType.ClientContribution, EntryType.ClientBureauContribution].includes(et));
    const hasFunding = entity.employerEntryTypes.some((et) => [EntryType.ClientFunding, EntryType.ClientNegativeFunding].includes(et));
    const schedule = schedules.find((s) => [FundingSourceType.ClientDirect, FundingSourceType.ClientToPlan].includes(s.fundingSource));
    if (
      hasFunding &&
      schedule &&
      schedule.contributionPosting === ContributionPostingType.AnnualSchedule &&
      [FundingPostingType.PayrollSchedule, FundingPostingType.CustomOther].includes(schedule.fundingPosting)
    ) {
      return entity.clientFundingAmount;
    }

    if (hasContribution) {
      return entity.clientContributionAmount;
    }

    return null;
  }
}
