import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { Client, Contact, ContactState, MatchType, SearchCriteria } from '@models/profile/model';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ClientStore, ContactStore } from 'src/app/state';

import { CoreService } from '../models/clux/enum';
import { Uri } from '../utils/uri';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public constructor(
    private clientStore: ClientStore,
    private contactStore: ContactStore,
    private http: HttpClient,
  ) {}

  /**
   * Retrieve the contacts having the specified email and store in Akita.
   * There will be more than one contact when the email is associated with a distributor.
   * @param email The email address of the contact
   */
  public loadContacts(email: string): Observable<void> {
    if (!email) {
      throw new Error('contact email not specified');
    }

    const criteria: SearchCriteria = [
      {
        key: 'email',
        value: email,
        matchType: MatchType.EXACT,
      },
      {
        key: 'currentState',
        value: ContactState.Active,
        matchType: MatchType.EXACT,
      },
    ];

    const uri = new Uri('/profile/profileType/contact/search', CoreService.Profile);
    return this.http.post<Contact[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((contacts) => this.contactStore.set(contacts)),
        mapTo(null),
      );
  }

  /**
   * Retrieve the contact having the specified ID and store in Akita.
   * @param contactId The contact ID
   */
  public loadContact(contactId: string): Observable<void> {
    if (!contactId) {
      throw new Error('contactId not specified');
    }
    const uri = new Uri(`/profile/${contactId}/profileType/contact`, CoreService.Profile);
    return this.http.get<Contact>(uri.toString())
      .pipe(
        withTransaction((contact) => {
          if (contact) {
            this.contactStore.upsert(contact.id, contact);
          }
        }),
        mapTo(null),
      );
  }

  /**
   * Retrieve the contacts belonging to the specified client and store in Akita.
   * This will replace any existing contacts that are in cache.
   * @param clientId The client ID
   */
  public loadContactsForClient(clientId: string): Observable<void> {
    const criteria: SearchCriteria = [
      {
        key: 'clientId',
        value: clientId,
        matchType: MatchType.EXACT,
      },
    ];

    const uri = new Uri('/profile/profileType/contact/search', CoreService.Profile);
    return this.http.post<Contact[]>(uri.toString(), criteria)
      .pipe(
        withTransaction((contacts) => this.contactStore.set(contacts)),
        mapTo(null),
      );
  }

  /**
   * Retrieve the client having the specified ID, store in Akita, and set it as active.
   * @param contactEmail The client ID
   */
  public loadClient(clientId: string): Observable<void> {
    if (!clientId) {
      throw new Error('clientId not specified');
    }

    const uri = new Uri(`/profile/${clientId}/profileType/client`, CoreService.Profile);
    return this.http.get<Client>(uri.toString())
      .pipe(
        withTransaction((client) => {
          if (client) {
            this.clientStore.set([client]);
            this.clientStore.setActive(client.id);
          } else {
            this.clientStore.set([]);
          }
        }),
        mapTo(null),
      );
  }
}
