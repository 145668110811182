import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PayrollSchedule } from '@models/profileConfiguration/model';

export interface PayrollScheduleState extends EntityState<PayrollSchedule, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'PayrollSchedule' })
export class PayrollScheduleStore extends EntityStore<PayrollScheduleState> {
  public constructor() {
    super();
  }
}
