import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { stringFormat } from '@brycemarshall/string-format';
import {
  ChainType,
  DistributionMethodType,
  MatchType,
  MessageCommandType,
  MessageStatusType,
} from '@models/communication/model';
import { Contact } from '@models/profile/model';
import { Buffer } from 'buffer';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonConstants } from 'src/app/shared/constants/common.constant';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ServiceFactory } from 'src/app/shared/services/service.factory';
import { Dates } from 'src/app/shared/utils/dates';
import { ContactQuery } from 'src/app/state';

@Component({
  selector: 'app-alerts-window',
  templateUrl: './alerts-window.component.html',
  styleUrls: ['./alerts-window.component.scss'],
})
export class AlertsWindowComponent implements OnInit, OnDestroy {
  @Input() public showWindow: boolean;
  @Output() public closeAlert: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public unreadCount: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('closeBtn') public closeBtn: ElementRef;
  public windowUnreadCount: number = 0;
  public alertsData: Model.AlertData[];
  public showSeeMoreLoader: boolean = false;
  public showListLoader: boolean = true;
  public showSeeMore: boolean = true;
  public contact: Contact;
  private requiredSize: number = 5;
  private diameter: number = 10;
  private isMessageLoaded: boolean = false;
  private alertDataSubscription: Subscription;
  private unreadCountSubscription: Subscription;
  private alertServiceSubscription: Subscription;

  public constructor(
    private contactQuery: ContactQuery,
    private serviceFactory: ServiceFactory,
    private alertService: AlertService,
  ) {
    this.contact = this.contactQuery.getActive();
  }

  public ngOnInit(): void {
    this.alertsData = [];
    this.getUnreadCount();
    this.getAlertsData();
  }

  public ngOnDestroy(): void {
    if (this.alertDataSubscription) {
      this.alertDataSubscription.unsubscribe();
    }
    if (this.unreadCountSubscription) {
      this.unreadCountSubscription.unsubscribe();
    }
    if (this.alertServiceSubscription) {
      this.alertServiceSubscription.unsubscribe();
    }
  }

  /**
   * Close alerts on clicking cross
   */
  public closeAlerts(): void {
    this.alertsData.forEach((element) => {
      element.isMessageShow = false;
    });
    this.closeAlert.emit(true);
  }

  /**
   * Load next five alerts after clicking see more
   */
  public onSeeMore(event: MouseEvent): void {
    event.stopPropagation();
    this.showSeeMoreLoader = true;
    this.requiredSize += 5;
    this.getAlertsData();
  }

  /**
   * this method fetches alert details for selected alert and marks it as read
   * @param alert = alert object
   */
  public showAlertDetail(alert: Model.AlertData): void {
    alert.isMessageShow = !alert.isMessageShow;
    if (!alert.isMessageLoaded) {
      if (alert.unread) {
        this.windowUnreadCount--;
        this.unreadCount.emit(this.windowUnreadCount);
      }

      // getAlertsDetails request params
      const serviceKey: string = CommonConstants.getPath.communication.serviceKey;
      const basePath: string = stringFormat(
        CommonConstants.getPath.communication.alertsDetailsURI,
        {
          profileId: this.contact.parentId,
          messageId: alert.alertId,
        },
      );

      this.alertServiceSubscription = this.alertService
        .getAlertsDetails(serviceKey, basePath)
        .pipe(
          switchMap((response) => {
            alert.isMessageLoaded = true;
            alert.messageContent = this.decodeMessage(response.messageContent);
            /**
             * Mark alert as Read if unread
             */
            if (alert.unread) {
              // updateReadMessage request params
              alert.unread = false;
              alert.alertObject.currentState = MessageStatusType.Read;
              alert.alertObject.lastTransition = MessageCommandType.DeliveredToRead;
              const markAsReadServiceKey: string =
                CommonConstants.getPath.communication.serviceKey;
              const markAsReadBasePath: string = stringFormat(
                CommonConstants.getPath.communication.alertsMarkAsReadURI,
                {
                  profileId: this.contact.parentId,
                  messageId: alert.alertId,
                  commandName: MessageCommandType.DeliveredToRead,
                },
              );
              const markAsReadRequestPayload: Model.MarkAsRead = {
                data: alert.alertObject,
                createdById: this.contact.id,
                createdBy: this.contact.firstName + ' ' + this.contact.lastName,
                created: Dates.now(),
                producerId: this.contact.clientId,
                id: this.contact.id,
                type: MessageCommandType.DeliveredToRead,
                eventCorrelationId: alert.alertObject.activityId,
              };
              return this.alertService.updateReadMessage(
                markAsReadServiceKey,
                markAsReadBasePath,
                markAsReadRequestPayload,
              );
            } else {
              return new Observable<string>();
            }
          }),
        )
        .subscribe();
    }
  }

  /*
  Set focus to the alert modal after giving it a short timeout to render.
  This is somewhat hacky, but there doesn't seem to be a better way to do it given how the markup is structured and where the alert modal is in the DOM.
  */
  public focus(): void {
    const self = this;
    setTimeout((): void => {
      if (self.closeBtn) {
        self.closeBtn.nativeElement.focus();
      }
    }, 100);
  }

  /**
   * this method fetches unread count
   */
  private getUnreadCount(): void {
    const requestParams: Model.AlertRequestParams = this.prepareRequest('fetchUnreadCount');
    this.unreadCountSubscription = this.alertService
      .getAlertsData(
        requestParams.requestPayload,
        requestParams.searchParams,
        this.contact.parentId,
      )
      .subscribe((response) => {
        this.windowUnreadCount = response.totalCount;
        this.unreadCount.emit(this.windowUnreadCount);
      });
  }

  /**
   * this method fetches alert messages
   */
  private getAlertsData(): void {
    const requestParams: Model.AlertRequestParams = this.prepareRequest('fetchNextFiveAlerts');
    this.alertDataSubscription = this.alertService
      .getAlertsData(
        requestParams.requestPayload,
        requestParams.searchParams,
        this.contact.parentId,
      )
      .subscribe((response) => {
        this.showSeeMoreLoader = false;
        this.showListLoader = false;
        const result: Model.AlertData[] = response.data.map((message) => ({
          alertId: message.id,
          alertMessage: message.subject,
          alertDateTime: message.created,
          unread: message.currentState === 'Delivered',
          isMessageLoaded: false,
          isMessageShow: false,
          alertObject: message,
          messageContent: '',
        }));
        if (result.length < 5) {
          this.showSeeMore = false;
        }
        this.alertsData.push(...result);
      });
  }

  /**
   * this method prepares request params for getUnreadCount and getAlertsData
   * @param purpose fetchUnreadCount or fetchNextFiveAlerts
   */
  private prepareRequest(purpose: string): Model.AlertRequestParams {
    const requestPayload: Model.SearchCriteria = [
      {
        key: 'recipientId',
        value: this.contact.tascId,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'recipientType',
        value: this.contact.contactType,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
      {
        key: 'distributionMethodType',
        value: DistributionMethodType.TASCMessageCenter,
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      },
    ];
    if (purpose === 'fetchUnreadCount') {
      requestPayload.push({
        key: 'currentState',
        value: 'Delivered',
        matchType: MatchType.EXACT,
        chainType: ChainType.AND,
      });
    }
    const searchParams = {
        take: purpose === 'fetchUnreadCount' ? 1 : 5,
        skip: purpose === 'fetchUnreadCount' ? 0 : this.requiredSize - 5,
        orderBy: 'created',
        orderDirection: 'desc',
    };
    const getTotalCount = true;
    return {
      requestPayload,
      searchParams,
    };
  }

  private decodeMessage(messageContent: string): string {
    return Buffer.from(messageContent, 'base64').toString();
  }
}
