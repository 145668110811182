import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { errorTypeMap } from '../../mappers';

import { ErrorNotificationService } from '../../services/error-notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(
    private raygunService: RaygunService,
    private errorNotificationService: ErrorNotificationService,
  ) { }

  // tslint:disable-next-line:no-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err) => {
          const serverErrortext = this.logRaygunError(err, request);
          const error: string = serverErrortext ? serverErrortext : err.message || err.error.message || err.statusText;
          if (err && err.url && err.url.indexOf('/createNetSuite') >= 0) {
            return throwError(error);
          }
          setTimeout(() => { // wait for everything to complete.
            const errorMap = this.getErrorMap(err);
            if (typeof errorMap === 'object') {
              Object.keys(this.getErrorMap(err)).forEach((errorKey) => {
                this.errorNotificationService.notifyErrorOthers({ status: err.status, type: errorTypeMap[errorKey] });
              });
            } else {
              this.errorNotificationService.notifyErrorOthers({ status: 500 });
            }
          }, 500);
          return throwError(error);
        }),
      );
  }

  // tslint:disable-next-line:no-any
  private logRaygunError(err: any, request: HttpRequest<any>): string {
    const serverErrorObj = this.getErrorMap(err);
    let serverErrortext: string;
    let token: string;
    if (request.headers && request.headers) {
      token = request.headers.get('authorization');
    }
    if (serverErrorObj && serverErrorObj instanceof Object && Object.keys(serverErrorObj).length > 0 && err.status !== 0) {
      serverErrortext = Object.keys(serverErrorObj)[0] + ': ' + Object.values(serverErrorObj)[0];
      this.raygunService.logError(serverErrortext, { err, token });
    } else {
      this.raygunService.logError({ err, token });
    }
    return serverErrortext;
  }

  // tslint:disable-next-line:no-any
  private getErrorMap(err: Record<string, any>): Record<string, string> {
    return err?.error?.error || err?.error?.errors || err.error;
  }
}
