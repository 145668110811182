import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EligibleEligibilityEvent } from '@models/profileConfiguration/model';

export interface EligibleEligibilityEventState extends EntityState<EligibleEligibilityEvent, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'EligibleEligibilityEvent', idKey: 'eligibilityEventId' })
export class EligibleEligibilityEventStore extends EntityStore<EligibleEligibilityEventState> {
  public constructor() {
    super();
  }
}
