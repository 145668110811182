import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appRouteTransform]',
})
export class RouteTransformDirective {

  public constructor(private el: ElementRef, private router: Router) { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    const elem = event.target as HTMLElement;
    if (elem.tagName === 'A') {
      this.router.navigate([elem.getAttribute('href')]).catch((reason) => {
        throw reason;
      });
      event.preventDefault();
    } else {
      return;
    }
  }

}
