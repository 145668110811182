import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { union } from 'lodash';
import { GroupPermissionModel } from '../model';
import { GroupPermissionState, GroupPermissionStore } from './group-permission.store';

@Injectable({
  providedIn: 'root',
})
export class GroupPermissionQuery extends QueryEntity<GroupPermissionState> {
  public constructor(protected store: GroupPermissionStore) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<GroupPermissionModel[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public getAllowedPaths(): Observable<string[]> {
    return this.selectAllWhenLoaded()
      .pipe(
        map((groupPermissions) => {
          if (!groupPermissions || !groupPermissions.length || !groupPermissions[0]) {
            return [];
          }
          const allowedPaths = groupPermissions.map((groupPermission) => groupPermission.allowedActions.sections[0].queryPaths);
          return union.apply(null, allowedPaths) as string[];
        }),
      );
  }
}
