export function parseRoute(route: string): string {
  const parseUUID = (s: string) => s.replace(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g, ':id');
  const parseExternalId = (s: string) => s.replace(/[0-9]{10}/g, ':id');
  const stripQueryParams = (s: string) => s.replace(/\?.*/g, '');

  return parseExternalId(parseUUID(stripQueryParams(route)));
}

export function mapAllowedUrls(allowedUrls: string[]): string[] {
  return allowedUrls.map((url) => url.replace(/{[^\/]*}/g, ':id'));
}
