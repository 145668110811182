import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { RaygunService } from 'src/app/raygun/raygun.service';
import { SessionStorage } from 'src/app/shared/models/clux/enum';
import { BrowserSessionStorageService } from 'src/app/shared/services/browser-session-storage.service';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })

export class AuthDistributorGuard implements CanActivate {

  public constructor(
    private authenticationService: AuthenticationService,
    private browserSessionStorageService: BrowserSessionStorageService,
    private raygunService: RaygunService,
    private router: Router,
    ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(this.authenticationService.refreshSession())
      .pipe(
        switchMap((refreshSessionResult) => {
          if (!refreshSessionResult || !refreshSessionResult.isValidSession) {
            this.browserSessionStorageService.set(SessionStorage.Url, _state.url);
            return of(this.router.parseUrl('/logout'));
          }
          return of(true);
        }),
        catchError((error) => {
          this.raygunService.logError('Error in auth-distributor.guard.ts:canActivate()', { error });
          return of(this.router.parseUrl('/logout'));
        }),
      );
    }
}
