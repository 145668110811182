import { Injectable } from '@angular/core';
import { EligibilityEventSummaryData } from '@clux-models';
import { QueryEntity } from '@datorama/akita';
import { EligibilityEvent, EventSummaryStateType } from '@models/profileConfiguration/model';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EligibilityEventOfferingQuery } from '../eligibility-event-offering';
import { EligibilityEventState, EligibilityEventStore } from './eligibility-event.store';
import { EligibilityEventMetaQuery } from '../eligibility-event-meta';

@Injectable({
  providedIn: 'root',
})
export class EligibilityEventQuery extends QueryEntity<EligibilityEventState> {
  public constructor(
    protected store: EligibilityEventStore,
    public eligibilityEventOfferingQuery: EligibilityEventOfferingQuery,
    public eligibilityEventMetaQuery: EligibilityEventMetaQuery,
  ) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<EligibilityEvent[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectAllByIdWhenLoaded(individualId: string): Observable<EligibilityEvent[]> {
    return this.selectAllWhenLoaded().pipe(
      map((eligibilityEvents) => eligibilityEvents.filter((ee) => ee.parentId === individualId)),
    );
  }

  public getEligibilityEventSummaryData(individualId: string): Observable<EligibilityEventSummaryData[]> {
    if (!individualId) {
      return of(null);
    }

    return combineLatest([
      this.selectAllByIdWhenLoaded(individualId),
      this.eligibilityEventMetaQuery.selectAllWhenLoaded(),
    ]).pipe(
      map(([ees, eeMetas]) =>
        ees.map((e, i) => {
          const meta = eeMetas.find((m) => m.id === e.id);
          return this.buildEligibilityEventSummaryDataFromEligibiltyEvent(
            e,
            meta && meta.eligibilityStartDate,
            meta && meta.eligibilityEndDate,
            e.electionDueDate,
            meta && meta.eventSummaryState || e.currentState as EventSummaryStateType,
          );
        }),
      ),
    );
  }

  public buildEligibilityEventSummaryDataFromEligibiltyEvent(
    event: EligibilityEvent,
    eligibilityStartDate: string,
    eligibilityEndDate: string,
    electionDueDate: string,
    eventSummaryState: EventSummaryStateType,
  ): EligibilityEventSummaryData {
    return {
      ...event,
      menu: false,
      eligibilityStartDate,
      eligibilityEndDate,
      electionDueDate,
      eventSummaryState,
    };
  }
}
