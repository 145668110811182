import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RaygunService } from './raygun.service';
import { RaygunErrorHandler } from './raygun.setup';

@NgModule({
  providers: [
    RaygunService,
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
  ],
})
export class RaygunModule {
  public constructor(@Optional() @SkipSelf() self: RaygunModule) {
    if (self) {
      throw new Error(
        'RaygunModule is already loaded. Import it in the AppModule only.');
    }
  }
}
