export enum BrandId {
  BASIC = 'BASIC',
  TASC = 'TASC',
  Unknown = 'Unknown',
}

export enum CoreService {
  Account = 'account',
  Card = 'card',
  Cognito = 'cognito',
  Communication = 'communication',
  Configuration = 'configuration',
  Core = 'core',
  Dashboard = 'dashboard',
  File = 'file',
  Lookup = 'lookup',
  Profile = 'profile',
  ProfileConfiguration = 'profileConfiguration',
  Report = 'report',
  Request = 'request',
  Security = 'security',
  Support = 'support',
}

export enum RememberUserAction {
  Forget = 'Forget',
  NoAction = 'NoAction',
  Remember = 'Remember',
}

export enum SessionStorage {
  Email = 'Email',
  FederatedError = 'FederatedError',
  Url = 'Url',
}

export enum HoldStatus {
  Manual,
  ManualAndSystem,
  System,
  SystemAndException,
  Exception,
  ExceptionAndManual,
  ExceptionAndSystem,
  All,
}

export enum ContentType {
  Jpg = 'image/jpeg',
  Pdf = 'application/pdf',
  Png = 'image/png',
}

export enum ContentDisposition {
  Inline = 'Inline',
  Attachment = 'Attachment',
}

/**
 * Specifies error codes that may be returned by AWS during the oAuth Federated login process
 * These values map to the error_description URL params returned during the SSO process
 */
export enum FederatedSignInError {
  NotAuthorizedException = 'Error in SAML response processing: PreSignUp failed with error UnknownUser. ', // When user is not found in UBA
}

export enum NetSuiteCommandType {
  StartToActive = 'StartToActive',
}

export enum NetSuiteState {
  Active = 'Active',
}

export enum ErrorType {
  IndividualNotUniqueEmail = 'IndividualNotUniqueEmail',
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// need this in lower case for type compatibility with third-party library
export enum SortOrderLowerCase {
  asc = 'asc',
  desc = 'desc',
}

export enum BasePlanType {
  Benefit = 'Benefit',
  Payment = 'Payment',
}

export enum EligibilityEventAction {
  Cancel = 'Cancel',
  Edit = 'Edit',
  View = 'View',
  DoNotOffer = 'Do not offer',
  Elect = 'Elect',
}

export enum PaymentAccountAction {
  Details = 'Details',
}

export enum ResponseStatusCodes {
  NothingChanged = 204,
  ReviewNeeded = 200,
  Error = 500,
}
