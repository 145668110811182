import { Injectable } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';

@Injectable()
export class TimeoutService {
  private idleSubscription = new Subscription();
  private timeoutSubscription = new Subscription();
  private idleMilliseconds: number = 0;
  private timeoutMilliseconds: number = 0;
  private idleTimer: Observable<number>;
  private timeoutTimer: Observable<number>;

  public constructor() {}

  public get hasSetIdleTimer(): boolean {
    return !!this.idleTimer;
  }
  public get hasSetTimeoutTimer(): boolean {
    return !!this.timeoutTimer;
  }

  /**
   * This sets the maximum length of time a user can interact with the application. When this time
   * is exceeded, the watchTimeout observable fires, which can be used to log the user out.
   * @param minutes The timeout time in minutes.
   */
  public setMaxTimeoutTime(minutes: number): void {
    this.timeoutMilliseconds = (1000 * 60 * minutes);
  }

  /**
   * This sets the maximum length of time a user can be idle. When this time
   * is exceeded, the watchIdle observable fires, which can be used to log the user out.
   * @param minutes The idle time in minutes.
   */
  public setMaxIdleTime(minutes: number): void {
    this.idleMilliseconds = (1000 * 60 * minutes);
  }

  public watchIdle(): Observable<void> {
    return this.idleTimer.pipe(
      first(),
      mapTo(null),
    );
  }

  public watchTimeout(): Observable<void> {
    return this.timeoutTimer.pipe(
      first(),
      mapTo(null),
    );
  }

  public startIdleTimer(): void {
    if (this.hasSetIdleTimer) {
      this.stopIdleTimer();
    }
    this.setIdleSubscription();
  }

  public startTimeoutTimer(): void {
    if (this.hasSetTimeoutTimer) {
      this.stopTimeoutTimer();
    }
    this.setTimeoutSubscription();
  }

  public stopIdleTimer(): void {
    this.idleSubscription.unsubscribe();
    this.idleTimer = null;
  }

  public stopTimeoutTimer(): void {
    this.timeoutSubscription.unsubscribe();
    this.timeoutTimer = null;
  }

  public resetIdleTimer(): void {
    this.startIdleTimer();
  }

  private setIdleSubscription(): void {
    this.idleTimer = timer(this.idleMilliseconds);
    this.idleSubscription.add(this.idleTimer.subscribe());
  }

  private setTimeoutSubscription(): void {
    this.timeoutTimer = timer(this.timeoutMilliseconds);
    this.timeoutSubscription.add(this.timeoutTimer.subscribe());
  }
}
