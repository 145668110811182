import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EligibilityEvent, EligibilityEventMeta } from '@models/profileConfiguration/model';

export interface EventMetaState extends EligibilityEventMeta {
  id: string;
}

export interface EventMetaStoreState extends EntityState<EventMetaState, string>, ActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'EligibilityEventMeta' })
export class EligibilityEventMetaStore extends EntityStore<EventMetaStoreState> {
  public constructor() {
    super();
  }
}
