import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
  public constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  // tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUrl = req.url.replace(/\/?[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g, '/*');
    let timeoutValue = this.defaultTimeout;
    if (currentUrl) {
      if (currentUrl.includes('profile/*/benefitAccount/*/command/StartToEnrolled')) {
        timeoutValue = 60000;
      }
    }
    return next.handle(req).pipe(timeout(timeoutValue));
  }
}
