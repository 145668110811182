import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { OfferingPrice } from '@models/profileConfiguration/model';

export interface OfferingPriceStoreState extends EntityState<OfferingPrice, string>, MultiActiveState {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'OfferingPrice' })
export class OfferingPriceStore extends EntityStore<OfferingPriceStoreState> {
  public constructor() {
    super();
  }
}
