/**
 * It will list the all features which require access rights.
 */
export enum FeatureNames {
  OverviewBenefitPlanTile = 'overview-benefit-plan-tile',
  OverviewContributionTile = 'overview-contribution-tile',
  OverviewTransferBalance = 'overview-transfer-balance',
  PostManuallyGrowlAccess = 'post-manually-growl-access',

  FilesUploadDataFiles = 'files-upload-data-files',
  FilesDataFilesDownloadFile = 'files-data-files-download-file',
  FilesMyDocumentsUploadDocument = 'files-my-documents-upload-document',
  FilesMyDocumentsDeleteDocument = 'files-my-documents-delete-document',
  FilesMyDocumentsViewDocument = 'files-my-documents-view-document',

  ReportsEnrollmentGetReport = 'reports-enrollment-get-report',
  ReportsEnrollmentDownloadReport = 'reports-enrollment-download-report',
  ReportsFundingDownloadReport = 'reports-funding-download-report',
  ReportsPaymentDetailDownloadReport = 'reports-payment-detail-download-report',

  TransactionsBilling = 'transactions-billing',
  TransactionsInvoice = 'transactions-invoice',
  TransactionsPayment = 'transactions-payment',
  TransactionsCreditMemo = 'transactions-credit-memo',

  SettingsManageUsersUpdate = 'settings-manage-users-update',
  SettingsManageUsersSetupUserAccess = 'settings-manage-users-setup-user-access',
  SettingsManageUsersEditUser = 'settings-manage-users-edit-user',

  BenefitVerifyPostingDownloadPostingData = 'benefit-verify-posting-download-posting-data',
  BenefitVerifyPostingUploadPostingData = 'benefit-verify-posting-upload-posting-data',
  BenefitVerifyPostingEditEmployerContribution = 'benefit-verify-posting-edit-employer-contribution',
  BenefitVerifyPostingEditEmployeeContribution = 'benefit-verify-posting-edit-employee-contribution',
  BenefitVerifyPostingViewEmployerContribution = 'benefit-verify-posting-view-employer-contribution',
  BenefitVerifyPostingViewEmployeeContribution = 'benefit-verify-posting-view-employee-contribution',
  BenefitPostManuallyPostPlan = 'benefit-post-manually-post-plan',

  EmployeesManageAddFeature = 'employees-manage-add-feature',
  EmployeesManageViewEmployeeSnowmanMenu = 'employees-manage-view-employee-snowman-menu',
  EmployeesManageEditPersonalInfo = 'employees-manage-edit-personal-info',
  EmployeesManageEditEmployeeProfile = 'employees-manage-edit-employee-profile',
  // snowman option
  EmployeesManageSnowManEditEmployee = 'Edit employee profile',
  EmployeesManageSnowManPersonalInformation = 'Personal information',
  EmployeesManageSnowManEmploymentInformation = 'Employment information',
  EmployeesManageSnowManEmployeeAccountsSummary = 'Accounts summary',
  EmployeesManageSnowManEmployeeTascCard = 'TASC Card',
  EmployeesManageSnowManEmployeeContributeToBA = 'Contribute to benefit account',
  EmployeesManageSnowManEmployeeEvent = 'Add Event',
  EmployeesManageSnowManEmployeeEligibilityEvent = 'Eligibility events',
  EmployeesManageSnowManEmployeeLetters = 'Letters',
  EmployeeDependentRequestNewTascCard =  'request-new-card',
  EmployeesManageSnowManEmployeeDependents = 'Dependent information',
  EmployeeDependentAdd = 'dependent-add',
  EmployeeDependentDelete = 'dependent-delete',
  EmployeesManageSnowManEmployeeTransactions = 'Transactions',
  EmployeesManageSnowManEmployeeInvestments = 'Investments',
  EmployeesManageSnowManEnrollEmployee = 'Enroll employee',
  EmployeeHeaderMenu = 'employee-header-menu',
  EmployeeViewVaccine = 'employee-view-vaccine',

  OverviewHeaderMenu = 'overview-header-menu',
  BenefitPlansHeaderMenu = 'benefit-plans-header-menu',
  FilesHeaderMenu = 'files-header-menu',
  TrnsactionsHeaderMenu = 'trnsactions-header-menu',
  ReportsHeaderMenu = 'reports-header-menu',

  /***
   * Page features *
   ***/
  // Employee Accounts Summary
  EmployeeAccountsSummaryEdit = 'allow-edit-employee-account-summary',
  EmployeeAccountsSummaryEditDisbursableDate = 'employee-account-summary-edit-disbursable-date',
  EmployeeAccountsSummaryManageAssociations = 'manage-dependent-association',
}

export interface MenuAccessMap {
  [name: string]: string[];
}

export enum HeaderMenuNames {
  Employee = 'menuEmployee',
  Overview = 'menuOverview',
  Funding = 'menuFunding',
  Plans = 'menuPlans',
  Files = 'menuFiles',
  Billing = 'menuBilling',
  Reports = 'menuReports',
}
export enum AccessRoles {
  Administrator = 'CU1',
  BenefitPlanManager = 'CU2',
  EmployeeManager = 'CU3',
  FileSpecialist = 'CU4',
  FinancialManager = 'CU5',
  BillingManager = 'CU6',
  ReportsManager = 'CU7',
  Reviewer = 'CU8',
}

export interface UserRoleAccessMap {
  [name: string]: AccessRoles[];
}

export interface LandingPageForRoles {
  [role: string]: string;
}

/**
 * Header menu configuration
 */
export const headerMenuMap: MenuAccessMap = {};
headerMenuMap[HeaderMenuNames.Employee] = [
  'employees/manage/add',
  'employees/manage',
  'employees/enroll',
  'employees/approve',
  'employees/vaccine',
];
headerMenuMap[HeaderMenuNames.Plans] = [
  'plan/plans',
  'plan/verify',
  'plan/summary',
  'plan/post-manually',
  'plan/terminate',
];
headerMenuMap[HeaderMenuNames.Overview] = [
  'home',
];
headerMenuMap[HeaderMenuNames.Funding] = [
  'transactions/funding',
  'transactions/fund-from-cash-account',
  'transactions/remittance-account',
];
headerMenuMap[HeaderMenuNames.Files] = [
  'files/data-files',
  'files/documents',
  'files/templates',
];
headerMenuMap[HeaderMenuNames.Billing] = [
  'transactions/billing',
  'transactions/credit-memo',
  'transactions/invoices',
  'transactions/payment-detail',
];
headerMenuMap[HeaderMenuNames.Reports] = [
  'reports/enrollment',
  'reports/funding',
  'reports/month-end-statements',
  'reports/participant-balance-summary',
  'reports/rate-table-summary',
  'reports/point-of-disbursement',
  'reports/payment-detail',
];
/**
 * User Role type and it's access type
 */
export const userRoleAccessMap: UserRoleAccessMap = {};
// Overview (home)
userRoleAccessMap[FeatureNames.OverviewBenefitPlanTile] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.ReportsManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.OverviewContributionTile] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.ReportsManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.OverviewTransferBalance] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.ReportsManager,
];

userRoleAccessMap[FeatureNames.PostManuallyGrowlAccess] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];

// Benefit Plan
userRoleAccessMap[FeatureNames.BenefitVerifyPostingDownloadPostingData] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.FinancialManager,
  AccessRoles.ReportsManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.BenefitVerifyPostingUploadPostingData] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];
userRoleAccessMap[FeatureNames.BenefitVerifyPostingEditEmployerContribution] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];
userRoleAccessMap[FeatureNames.BenefitVerifyPostingViewEmployerContribution] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.FinancialManager,
  AccessRoles.ReportsManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.BenefitVerifyPostingEditEmployeeContribution] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];
userRoleAccessMap[FeatureNames.BenefitVerifyPostingViewEmployeeContribution] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.FinancialManager,
  AccessRoles.ReportsManager,
  AccessRoles.Reviewer,
];

// Files
userRoleAccessMap[FeatureNames.FilesUploadDataFiles] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.FileSpecialist,
];
userRoleAccessMap[FeatureNames.FilesMyDocumentsUploadDocument] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.FilesMyDocumentsDeleteDocument] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.FilesMyDocumentsViewDocument] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];

// Transactions
userRoleAccessMap[FeatureNames.TransactionsBilling] = [
  AccessRoles.Administrator,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.TransactionsInvoice] = [
  AccessRoles.Administrator,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.TransactionsPayment] = [
  AccessRoles.Administrator,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.TransactionsCreditMemo] = [
  AccessRoles.Administrator,
  AccessRoles.FinancialManager,
  AccessRoles.BillingManager,
  AccessRoles.Reviewer,
];

// Reports
userRoleAccessMap[FeatureNames.ReportsEnrollmentGetReport] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.FinancialManager,
  AccessRoles.ReportsManager,
];

// Settings
userRoleAccessMap[FeatureNames.SettingsManageUsersSetupUserAccess] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];
userRoleAccessMap[FeatureNames.SettingsManageUsersEditUser] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];

// Employees
userRoleAccessMap[FeatureNames.EmployeeHeaderMenu] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];
userRoleAccessMap[FeatureNames.EmployeesManageAddFeature] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];

userRoleAccessMap[FeatureNames.EmployeesManageEditPersonalInfo] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.EmployeesManageEditEmployeeProfile] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEditEmployee] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManPersonalInformation] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmploymentInformation] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeAccountsSummary] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeTascCard] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeContributeToBA] = [
  AccessRoles.Administrator,
];
userRoleAccessMap[FeatureNames.EmployeeDependentRequestNewTascCard] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeDependents] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeeDependentAdd] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.EmployeeDependentDelete] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeTransactions] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeInvestments] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
  AccessRoles.Reviewer,
];
userRoleAccessMap[FeatureNames.EmployeesManageSnowManEnrollEmployee] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];

userRoleAccessMap[FeatureNames.EmployeeAccountsSummaryEdit] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];

userRoleAccessMap[FeatureNames.EmployeeAccountsSummaryEditDisbursableDate] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
];

userRoleAccessMap[FeatureNames.EmployeeAccountsSummaryManageAssociations] = [
  AccessRoles.Administrator,
  AccessRoles.BenefitPlanManager,
  AccessRoles.EmployeeManager,
];

userRoleAccessMap[FeatureNames.EmployeeViewVaccine] = [
  AccessRoles.Administrator,
];

userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeEvent] = Object.values(AccessRoles);

userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeEligibilityEvent] = Object.values(AccessRoles);

userRoleAccessMap[FeatureNames.EmployeesManageSnowManEmployeeLetters] = Object.values(AccessRoles);

/**
 * Define the landing page for each different roles to redirect after the login.
 */
export const landingPageForRoles: LandingPageForRoles = {};
landingPageForRoles[AccessRoles.Administrator] = '/home';
landingPageForRoles[AccessRoles.BenefitPlanManager] = '/home';
landingPageForRoles[AccessRoles.EmployeeManager] = '/home';
landingPageForRoles[AccessRoles.FileSpecialist] = '/files/data-files';
landingPageForRoles[AccessRoles.FinancialManager] = '/home';
landingPageForRoles[AccessRoles.BillingManager] = '/home';
landingPageForRoles[AccessRoles.ReportsManager] = '/home';
landingPageForRoles[AccessRoles.Reviewer] = '/home';
