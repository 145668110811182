import { Injectable } from '@angular/core';
import { EligibilityEventSummaryData } from '@clux-models';
import { QueryEntity } from '@datorama/akita';
import { EligibilityEvent, EligibilityEventMeta } from '@models/profileConfiguration/model';
import { max, min } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {  EligibilityEventMetaStore, EventMetaState, EventMetaStoreState } from './eligibility-event-meta.store';

@Injectable({
  providedIn: 'root',
})
export class EligibilityEventMetaQuery extends QueryEntity<EventMetaStoreState> {
  public constructor(
    protected store: EligibilityEventMetaStore,
  ) {
    super(store);
  }

  public selectAllWhenLoaded(): Observable<EventMetaState[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll()),
      );
  }

  public selectByEvent(eventId: string): Observable<EligibilityEventMeta> {
    return this.selectEntity(eventId)
      .pipe(
        map((eventMeta) => {
          const meta = {
            ...eventMeta,
          };

          delete meta.id;

          return meta;
        }),
      );
  }
}
