import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EmitterMessage } from '../models/clux/model';

@Injectable({ providedIn: 'root' })
export class EmitterService {
  public closeGrowl = new Subject<boolean>();
  public extraFooterMargin: Subject<boolean> = new Subject<boolean>();
  private _emitters: { [channel: string]: EventEmitter<EmitterMessage | boolean> } = {};
  public on(channel: string): EventEmitter<EmitterMessage | boolean> {
    if (!this._emitters[channel]) {
      this._emitters[channel] = new EventEmitter();
    }
    return this._emitters[channel];
  }

  public notifyCloseGrowl(action: boolean): void {
    this.closeGrowl.next(action);
  }

  public setFooterMargin(state: boolean): void {
    this.extraFooterMargin.next(state);
  }

  public getFooterMargin(): Observable<boolean> {
    return this.extraFooterMargin.asObservable();
  }

  public getEmitterMessage(msg: string, status: boolean | string, undoStatus?: boolean, link?: string, postManuallyLink?: boolean): EmitterMessage {
    return {
      message: msg,
      type: status,
      undo: undoStatus,
      link,
      postManuallyLink,
    };
  }
}
