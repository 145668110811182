import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { CommonConstants } from '../constants/common.constant';
import { ServiceFactory } from './service.factory';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public constructor(private serviceFactory: ServiceFactory) {
  }

  public getAlertsData(requestPayload: Model.SearchCriteria, searchParams: Model.SearchParams, profileId: string): Observable<Model.SearchResults<Model.AlertObject[]>> {
    return this.serviceFactory.search<Model.AlertObject[]>(
      CommonConstants.getPath.communication.serviceKey,
      CommonConstants.getPath.communication.alertsURI,
      requestPayload,
      { profileId },
      searchParams,
      true,
    );
  }

  public getAlertsDetails(serviceKey: string, basePath: string): Observable<Model.AlertObject> {
    return this.serviceFactory.query(serviceKey, basePath);
  }

  public updateReadMessage(serviceKey: string, basePath: string, requestPayload: Model.MarkAsRead): Observable<string> {
    return this.serviceFactory.queryPut(serviceKey, basePath, requestPayload);
  }
}
