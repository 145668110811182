import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Card, CardCommandType, MatchType, SearchCriteria } from '@models/card/model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreService } from '../models/clux/enum';
import { CommandFactory } from '../utils/command-factory';
import { Uri } from '../utils/uri';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  public constructor(
    private commandFactory: CommandFactory,
    private http: HttpClient,
  ) {}

  /**
   * Return the cards belonging to the specified individual or dependents.
   * @param personIds The IDs of the individual or dependents
   */
  public getCards(personIds: string[]): Observable<Card[]> {
    if (!personIds || !personIds.length) {
      return of([]);
    }

    const criteria: SearchCriteria = [
      {
        key: 'parentId',
        value: personIds.join('|'),
        matchType: MatchType.IN,
      },
    ];

    const uri = new Uri('/profile/*/card/search', CoreService.Card);
    return this.http.post<Card[]>(uri.toString(), criteria);
  }

  public updateCard(card: Card, commandName: CardCommandType): Observable<Card> {
    const command = this.commandFactory.createCommand(card, commandName);
    const uri = new Uri(`/profile/${card.individualId}/card/${card.id}/command/${command.type}`, CoreService.Card);
    return this.http.put<void>(uri.toString(), command)
      .pipe(
        map(() => command.data),
      );
  }
}
