import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Model } from 'src/app/shared/models/clux/model-legacy';
import { CurrentUserQuery } from 'src/app/state/current-user';

import { environment } from '../../../../environments/environment';
import { CommonConstants } from '../../constants/common.constant';
import { BrandService } from '../../services/brand.service';

@Component({
  selector: 'app-net-suite-modal',
  templateUrl: './net-suite-modal.component.html',
  styleUrls: ['./net-suite-modal.component.scss'],
})
export class NetSuiteModalComponent implements OnInit {
  public externalRouteMessage: string;

  public constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Model.MatDialogModel,
    private modalRef: MatDialogRef<NetSuiteModalComponent>,
    private currentUserQuery: CurrentUserQuery,
  ) { }

  public ngOnInit(): void {
    this.externalRouteMessage = CommonConstants.errorCodes.externalRouteMessage;
  }

  public onContinue(): void {
    this.modalRef.close();
    const idToken = this.currentUserQuery.getJwtToken();
    window.open(`https://${environment.netSuite.cluxHost}/${CommonConstants.appRoutes.netSuiteSSORedirect}?ns=${this.data.cameFrom}&t=${idToken}`, this.data.cameFrom);
  }

  public onCancel(): void {
    this.modalRef.close();
  }
}
